<p-dialog [(visible)]="visibility"
    [style]="{
        'min-width': '90%',
        'max-width': '90%',
        'width': '100%',
        'max-height': '100%',
        'height': 'fit-content',
    }"
    (keydown.enter)="onOkDialog()" (keydown.escape)="onCancelDialog()"
    [contentStyle]="{'background': recording ? '#ff0000' : '#ffffff'}"
    (onShow)="onShowDialog()" (onClose)="onCloseDialog()" (onHide)="onHideDialog()">
    <ng-template pTemplate="header">
        <span class="p-dialog-title"><i class="fa-solid fa-list-ol mr-2"></i> <span i18n>Model 3D</span></span>
    </ng-template>

    <div #wrapper class="relative w-full">
        <app-3d-render #renderer class="w-full h-full" (onFlyModeChange)="onFlyModeChange($event)">
        </app-3d-render>
        <div *ngIf="instructions" class="absolute left-0 top-0 w-4 opacity-90 bg-blue-300 flex flex-column p-3">
            <div class="flex grid p-2 m-0 font-mono font-medium">
                <div class="py-1 col-4" i18n>Strzałki lub WSAD</div><div class="py-1 col-8" i18n>ruch do przodu, tyłu, lewo, prawo</div>
                <div class="py-1 col-4" i18n>Q i E</div><div class="py-1 col-8" i18n>obrót w lewo lub prawo</div>
                <div class="py-1 col-4" i18n>Spacja</div><div class="py-1 col-8" i18n>ruch w górę lub skok</div>
                <div class="py-1 col-4">Control</div><div class="py-1 col-8" i18n>ruch w dół</div>
                <div class="py-1 col-4" i18n>Myszka</div><div class="py-1 col-8" i18n>kierunek ruchu</div>
                <div class="py-1 col-4" i18n>Kółko myszki</div><div class="py-1 col-8" i18n>pole widzenia</div>
                <div class="py-1 col-4">Shift</div><div class="py-1 col-8" i18n>przyspieszenie</div>
                <div class="py-1 col-4">?</div><div class="py-1 col-8" i18n>instrukcja</div>
                <div class="py-1 col-4">Escape</div><div class="py-1 col-8" i18n>wyjście</div>
                <div class="py-1 col-4">Enter</div><div class="py-1 col-8" i18n>wyjście z zachowaniem aktualnych parametrów</div>
                <div class="py-1 col-4">1</div><div class="py-1 col-8" i18n>linia trasy</div>
                <div class="py-1 col-4">2</div><div class="py-1 col-8" i18n>animacja trasy</div>
                <div class="py-1 col-4">3</div><div class="py-1 col-8" i18n>cienie</div>
                <div class="py-1 col-4">4</div><div class="py-1 col-8" i18n>trybuny</div>
                <div class="py-1 col-4">0</div><div class="py-1 col-8" i18n>pełny ekran</div>
                <div class="py-1 col-4">P</div><div class="py-1 col-8" i18n>nagrywanie start/stop</div>
                <div class="py-1 col-4">F</div><div class="py-1 col-8" i18n>przelot nad trasą start/stop</div>
                <div class="py-1 col-4">G</div><div class="py-1 col-8" i18n>tryb chodzenia lub latania</div>
                <div class="py-1 col-4" i18n>+ lub =</div><div class="py-1 col-8" i18n>zwiększ prędkość przelotu lub chodzenia</div>
                <div class="py-1 col-4">-</div><div class="py-1 col-8" i18n>zmniejsz prędkość przelotu lub chodzenia</div>
                <div class="py-1 col-4" i18n>&gt; lub .</div><div class="py-1 col-8" i18n>później w ciągu dnia</div>
                <div class="py-1 col-4" i18n>&lt; lub ,</div><div class="py-1 col-8" i18n>wcześniej w ciągu dnia</div>
            </div>
        </div>
        <img class="absolute top-0 right-0 w-2" src="assets/logo-transparent.png"/>
        <div #msgs class="absolute right-0 bottom-0 p-4 text-2xl w-full text-right text-white font-medium custom-outline-mask">
            {{ message }}
        </div>
    </div>

    <ng-template pTemplate="footer">
        <div class="flex justify-content-between align-items-center">
            <div class="flex flex-row justify-content-start align-items-center">
                <p-button icon="pi pi-question" (click)="onHelp()" label="Pomoc" i18n-label></p-button>
                <span i18n>Wciśnij ? aby zobaczyć instrukcję</span>
            </div>
            <p-button icon="pi pi-check" (click)="onOkDialog()" label="Zamknij" i18n-label></p-button>
        </div>
    </ng-template>
</p-dialog>
