
<input type="file" id="fileUpload" (change)="onFileSelected($event)">
<div id="status">
    {{ status }}
</div>
<div style="
    width: 1536px;
    height: 1536px;
    max-width: 1536px;
    min-width: 1536px;
    max-height: 1536px;
    min-height: 1536px;
">
    <parkour-canvas id="canvas" #canvas [cfg]="cfg" [width]="1536" [height]="1536">
    </parkour-canvas>
</div>
