import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { InputNumberModule } from 'primeng/inputnumber';
import { TooltipModule } from 'primeng/tooltip';
import { DetailComponent } from '../../detail.component';
import { DialogModule } from 'primeng/dialog'
import { ParkourDialog } from '../../../dialog.interface'
import { CheckboxModule } from 'primeng/checkbox'
import { PathObject } from '../../parkour-objects/path-object'
import { ParkourBannerObject } from '../../parkour-objects/banner'
import { UnitPipe } from '../../../pipes'
import { ConversionService } from '../../../services/conversion.service'

type DesignStats = {
    // global
    objectsCount: string,
    // round stats
    length: string[],
    correctedLength: string[],
    effortsCount: string[],
    obstaclesCount: string[],
    leftTurnsCount: string[],
    rightTurnsCount: string[],
    obstacles: any[],
    combinations: string[],
}

@Component({
    selector: 'app-parkour-design-stats',
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ButtonModule,
        InputNumberModule,
        TooltipModule,
        DialogModule,
        CheckboxModule,
        UnitPipe,
    ],
    templateUrl: './parkour-design-stats.component.html',
    styleUrl: './parkour-design-stats.component.scss'
})
export class ParkourDesignStatsComponent implements ParkourDialog {
    @Input({ required: true }) view!: DetailComponent
    @Output() onOk = new EventEmitter<void>()
    @Output() onCancel = new EventEmitter<void>()

    designStats?: DesignStats

    visibility: boolean = false
    errorMsg: string = ''

    constructor(public conversionService: ConversionService) {
    }
    
    update() {
        if (!this.view.canvas) {
            return
        }
        const path = this.view.canvas.obstaclePath
        const round1Turns = path.calculateTurnsCount(1)
        const round2Turns = path.calculateTurnsCount(2)
        this.designStats = {
            // global stats
            objectsCount: '0',

            length: [
                path.firstRound ? '' + path.firstRound.length : '',
                path.secondRound && path.secondRound.length > 0 ? '' + path.secondRound.length : '',
            ],
            correctedLength: [
                path.firstRound ? '' + path.firstRound.correctedLength : '',
                path.secondRound && path.secondRound.correctedLength > 0 ? '' + path.secondRound.correctedLength : '',
            ],
            effortsCount: [
                path.firstRound ? path.firstRound.efforts.toFixed(0) : '',
                path.secondRound ? path.secondRound.efforts.toFixed(0) : '',
            ],
            obstaclesCount: [
                path.firstRound ? path.firstRound.obstacles.toFixed(0) : '',
                path.secondRound ? path.secondRound.obstacles.toFixed(0) : '',
            ],
            leftTurnsCount: [
                round1Turns[0].toFixed(0),
                round2Turns[0].toFixed(0),
            ],
            rightTurnsCount: [
                round1Turns[1].toFixed(0),
                round2Turns[1].toFixed(0),
            ],
            combinations: [
                path.firstRound ? path.firstRound.combinations.length.toFixed(0) : '',
                path.secondRound ? path.secondRound.combinations.length.toFixed(0) : '',
            ],
            obstacles: []
        }

        let objectsCount = 0
        for (const o of this.view.canvas.objects) {
            if (!(o instanceof PathObject) && !(o instanceof ParkourBannerObject)) {
                objectsCount += 1
            }
        }
        this.designStats.objectsCount = objectsCount.toFixed(0)

        const obstacles1: any = {}
        const obstacles2: any = {}
        let obstacles = obstacles1
        for (const n of path.route) {
            const kind = n.obstacle.kind.kind
            if (obstacles[kind] === undefined) {
                obstacles[kind] = 1
            } else {
                obstacles[kind] += 1
            }
            if (['finish', 'finish-start'].includes(n.obstacle.kind.kind)) {
                obstacles = obstacles2
            }
        }
        // build list of obstacle types in proper order that appears in objectFactory.obstacleTemplates
        for (const ot of this.view.objectFactory.getObstacleTemplates()) {
            if (obstacles1[ot.kind] === undefined && obstacles2[ot.kind] === undefined ||
                ot.kind === 'vertical-vector' && ot.bars === 1 && ot.decorations && ot.decorations['water']) {
                continue
            }
            this.designStats.obstacles.push({
                kind: ot.name,
                svg: ot.svg,
                counts: [
                    obstacles1[ot.kind] === undefined ? '' : obstacles1[ot.kind].toFixed(0),
                    obstacles2[ot.kind] === undefined ? '' : obstacles2[ot.kind].toFixed(0),
                ]
            })
        }
    }

    onCloseDialog() {
    }

    onShowDialog() {
        this.update()
    }

    onHideDialog() {
    }

    onOkDialog() {
        this.visibility = false
        this.onOk.emit()
    }

    onCancelDialog() {
        this.visibility = false
        this.onCancel.emit()
    }

    open() {
        this.update()
        this.visibility = true
    }

    closeOk() {
        this.onOkDialog()
    }

    closeCancel() {
        this.onCancelDialog()
    }
}
