import paper from 'paper'
import { DesignDrawnObstacleDecorations, DesignDrawnObstacleObject, Direction, LimitRange } from "../../design.schema"
import { LimitSet } from "../../services/limits.service"
import { ObstacleMaterials } from "../detail.materials"
import { ArcArrows, Arrows, StraightArrows } from "./arrows"
import { Obstacle } from "./obstacle"
import { ParkourObjectConfig, ParkourObjectDecorations } from "./parkour-object"
import { PathObject } from "./path-object"
import { findIndexOfMax, findIndexOfMin, toPrecision } from '../../utils'
import { OutlinedText } from '../detail.paper.extensions'
import { Unit } from '../../pipes'
import { LayerId } from '../../parkour-canvas/parkour-canvas'

enum PosLineDir {
    LEFT = 0,
    RIGHT,
    UP,
    DOWN
}

export class ObstacleWithBars extends Obstacle {
    drawing: paper.Group = new paper.Group()
    obstacle: paper.Group = new paper.Group()
    arrows?: Arrows
    arrowsColor: paper.Color = new paper.Color(this.cfg.params.colors.obstacleArrows || '#ff8080')
    grass: paper.Group = new paper.Group()
    water: paper.Group = new paper.Group()
    singlePond: paper.Group = new paper.Group()
    doublePond: paper.Group = new paper.Group()
    drawingSize: paper.Size | undefined
    decorations: DesignDrawnObstacleDecorations = new DesignDrawnObstacleDecorations()
    pondLocation: boolean | null
    protected readonly selRadiusZoom: number = 1.25
    corner: boolean
    entryAngle: number = 0
    exitAngle: number = 0

    // obstacle dimensions used to calculate distances (not drawing size)
    private _obstacleHeight: number = 0
    barWidth: number
    bars: number
    stripes: number    
    defaultObstacleWidth: number
    defaultObstacleLength: number
    defaultObstacleHeight: number
    widthLimits: LimitRange
    lengthLimits: LimitRange
    heightLimits: LimitRange
    materials?: ObstacleMaterials
    backupMaterials?: ObstacleMaterials
    layoutLines?: paper.Group
    private _layoutLinesVisibility: boolean = false
    protected dimensionsText: OutlinedText

    set layoutLinesVisibility(visibility: boolean) {
        this._layoutLinesVisibility = visibility
        if (visibility) {
            this.drawLayoutLines()
        } else {
            this.layoutLines?.removeChildren()
        }
    }

    get layoutLinesVisibility(): boolean {
        return this._layoutLinesVisibility
    }

    set obstacleHeight(w: number) {
        this._obstacleHeight = w
    }

    get obstacleHeight(): number {
        return this._obstacleHeight
    }

    set obstacleHeightInUnits(w: number) {
        this.obstacleHeight = this.config.conversion.convert(w, {
            from: this.cfg.params.sizeUnit,
            to: Unit.CM,
            rules: this.config.conversion.fullPrecisionNoRounding
        }) || 0
    }

    get obstacleHeightInUnits(): number {
        return this.config.conversion.convert(this.obstacleHeight, {
            from: Unit.CM,
            to: this.cfg.params.sizeUnit,
            rules: this.config.conversion.rulesForObstacleHeight
        }) || 0
    }

    set obstacleWidthInUnits(w: number) {
        this.obstacleWidth = this.config.conversion.convert(w, {
            from: this.cfg.params.sizeUnit,
            to: Unit.CM,
            rules: this.config.conversion.fullPrecisionNoRounding
        }) || 0
    }

    get obstacleWidthInUnits(): number {
        return this.config.conversion.convert(this.obstacleWidth, {
            from: Unit.CM,
            to: this.cfg.params.sizeUnit,
            rules: this.config.conversion.rulesForObstacleWidth
        }) || 0
    }

    set obstacleLengthInUnits(w: number) {
        this.obstacleLength = this.config.conversion.convert(w, {
            from: this.cfg.params.sizeUnit,
            to: Unit.CM,
            rules: this.config.conversion.fullPrecisionNoRounding
        }) || 0
    }

    get obstacleLengthInUnits(): number {
        return this.config.conversion.convert(this.obstacleLength, {
            from: Unit.CM,
            to: this.cfg.params.sizeUnit,
            rules: this.config.conversion.rulesForObstacleLength
        }) || 0
    }

    isDitch(): boolean {
        return this.kind.kind === 'ditch'
    }

    isWall(): boolean {
        return this.kind.kind === 'wall'
    }

    isObstacleWithBars() {
        return true
    }

    set obstacleLength(w: number) {
        this.objectSize.width = w
        this.drawObject(this.getPosition(), this.angle)
    }

    get obstacleLength(): number {
        return this.objectSize.width
    }

    set obstacleWidth(w: number) {
        this.objectSize.height = w
        this.drawObject(this.getPosition(), this.angle)
    }

    get obstacleWidth(): number {
        return this.objectSize.height
    }

    isWaterDitch(): boolean {
        return this.isDitch() && this.decorations.water
    }

    isLiverpool(): boolean {
        return this.kind.kind === 'vertical-vector' && this.decorations.water
    }

    constructor(protected config: ParkourObjectConfig) {
        super(config)
        const object = config.object
        this.bars = this.kind.bars || 0
        this.stripes = this.kind.stripes || this.cfg.params.barSegments
        let obstacleWidth = this.objectSize.height
        let obstacleHeight = this.cfg.params.obstacleHeight
        let obstacleLength = this.cfg.params.obstacleLength

        const limits = this.cfg.currentLimits
        this.barWidth = 10
        switch (this.bars) {
            case 0:
                if (this.isDitch()) {
                    obstacleWidth = this.cfg.params.ditchWidth
                    obstacleHeight = 0
                    this.widthLimits = limits.width?.ditch || { min: 0, max: 100 }
                } else if (this.isWall()) {
                    obstacleWidth = this.cfg.params.wallWidth
                    this.widthLimits = limits.width?.wall || { min: 0, max: 50 }
                } else {
                    obstacleWidth = 0
                    this.widthLimits = { min: 0, max: 0 }
                }
                break
            case 1:
                this.widthLimits = this.getVerticalLimits(limits)
                obstacleWidth = this.widthLimits.default || this.barWidth
                break
            case 2:
                obstacleWidth = this.cfg.params.oxerWidth
                this.widthLimits = limits.width!.oxer
                break
            case 3:
                obstacleWidth = this.cfg.params.tripleBarWidth
                this.widthLimits = limits.width!.tripleBarre
                break
            default:
                obstacleWidth = 0
                this.widthLimits = { min: 0, max: 0 }
                break
        }
        this.lengthLimits = limits.length || { min: 10, max: 500 }
        this.heightLimits = limits.height || { min: 50, max: 250 }
        this.defaultObstacleLength = obstacleLength
        this.defaultObstacleWidth = obstacleWidth
        this.defaultObstacleHeight = obstacleHeight

        this.obstacleHeight = object.height || this.defaultObstacleHeight
        let l = object.length || this.defaultObstacleLength
        let w = object.width || this.defaultObstacleWidth
        this.objectSize.set(l, w)

        if (object.materials) {
            this.materials = new ObstacleMaterials(this.bars, this.cfg.params.colors.materials, this.cfg.params.colorfulMaterials, object.materials)
            this.allGroup.addChild(this.materials.group)
        }

        this.dimensionsText = new OutlinedText({
            content: this.getHeightSpreadText() || '',
            strokeColor: new paper.Color(this.cfg.params.colors.dimensions),
            strokeWidth: 3,
            fontSize: this.cfg.getFontSize(),
            position: this.initialPos
        })
        this.allGroup.addChild(this.dimensionsText)
        this.dimensionsText.visible = this.cfg.params.showDimensions

        if (object.decorations) {
            this.decorations = Object.assign(object.decorations)
        }
        if (this.isLiverpool()) {
            this.widthLimits = this.getLiverpoolLimits()
            this.defaultObstacleWidth = this.cfg.params.liverPoolWidth
            this.objectSize.height = object.width || this.defaultObstacleWidth
        }
        this.corner = this.kind.kind.startsWith('corner')
        this.allGroup.addChild(this.drawing)
        this.drawing.addChild(this.obstacle)
        this.drawing.addChild(this.grass)
        this.drawing.addChild(this.water)
        this.drawing.addChild(this.singlePond)
        if (this.decorations.pondLeft) {
            this.pondLocation = true
        } else if (this.decorations.pondRight) {
            this.pondLocation = false
        } else {
            this.pondLocation = null
        }
        this.drawing.addChild(this.doublePond)
        this.drawObject(this.initialPos, 0)
        this.setArrowDirection(Direction.none)
        this.levelItem = this.drawing

        this.layoutLines = new paper.Group()
        this.canvas.getLayer(LayerId.FRONT).addChild(this.layoutLines)

        if (this.canvas.layoutLinesVisibility) {
            this.layoutLinesVisibility = true
        }
    }

    reset() {
        super.reset()
        this.drawLayoutLines()
    }

    showDimensions() {
        this.dimensionsText.visible = true
    }

    hideDimensions() {
        this.dimensionsText.visible = false
    }

    protected getVerticalLimits(limits?: LimitSet): LimitRange {
        if (!limits) {
            limits = this.cfg.currentLimits
        }
        return limits?.width?.vertical || { min: this.barWidth, max: 50, default: this.barWidth }
    }

    fixSizeToLimits(height: boolean, width: boolean, length: boolean) {
        if (height) {
            if (this.heightLimits.max !== undefined && this.obstacleHeight > this.heightLimits.max) {
                this.obstacleHeight = this.heightLimits.max
            }
            if (this.heightLimits.min !== undefined && this.obstacleHeight < this.heightLimits.min) {
                this.obstacleHeight = this.heightLimits.min
            }
        }
        if (width) {
            if (this.widthLimits.max !== undefined && this.obstacleWidth > this.widthLimits.max) {
                this.obstacleWidth = this.widthLimits.max
            }
            if (this.widthLimits.min !== undefined && this.obstacleWidth < this.widthLimits.min) {
                this.obstacleWidth = this.widthLimits.min
            }
        }
        if (length) {
            if (this.lengthLimits.max !== undefined && this.obstacleLength > this.lengthLimits.max) {
                this.obstacleLength = this.lengthLimits.max
            }
            if (this.lengthLimits.min !== undefined && this.obstacleLength < this.lengthLimits.min) {
                this.obstacleLength = this.lengthLimits.min
            }
        }
    }

    protected getLiverpoolLimits(limits?: LimitSet): LimitRange {
        if (!limits) {
            limits = this.cfg.currentLimits
        }
        return limits?.width?.liverpool || { min: 50, max: 200, default: 100 }
    }

    addMaterials(drawLabels: boolean) {
        if (this.bars > 0) {
            if (!this.materials) {
                this.backupMaterials = undefined
                this.materials = new ObstacleMaterials(this.bars, this.cfg.params.colors.materials, this.cfg.params.colorfulMaterials)
                this.allGroup.addChild(this.materials.group)
            } else {
                this.backupMaterials = this.materials.clone()
                this.backupMaterials.visible = this.materials.visible // clone does not clone visibility
            }
            this.drawMaterials(drawLabels)
        }
    }

    drawMaterials(drawLabels: boolean) {
        if (this.materials) {
            this.materials.draw(drawLabels)
            this.setMaterialsPosition()
        }
        this.setNamePosition()
    }

    restoreMaterials(drawLabels: boolean) {
        this.materials?.destroy()
        this.materials = this.backupMaterials
        this.backupMaterials = undefined
        this.drawMaterials(drawLabels)
        if (this.materials) {
            this.allGroup.addChild(this.materials.group)
        }
    }

    removeMaterials() {
        this.materials?.destroy()
        this.materials = undefined
    }

    getHeightSpreadText(): string {
        let t = ''
        if (this.obstacleHeight > 0) {
            t += this.config.conversion.transform(this.obstacleHeight, {
                from: Unit.CM,
                to: this.cfg.params.sizeUnit,
                rules: this.config.conversion.rulesForObstacleHeight,
                skipSuffix: true,
            })
        }
        if (this.materials?.containsRaisedBar() && this.materials.raisedHeight > 0) {
            t += t ? '/' : ''
            t += this.config.conversion.transform(
                this.obstacleHeight + this.materials.raisedHeight, {
                    from: Unit.CM,
                    to: this.cfg.params.sizeUnit,
                    rules: this.config.conversion.rulesForObstacleHeight,
                    skipSuffix: true,
                }
            )
        }
        if (this.bars > 1) {
            t += t ? '/' : ''
            t += this.config.conversion.transform(this.obstacleWidth, {
                from: Unit.CM,
                to: this.cfg.params.sizeUnit,
                rules: this.config.conversion.rulesForObstacleWidth,
                skipSuffix: true,
            })
        }
        return t
    }

    setNamePosition() {
        super.setNamePosition()
        this.dimensionsText.content = this.getHeightSpreadText()
        if (this.dimensionsText.content) {
            this.dimensionsText.setRotation(0)
            const pos = this.getPosition()
            const dy = Math.max(this.objectSize.width, this.objectSize.height)
            const height = this.nameText.content ? this.nameText.bounds.height / 2 + this.dimensionsText.bounds.height / 2 + 10 :0
            this.dimensionsText.position = new paper.Point(pos.x, this.getPosition().y - dy * 0.7 - height)

            const x = this.calculateNameXPosition(this.dimensionsText.bounds)
            if (x) {
                this.dimensionsText.position.x = x
            }
        }
    }

    setMaterialsPosition() {
        if (this.materials) {
            const w = new paper.Point(this.getExternalSize()).length + this.materials.size.width
            this.materials.position = this.getPosition().add([w / 2, 0])

            const mat = this.materials.group.bounds
            const gap = 25
            for (let l of this.labelsPerDirection) {
                const label = l.bounds
                if (mat.left <= label.right && mat.right >= label.left &&
                    mat.top <= label.bottom && mat.bottom >= label.top) {
                    // materials overlap with label, move it to the right
                    this.materials.group.position.x = l.bounds.right + mat.width / 2 + gap
                }
            }
        }
    }

    drawExtraElements(): void {
        super.drawExtraElements()
        this.drawMaterials(true)
    }

    drawObject(initialPos: paper.Point, angle: number) {
        this.obstacle.removeChildren()
        this.grass.removeChildren()
        this.water.removeChildren()
        this.singlePond.removeChildren()
        this.doublePond.removeChildren()
        if (!this.objectSize) {
            this.objectReady(false)
            return
        }

        const size = this.objectSize
        if (!this.corner) {
            let pos = initialPos
            let gap = this.bars > 1 ? (size.height - this.barWidth * this.bars) / (this.bars - 1) : 0
            if (gap < 60) {
                gap = 60
            }
            for (let i = 1; i <= this.bars; i++) {
                this._drawBar(pos, this.barWidth, size.width)
                pos = pos.add([0, gap])
            }
        } else {
            if (this.bars === 3 && size.height < 100) {
                size.height = 100
            } else if (this.bars === 2 && size.height < 50) {
                size.height = 50
            }
            let angle = -Math.asin(size.height / (2 * size.width)) * 180 / Math.PI
            const angleSpan = Math.abs(2 * angle)
            this.entryAngle = angle
            this.exitAngle = angle + angleSpan
            let angleStep = this.bars > 1 ? angleSpan / (this.bars - 1) : angleSpan
            for (let i = 1; i <= this.bars; i++) {
                const bar = this._drawBar(initialPos, this.barWidth, size.width)
                bar.translate([0, size.height / 2 - bar.internalBounds.height / 2])
                const cornerEnd = bar.position.add([size.width / 2, 0])
                bar.rotate(angle, cornerEnd)
                angle += angleStep
            }
            const pos = initialPos.add([size.width / 2, 0])
            const radius = size.width / 1.35
            const width = 20
            this.arrows?.destroy()
            this.arrows = new ArcArrows(Direction.both, this.cfg, this.drawing, pos, width, 0, radius, angle, this.arrowsColor)
            this.arrows.draw()
        }

        if (this.isDitch()) {
            this._drawDitch(initialPos, size.width, size.height)
        } else if (this.isWall()) {
            this._drawWall(initialPos, size.width, size.height)
        }

        const dh = (this.bars === 1 && this.decorations.water ? this.obstacleWidth : this.obstacle.internalBounds.height)
        const dw = this.obstacle.internalBounds.width
        if (!this.corner) {
            const aw = 90
            const ah = dh + 2.5 * aw
            this.arrows?.destroy()
            this.arrows = new StraightArrows(Direction.both, this.cfg, this.drawing, initialPos.add([-aw / 2, ah / 2]), aw, ah, this.arrowsColor)
            this.arrows.draw()
        }
        this._drawGrass(initialPos, dw, dh)
        this._drawWater(initialPos, dw, dh)
        this._drawSinglePond(initialPos, dw, dh)
        this._drawDoublePond(initialPos, dw, dh)
        this.obstacle.position = initialPos

        if (this.isDitch()) {
            this.obstacle.sendToBack()
        }
        this.grass.visible = true
        this.water.visible = true
        this.singlePond.visible = true
        this.doublePond.visible = true
        let b = this.drawing.internalBounds
        let p = this.getPosition()
        this.drawingSize = new paper.Size(Math.max(b.right - p.x, p.x - b.left) * 2, Math.max(b.bottom - p.y, p.y - b.top) * 2)
        if (angle !== 0) {
            this.drawing.rotate(angle, initialPos)
        }
        this.objectReady(true)
        this.showDecorations()
    }

    setArrowDirection(direction: Direction, roundNo?: number): void {
        super.setArrowDirection(direction, roundNo)
        this.arrows?.setVisibleDirection(this.getArrowDirection())
    }

    rotate(angle: number) {
        super.rotate(angle)
        if (this.materials) {
            this.materials.group.rotate(-angle)
        }
    }

    private addLayoutLine(dir: PosLineDir, to: paper.Point) {
        const strokeParams = {
            strokeColor: '#000',
            strokeWidth: this.cfg.params.lineWidth * 0.25,
            strokeScaling: false
        }
        const params = this.cfg.params
        const arena = this.canvas.field
        const fs = this.cfg.getFontSize()
        const x = (dir === PosLineDir.RIGHT ? arena.left + params.parkourWidth * 100 : (dir === PosLineDir.LEFT ? arena.left : to.x))
        const y = (dir === PosLineDir.DOWN ? arena.top + params.parkourHeight * 100 : (dir === PosLineDir.UP ? arena.top : to.y))
        const l = new paper.Path.Line({
            ...strokeParams,
            from: [x, y],
            to: to
        })
        this.layoutLines?.addChild(l)
        const l2 = new paper.Path.Line({
            ...strokeParams,
            from: [x - fs / 2, y - fs / 2],
            to: [x + fs / 2, y + fs / 2]
        })
        this.layoutLines?.addChild(l2)
        const txt = new OutlinedText({
            content: this.config.conversion.transform(l.length, {
                from: Unit.CM,
                to: this.cfg.params.distanceUnit,
                rules: this.config.conversion.rulesForLayoutLines
            }),
            strokeColor: '#000',
            fontFamily: 'Roboto',
            fontSize: fs * 0.8,
            position: [(to.x - x) / 2 + x, (to.y - y) / 2 + y]
        })
        if (dir === PosLineDir.UP || dir === PosLineDir.DOWN) {
            txt.rotate(-90)
        }
        this.layoutLines?.addChild(txt)
    }

    // returns two indexes:
    // first is the index of the obstacle corner closest to the arena side in the dir direction
    // second is the index of the other side of the bar 
    private getCornerIndexes(dir: PosLineDir, corners: paper.Point[]): [number, number] {
        let idx = 0
        if (dir === PosLineDir.LEFT) {
            idx = findIndexOfMin(corners.map(c => c.x))
        } else if (dir === PosLineDir.RIGHT) {
            idx = findIndexOfMax(corners.map(c => c.x))
        } else if (dir === PosLineDir.UP) {
            idx = findIndexOfMin(corners.map(c => c.y))
        } else if (dir === PosLineDir.DOWN) {
            idx = findIndexOfMax(corners.map(c => c.y))
        }
        let idx2 = [1, 0, 3, 2][idx]
        return [idx, idx2]
    }

    private drawLayoutLines() {
        if (!this._layoutLinesVisibility) {
            this.layoutLines?.removeChildren()
            return
        }
        const pos = this.getPosition()
        const dL = pos.x - this.canvas.field.left
        const dR = this.canvas.field.left + this.cfg.params.parkourWidth * 100 - pos.x
        const dT = pos.y - this.canvas.field.top
        const dB = this.canvas.field.top + this.cfg.params.parkourHeight * 100 - pos.y
        // don't draw for obstacles outside of the arena
        if (dL < 0 || dR < 0 || dT < 0 || dB < 0) {
            return
        }
        // find direction where the obstacle is closest to the arena side
        const dists: [number, PosLineDir][] = [[dL, PosLineDir.LEFT], [dR, PosLineDir.RIGHT], [dT, PosLineDir.UP], [dB, PosLineDir.DOWN]]
        const idxD = findIndexOfMin(dists.map(d => d[0]))
        let dir = dists[idxD][1]

        // construct the corners of the obstacle (all bars)
        const length2 = this.objectSize.width / 2
        const spread2 = this.obstacleWidth / 2
        const angle = this.angle - 90
        let corners
        if (this.corner) {
            corners = [new paper.Point(pos.add([0.00001, length2]).rotate(angle, pos)),
                    new paper.Point(pos.add([spread2, -length2]).rotate(angle, pos)),
                    new paper.Point(pos.add([-spread2, -length2]).rotate(angle, pos)),
                    new paper.Point(pos.add([-0.00001, length2]).rotate(angle, pos))]
        } else {
            corners = [new paper.Point(pos.add([spread2, length2]).rotate(angle, pos)),
                    new paper.Point(pos.add([spread2, -length2]).rotate(angle, pos)),
                    new paper.Point(pos.add([-spread2, -length2]).rotate(angle, pos)),
                    new paper.Point(pos.add([-spread2, length2]).rotate(angle, pos))]
        }

        // if the lines from the two ends of the bar are too close to each other to place the distance
        // choose the other direction (horizontal or vertical)
        const limit = this.cfg.getFontSize()
        let [idx, idx2] = this.getCornerIndexes(dir, corners)
        if ((dir === PosLineDir.LEFT || dir === PosLineDir.RIGHT) &&
            Math.abs(corners[idx].y - corners[idx2].y) < limit) {
            dir = dT < dB ? PosLineDir.UP : PosLineDir.DOWN;
            [idx, idx2] = this.getCornerIndexes(dir, corners)
        } else if ((dir === PosLineDir.UP || dir === PosLineDir.DOWN) &&
            Math.abs(corners[idx].x - corners[idx2].x) < limit) {
            dir = dL < dR ? PosLineDir.LEFT : PosLineDir.RIGHT;
            [idx, idx2] = this.getCornerIndexes(dir, corners)
        }

        // draw first line
        this.layoutLines?.removeChildren()
        this.addLayoutLine(dir, corners[idx])

        // precision determines if the second line is needed or both ends of the bar are in the same
        // distance to the arena side
        const prec = 15
        if ((dir === PosLineDir.LEFT || dir === PosLineDir.RIGHT) && (toPrecision(corners[idx].x, prec) !== toPrecision(corners[idx2].x, prec)) ||
            (dir === PosLineDir.UP || dir === PosLineDir.DOWN) && (toPrecision(corners[idx].y, prec) !== toPrecision(corners[idx2].y, prec))) {
            this.addLayoutLine(dir, corners[idx2])
        }
    }

    private _drawSegment(pos: paper.Point, width: number, length: number, fill: boolean): paper.Shape.Rectangle {
        return new paper.Shape.Rectangle({
            point: pos,
            size: [length, width],
            fillColor: fill ? '#000' : '#fff',
            strokeColor: '#000',
            strokeWidth: 0.8 * this.cfg.params.lineWidth,
            strokeScaling: false
        })
    }

    private _drawBar(pos: paper.Point, width: number, length: number): paper.Group {
        const bar = new paper.Group()
        // draw larger bar than in reality if it is too small
        if (width < 30) {
            width = 30
        }
        bar.addChild(this._drawSegment(pos, width, length, false))
        const mark = length / this.stripes
        for (let i = 1; i <= Math.round(this.stripes / 2); i++) {
            bar.addChild(this._drawSegment(pos, width, mark, true))
            pos = pos.add([mark * 2, 0])
        }
        this.obstacle.addChild(bar)
        return bar
    }

    private _drawGrass(p: paper.Point, w: number, h: number) {
        const s = 75
        this.grass.addChild(new paper.Shape.Rectangle({
            point: [p.x + w / 2 - s, p.y - h / 2 - s / 2],
            size: [s, h + s],
            fillColor: '#0a0',
            strokeColor: '#0a0',
            strokeWidth: 0.5 * this.cfg.params.lineWidth,
            strokeScaling: false,
        }))
        this.grass.addChild(new paper.Shape.Rectangle({
            point: [p.x - w / 2, p.y - h / 2 - s / 2],
            size: [s, h + s],
            fillColor: '#0a0',
            strokeColor: '#0a0',
            strokeWidth: 0.5 * this.cfg.params.lineWidth,
            strokeScaling: false,
        }))
        this.grass.sendToBack()
        this.grass.visible = this.decorations[ParkourObjectDecorations.grass] || false
    }

    private _drawWater(p: paper.Point, w: number, h: number) {
        const s = 100
        w -= s, h -= this.bars > 0 ? s / 2 : s
        if (h < 0) {
            h = 1
        }
        if (w < 0) {
            w = 1
        }
        this.water.addChild(new paper.Shape.Rectangle({
            point: p.subtract([w / 2, h / 2]),
            size: [w, h],
            fillColor: '#8dd',
            strokeColor: '#8dd',
            strokeWidth: s,
            strokeJoin: 'round',
            strokeScaling: true
        }))
        this.water.insertAbove(this.grass)
        this.water.visible = this.decorations[ParkourObjectDecorations.water] || false
    }

    private _drawSinglePond(p: paper.Point, w: number, h: number) {
        this.singlePond.addChild(new paper.Shape.Ellipse({
            center: p,
            size: [w / 2, w / 2.3],
            fillColor: '#6dd',
            strokeColor: '#6dd',
            strokeWidth: 0.5 * this.cfg.params.lineWidth,
            strokeScaling: false
        }))
        this.singlePond.insertBelow(this.grass)
        this.singlePond.visible = this.decorations[ParkourObjectDecorations.singlePond] || false
    }

    private _setSinglePondPosition() {
        if (this.singlePond.visible) {
            const w = this.obstacleLength
            let p
            if (this.decorations.pondLeft) {
                p = new paper.Point(-w / 4, 0)
            } else if (this.decorations.pondRight) {
                p = new paper.Point(w / 4, 0)
            } else {
                p = new paper.Point(0, 0)
            }
            const center = this.getPosition()
            this.singlePond.position = center.add(p).rotate(this.angle, center)
        }
    }

    private _drawDoublePond(p: paper.Point, w: number, h: number) {
        this.doublePond.addChild(new paper.Shape.Ellipse({
            center: [p.x + w / 3.5, p.y],
            size: [w / 3, w / 2.5],
            fillColor: '#6dd',
            strokeColor: '#6dd',
            strokeWidth: 0.5 * this.cfg.params.lineWidth,
            strokeScaling: false
        }))
        this.doublePond.addChild(new paper.Shape.Ellipse({
            center: [p.x - w / 3.5, p.y],
            size: [w / 3, w / 2.5],
            fillColor: '#6dd',
            strokeColor: '#6dd',
            strokeWidth: 0.5 * this.cfg.params.lineWidth,
            strokeScaling: false
        }))
        this.doublePond.insertBelow(this.grass)
        this.doublePond.visible = this.decorations[ParkourObjectDecorations.doublePond] || false
    }

    private _drawDitch(p: paper.Point, w: number, h: number) {
        this.obstacle.addChild(new paper.Shape.Rectangle({
            center: p.subtract([w / 2, h / 2]),
            size: [w, h],
            fillColor: '#ffea80',
            strokeColor: '#e69900',
            strokeWidth: this.cfg.params.lineWidth,
            strokeJoin: 'round',
            strokeScaling: false
        }))
    }

    private _drawWall(p: paper.Point, length: number, width: number) {
        let brickLength = 70, brickWidth = 40
        let numBricks = Math.ceil(length / brickLength)
        brickLength = length / numBricks
        let layers = Math.ceil(width / brickWidth)
        brickWidth = width / layers
        let pos = p.subtract([0, width / 2])
        for (let l = 0; l < layers; l++) {
            const bricksInLayer = numBricks + (l % 2)
            for (let i = 0; i < bricksInLayer; i++) {
                let thisBrickLength = brickLength
                if ((l % 2 > 0) && (i === 0 || i === bricksInLayer - 1)) {
                    thisBrickLength /= 2
                }
                this.obstacle.addChild(new paper.Shape.Rectangle({
                    point: pos,
                    size: [thisBrickLength, brickWidth],
                    fillColor: '#ff383d',
                    strokeColor: '#200809',
                    strokeWidth: this.cfg.params.lineWidth,
                    strokeScaling: false
                }))
                pos = pos.add([thisBrickLength, 0])
            }
            pos = pos.add([-length, brickWidth])
        }
    }

    getKindName(): string {
        if (this.isLiverpool()) {
            return 'Liverpool'
        }
        return super.getKindName()
    }

    getEntryAngle(direction: Direction): number {
        if (direction === Direction.backward) {
            return this.exitAngle
        }
        return this.entryAngle
    }

    getExitAngle(direction: Direction): number {
        if (direction === Direction.backward) {
            return this.entryAngle
        }
        return this.exitAngle
    }

    getExternalSize(): paper.Size {
        return this.drawingSize || this.objectSize
    }

    destroy(): void {
        super.destroy()
        this.materials?.destroy()
        if (this.drawing.isInserted()) {
            this.drawing.remove()
        }
        if (this.layoutLines?.isInserted()) {
            this.layoutLines.remove()
        }
    }

    setDecoration(decoration: ParkourObjectDecorations, enabled: boolean) {
        this.decorations[decoration] = enabled
        this.showDecorations()
        if (this.isLiverpool()) {
            this.widthLimits = this.getLiverpoolLimits()
            this.defaultObstacleWidth = this.cfg.params.liverPoolWidth
        } else if (this.bars === 1) {
            this.widthLimits = this.getVerticalLimits()
            this.defaultObstacleWidth = this.widthLimits.default || this.barWidth
        }
    }

    changeObjectDecorations(decoration: ParkourObjectDecorations, enabled: boolean) {
        this.setDecoration(decoration, enabled)
        this.view?.validateParkour(false)
        this.view?.saveData()
    }

    changeObjectPondLocation() {

        this.setDecoration(ParkourObjectDecorations.pondLeft, this.pondLocation === true)
        this.setDecoration(ParkourObjectDecorations.pondRight, this.pondLocation === false)
        this.view?.validateParkour(false)
        this.view?.saveData()

        // TBD: cycling through 3 states
    }

    showDecorations() {
        this.grass.visible = this.decorations[ParkourObjectDecorations.grass] || false
        this.water.visible = this.decorations[ParkourObjectDecorations.water] || false
        this.singlePond.visible = this.decorations[ParkourObjectDecorations.singlePond] || false
        this._setSinglePondPosition()
        this.doublePond.visible = this.decorations[ParkourObjectDecorations.doublePond] || false
    }

    toSvg(): string | undefined {
        return this.drawing.exportSVG({
            asString: true
        }) as string
    }

    toJson(): DesignDrawnObstacleObject {
        return {
            ...super.toJson(),
            height: this.obstacleHeight === this.defaultObstacleHeight ? null : this.obstacleHeight,
            width: this.obstacleWidth === this.defaultObstacleWidth ? null : this.obstacleWidth,
            length: this.obstacleLength === this.defaultObstacleLength ? null : this.obstacleLength,
            materials: this.materials?.toJson() || null,
            decorations: this.decorations
        } as DesignDrawnObstacleObject
    }

    select(point?: paper.Point) {
        super.select(point)
        this.levelItem = this.allGroup
    }

    deselect() {
        super.deselect()
        this.levelItem = this.drawing
    }

    onMouseDown(point: paper.Point, internal?: boolean): boolean {
        const ret = super.onMouseDown(point, internal)
        if (ret && this.connector && !internal) {
            PathObject.connectorDistanceCorr -= this.obstacleWidth / 2
        }
        return ret
    }
}
