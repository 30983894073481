import * as B from '@babylonjs/core'
import { MaterialManager, ParkourMaterial } from './parkour-renderer.materials'

export class RenderedRoute {
    private trackMesh: B.Mesh
    private lineMesh: B.Mesh
    private materialIndex: number = 0
    private materials: {
        material: ParkourMaterial | undefined,
        name: string
    }[] = []
    private animationStep: number = 0.05

    private _buildMesh(track: B.Vector3[], normals: B.Vector2[], width: number): B.Mesh {
        const trackLeft: B.Vector3[] = []
        const trackRight: B.Vector3[] = []

        for (let i = 0; i < track.length; i++) {
            if (i < track.length - 1) {
                const t = normals[i]
                const perpendicular = new B.Vector3(t.x, 0, t.y).scale(width / 2)
                trackLeft.push(track[i].add(perpendicular))
                trackRight.push(track[i].subtract(perpendicular))
            } else {
                trackLeft.push(trackLeft[i - 1])
                trackRight.push(trackRight[i - 1])
            }
        }

        const mesh = B.MeshBuilder.CreateRibbon('track', {
            pathArray: [trackLeft, trackRight],
            closeArray: false,
            offset: 0,
            sideOrientation: B.Mesh.FRONTSIDE,
            updatable: true
        }, this.scene)

        const vertexData = B.VertexData.ExtractFromMesh(mesh)
        const uvs = vertexData.uvs || []
        for (let i = 0; i < trackLeft.length; i++) {
            const v = i / (trackLeft.length - 1)
            uvs[i * 2] = v
            uvs[i * 2 + 1] = 0
            uvs[(i + trackLeft.length) * 2] = v
            uvs[(i + trackLeft.length) * 2 + 1] = 1
        }
        vertexData.uvs = uvs
        vertexData.applyToMesh(mesh)

        return mesh
    }

    private _createMaterial(path:string, width: number, uScale: number, color: B.Color3): ParkourMaterial {
        uScale = Math.round(uScale * 1000) / 1000
        const key = `${path}-${width}-${uScale}`
        const texture = this.materialManager.getTexture(key, () => {
            const t = new B.Texture(path, this.scene)
            t.uScale = uScale
            t.vScale = 1
            t.hasAlpha = true
            this.scene.onBeforeRenderObservable.add(() => {
                t.uOffset -= this.animationStep
            })        
            return t
        })
        
        const mKey = `${key}-${color.getHashCode().toString()}`
        const material = this.materialManager.getMaterial(mKey, (name: string) => {
            const m = new B.StandardMaterial(name, this.scene)
            m.diffuseTexture = texture
            m.diffuseColor = color
            m.specularColor = B.Color3.Black()
            m.backFaceCulling = false
            return m
        })
        return material
    }

    setMaterial(idx: number) {
        idx = Math.max(0, idx)
        if (idx >= this.materials.length) {
            idx = 0
        }
        this.materialIndex = idx
        this.trackMesh.material = this.materials[this.materialIndex].material ?? null
        this.trackMesh.isVisible = this.trackMesh.material ? true : false
    }
    
    getMaterial(): number {
        return this.materialIndex
    }

    setLineVisibility(visible: boolean) {
        this.lineMesh.isVisible = visible
    }

    getActiveMaterialName(): string {
        return this.materials[this.materialIndex]?.name || ''
    }

    constructor(
        private scene: B.Scene,
        private materialManager: MaterialManager,
        track: B.Vector3[],
        pathLength: number,
        normals: B.Vector2[],
        round: number)
    {
        if (round <= 0 || round > 3) {
            round = 1
        }

        let arrowsColor, dotsColor, lineColor
        if (round === 2) {
            arrowsColor = new B.Color3(255 / 255, 253 / 255, 142 / 255)
            dotsColor = new B.Color3(1, 1, 0)
            lineColor = new B.Color3(255 / 255, 253 / 255, 142 / 255).scale(0.9)
        } else if (round === 3) {
            arrowsColor = new B.Color3(142 / 255, 253 / 255, 142 / 255)
            dotsColor = new B.Color3(0, 0.5, 0.2)
            lineColor = new B.Color3(142 / 255, 253 / 255, 142 / 255).scale(0.9)
        } else {
            arrowsColor = new B.Color3(133 / 255, 150 / 255, 240 / 255)
            dotsColor = new B.Color3(0, 0, 1)
            lineColor = new B.Color3(133 / 255, 150 / 255, 240 / 255).scale(0.9)
        }

        this.materials.push({
            material: undefined,
            name: ''
        }, {
            material: this._createMaterial('assets/textures/arrows-4.png', 0.5, pathLength / 200, arrowsColor),
            name: $localize`strzałki`
        }, {
            material: this._createMaterial('assets/textures/dot.png', 0.5, pathLength / 350, dotsColor),
            name: $localize`punkty`
        })

        let lineMaterial = this.materialManager.getMaterial('route-line' + round, (name: string) => {
            const m = new B.StandardMaterial(name, this.scene)
            m.diffuseColor = lineColor
            return m
        })
        const m = this.materials[this.materialIndex].material
        this.trackMesh = this._buildMesh(track, normals, 1)
        this.setMaterial(this.materialIndex)

        this.lineMesh = this._buildMesh(track, normals, 0.1)
        this.lineMesh.material = lineMaterial
        this.lineMesh.position.y -= 0.01
    }
}
