import { CommonModule } from '@angular/common'
import { Component, EventEmitter, Inject, LOCALE_ID, OnDestroy, OnInit, Output, ViewChild } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { Router, RouterModule } from '@angular/router'

import { MessageService } from 'primeng/api'
import { ButtonModule } from 'primeng/button'
import { DropdownModule } from 'primeng/dropdown'
import { InputTextModule } from 'primeng/inputtext'
import { SkeletonModule } from 'primeng/skeleton'
import { TooltipModule } from 'primeng/tooltip'
import { DialogModule } from 'primeng/dialog';

import { User } from '@angular/fire/auth'
import { Subscription, timeout } from 'rxjs'

import { AuthService } from '../services/auth.service'
import { StorageService } from '../services/storage.service'
import { showErrorBox, showInfoBox, showSuccessBox } from '../utils'
import { DesignSchema, ParkourRendererWalkMode } from '../design.schema'
import { ParkourCanvas } from '../parkour-canvas/parkour-canvas'
import { Parkour3dRenderComponent } from '../parkour-render-dialog/parkour-renderer-dialog.component'
import { ParkourConfig } from '../parkour.config'
import { LimitsService } from '../services/limits.service'
import { ConversionService } from '../services/conversion.service'

@Component({
    selector: 'app-designs-list',
    standalone: true,
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ButtonModule,
        DropdownModule,
        InputTextModule,
        SkeletonModule,
        TooltipModule,
        DialogModule,
        ParkourCanvas,
        Parkour3dRenderComponent,
    ],
    templateUrl: './designs-list.component.html',
    styleUrls: ['./designs-list.component.scss']
})
export class DesignsListComponent implements OnInit, OnDestroy {
    private subs: Subscription = new Subscription()

    @Output() events = new EventEmitter<any[]>()
    @Output() preview = new EventEmitter<string>()
    @ViewChild('canvasFor3d') canvasFor3d?: ParkourCanvas
    @ViewChild('renderDialog') renderDialog: Parkour3dRenderComponent | undefined

    user: any = null
    renderCfg: ParkourConfig

    eventsWithDesigns: any[] = []
    designs: any[] = []

    readonly inProgressMessage = $localize`Ładowanie listy...`
    listMsg: string = ''
    loadingList = false

    searchPanelVisible = false
    searchText: string = ''
    sortField: string = 'updatedAt'
    sortDir: string = 'desc'
    sortOption: string = 'updatedAt-desc'
    sortOptions: any[] = [
        { name: $localize`Data zmiany: od najnowszych`, value: 'updatedAt-desc' },
        { name: $localize`Data zmiany: od najstarszych`, value: 'updatedAt-asc' },
        { name: $localize`Data wydarzenia: od najnowszych`, value: 'eventDate-desc' },
        { name: $localize`Data wydarzenia: od najstarszych`, value: 'eventDate-asc' },
    ]

    bigPreviewPngUrl: string = ''
    previewDlgVisible: boolean = false

    get is3dSupported(): boolean {
        return Parkour3dRenderComponent.is3dSupported
    }

    constructor(
        private router: Router,
        private auth: AuthService,
        private store: StorageService,
        private msgSvc: MessageService,
        private limitsService: LimitsService,
        private conversionService: ConversionService,
        @Inject(LOCALE_ID) private locale: string
    ) {
        this.renderCfg = new ParkourConfig(this.limitsService, this.conversionService, {})
    }

    ngOnInit(): void {
        this.loadDesignsList()
        this.subs.add(
            this.auth.user.subscribe({
                next: (aUser: User | null) => {
                    this.user = aUser
                },
                error: (err) => {
                    console.error('error occured', err)
                }
            })
        )
    }

    ngOnDestroy() {
        this.subs.unsubscribe()
    }

    prepareEventsList() {
        const events = []
        this.eventsWithDesigns = []
        for (const d of this.designs) {
            if (this.searchText) {
                if ((d.eventName && d.eventName.includes(this.searchText)) ||
                    (d.title && d.title.includes(this.searchText)) ||
                    (d.authorName && d.authorName.includes(this.searchText)) ||
                    (d.shortDescr && d.shortDescr.includes(this.searchText)) ||
                    (d.nationalRules && d.nationalRules.includes(this.searchText)) ||
                    (d.feiRules && d.feiRules.includes(this.searchText))) {
                    //ok
                } else {
                    continue
                }
            }

            let event: any
            if (d.eventName) {
                event = events.find(v => v.eventName === d.eventName)
                if (!event) {
                    event = {
                        eventName: d.eventName,
                        eventDate: d.eventDate?.toLocaleDateString(this.locale, { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }),
                        eventDescr: d.eventDescr,
                        dims: [d.parkourWidth, d.parkourHeight],
                        designs: [],
                    }
                    this.eventsWithDesigns.push(event)
                    events.push(event)
                }
                event.designs.push(d)
            } else {
                // design with empty event
                event = {
                    eventName: '',
                    eventDate: d.eventDate?.toLocaleDateString(this.locale, { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }),
                    designs: [d],
                }
                this.eventsWithDesigns.push(event)
            }
        }
        this.events.emit(events)
    }

    loadLocalDesigns() {
        this.subs.add(
            this.store.getLocalDesigns().subscribe({
                next: (designs: any[]) => {
                    this.listMsg = ''
                    this.designs = designs
                    this.prepareEventsList()
                },
                error: (err) => {
                    console.error('error occured', err)
                    showErrorBox(this.msgSvc, $localize`Pobieranie lokalnych projeków`, $localize`Wystąpił nieznany błąd`)
                }
            })
        )
    }

    loadDesignsList() {
        this.loadingList = true
        if (this.designs.length === 0) {
            this.listMsg = this.inProgressMessage
        } else {
            this.listMsg = ''
        }
        const obs = this.store.loadPublicDesigns(30, this.sortField, this.sortDir)
        this.subs.add(
            obs.subscribe({
                next: (designs: any[]) => {
                    this.loadingList = false
                    this.designs = designs
                    this.prepareEventsList()

                    if (this.designs.length === 0) {
                        this.listMsg = $localize`Biblioteka jest pusta`
                    } else {
                        this.listMsg = ''
                    }
                },
                error: (err) => {
                    this.loadingList = false
                    this.listMsg = $localize`Pobieranie projektów nie powiodło się`
                    console.error('error occured', err)
                    showErrorBox(this.msgSvc, $localize`Pobieranie projektów`, $localize`Wystąpił nieznany błąd`)
                }
            })
        )
    }

    cloneDesign(oldDesign: any) {
        const [localId, _, obs] = this.store.cloneDesign(oldDesign, oldDesign.title, true)

        // IMPORTANT: do not put this subscription to subs because in case of navigate to designer page
        // it will be canceled in ngOnDestroy
        obs.subscribe({
            next: (docRef: any) => {
                showSuccessBox(this.msgSvc, $localize`Duplikowanie projektu`, $localize`Projekt zapisano zdalnie, na serwerze`)
            },
            error: (err: any) => {
                console.error("Error adding design: ", err)
                showErrorBox(this.msgSvc, $localize`Duplikowanie projektu`, $localize`Wystąpił nieznany błąd`)
            }
        })
        showInfoBox(this.msgSvc, $localize`Duplikowanie projektu`, $localize`Projekt zapisano lokalnie`)
        this.router.navigate(['/designer'], { queryParams: { id: localId } })
    }

    renderDesign(design: DesignSchema) {
        if (!this.canvasFor3d) {
            return
        }
        this.canvasFor3d.loadDesign(design).pipe(
            timeout(5000),
        ).subscribe({
            next: () => {
                this.canvasFor3d?.resetView()
                this.renderDialog?.open(ParkourRendererWalkMode.FLY_FREELY)
            },
            error:(err) => {
                console.log('Render 3d error:', err)
                showErrorBox(this.msgSvc, $localize`Model 3D`, $localize`Nie można wyświetlić modelu 3D tego projektu, spróbuj z innym projektem`)           
            }
        })
    }

    onImageError(design: any) {
        design.pngUrl = null
    }

    onImageLoad(ev: any, design: any) {
        const img = ev.target
        const canvas = document.createElement("canvas");
        canvas.width = img.naturalWidth
        canvas.height = img.naturalHeight
        const ctx = canvas.getContext("2d");
        ctx!.drawImage(img, 0, 0, img.naturalWidth, img.naturalHeight);
        const dataURL = canvas.toDataURL("image/png");
        this.store.savePngPreviewLocally(design.localId, dataURL)
    }

    togglePreview(d: any) {
        let previewPngUrl = ''

        if (d.pngUrl) {
            previewPngUrl = d.pngUrl
        } else if (d.png) {
            previewPngUrl = d.png
        }
        this.bigPreviewPngUrl = previewPngUrl
        this.previewDlgVisible = !this.previewDlgVisible
    }


    doSearch(event: any) {
        if (event.key === "Escape") {
            this.searchText = ''
            this.searchPanelVisible = false
        }
        this.prepareEventsList()
    }

    sortChange() {
        const [sortField, sortDir] = this.sortOption.split('-')
        this.sortField = sortField
        this.sortDir = sortDir
        this.loadDesignsList()
    }
}
