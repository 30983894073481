import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { InputNumberModule } from 'primeng/inputnumber';
import { CheckboxModule } from 'primeng/checkbox';
import { TooltipModule } from 'primeng/tooltip';
import { DropdownModule } from 'primeng/dropdown';
import { DetailComponent } from '../../detail.component';
import { DialogModule } from 'primeng/dialog'
import { ParkourDialog } from '../../../dialog.interface'
import { SplitButtonModule } from 'primeng/splitbutton'
import { DesignSchema, designDisplayOptionsFields, designPathPatternDistances } from '../../../design.schema'

import { PathPattern, PathPatternSelector } from '../../../path-pattern-selector/path-pattern-selector'
import { DesignDisplayOptions, UserProfile, UserService } from '../../../services/user.service'
import { UserSubscriptionService, UserFeatures } from '../../../services/user-subscription.service'
import { Subscription } from 'rxjs'
import { PanelModule } from 'primeng/panel'
import { TabMenu, TabMenuModule } from 'primeng/tabmenu'
import { MenuItem } from 'primeng/api'
import { DividerModule } from 'primeng/divider'
import { getFormValidationErrors } from '../../../utils'
import { NewColorPicker } from '../../../new-colorpicker/new-colorpicker'

@Component({
    selector: 'app-parkour-display-options',
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ButtonModule,
        InputNumberModule,
        CheckboxModule,
        TooltipModule,
        DropdownModule,
        DialogModule,
        PanelModule,
        SplitButtonModule,
        TabMenuModule,
        DividerModule,
        NewColorPicker,
        PathPatternSelector
    ],
    templateUrl: './parkour-display-options.component.html',
    styleUrl: './parkour-display-options.component.scss'
})
export class ParkourDisplayOptionsComponent implements ParkourDialog, AfterViewInit, OnDestroy {
    @Input({ required: true }) view!: DetailComponent
    @Output() onOk = new EventEmitter<void>()
    @Output() onCancel = new EventEmitter<void>()

    @ViewChild('displayOptionsTabMenu') displayOptionsTabMenuEl: TabMenu | undefined

    displayOptionsTabMenuItems: MenuItem[] = [
        { label: $localize`Ogólne`, icon: 'pi pi-fw pi-sliders-h' },
        { label: $localize`Rozmiary i wzory`, icon: 'pi pi-fw pi-asterisk' },
        { label: $localize`Kolory`, icon: 'pi pi-fw pi-palette' },
    ]
    displayOptionsActiveItem: MenuItem = this.displayOptionsTabMenuItems[0]

    distanceUnitOptions = [
        { name: $localize`Metry (m)`, value: 'm-m' },
        { name: $localize`Stopy (ft)`, value: 'ft-ft' },
        { name: $localize`Stopy (\')`, value: 'ft-sign' }
    ]

    sizeUnitOptions = [
        { name: $localize`Centymetry (cm)`, value: 'cm-cm' },
        { name: $localize`Stopy (ft)`, value: 'ft-ft' },
        { name: $localize`Stopy (\')`, value: 'ft-sign' }
    ]

    pathPatterns: PathPattern[] = [
        { name: $localize`Brak`, value: 0, pattern: designPathPatternDistances[0] },
        { name: $localize`Ciągła`, value: 1, pattern: designPathPatternDistances[1] },
        { name: $localize`Kropki 1`, value: 2, pattern: designPathPatternDistances[2] },
        { name: $localize`Kropki 2`, value: 3, pattern: designPathPatternDistances[3] },
        { name: $localize`Kropki 3`, value: 4, pattern: designPathPatternDistances[4] },
        { name: $localize`Kreski 1`, value: 5, pattern: designPathPatternDistances[5] },
        { name: $localize`Kreski 2`, value: 6, pattern: designPathPatternDistances[6] },
        { name: $localize`Kreski 3`, value: 7, pattern: designPathPatternDistances[7] },
        { name: $localize`Kreski i kropki 1`, value: 8, pattern: designPathPatternDistances[8] },
        { name: $localize`Kreski i kropki 2`, value: 9, pattern: designPathPatternDistances[9] },
        { name: $localize`Kreski i kropki 3`, value: 10, pattern: designPathPatternDistances[10] },
    ]

    displayOptionsDefaultActions = [
        {
            label: $localize`Zapisz obecne ustawienia w moim profilu`,
            icon: 'pi pi-save',
            command: () => {
                this.saveDisplayOptionsInUserProfile()
            }
        },
        {
            label: $localize`Zresetuj ustawienia w moim profilu`,
            icon: 'pi pi-undo',
            command: () => {
                this.resetDisplayOptions()
            }
        },
    ]

    visibility: boolean = false
    errorMsg: string = ''
    designDisplayOptions: DesignDisplayOptions = {}
    public subs: Subscription = new Subscription()

    parkourDesignLabelChangable: boolean = false
    showParkourDesignLabelTooltip = ''

    constructor(
        private changeDetectorRef: ChangeDetectorRef,
        public userService: UserService,
        private userSubscriptionService: UserSubscriptionService) {
    }

    ngAfterViewInit() {
        this.subs.add(
            this.userService.getUserProfile().subscribe({
                next: (userProfile: UserProfile | null | undefined) => {
                    if (!userProfile || !userProfile.designDisplayOptions) {
                        return
                    }
                    this.designDisplayOptions = structuredClone(userProfile.designDisplayOptions)
                },
                error: (err) => {
                    console.error('error occured', err)
                }
            })
        )

        this.subs.add(
            this.userSubscriptionService.getFeatures().subscribe({
                next: (features: UserFeatures) => {
                    if (features.parkourDesignLabelChangable) {
                        this.view.cfg.form.get('showParkourDesignLabel')?.enable()
                    } else {
                        this._disableParkourDesignLabelChange()
                        this.showParkourDesignLabelTooltip = $localize`Aby móc wyłączyć tę etykietę, wykup subskrypcję Masters`
                    }
                },
                error: (err) => {
                    console.error('error occured', err)
                }
            })
        )
    }

    ngOnDestroy() {
        this.subs.unsubscribe()
    }

    resetDisplayOptionsTabMenu() {
        this.displayOptionsActiveItem = this.displayOptionsTabMenuItems[0]
        this.displayOptionsTabMenuEl?.itemClick(new Event('click'), this.displayOptionsActiveItem)
        this.changeDetectorRef.detectChanges()
    }

    resetDisplayOptions() {
        const params = new DesignSchema()
        const dispOpts: any = {}
        for (let field of designDisplayOptionsFields) {
            let val
            if (field.includes('.')) {
                const parts = field.split('.')
                val = (params as any)[parts[0]][parts[1]]
            } else {
                val = params[field as keyof DesignSchema]
            }
            console.info(field, ' = ', val)
            if (val !== undefined) {
                dispOpts[field] = val
            }
        }
        this.designDisplayOptions = dispOpts
        const data: UserProfile = {
            designDisplayOptions: dispOpts
        }
        this.saveUserProfile(data)
        this.loadDisplayOptionsFromUserProfile()
    }

    loadDisplayOptionsFromUserProfile() {
        if (this.designDisplayOptions) {
            for (let field of designDisplayOptionsFields) {
                const value = this.designDisplayOptions[field]
                if (value !== undefined) {
                    this.view.cfg.form.get(field)?.patchValue(value)
                }
            }
        }
        const err = getFormValidationErrors(this.view.cfg.form.controls)
        err.forEach(e => console.log(e.control_name, e.error_name, e.error_value))
    }

    saveDisplayOptionsInUserProfile() {
        const dispOpts: any = {}
        for (let field of designDisplayOptionsFields) {
            const val = this.view.cfg.form.get(field)?.value
            console.info(field, ' = ', val)
            if (val !== undefined) {
                dispOpts[field] = val
            }
        }
        this.designDisplayOptions = dispOpts
        const data: UserProfile = {
            designDisplayOptions: dispOpts
        }
        this.saveUserProfile(data)
    }

    saveUserProfile(data: UserProfile) {
        this.userService.updateUserProfile(data).subscribe({
            next: (resp: any) => {
                console.info('updated profile', resp)
            },
            error: (err) => {
                console.error('error occured', err)
            }
        })
    }

    onCloseDialog() {
    }

    onShowDialog() {
        this.view.cfg.setValuesToForm()
        if (!this.view.features.parkourDesignLabelChangable) {
            this._disableParkourDesignLabelChange()
        }
        this.resetDisplayOptionsTabMenu()
    }

    onHideDialog() {
        this.resetDisplayOptionsTabMenu()
    }

    onOkDialog() {
        this.visibility = false
        this.onOk.emit()
    }

    onCancelDialog() {
        this.visibility = false
        this.onCancel.emit()
    }

    open() {
        this.visibility = true
    }

    closeOk() {
        this.onOkDialog()
    }

    closeCancel() {
        this.onCancelDialog()
    }

    private _disableParkourDesignLabelChange() {
        const c = this.view.cfg.form.get('showParkourDesignLabel')
        c?.disable()
        c?.patchValue(true)
        c?.updateValueAndValidity()
    }
}
