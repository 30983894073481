import { Component, Input, Output, EventEmitter, ElementRef, ViewChild, OnInit, OnDestroy } from '@angular/core'
import { CommonModule } from '@angular/common'

import { ButtonModule } from 'primeng/button'
import { MessageService } from 'primeng/api'
import { Subscription } from 'rxjs'

import { showErrorBox, showSuccessBox } from '../utils'
import { UserImagesService } from '../services/user-images.service'
import { getRandomId } from '../design.schema'
import { Landscape } from '../detail/parkour-objects/landscape'
import { UserImage } from '../detail/parkour-objects/user-image'

type UserImageDescriptor = {
    url: string;
    selected: boolean;
    id: string;
    source?: string;
};

@Component({
    selector: 'app-image-select-panel',
    standalone: true,
    imports: [
        CommonModule,
        ButtonModule,
    ],
    templateUrl: './image-select-panel.component.html',
    styleUrl: './image-select-panel.component.scss'
})
export class ImageSelectPanelComponent implements OnInit, OnDestroy {
    private subs: Subscription = new Subscription()

    builtInImages: UserImageDescriptor[] = [
        { url: 'assets/service-hut.svg', selected: false, id: 'service-hut', source: 'built-in' },
        { url: 'assets/grass-1.svg', selected: false, id: 'grass-1', source: 'built-in' },
        { url: 'assets/grass-2.svg', selected: false, id: 'grass-2', source: 'built-in' },
        { url: 'assets/car.svg', selected: false, id: 'car', source: 'built-in' },
        { url: 'assets/clock.svg', selected: false, id: 'clock', source: 'built-in' },
    ]
    images: UserImageDescriptor[] = []

    _selectedObj?: any
    _selectedImage?: UserImageDescriptor
    canRestoreImageId?: string

    @Input()
    set selectedObject(obj: any) {
        this._selectedObj = obj
        if (obj instanceof UserImage) {
            this.selectImageById(obj.imageId)
        } else if (obj instanceof Landscape && obj.kind.url) {
            this.selectImageById(obj.kind.url)
        }
    }

    @Output() selectedImage = new EventEmitter<any>()
    @Output() dragImageStarted = new EventEmitter<any>()

    @ViewChild('userImageFileInput') userImageFileInputEl!: ElementRef<HTMLInputElement>

    constructor(
        private msgSvc: MessageService,
        private userImgSvc: UserImagesService,
    ) {
    }

    ngOnInit(): void {
        this.loadAllImages()
    }

    ngOnDestroy() {
        this.subs.unsubscribe()
    }

    loadAllImages() {
        this.subs.add(this.userImgSvc.getImages().subscribe({
            next: (images) => {
                this.images = [...this.builtInImages]
                for (const p of images) {
                    this.images.push({ url: p.url, selected: false, id: p.id })
                }
                if (this._selectedObj) {
                    this.selectedObject = this._selectedObj
                }
            },
            error: (err) => {
                console.error('error occured', err)
            }
        }))
    }

    openFileSelectDialog() {
        this.userImageFileInputEl.nativeElement.click()
    }

    uploadUserImage(event: any) {
        const files = event?.target?.files
        if (files instanceof FileList && files.length > 0) {
            const file = files[0]
            const sizeLimitMB = 5
            if (file.size > 5 * 1024 * 1024) {
                showErrorBox(this.msgSvc, $localize`Błąd przy ładowaniu obrazka`,
                    $localize`Rozmiar pliku większy od ${sizeLimitMB} MB`)
                return
            }

            const imageFormat = file.type

            const imageId = getRandomId()
            this.subs.add(this.userImgSvc.uploadImage(imageId, imageFormat, file).subscribe({
                next: () => {
                },
                error: (err) => {
                    console.error('error occured', err)
                    showErrorBox(this.msgSvc, $localize`Błąd przy zapisie obrazka`, $localize`Wystąpił nieznany błąd`)
                }
            }))
        }
    }

    private _justSelectImage(p?: any) {
        for (let r of this.images) {
            r.selected = false
        }
        if (p) {
            p.selected = true
            this._selectedImage = p
            this.canRestoreImageId = undefined
        } else {
            this._selectedImage = undefined
        }
    }

    selectImage(p: any) {
        this._justSelectImage(p)
        this.selectedImage.emit(this._selectedImage)
    }

    selectImageById(id: string) {
        let selectedImg
        for (let r of this.images) {
            if (r.id === id || r.url === id) {
                selectedImg = r
                break
            }
        }
        if (selectedImg) {
            this._justSelectImage(selectedImg)
        } else {
            this._justSelectImage(undefined)
            this._checkRestoreImage(id)
        }
    }

    dragImageStart(event: any, p: any) {
        this.dragImageStarted.emit({ event: event, img: p })
    }

    deleteImage() {
        if (!this._selectedImage) {
            return
        }
        const img = this._selectedImage
        this.subs.add(this.userImgSvc.deleteImage(img.id).subscribe({
            next: () => {
                this._justSelectImage(undefined)
                showSuccessBox(this.msgSvc, $localize`Kasowanie obrazka`, $localize`Skasowano obrazek`)
            },
            error: (err: any) => {
                console.error('error occured', err)
                showErrorBox(this.msgSvc, $localize`Błąd przy kasowaniu obrazka`, $localize`Wystąpił nieznany błąd`)
            }
        }))
    }

    _checkRestoreImage(id: string) {
        this.canRestoreImageId = id
    }

    restoreImage() {
        if (!this.canRestoreImageId) {
            return
        }
        this.subs.add(this.userImgSvc.restoreImage(this.canRestoreImageId).subscribe({
            next: () => {
                showSuccessBox(this.msgSvc, $localize`Odzyskiwanie obrazka`, $localize`Odzyskano obrazek`)
            },
            error: (err: any) => {
                console.error('error occured', err)
                showErrorBox(this.msgSvc, $localize`Błąd przy odzyskiwaniu obrazka`, $localize`Wystąpił nieznany błąd`)
            }
        }))
    }
}
