import { DesignBar, DesignDrawnObstacleDecorations, DesignDrawnObstacleObject, DesignMaterials, DesignSchema, Direction, ObstacleMaterial } from "../design.schema"
import * as B from '@babylonjs/core'
import * as M from '@babylonjs/materials'
import * as T from '@babylonjs/procedural-textures'
import { RenderedObject } from "./parkour-renderer.object"
import { MaterialManager } from "./parkour-renderer.materials"
import { rotateTextureUV } from "./parkour-renderer.component"

export class RenderedObstacle extends RenderedObject {
    private _posCenter: B.Vector3 = B.Vector3.Zero()
    private _width: number = 0
    private _height: number = 0
    private _length: number = 0
    private _grassSize = 1
    private _group: B.TransformNode
    private _labelsPerDirection: number[] = [0, 0]
    private _labels: string[] = []
    private _direction: Direction = Direction.forward

    private _createGrass(
        pos: B.Vector3, width: number, length: number)
    {
        const scene = this.scene, mm = this.materialManager
        const grass = B.MeshBuilder.CreateGround('grass', { width: width, height: length }, scene)
    
        const texture = mm.getTexture('grass-clean', () => {
            return new B.Texture('assets/textures/grass.jpg', scene)
        })

        grass.material = mm.getMaterial('grass-obstacle', (name: string) => {
            const m = new B.StandardMaterial(name, scene)
            m.specularColor = new B.Color3(0, 0, 0)
            m.diffuseTexture = texture
            return m
        })
        grass.position = pos
        if (pos.y === 0) {
            grass.position.y = 0.03
        }
        grass.parent = this._group
        return grass
    }

    private _createWaterMaterial(): M.WaterMaterial {
        const scene = this.scene, mm = this.materialManager
        const texture = mm.getTexture('water-clear', () => {
            return new B.Texture('assets/textures/waterbump.png', scene)
        })
        return mm.getMaterial('water-obstacle', (name: string) => {
            const m = new M.WaterMaterial(name, scene)
            m.bumpTexture = texture
            m.waveHeight = 0
            m.waveSpeed = 0
            m.windForce = 10
            m.windDirection = new B.Vector2(1, 1)
            m.waveLength = 1
            m.waterColor = new B.Color3(0.1, 0.1, 0.6)
            m.colorBlendFactor = 0.4
            m.bumpHeight = 0.2
            m.addToRenderList(this.skyBox)
            m.addToRenderList(this.ground)
            return m
        }) as M.WaterMaterial
    }

    private _createWater(
        pos: B.Vector3, width: number, length: number)
    {
        const scene = this.scene, mm = this.materialManager
        const water = B.MeshBuilder.CreateGround('water', { width: width, height: length }, scene)
        water.material = this._createWaterMaterial()
        water.position = pos.clone()
        if (pos.y === 0) {
            water.position.y = 0.02
        }
        water.parent = this._group
        return water
    }

    private _createPond(
        pos: B.Vector3, width: number, length: number)
    {
        const scene = this.scene, mm = this.materialManager
        const pond = B.MeshBuilder.CreateDisc('ellipse', { radius: width, tessellation: 64, sideOrientation: B.Mesh.FRONTSIDE }, scene);
        pond.scaling.y = length / width
        pond.rotation.x = Math.PI / 2
        pond.material = this._createWaterMaterial()
        pond.position = pos.clone()
        if (pos.y === 0) {
            pond.position.y = 0.02
        }
        pond.parent = this._group
        return pond
    }

    private _createDitch(
        pos: B.Vector3, width: number, length: number)
    {
        const scene = this.scene, mm = this.materialManager
        const sand = B.MeshBuilder.CreateGround('sand', { width: width, height: length }, scene)

        const texture = mm.getTexture('sand-clean', () => {
            return new B.Texture('assets/textures/coast_sand.jpg', scene)
        })
        sand.material = mm.getMaterial('sand-obstacle', (name: string) => {
            const m = new B.StandardMaterial(name, scene)
            m.specularColor = new B.Color3(0, 0, 0)
            m.diffuseTexture = texture
            return m
        })
        sand.position = pos.clone()
        if (pos.y === 0) {
            sand.position.y = 0.01
        }
        sand.parent = this._group
        return sand
    }

    private _createWall(
        pos: B.Vector3, width: number, length: number, height: number): B.Mesh
    {
        const scene = this.scene, mm = this.materialManager
        const wall = B.MeshBuilder.CreateBox('wall', { width: length, height: height, depth: width }, scene)

        rotateTextureUV(wall, [0, 1])
        
        const texture = mm.getTexture('bricks-obstacle', () => {
            const t = new B.Texture('assets/textures/bricks.jpg', scene)
            t.uScale = t.vScale = 1
            t.wAng = Math.PI / 2
            return t
        })
        wall.material = mm.getMaterial('bricks-obstacle', (name: string) => {
            const m = new B.StandardMaterial(name, scene)
            m.specularColor = new B.Color3(0, 0, 0)
            m.diffuseTexture = texture
            return m
        })
        wall.position = pos.add(new B.Vector3(0, height / 2, 0))
        wall.rotation.y = Math.PI / 2
        wall.checkCollisions = true
        wall.parent = this._group
        this.shadowGenerator.addShadowCaster(wall)
        return wall
    }

    private _hexToColor3(hex: string | undefined): B.Color3 | undefined {
        if (!hex) {
            return undefined
        }
        hex = hex.replace(/^#/, "");
        const r = parseInt(hex.substring(0, 2), 16) / 255
        const g = parseInt(hex.substring(2, 4), 16) / 255
        const b = parseInt(hex.substring(4, 6), 16) / 255
        return new B.Color3(r, g, b)
    }

    private _createSingleObstacle(
        group: B.TransformNode, postMaterial: B.Material, 
        pos: B.Vector3, spread: number, length: number, height: number, prevHeight: number,
        connectToPrevious: boolean, verticalBars: number, horizontalIdx: number,
        materials?: DesignMaterials,
    ) {
        const scene = this.scene, mm = this.materialManager

        const barSize = 0.1
        const postSize = 0.1
        const numStripes = 8
        const minPostHeight = 1.8

        let postHeight = height + barSize * 1.5
        if (postHeight < minPostHeight) {
            postHeight = minPostHeight
        }
        const postLeft = B.MeshBuilder.CreateBox('postLeft', { width: postSize, height: postHeight, depth: postSize }, scene)
        postLeft.position = pos.add(new B.Vector3(-length / 2, postHeight / 2, 0))
        postLeft.material = postMaterial
        postLeft.checkCollisions = true
        postLeft.parent = group
        this.shadowGenerator.addShadowCaster(postLeft)
    
        const postRight = B.MeshBuilder.CreateBox('postRight', { width: postSize, height: postHeight, depth: postSize }, scene)
        postRight.position = pos.add(new B.Vector3(length / 2, postHeight / 2, 0))
        postRight.material = postMaterial
        postRight.checkCollisions = true
        postRight.parent = group
        this.shadowGenerator.addShadowCaster(postRight)

        let prevPostHeight = prevHeight + barSize * 1.5
        if (prevPostHeight < minPostHeight) {
            prevPostHeight = minPostHeight
        }
        if (connectToPrevious) {
            const ps = postSize * 0.75
            const crossLength = Math.sqrt((spread - postSize) ** 2 + (prevPostHeight - postSize) ** 2)
            const cross1 = B.MeshBuilder.CreateBox('postCross1', { width: crossLength, height: ps, depth: ps }, scene)
            cross1.position = pos.add(new B.Vector3(length / 2, prevPostHeight / 2, -spread / 2))
            const r = Math.atan2(prevPostHeight, -(spread + postSize))
            cross1.rotation.z = -r
            cross1.rotation.y = Math.PI / 2
            cross1.material = postMaterial
            cross1.parent = group
            this.shadowGenerator.addShadowCaster(cross1)

            const crossLength2 = Math.sqrt((spread - postSize) ** 2 + (postHeight - postSize) ** 2)
            const cross2 = B.MeshBuilder.CreateBox('postCross3', { width: crossLength2, height: ps, depth: ps }, scene)
            cross2.position = pos.add(new B.Vector3(length / 2, postHeight / 2, -spread / 2))
            const r2 = Math.atan2(postHeight, -(spread + postSize))
            cross2.rotation.z = r2
            cross2.rotation.y = Math.PI / 2
            cross2.material = postMaterial
            cross2.parent = group
            this.shadowGenerator.addShadowCaster(cross2)

            const cross3 = cross1.clone()
            this.shadowGenerator.addShadowCaster(cross3)

            const cross4 = cross2.clone()
            this.shadowGenerator.addShadowCaster(cross4)
            cross4.position.x = cross3.position.x = pos.x - length / 2
        }
    
        const red = new B.Color3(1, 0, 0)
        const white = new B.Color3(1, 1, 1)        
        const barSpacing = verticalBars > 1 ? (height - barSize * 2) / (verticalBars - 1) : 0
    
        let prevPosY = 0
        for (let i = 0; i < verticalBars; i++) {
            const position = pos.add(new B.Vector3(0, height - barSize / 2 - barSpacing * i, 0))
            if (i === 0) {
                prevPosY = position.y
            }
            let designBar: DesignBar | undefined
            if (i === 0 || !materials || materials && (i - 1 > materials.rows.length || i > 0 && !materials.rows[i - 1])) {
                designBar = materials?.topRow[horizontalIdx]
            } else {
                designBar = materials.rows[i - 1]
            }
            const barType = designBar?.material || ObstacleMaterial.BAR

            if (barType === ObstacleMaterial.BRICKS && i === verticalBars - 1) {

                const texture = mm.getTexture('obstacle-bricks', () => {
                    const t = new T.BrickProceduralTexture('obstacle-bricks', 512, scene)
                    t.numberOfBricksHeight = 3
                    t.numberOfBricksWidth = 10
                    return t    
                })
                const material = mm.getMaterial('obstacle-bricks', (name: string) => {
                    const m = new B.StandardMaterial(name, scene)
                    m.diffuseTexture = texture
                    return m
                })
                const h = (prevPosY + position.y) / 2 - barSize
                const wall = B.CreateBox('obstacle-wall', {
                    width: length - postSize,
                    height: h,
                    depth: postSize,
                })
                wall.material = material
                wall.position = position
                wall.position.y = h / 2
                wall.checkCollisions = true
                wall.parent = group
                this.shadowGenerator.addShadowCaster(wall)

            } else if (barType === ObstacleMaterial.PLANK) {

                const w = length - postSize, h = postSize / 4, d = postSize * 2
                const idx = (Math.random() * 5).toFixed(0)
                const texture = mm.getTexture('obstacle-plank' + idx, () => {
                    const t = new B.Texture('assets/textures/wood-plank.jpg', scene)
                    t.uScale = t.vScale = 1
                    t.wAng = Math.PI / 2
                    t.wrapU = B.Texture.WRAP_ADDRESSMODE
                    t.wrapV = B.Texture.WRAP_ADDRESSMODE
                    t.uOffset = Math.random() * w
                    return t    
                })
                const color = this._hexToColor3(designBar?.color1 ?? undefined)

                const material = mm.getMaterial('obstacle-plank' + color + idx, (name: string) => {
                    const m = new B.StandardMaterial(name, scene)
                    m.diffuseTexture = texture
                    m.diffuseColor = color || new B.Color3(166/255, 114/255, 90/255)
                    return m
                })
                const plank = B.CreateBox('obstacle-plank', { width: w, height: h, depth: d })        
                plank.rotate(new B.Vector3(1, 0, 0), Math.PI / 2)
                plank.material = material
                plank.position = position
                plank.checkCollisions = true
                plank.parent = group
                this.shadowGenerator.addShadowCaster(plank)

            } else {

                const colors: B.Color3[] = []
                const c1 = this._hexToColor3(designBar?.color1 ?? undefined) || red
                const c2 = this._hexToColor3(designBar?.color2 ?? undefined) || white
                for (let i = 0; i < numStripes; i++) {
                    colors.push(i % 2 === 0 ? c1 : c2)                        
                }
        
                const bar = B.MeshBuilder.CreateCylinder(`bar${i}`, { 
                    diameter: barSize, 
                    height: length, 
                    tessellation: 24,
                    hasRings: true,
                    enclose: false,
                    subdivisions: numStripes - 1,
                    faceColors: colors.map(c => c.toColor4()),
                }, scene)

                bar.rotation.z = Math.PI / 2
                bar.position = position
                bar.checkCollisions = true
                bar.parent = group
                this.shadowGenerator.addShadowCaster(bar)
            }
            prevPosY = position.y
        }
    }

    private _createObstacle(
        pos: B.Vector3, spread: number, length: number, height: number,
        horizontalBars: number, decorations: DesignDrawnObstacleDecorations,
        kind: string, materials?: DesignMaterials)
    {
        const scene = this.scene, group = this._group

        const postMaterial = this.materialManager.getMaterial('post-obstacle', (name: string) => {
            const m = new B.StandardMaterial(name, scene)
            m.diffuseColor = new B.Color3(1, 1, 1)
            return m
        })
    
        if (horizontalBars > 0) {
            let raiseDelta = 0, verticalBars: number = (materials?.rows.length || 0) + 1
            if (materials) {
                if (materials.topRow.length > 1 && materials.raisedHeight > 0) {
                    const raised = materials.topRow.filter(m => m.material === ObstacleMaterial.BAR_RAISED).length
                    if (raised >= 1) {
                        raiseDelta = (materials.raisedHeight / 100) / raised
                    }
                }
            }
            if (!(kind === 'corner-oxer') && !(kind === 'corner-triple-barre')) {
                const gap = spread / (horizontalBars - 1)
                let h = height, ph = height
                for (let i = -spread / 2, j = 0; i <= spread / 2; i += gap, j++) {
                    const p = pos.add(new B.Vector3(0, 0, i))
                    if (materials && materials.topRow.length >= horizontalBars && materials.topRow[j].material === ObstacleMaterial.BAR_RAISED) {
                        ph = h
                        h += raiseDelta
                    }
                    this._createSingleObstacle(group, postMaterial, p, gap, length, h, ph, false /* j > 0  */,
                        j === 0 ? verticalBars : 1, j, materials)
                }
            } else {
                let a = -Math.asin((spread / 2) / length) 
                const angleSpan = Math.abs(2 * a)
                let h = height, ph = height
                let angleStep = horizontalBars > 1 ? angleSpan / (horizontalBars - 1) : angleSpan
                for (let i = 1; i <= horizontalBars; i++) {
                    const oneGroup = new B.TransformNode('oneGroup', scene)
                    oneGroup.parent = group
                    const p = pos
                    if (materials && materials.topRow.length >= horizontalBars && materials.topRow[i - 1].material === ObstacleMaterial.BAR_RAISED) {
                        ph = h
                        h += raiseDelta
                    }
                    this._createSingleObstacle(oneGroup, postMaterial, p, 0, length, h, ph, false, i === 1 ? verticalBars : 1, i - 1, materials)
                    const cornerEnd = p.add(new B.Vector3(length / 2, 0, 0))
                    oneGroup.rotateAround(cornerEnd, new B.Vector3(0, 1, 0), a)
                    a += angleStep
                }
            }
        } else {
            if (kind === 'ditch') {
                this._createDitch(pos, length, spread)
            } else if (kind === 'wall') {
                this._createWall(pos, length, spread, height)
            }
        }

        if (decorations.grass) {
            this._createGrass(pos.add(new B.Vector3(-length / 2, 0, 0)), this._grassSize, spread + this._grassSize)
            this._createGrass(pos.add(new B.Vector3(length / 2, 0, 0)), this._grassSize, spread + this._grassSize)
        }
        if (decorations.water) {
            this._createWater(pos, length + this._grassSize, spread + this._grassSize)
        }
        if (decorations.singlePond && decorations.pondLeft || decorations.doublePond) {
            const size = length / 4
            this._createPond(pos.add(new B.Vector3(-size, 0, 0)), size * 0.9, size * 0.8)
        }
        if (decorations.singlePond && decorations.pondRight || decorations.doublePond) {
            const size = length / 4
            this._createPond(pos.add(new B.Vector3(size, 0, 0)), size * 0.9, size * 0.8)
        }
        if (decorations.singlePond && !decorations.pondLeft && !decorations.pondRight && !decorations.doublePond) {
            const size = (Math.min(spread, length - this._grassSize)) * 0.5
            this._createPond(pos, size, size)
        }

        group.rotateAround(pos, new B.Vector3(0, 1, 0), this.angle)
        return group
    }

    private _createLabel(
        text: string, pos: B.Vector3, 
        standWidth: number, standHeight: number, standDepth: number, round: number,
        direction: Direction): B.TransformNode
    {
        const scene = this.scene, mm = this.materialManager
        const labelGroup = new B.TransformNode('labelGroup', scene)
        const stand = B.MeshBuilder.CreateBox('label', { width: standWidth, height: standHeight, depth: standDepth }, scene)
        
        if (round === 2) {
            stand.material = mm.getMaterial('label2-obstacle', (name: string) => {
                const m = new B.StandardMaterial(name, scene)
                m.diffuseColor = new B.Color3(1, 1, 0.5)
                m.specularColor = new B.Color3(1, 1, 0)
                return m
            })
        } else if (round === 3) {
            stand.material = mm.getMaterial('label3-obstacle', (name: string) => {
                const m = new B.StandardMaterial(name, scene)
                m.diffuseColor = new B.Color3(1, 0.5, 0)
                m.specularColor = new B.Color3(1, 0, 0)
                return m
            })
        } else {
            stand.material = mm.getMaterial('label1-obstacle', (name: string) => {
                const m = new B.StandardMaterial(name, scene)
                m.diffuseColor = B.Color3.White()
                return m
            })
        }
        stand.checkCollisions = true
        stand.parent = labelGroup
        this.shadowGenerator.addShadowCaster(stand)

        const labelPlane = B.MeshBuilder.CreatePlane('labelPlane', { width: standWidth * 0.7, height: standHeight * 0.7 }, scene)
        labelPlane.position.set(0, 0, -standDepth / 2 - 0.01)
        labelPlane.material = mm.getTextMaterial(scene, text)
        labelPlane.parent = labelGroup
        labelGroup.position = pos.add(new B.Vector3(0, standHeight / 2, 0))
        labelGroup.rotateAround(this._posCenter, new B.Vector3(0, 1, 0), this.angle + (direction === Direction.forward ? 0 : Math.PI))
        return labelGroup
    }

    addLabel(label: string, round: number, direction: Direction) {
        if (this._labels.indexOf(label) >= 0) {
            return
        }
        if (this._direction !== direction) {
            this._direction = direction
            this._group.rotateAround(this._posCenter, new B.Vector3(0, 1, 0), Math.PI)
        }
        const idx = (direction === Direction.forward ? 0 : 1)
        const c = this._labelsPerDirection[idx]++
        const width = 0.75, height = 0.75, depth = 0.075, gap = 0.1
        const l = this._createLabel(
            label,
            this._posCenter.add(
                new B.Vector3(this._length / 2 + width / 2 + (c > 0 ? width + gap : 0), 0, -this._width / 2 - depth - this._grassSize / 2)), 
                width, height, depth, round, direction
        )
        this._labels.push(label)
    }
    
    constructor(
        protected scene: B.Scene,
        protected materialManager: MaterialManager,
        protected shadowGenerator: B.ShadowGenerator,
        o: DesignDrawnObstacleObject,
        design: DesignSchema,
        private skyBox: B.Mesh | undefined,
        private ground: B.Mesh | undefined)
    {
        super(scene, o, design)
        this._posCenter = new B.Vector3(this.x, 0, this.y)

        let bars = 1, width = 100
        if (o.kind === 'vertical-vector' || o.kind === 'gate') {
            width = 0.1
        } else if (o.kind === 'oxer-vector' || o.kind === 'corner-oxer') {
            width = o.width || design.oxerWidth
            bars = 2
        } else if (o.kind === 'triple-barre-vector' || o.kind === 'corner-triple-barre') {
            width = o.width || design.tripleBarWidth
            bars = 3
        } else if (o.kind === 'ditch') {
            width = o.width || design.ditchWidth
            bars = 0
        } else if (o.kind === 'wall') {
            width = o.width || design.wallWidth
            bars = 0
        }

        this._group = this._group = new B.TransformNode('obstacleGroup', scene)
        this._createObstacle(
            this._posCenter, 
            this._width = width / 100,
            this._length = (o.length || design.obstacleLength) / 100, 
            this._height = (o.height || design.obstacleHeight) / 100, 
            bars,
            o.decorations,
            o.kind,
            o.materials ?? undefined,
        )
    }
}