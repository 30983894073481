import { Component } from '@angular/core';

import { AuthService } from '../services/auth.service';
import { CommonModule } from '@angular/common'
import { HomeComponent } from '../home/home.component'
import { LoginPageComponent } from '../login-page/login-page.component'

@Component({
    selector: 'app-main-page',
    templateUrl: './main-page.component.html',
    styleUrls: ['./main-page.component.scss'],
    imports: [ CommonModule, HomeComponent, LoginPageComponent ],
    standalone: true,
})
export class MainPageComponent {

    constructor(
        protected auth: AuthService,
    ) {
    }

}
