import paper from 'paper';
import { DesignBar, DesignMaterials, ObstacleMaterial } from '../design.schema';
import { OutlinedText } from './detail.paper.extensions';

export class ObstacleBar extends DesignBar {
    shape?: paper.Item
    txtShape?: OutlinedText
    constructor(material: ObstacleMaterial, label?: string, color1?: string, color2?: string) {
        super()
        this.material = material
        this.label = label || ''
        this.assignDefaultColors()
        this.color1 = color1 || this.color1
        this.color2 = color2 || this.color2
    }

    toJson(): DesignBar {
        return {
            material: this.material,
            label: this.label,
            color1: this.color1,
            color2: this.color2,
        } as DesignBar
    }
}

export class ObstacleMaterials {
    topRow: ObstacleBar[] = []
    rows: ObstacleBar[] = []
    svg?: string
    viewBox?: string
    width?: number
    height?: number
    raisedHeight: number

    group: paper.Group = new paper.Group()

    set position(pos: paper.Point) {
        this.group.position = pos
    }

    get position(): paper.Point {
        return this.group.position
    }

    set visible(v: boolean) {
        this.group.visible = v
    }

    get visible(): boolean {
        return this.group.visible
    }

    get size(): paper.Size {
        return this.group.internalBounds.size
    }

    get maxRows(): number {
        return DesignMaterials.maxRows
    }

    containsRaisedBar(): boolean {
        return this.topRow.filter(r => r.material === ObstacleMaterial.BAR_RAISED).length > 0
    }
    
    // barsNumber - 1 for vertical, 2 for oxer, ...
    constructor(private barsNumber: number, private readonly color: string, private colorfulMaterials: boolean, materials?: DesignMaterials) {
        if (!materials) {
            this.raisedHeight = 0
            for (let i = 0; i < barsNumber; i++) {
                this.topRow.push(new ObstacleBar(i === 0 ? ObstacleMaterial.BAR : ObstacleMaterial.BAR_RAISED))
            }
            for (let i = 0; i < DesignMaterials.defaultRows - 1; i++) {
                this.rows.push(new ObstacleBar(ObstacleMaterial.BAR))
            }
        } else {
            this.raisedHeight = materials.raisedHeight
            materials.topRow.forEach(r => {
                this.topRow.push(new ObstacleBar(r.material, r.label, r.color1 ?? undefined, r.color2 ?? undefined))
            })
            materials.rows.forEach(r => {
                this.rows.push(new ObstacleBar(r.material, r.label, r.color1 ?? undefined, r.color2 ?? undefined))
            })
            this.visible = materials.visible
        }
    }

    draw(drawLabels: boolean) {
        let scale = 0.5
        let gapx = 30 * scale, gapy = 50 * scale
        let pos = new paper.Point(0, 0)
        this.group.removeChildren()
        let shape
        for (let i = 0; i < this.barsNumber && i < this.topRow.length; i++) {
            const row = this.topRow[i]
            if (shape) {
                pos = pos.add([shape.internalBounds.size.width / 2, row.material === ObstacleMaterial.BAR_RAISED ? -shape.internalBounds.size.height / 2 : 0])
            }
            shape = this._drawBar(pos, row, scale)
            row.shape = shape
            if (shape) {
                if (i > 0) {
                    pos = pos.add([shape.internalBounds.size.width / 2 + gapx, 0])
                }
                shape.position = pos
                if (row.label && drawLabels) {
                    const txt = this._addLabel(row.label, scale)
                    if (i === 0) {
                        txt.point = new paper.Point(-txt.bounds.width - 100 * scale, txt.bounds.height / 4)
                    } else if (i === 1) {
                        txt.point = new paper.Point(pos.x, pos.y + shape.bounds.height + txt.bounds.height / 2)
                    } else if (i === 2) {
                        txt.point = new paper.Point(pos.x + shape.bounds.width, pos.y + txt.bounds.height / 4)
                    }
                    this.group.addChild(txt)
                    row.txtShape = txt
               }
            }
        }
        if (this.topRow[0] && this.topRow[0].shape) {
            pos = new paper.Point(0, this.topRow[0].shape.internalBounds.height / 2)
        }
        shape = undefined
        for (let i = 0; i < this.rows.length; i++) {
            const row = this.rows[i]
            if (shape) {
                pos = pos.add([0, shape.internalBounds.height / 2])
            }
            shape = this._drawBar(pos, row, scale)
            row.shape = shape
            if (shape) {
                if (row.label && drawLabels) {
                    const txt = this._addLabel(row.label, scale)
                    txt.point = new paper.Point(-txt.bounds.width - 100 * scale, pos.y + shape.bounds.height / 2 + txt.bounds.height / 2)
                    this.group.addChild(txt)
                    row.txtShape = txt
                }
                pos = pos.add([0, shape.internalBounds.size.height / 2 + gapy])
                shape.position = pos
            }
        }
    }

    private _addLabel(label: string, scale: number): OutlinedText {
        return new OutlinedText({
            content: label,
            fillColor: this.color,
            fontSize: 110 * scale
        })
    }

    generateSvg() {
        const pos = this.group.position
        this.group.position = new paper.Point(0, 0)
        this.svg = this.group.exportSVG({
            asString: true
        }) as string
        const s = this.size
        const m = 10
        const w = (s.width / s.height < 0.25) ? s.height * 0.25 : s.width
        this.width = w + m * 2
        this.height = s.height + m * 2
        this.viewBox = (-w / 2 - m) + ' ' + (-s.height / 2 - m) + ' ' + this.width + ' ' + this.height
        this.group.position = pos
    }

    private _drawBar(pos: paper.Point, bar: ObstacleBar, scale: number): paper.Item | undefined {
        const material = bar.material
        let barRadius = 50 * scale, plankWidth = 75 * scale, plankHeight = 150 * scale
        let bricksWidth = 150 * scale, brickHeight = 150 * scale
        const options = {
            fillColor: this.colorfulMaterials ? bar.color1 : this.color,
            strokeColor: this.color,
            strokeWidth: 1,
            strokeScaling: false
        }
        let shape: paper.Item | undefined
        if (material === ObstacleMaterial.BAR || material === ObstacleMaterial.BAR_RAISED) {
            const c1 = new paper.Shape.Circle({
                ...options,
                radius: barRadius,
                center: pos,
            })
            if (bar.color1 !== bar.color2 && this.colorfulMaterials) {
                const g = new paper.Group()
                const c2 = new paper.Path.Arc({
                    ...options,
                    fillColor: bar.color2,
                    from: [pos.x - barRadius, pos.y],
                    through: [pos.x, pos.y + barRadius],
                    to: [pos.x + barRadius, pos.y],
                })
                g.addChild(c1)
                g.addChild(c2)
                shape = g
            } else {
                shape = c1
            }
        } else if (material === ObstacleMaterial.PLANK) {
            shape  = new paper.Shape.Rectangle({
                ...options,
                position: pos,
                size: [plankWidth, plankHeight],
            })
        } else if (material === ObstacleMaterial.BRICKS) {
            const w = bricksWidth, h = brickHeight
            shape = new paper.Path({
                ...options,
                strokeWidth: 2,
                fillColor: null,
                segments: [[-w / 2, h / 2], [w / 2, h / 2], [0, h / 2], [0, -h / 2]]
            })
            shape.position = pos
        }
        if (shape) {
            this.group.addChild(shape)
        }
        return shape
    }

    addMaterialRow() {
        const len = this.rows.length
        if (len < DesignMaterials.maxRows - 1) {
            let lastRow
            if (len > 0) {
                lastRow = this.rows[len - 1]
            } else {
                lastRow = this.topRow[0]
            }
            const newMat = new ObstacleBar(lastRow.material, '', lastRow.color1 ?? undefined, lastRow.color2 ?? undefined)
            this.rows.push(newMat)
            if (lastRow.material === ObstacleMaterial.BRICKS) {
                lastRow.material = ObstacleMaterial.BAR
                lastRow.assignDefaultColors()
            }
        }
    }

    removeMaterialRow() {
        const len = this.rows.length
        if (len > 0) {
            const lastRow = this.rows[len - 1]
            if (len > 1) {
                const prevRow = this.rows[len - 2]
                if (lastRow.material === ObstacleMaterial.BRICKS) {
                    prevRow.material = lastRow.material
                }
            }
            this.rows.pop()
        }
    }

    destroy() {
        if (this.group.isInserted()) {
            this.group.remove()
        }
    }

    clone(): ObstacleMaterials {
        const m = new ObstacleMaterials(this.barsNumber, this.color, this.colorfulMaterials)
        m.group.removeChildren()
        m.topRow = []
        this.topRow.forEach(r => {
            m.topRow.push(new ObstacleBar(r.material, r.label, r.color1 ?? undefined, r.color2 ?? undefined))
        })
        m.rows = []
        this.rows.forEach(r => {
            m.rows.push(new ObstacleBar(r.material, r.label, r.color1 ?? undefined, r.color2 ?? undefined))
        })
        m.raisedHeight = this.raisedHeight
        return m
    }

    toJson(): DesignMaterials {
        return {
            topRow: this.topRow.map(r => r.toJson()),
            rows: this.rows.map(r => r.toJson()),
            visible: this.visible,
            raisedHeight: this.raisedHeight,
        } as DesignMaterials
    }
}

