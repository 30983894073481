import { KeyShortcuts, ShortcutGroup } from "./detail.ui.shortcuts"
import { ParkourDialogId } from "../dialog.interface"
import { getWPWebLink, PriorityArray, toFirstLowerCase } from "../utils"
import { ToggleMenuItem } from "./detail.ui.toggle"
import { ParkourObjectGroup } from "./detail.group"
import { Selectable } from "./detail.selection"
import { ObstacleWithBars } from "./parkour-objects/obstacle-with-bars"
import { Obstacle } from "./parkour-objects/obstacle"
import { PathObject } from "./parkour-objects/path-object"
import { KeyModifiers, UiAccepts, UiButton, UiCommand, UiCommandId, UiContextMenuItem, UiGroupId, UiMenu, UiParams } from "./detail.ui.commands.defs"
import { Drawing } from "./parkour-objects/drawing"
import { DetailComponentInterface } from "./detail.component.interface"
import { Parkour3dRenderComponent } from "../parkour-render-dialog/parkour-renderer-dialog.component"

export class UiCommands {

    commands: UiCommand[] = [{
        id: UiCommandId.NONE,
        group: UiGroupId.NONE,
        label: $localize`Brak poleceń dla tego obiektu`,
        contextMenu: {
            disabled: true,
            getDisabled: () => true,
            order: 0,
            accepts: UiAccepts.SINGLE
        },
    }, {
        id: UiCommandId.CHOOSE_OBJECT_PROMPT,
        group: UiGroupId.NONE,
        label: $localize`Wybierz obiekt`,
        icon: 'fa-solid fa-arrow-pointer',
        contextMenu: {
            disabled: true,
            getDisabled: () => true,
            order: 0,
            accepts: UiAccepts.SINGLE
        },
    }, {
        id: UiCommandId.CREATE_NEW_DESIGN,
        group: UiGroupId.PROJECT,
        label: $localize`Utwórz nowy konkurs`,
        icon: 'pi pi-fw pi-file-o',
        shortcut: { default: 'n', modifiers: KeyModifiers.CONTROL },
        menu: { separatorAfter: true },
        command: (params: UiParams) => {
            params.view.createNewDesign()
        }
    }, {
        id: UiCommandId.COMP_PARAMS,
        group: UiGroupId.PROJECT,
        label: $localize`Parametry konkursu`,
        icon: 'pi pi-fw pi-table',
        shortcut: { default: 'p' },
        menu: {},
        command: (params: UiParams) => {
            params.view.openDialog(ParkourDialogId.COMPETITION_PARAMS)
        }
    }, {
        id: UiCommandId.CHANGE_FIELD_SIZE,
        group: UiGroupId.PROJECT,
        label: $localize`Rozmiar placu`,
        icon: 'fa-solid fa-maximize',
        shortcut: { default: 'f', modifiers: KeyModifiers.CONTROL },
        menu: {},
        command: (params: UiParams) => {
            params.view.openDialog(ParkourDialogId.FIELD_SIZE)
        }
    }, {
        id: UiCommandId.VIEW_PARAMS,
        group: UiGroupId.PROJECT,
        label: $localize`Opcje wyświetlania`,
        icon: 'fa-solid fa-list-check',
        shortcut: { default: 'p', modifiers: KeyModifiers.SHIFT },
        menu: { separatorAfter: true },
        command: (params: UiParams) => {
            params.view.openDialog(ParkourDialogId.DISPLAY_OPTIONS)
        }
    }, {
        id: UiCommandId.STATS,
        group: UiGroupId.PROJECT,
        label: $localize`Statystyki projektu`,
        icon: 'fa-solid fa-list-ol',
        shortcut: { default: 's' },
        menu: {},
        command: (params: UiParams) => {
            params.view.openDialog(ParkourDialogId.DESIGN_STATS)
        }
    }, {
        id: UiCommandId.RENDER,
        group: UiGroupId.PROJECT,
        label: $localize`Model 3D`,
        icon: 'fa-solid fa-cube',
        shortcut: { default: 's', modifiers: KeyModifiers.META },
        menu: {
            separatorAfter: true,
            disabled: !Parkour3dRenderComponent.is3dSupported           
        },
        command: (params: UiParams) => {
            if (Parkour3dRenderComponent.is3dSupported) {
                params.view.openDialog(ParkourDialogId.RENDER)
            }
        }
    }, {
        id: UiCommandId.PRINT,
        group: UiGroupId.PROJECT,
        label: $localize`Drukuj`,
        icon: 'pi pi-print',
        shortcut: { default: 'p', modifiers: KeyModifiers.CONTROL },
        menu: {},
        command: (params: UiParams) => {
            params.view.validateParkour(true, () => params.view.openPDFDialog('print'))
        }
    }, {
        id: UiCommandId.SAVE_PDF,
        group: UiGroupId.PROJECT,
        label: $localize`Zapisz jako PDF`,
        icon: 'fa-regular fa-file-pdf',
        shortcut: { default: 's', modifiers: KeyModifiers.CONTROL },
        menu: {},
        command: (params: UiParams) => {
            params.view.validateParkour(true, () => params.view.openPDFDialog('save'))
        }
    }, {
        id: UiCommandId.VALIDATE_PARKOUR,
        group: UiGroupId.PROJECT,
        label: $localize`Sprawdź projekt`,
        icon: 'fa-regular fa-check-square',
        shortcut: { default: 'v' },
        menu: { separatorAfter: true },
        command: (params: UiParams) => {
            params.view.validateParkour(true)
        }
    }, {
        id: UiCommandId.EXIT_TO_LIST_OF_DESIGNS,
        group: UiGroupId.PROJECT,
        label: $localize`Wyjdź do listy projektów`,
        icon: 'fa fa-sign-out',
        shortcut: { default: 'w', modifiers: KeyModifiers.CONTROL },
        menu: {},
        command: (params: UiParams) => {
            params.view.exitToListOfDesigns()
        }
    }, {
        id: UiCommandId.OPEN_DEBUG,
        group: UiGroupId.PROJECT,
        label: '',
        shortcut: { default: 'd', modifiers: KeyModifiers.CONTROL },
        command: (params: UiParams) => {
            params.view.openDialog(ParkourDialogId.DEBUG_OPTIONS)
        }
    }, {
        id: UiCommandId.PAN_FIELD,
        group: UiGroupId.VIEW,
        label: $localize`Przesuwaj pole`,
        shortcut: { default: ' ', ro: true },
    }, {
        id: UiCommandId.CENTER_VIEW,
        group: UiGroupId.VIEW,
        icon: 'fa-solid fa-arrows-to-dot',
        label: $localize`Wycentruj widok`,
        shortcut: { default: '0' },
        menu: { separatorAfter: true },
        command: (params: UiParams) => {
            params.view.resetView()
        }
    }, {
        id: UiCommandId.ZOOM_IN,
        group: UiGroupId.VIEW,
        label: $localize`Powiększ`,
        icon: 'pi pi-search-plus',
        shortcut: { default: '=' },
        menu: {},
        command: (params: UiParams) => {
            params.view.doZoomDelta(5)
        }
    }, {
        id: UiCommandId.ZOOM_OUT,
        group: UiGroupId.VIEW,
        label: $localize`Pomniejsz`,
        icon: 'pi pi-search-minus',
        shortcut: { default: '-' },
        menu: {},
        command: (params: UiParams) => {
            params.view.doZoomDelta(-5)
        }
    }, {
        id: UiCommandId.ZOOM_100_PERCENT,
        group: UiGroupId.VIEW,
        label: $localize`Ustaw powiększenie na 100%`,
        icon: 'pi pi-search',
        shortcut: { default: '0', modifiers: KeyModifiers.CONTROL },
        menu: { separatorAfter: true },
        command: (params: UiParams) => {
            params.view.zoom100Percent()
        }
    }, {
        id: UiCommandId.TOGGLE_ROUTE_1,
        group: UiGroupId.VIEW,
        label: $localize`Widoczność trasy 1`,
        icon: 'fa-solid fa-route',
        shortcut: { default: '1' },
        toggle: {
            onOffState: true,
            labelOn: $localize`Ukryj trasę 1`,
            labelOff: $localize`Pokaż trasę 1`,
        },
        command: (params: UiParams) => {
            params.view.toggleRouteVisibility(1)
        }
    }, {
        id: UiCommandId.TOGGLE_ROUTE_2,
        group: UiGroupId.VIEW,
        label: $localize`Widoczność trasy 2`,
        icon: 'fa-solid fa-route',
        shortcut: { default: '2' },
        toggle: {
            onOffState: true,
            labelOn: $localize`Ukryj trasę 2`,
            labelOff: $localize`Pokaż trasę 2`,
        },
        command: (params: UiParams) => {
            params.view.toggleRouteVisibility(2)
        }
    }, {
        id: UiCommandId.TOGGLE_ROUTE_25,
        group: UiGroupId.VIEW,
        label: $localize`Widoczność trasy 3`,
        icon: 'fa-solid fa-route',
        shortcut: { default: '3' },
        toggle: {
            onOffState: true,
            labelOn: $localize`Ukryj trasę 3`,
            labelOff: $localize`Pokaż trasę 3`,
        },
        command: (params: UiParams) => {
            params.view.toggleRouteVisibility(3)
        }
    }, {
        id: UiCommandId.TOGGLE_ROUTE_3,
        group: UiGroupId.VIEW,
        label: $localize`Widoczność trasy poza rundami`,
        icon: 'fa-solid fa-route',
        shortcut: { default: '4' },
        toggle: {
            onOffState: true,
            labelOn: $localize`Ukryj trasę poza rundami`,
            labelOff: $localize`Pokaż trasę poza rundami`,
            separatorAfter: true,
        },
        command: (params: UiParams) => {
            params.view.toggleRouteVisibility(0)
        }
    }, {
        id: UiCommandId.TOGGLE_LABELS_1,
        group: UiGroupId.VIEW,
        label: $localize`Widoczność numerów przeszkód trasy 1`,
        icon: 'fa-solid fa-tag',
        shortcut: { default: '1', modifiers: KeyModifiers.META },
        toggle: {
            onOffState: true,
            labelOn: $localize`Ukryj numery przeszkód trasy 1`,
            labelOff: $localize`Pokaż numery przeszkód trasy 1`,
        },
        command: (params: UiParams) => {
            params.view.toggleLabelsVisibility(1)
        }
    }, {
        id: UiCommandId.TOGGLE_LABELS_2,
        group: UiGroupId.VIEW,
        label: $localize`Widoczność numerów przeszkód trasy 2`,
        icon: 'fa-solid fa-tag',
        shortcut: { default: '2', modifiers: KeyModifiers.META },
        toggle: {
            onOffState: true,
            labelOn: $localize`Ukryj numery przeszkód trasy 2`,
            labelOff: $localize`Pokaż numery przeszkód trasy 2`,
        },
        command: (params: UiParams) => {
            params.view.toggleLabelsVisibility(2)
        }
    }, {
        id: UiCommandId.TOGGLE_LABELS_25,
        group: UiGroupId.VIEW,
        label: $localize`Widoczność numerów przeszkód trasy 3`,
        icon: 'fa-solid fa-tag',
        shortcut: { default: '3', modifiers: KeyModifiers.META },
        toggle: {
            onOffState: true,
            labelOn: $localize`Ukryj numery przeszkód trasy 3`,
            labelOff: $localize`Pokaż numery przeszkód trasy 3`,
            separatorAfter: true,
        },
        command: (params: UiParams) => {
            params.view.toggleLabelsVisibility(3)
        }
    }, {
        id: UiCommandId.TOGGLE_DISTANCES_1,
        group: UiGroupId.VIEW,
        label: $localize`Widoczność odległości trasy 1`,
        icon: 'fa-solid fa-arrows-h',
        shortcut: { default: '1', modifiers: KeyModifiers.CONTROL },
        toggle: {
            onOffState: true,
            labelOn: $localize`Ukryj odległości trasy 1`,
            labelOff: $localize`Pokaż odległości trasy 1`,
        },
        command: (params: UiParams) => {
            params.view.toggleDistancesVisibility(1)
        }
    }, {
        id: UiCommandId.TOGGLE_DISTANCES_2,
        group: UiGroupId.VIEW,
        label: $localize`Widoczność odległości trasy 2`,
        icon: 'fa-solid fa-arrows-h',
        shortcut: { default: '2', modifiers: KeyModifiers.CONTROL },
        toggle: {
            onOffState: true,
            labelOn: $localize`Ukryj odległości trasy 2`,
            labelOff: $localize`Pokaż odległości trasy 2`,
        },
        command: (params: UiParams) => {
            params.view.toggleDistancesVisibility(2)
        }
    }, {
        id: UiCommandId.TOGGLE_DISTANCES_25,
        group: UiGroupId.VIEW,
        label: $localize`Widoczność odległości trasy 3`,
        icon: 'fa-solid fa-arrows-h',
        shortcut: { default: '3', modifiers: KeyModifiers.CONTROL },
        toggle: {
            onOffState: true,
            labelOn: $localize`Ukryj odległości trasy 3`,
            labelOff: $localize`Pokaż odległości trasy 3`,
        },
        command: (params: UiParams) => {
            params.view.toggleDistancesVisibility(3)
        }
    }, {
        id: UiCommandId.TOGGLE_DISTANCES_3,
        group: UiGroupId.VIEW,
        label: $localize`Widoczność odległości poza rundami`,
        icon: 'fa-solid fa-arrows-h',
        shortcut: { default: '4', modifiers: KeyModifiers.CONTROL },
        toggle: {
            onOffState: true,
            labelOn: $localize`Ukryj odległości poza rundami`,
            labelOff: $localize`Pokaż odległości poza rundami`,
            separatorAfter: true,
        },
        command: (params: UiParams) => {
            params.view.toggleDistancesVisibility(0)
        }
    }, {
        id: UiCommandId.TOGGLE_PAGE_LIMITS,
        group: UiGroupId.VIEW,
        label: $localize`Widoczność granic kartki`,
        icon: 'fa-regular fa-square-full',
        shortcut: { default: '6' },
        toggle: {
            onOffState: true,
            labelOn: $localize`Ukryj granice kartki`,
            labelOff: $localize`Pokaż granice kartki`,
        },
        command: (params: UiParams) => {
            params.view.togglePageLimitsVisibility()
        }
    }, {
        id: UiCommandId.TOGGLE_GRID,
        group: UiGroupId.VIEW,
        label: $localize`Widoczność siatki`,
        icon: 'fa-solid fa-border-none',
        shortcut: { default: '7' },
        toggle: {
            onOffState: false,
            labelOn: $localize`Ukryj siatkę`,
            labelOff: $localize`Pokaż siatkę`,
        },
        command: (params: UiParams) => {
            params.view.toggleGridVisibility()
        }
    }, {
        id: UiCommandId.TOGGLE_CONTROL_POINTS,
        group: UiGroupId.VIEW,
        label: $localize`Widoczność punktów kontrolnych ścieżki`,
        icon: 'fa-solid fa-bezier-curve',
        shortcut: { default: '8' },
        toggle: {
            onOffState: false,
            labelOn: $localize`Ukryj punkty kontrolne ścieżki`,
            labelOff: $localize`Pokaż punkty kontrolne ścieżki`,
        },
        command: (params: UiParams) => {
            params.view.toggleControlPointsVisibility()
        }
    }, {
        id: UiCommandId.TOGGLE_POSITION_LINES,
        group: UiGroupId.VIEW,
        label: $localize`Widoczność linii odległości końców przeszkody`,
        icon: 'fa-solid fa-arrows-left-right-to-line',
        shortcut: { default: '9' },
        toggle: {
            onOffState: false,
            labelOn: $localize`Ukryj linie odległości końców przeszkody`,
            labelOff: $localize`Pokaż linie odległości końców przeszkody`,
        },
        command: (params: UiParams) => {
            params.view.toggleLayoutLinesVisibility()
        }
    }, {
        id: UiCommandId.UNDO,
        group: UiGroupId.EDIT,
        label: $localize`Cofnij`,
        icon: 'fa-solid fa-undo',
        shortcut: { default: 'z', modifiers: KeyModifiers.CONTROL },
        menu: {},
        command: (params: UiParams) => {
            params.view.doUndoRedo(true)
        }
    }, {
        id: UiCommandId.REDO,
        group: UiGroupId.EDIT,
        label: $localize`Ponów`,
        icon: 'fa-solid fa-redo',
        shortcut: { default: 'z', modifiers: KeyModifiers.CONTROL | KeyModifiers.SHIFT },
        menu: { separatorAfter: true },
        command: (params: UiParams) => {
            params.view.doUndoRedo(false)
        }
    }, {
        id: UiCommandId.DELETE_ALL_FORWARD_PATHS,
        group: UiGroupId.EDIT,
        label: $localize`Uprość trasę`,
        description: $localize`Usuwa jakiekolwiek ręczne modyfikacje krzywej trasy.`,
        icon: 'fa-solid fa-minus',
        shortcut: { default: 'k', modifiers: KeyModifiers.SHIFT },
        menu: {},
        command: (params: UiParams) => {
            params.view.straightenAllForwardPaths()
        }
    }, {
        id: UiCommandId.CLEAR_PATH,
        group: UiGroupId.EDIT,
        label: $localize`Usuń trasę`,
        description: $localize`Usuwa trasę ale przeszkody pozostają na placu bez zmian.`,
        icon: 'pi pi-eraser fa-lg',
        shortcut: { default: 'r', modifiers: KeyModifiers.SHIFT },
        menu: {},
        command: (params: UiParams) => {
            params.view.clearPath()
            params.view.saveData()
            params.view.adjustTablesInRightPanel()  
        }
    }, {
        id: UiCommandId.CLEAR_FIELD,
        group: UiGroupId.EDIT,
        label: $localize`Wyczyść plac`,
        description: $localize`Usuwa z placu wszystkie przeszkody i obiekty. Przywraca stan placu do inicjalnej postaci, tylko ze Startem.`,
        icon: 'fa-regular fa-trash-can',
        shortcut: { default: 'Delete', modifiers: KeyModifiers.META },
        menu: {},
        command: (params: UiParams) => {
            params.view.reset()
            params.view.saveData()
        }
    }, {
        id: UiCommandId.CLEAR_ALL_MANUAL_LABELS,
        group: UiGroupId.EDIT,
        label: $localize`Wyczyść wszystkie wpisane numery przeszkód`,
        description: $localize`Usuwa wszystkie numery przeszkód ręcznie przypisane przez użytkownika.`,
        icon: 'fa-regular fa-circle-xmark',
        shortcut: { default: 'n', modifiers: KeyModifiers.SHIFT },
        menu: { separatorAfter: true },
        command: (params: UiParams) => {
            params.view.clearManualLabels()
        }
    }, {
        id: UiCommandId.CHANGE_PATH_EDIT_MODE,
        group: UiGroupId.EDIT,
        label: $localize`Przełącz tryb łączenia przeszkód`,
        icon: 'fa-solid fa-plug',
        shortcut: { default: 'e' },
        toggle: {
            onOffState: false,
            labelOn: $localize`Przełącz w tryb łączenia przeszkód liniami`,
            labelOff: $localize`Przełącz w tryb rysowania ścieżki`,
        },
        command: (params: UiParams) => {
            params.view.togglePathEditMode()
        }
    }, {
        id: UiCommandId.TAPE_MEASURE,
        group: UiGroupId.EDIT,
        label: $localize`Użyj miarkę`,
        icon: 'fa-solid fa-ruler',
        shortcut: { default: 'm', modifiers: KeyModifiers.SHIFT },
        toggle: {
            onOffState: false,
            labelOn: $localize`Skończ używać miarki`,
            labelOff: $localize`Użyj miarkę`,
            tooltip: $localize`Miarka odległości.` + '\n\n' + $localize`Trzymaj wciśnięty shift aby zmierzyć odcinek prosty.`,
        },
        command: (params: UiParams) => {
            params.view.toggleTapeMeasure()
        }
    }, {
        id: UiCommandId.SELECT_MULTIPLE,
        group: UiGroupId.EDIT,
        label: $localize`Zaznacz obiekty`,
        shortcut: { default: 'Shift', ro: true },
    }, {
        id: UiCommandId.BLOCK_OBJECT_SCALE,
        group: UiGroupId.EDIT,
        label: $localize`Nie zmieniaj rozmiaru przy obracaniu`,
        shortcut: { default: 'Control', ro: true },
    }, {
        id: UiCommandId.BLOCK_ROTATE_SNAP,
        group: UiGroupId.EDIT,
        label: $localize`Nie przyciągaj do poziomu/pionu przy obracaniu`,
        shortcut: { default: 'Shift', ro: true },
    }, {
        id: UiCommandId.SELECT_ALL,
        group: UiGroupId.EDIT,
        label: $localize`Zaznacz wszystkie obiekty`,
        shortcut: { default: 'a', modifiers: KeyModifiers.CONTROL },
        command: (params: UiParams) => {
            params.view.selectAllObjects()
        }
    }, {
        id: UiCommandId.CONNECT_PATH,
        group: UiGroupId.EDIT,
        label: $localize`Łącz obiekty w trasę`,
        shortcut: {
            default: 'c',
            keyDownAction: (view: DetailComponentInterface) => {
                view.connectMode = true
                view.updatePath()
            },
            keyUpAction: (view: DetailComponentInterface) => {
                if (view.connectMode) {
                    view.connectMode = false
                    view.updatePath()
                }
            },
            outOfFocusKeyUp: true
        },
    }, {
        id: UiCommandId.ADD_OXER,
        group: UiGroupId.EDIT,
        label: $localize`Dodaj przeszkodę typu okser`,
        shortcut: { default: 'o' },
        command: (params: UiParams) => {
            params.view.addOxer()
        }
    }, {
        id: UiCommandId.GROUP_OBJECTS,
        group: UiGroupId.OBJECT,
        label: $localize`Zgrupuj`,
        description: $localize`Zgrupuj zaznaczone obiekty`,
        icon: 'fa-regular fa-object-group',
        shortcut: { default: 'g' },
        contextMenu: {
            order: 100,
            accepts: UiAccepts.MANY,
            getDisabled: (params: UiParams) => params.items.length <= 1,
        },
        command: (params: UiParams) => {
            params.view.groupObjects(params.flatItems)
        }
    }, {
        id: UiCommandId.UNGROUP_OBJECTS,
        group: UiGroupId.OBJECT,
        label: $localize`Rozdziel grupę`,
        description: $localize`Rozdziel zaznaczone grupy obiektów`,
        icon: 'fa-regular fa-object-ungroup',
        shortcut: { default: 'g', modifiers: KeyModifiers.CONTROL },
        contextMenu: {
            order: 110,
            accepts: UiAccepts.SINGLE | UiAccepts.MANY,
            getDisabled: (params: UiParams) => !params.items.some(i => i instanceof ParkourObjectGroup || i.parentSelector)
        },
        command: (params: UiParams) => {
            params.view.ungroupObjects(params.items)
        }
    }, {
        id: UiCommandId.REMOVE_FROM_GROUP,
        group: UiGroupId.OBJECT,
        label: $localize`Usuń z grupy`,
        description: $localize`Usuń zaznaczone obiekty z grupy`,
        icon: 'fa-solid fa-arrow-right-from-bracket',
        shortcut: { default: 'g', modifiers: KeyModifiers.SHIFT },
        contextMenu: {
            order: 120,
            accepts: UiAccepts.SINGLE | UiAccepts.MANY,
            getDisabled: (params: UiParams) => !params.flatItems.some(s => s.parentSelector),
        },
        command: (params: UiParams) => {
            params.view.removeObjectsFromGroup(params.flatItems)
        }
    }, {
        id: UiCommandId.MAKE_COMBINATION,
        group: UiGroupId.OBJECT,
        label: $localize`Ustaw w linię lub szereg`,
        description: $localize`Ustaw zaznaczone przeszkody w linię lub szereg`,
        icon: 'fa-solid fa-link',
        shortcut: { default: 'q' },
        contextMenu: {
            order: 200,
            accepts: UiAccepts.MANY,
            getDisabled: (params: UiParams) => params.flatItems.filter(s => !(s instanceof ParkourObjectGroup)).some(s => !(s instanceof Obstacle)),
        },
        command: (params: UiParams) => {
            params.view.makeCombination(params.flatItems)
        }
    }, {
        id: UiCommandId.DETACH_OBJECT,
        group: UiGroupId.OBJECT,
        label: $localize`Odczep z trasy`,
        description: $localize`Usuwa zaznaczone obiekty z trasy`,
        icon: 'fa-solid fa-arrow-right-from-bracket',
        shortcut: { default: 'r' },
        contextMenu: {
            order: 230,
            accepts: UiAccepts.SINGLE | UiAccepts.MANY,
            getDisabled: this.allSelectedNotOnCourse,
            options: [{
                label: $localize`W pierwszej rundzie`,
                command: () => this._view.detachFromPath(this._view.selection.selectedFlatItems, 1),
                getDisabled: (params: UiParams) => !params.flatItems.some(i => i instanceof PathObject && i.useCount[0] > 0),
            }, {
                label: $localize`W drugiej rundzie`,
                command: () => this._view.detachFromPath(this._view.selection.selectedFlatItems, 2),
                getDisabled: (params: UiParams) => !params.flatItems.some(i => i instanceof PathObject && i.useCount[1] > 0),
            }, {
                label: $localize`W trzeciej rundzie`,
                command: () => this._view.detachFromPath(this._view.selection.selectedFlatItems, 3),
                getDisabled: (params: UiParams) => !params.flatItems.some(i => i instanceof PathObject && i.useCount[2] > 0),
            }]
        },
        command: (params: UiParams) => {
            params.view.detachFromPath(params.flatItems)
        }
    }, {
        id: UiCommandId.REVERSE_DIRECTION,
        group: UiGroupId.OBJECT,
        label: $localize`Odwróć kierunek`,
        description: $localize`Odwraca kierunek pokonywania przeszkody.`,
        icon: 'fa-solid fa-arrow-right-arrow-left',
        shortcut: { default: 'r', modifiers: KeyModifiers.CONTROL },
        contextMenu: {
            order: 300,
            accepts: UiAccepts.SINGLE | UiAccepts.MANY,
            getDisabled: this.allSelectedNotOnCourse,
            options: [{
                label: $localize`W pierwszej rundzie`,
                command: () => this._view.reverseDirection(this._view.selection.selectedFlatItems, 1),
                getDisabled: (params: UiParams) => !params.flatItems.some(i => i instanceof PathObject && i.useCount[0] > 0),
            }, {
                label: $localize`W drugiej rundzie`,
                command: () => this._view.reverseDirection(this._view.selection.selectedFlatItems, 2),
                getDisabled: (params: UiParams) => !params.flatItems.some(i => i instanceof PathObject && i.useCount[1] > 0),
            }, {
                label: $localize`W trzeciej rundzie`,
                command: () => this._view.reverseDirection(this._view.selection.selectedFlatItems, 3),
                getDisabled: (params: UiParams) => !params.flatItems.some(i => i instanceof PathObject && i.useCount[2] > 0),
            }]
        },
        command: (params: UiParams) => {
            params.view.reverseDirection(params.flatItems)
        }
    }, {
        id: UiCommandId.DELETE_FORWARD_PATH,
        group: UiGroupId.OBJECT,
        label: $localize`Uprość trasę za`,
        description: $localize`Usuwa ręczne modyfikacje krzywej trasy po zaznaczonych przeszkodach.`,
        icon: 'fa-solid fa-minus',
        shortcut: { default: 'k' },
        contextMenu: {
            order: 310,
            accepts: UiAccepts.SINGLE | UiAccepts.MANY,
            getDisabled: this.allSelectedNotOnCourse,
        },
        command: (params: UiParams) => {
            params.view.straightenForwardPath(params.flatItems)
        }
    }, {
        id: UiCommandId.DELETE_ROUTE_FORWARD,
        group: UiGroupId.OBJECT,
        label: $localize`Usuń trasę za`,
        description: $localize`Usuwa trasę po zaznaczonych przeszkodach. Trasa do przeszkód pozostaje bez zmian.`,
        icon: 'pi pi-delete-left fa-lg fa-flip-horizontal',
        shortcut: { default: 't' },
        contextMenu: {
            order: 320,
            accepts: UiAccepts.SINGLE | UiAccepts.MANY,
            getDisabled: this.allSelectedNotOnCourse,
        },
        command: (params: UiParams) => {
            params.view.clearPathForward(params.flatItems)
            params.view.saveData()
            params.view.adjustTablesInRightPanel()    
        }
    }, {
        id: UiCommandId.DELETE_ROUTE_BACKWARD,
        group: UiGroupId.OBJECT,
        label: $localize`Usuń trasę przed`,
        icon: 'pi pi-delete-left fa-lg',
        description: $localize`Usuwa trasę przed zaznaczonymi przeszkodami. Trasa za przeszkodami pozostaje bez zmian.`,
        shortcut: { default: 'f' },
        contextMenu: {
            order: 330,
            accepts: UiAccepts.SINGLE | UiAccepts.MANY,
            getDisabled: this.allSelectedNotOnCourse,
        },
        command: (params: UiParams) => {
            params.view.clearPathBackward(params.flatItems)
            params.view.saveData()
            params.view.adjustTablesInRightPanel()    
        }
    }, {
        id: UiCommandId.SPLIT_PATH_AFTER,
        group: UiGroupId.OBJECT,
        label: $localize`Podziel trasę za`,
        description: $localize`Dzieli trasę za przeszkodą tworząc dwa odrębne odcinki trasy.`,
        icon: 'fa-solid fa-divide',
        shortcut: { default: 't', modifiers: KeyModifiers.CONTROL },
        contextMenu: {
            order: 340,
            accepts: UiAccepts.SINGLE | UiAccepts.MANY,
            getDisabled: this.allSelectedNotOnCourse,
        },
        command: (params: UiParams) => {
            if (params.view.splitPathAfter(params.flatItems)) {
                params.view.saveData()
                params.view.adjustTablesInRightPanel()
            }    
        }
    }, {
        id: UiCommandId.EDIT_MATERIALS,
        group: UiGroupId.OBJECT,
        label: $localize`Edytuj materiały`,
        description: $localize`Otwiera edytor materiałów przeszkody.`,
        icon: 'fa-solid fa-recycle',
        shortcut: { default: 'm' },
        contextMenu: {
            order: 500,
            accepts: UiAccepts.SINGLE,
            getDisabled: (params: UiParams) => {
                const i = params.view.selection.getFocusItem()
                return !(i instanceof ObstacleWithBars && i.bars >= 1)
            },
            options: [{
                label: $localize`Usuń materiały`,
                command: () => this._view.removeMaterials(),
                getDisabled: () => false,
            }]
        },
        command: (params: UiParams) => {
            params.view.editMaterials()
        }
    }, {
        id: UiCommandId.CLEAR_MANUAL_LABELS,
        group: UiGroupId.OBJECT,
        label: $localize`Wyczyść wpisane numery przeszkód`,
        description: $localize`Dla wybranych przeszkód, usuwa numery wpisane ręcznie przez użytkownika.`,
        icon: 'fa-regular fa-circle-xmark',
        shortcut: { default: 'n' },
        contextMenu: {
            order: 600,
            accepts: UiAccepts.SINGLE | UiAccepts.MANY,
            getDisabled: (params: UiParams) => !params.flatItems.some(i => i instanceof Obstacle && i.manualLabels.some(l => l)),
        },
        command: (params: UiParams) => {
            params.view.clearSelectedManualLabels()
        }
    }, {
        id: UiCommandId.AUTO_ROTATE,
        group: UiGroupId.OBJECT,
        label: $localize`Obróć automatycznie`,
        description: $localize`Automatycznie obraca obiekty względem obiektów sąsiednich.`,
        icon: 'fa-solid fa-arrows-spin',
        shortcut: { default: 'a' },
        contextMenu: {
            order: 610,
            accepts: UiAccepts.SINGLE | UiAccepts.MANY,
            getDisabled: this.allSelectedNotOnCourse,
        },
        command: (params: UiParams) => {
            params.view.autoRotateSelectedObjects()
        }
    }, {
        id: UiCommandId.ROTATE_LEFT,
        group: UiGroupId.OBJECT,
        label: $localize`Obróć o 90° w lewo`,
        description: $localize`Obróć zaznaczone obiekty w lewo o 90 stopni`,
        icon: 'fa-solid fa-rotate-left',
        shortcut: { default: '[' },
        contextMenu: {
            order: 620,
            accepts: UiAccepts.SINGLE | UiAccepts.MANY,
            getDisabled: () => false,
        },
        command: (params: UiParams) => {
            params.view.rotateSelection(-90)
        }
    }, {
        id: UiCommandId.ROTATE_RIGHT,
        group: UiGroupId.OBJECT,
        label: $localize`Obróć o 90° w prawo`,
        description: $localize`Obróć zaznaczone obiekty w prawo o 90 stopni`,
        icon: 'fa-solid fa-rotate-right',
        shortcut: { default: ']' },
        contextMenu: {
            order: 630,
            accepts: UiAccepts.SINGLE | UiAccepts.MANY,
            getDisabled: () => false,
        },
        command: (params: UiParams) => {
            params.view.rotateSelection(90)
        }
    }, {
        id: UiCommandId.DELETE_OBJECT,
        group: UiGroupId.OBJECT,
        label: $localize`Usuń`,
        description: $localize`Usuwa z placu zaznaczone przeszkody.`,
        icon: 'fa-regular fa-trash-can',
        shortcut: { default: 'Delete' },
        contextMenu: {
            order: 700,
            accepts: UiAccepts.SINGLE | UiAccepts.MANY,
            getDisabled: () => false,
        },
        command: (params: UiParams) => {
            params.view.deleteObjectsFromUi(params.items)
        }
    }, {
        id: UiCommandId.MOVE_LEFT,
        group: UiGroupId.OBJECT,
        label: $localize`Przesuń obiekt w lewo o pół metra`,
        shortcut: { default: 'ArrowLeft' },
        command: (params: UiParams) => {
            params.view.moveSelection(-50, 0)
        }
    }, {
        id: UiCommandId.MOVE_RIGHT,
        group: UiGroupId.OBJECT,
        label: $localize`Przesuń obiekt w prawo o pół metra`,
        shortcut: { default: 'ArrowRight' },
        command: (params: UiParams) => {
            params.view.moveSelection(50, 0)
        }
    }, {
        id: UiCommandId.MOVE_UP,
        group: UiGroupId.OBJECT,
        label: $localize`Przesuń obiekt w górę o pół metra`,
        shortcut: { default: 'ArrowUp' },
        command: (params: UiParams) => {
            params.view.moveSelection(0, -50)
        }
    }, {
        id: UiCommandId.MOVE_UP,
        group: UiGroupId.OBJECT,
        label: $localize`Przesuń obiekt w dół o pół metra`,
        shortcut: { default: 'ArrowDown' },
        command: (params: UiParams) => {
            params.view.moveSelection(0, 50)
        }
    }, {
        id: UiCommandId.CLONE_OBJECT,
        group: UiGroupId.OBJECT,
        label: $localize`Zduplikuj obiekt i umieść go na obecnej pozycji myszki`,
        shortcut: { default: 'd' },
        command: (params: UiParams) => {
            params.view.cloneFocusItem()
        }
    }, {
        id: UiCommandId.TRACE_PATH,
        group: UiGroupId.OBJECT,
        label: $localize`Prześledź trasę za przeszkodą`,
        shortcut: { default: 'l' },
        command: (params: UiParams) => {
            params.view.traceForwardPath()
        }
    }, {
        id: UiCommandId.ESCAPE,
        group: UiGroupId.OBJECT,
        label: $localize`Przerwanie operacji`,
        shortcut: { default: 'escape', ro: true },
        command: (params: UiParams) => {
            params.view.exitEditMode()
        }
    }, {
        id: UiCommandId.CURVE_SMOOTH_ALL,
        group: UiGroupId.OBJECT,
        label: $localize`Wygładź wszystko`,
        description: $localize`Wygładza wszystkie segmenty krzywej.`,
        icon: 'fa-regular fa-circle',
        contextMenu: {
            order: 10,
            accepts: UiAccepts.SINGLE,
            getDisabled: () => false,
        },
        command: (params: UiParams) => {
            params.flatItems.forEach(i => {
                if (i instanceof Drawing) {
                    i.smoothAllSegments()
                }
            })
        },
    }, {
        id: UiCommandId.CURVE_STRAIGHTEN_ALL,
        group: UiGroupId.OBJECT,
        label: $localize`Wyprostuj wszystko`,
        description: $localize`Likwiduje wygładzenie wszystkich segmentów krzywej.`,
        icon: 'fa-solid fa-vector-square',
        contextMenu: {
            order: 11,
            accepts: UiAccepts.SINGLE,
            getDisabled: (params: UiParams) => !params.flatItems.some(o => o instanceof Drawing && o.path.hasHandles()),
        },
        command: (params: UiParams) => {
            params.flatItems.forEach(i => {
                if (i instanceof Drawing) {
                    i.straightenAllSegments()
                }
            })
        },
    }, {
        id: UiCommandId.CURVE_SMOOTH_POINT,
        group: UiGroupId.OBJECT,
        label: $localize`Wygładź wokół punktu`,
        description: $localize`Wygładza wybrany segment krzywej.`,
        icon: 'fa-solid fa-bezier-curve',
        contextMenu: {
            order: 12,
            accepts: UiAccepts.SINGLE,
            getDisabled: (params: UiParams) => params.flatItems.every(o => o instanceof Drawing &&
                (!o.selectedSegment || o.selectedSegment.hasHandles())),
        },
        command: (params: UiParams) => {
            params.flatItems.forEach(i => {
                if (i instanceof Drawing) {
                    i.smoothSelectedSegment()
                }
            })
        },
    }, {
        id: UiCommandId.CURVE_STRAIGHTEN_POINT,
        group: UiGroupId.OBJECT,
        label: $localize`Wyprostuj wokół punktu`,
        description: $localize`Likwiduje wygładzenie wybranego segmentu krzywej.`,
        icon: 'fa-solid fa-minus',
        contextMenu: {
            order: 13,
            accepts: UiAccepts.SINGLE,
            getDisabled: (params: UiParams) => params.flatItems.every(o => o instanceof Drawing &&
                (!o.selectedSegment || !o.selectedSegment.hasHandles())),
        },
        command: (params: UiParams) => {
            params.flatItems.forEach(i => {
                if (i instanceof Drawing) {
                    i.straightenSelectedSegment()
                }
            })
        },
    }, {
        id: UiCommandId.IMAGE_SELECT,
        group: UiGroupId.OBJECT,
        label: $localize`Zmień obrazek`,
        description: $localize`Zmienia obrazek.`,
        icon: 'fa-regular fa-image',
        contextMenu: {
            order: 10,
            accepts: UiAccepts.SINGLE,
            getDisabled: () => false,
        },
        command: (params: UiParams) => {
            params.view.openImageSelectTab(true)
        },
    }, {
        id: UiCommandId.TRANS_FLIP_VERTICAL,
        group: UiGroupId.TRANSFORM,
        label: $localize`Odbij w pionie`,
        icon: 'fa-solid fa-arrows-up-down',
        shortcut: { default: 'ArrowUp', modifiers: KeyModifiers.SHIFT | KeyModifiers.CONTROL },
        menu: {},
        command: (params: UiParams) => {
            params.view.flipVertical()
        }
    }, {
        id: UiCommandId.TRANS_FLIP_HORIZONTAL,
        group: UiGroupId.TRANSFORM,
        label: $localize`Odbij w poziomie`,
        icon: 'fa-solid fa-arrows-left-right',
        shortcut: { default: 'ArrowDown', modifiers: KeyModifiers.SHIFT | KeyModifiers.CONTROL },
        menu: {},
        command: (params: UiParams) => {
            params.view.flipHorizontal()
        }
    }, {
        id: UiCommandId.TRANS_ROTATE_RIGHT,
        group: UiGroupId.TRANSFORM,
        label: $localize`Obróć w prawo`,
        icon: 'fa-solid fa-rotate-right',
        shortcut: { default: 'ArrowRight', modifiers: KeyModifiers.SHIFT | KeyModifiers.CONTROL },
        menu: {},
        command: (params: UiParams) => {
            params.view.rotateRight()
        }
    }, {
        id: UiCommandId.TRANS_ROTATE_LEFT,
        group: UiGroupId.TRANSFORM,
        label: $localize`Obróć w lewo`,
        icon: 'fa-solid fa-rotate-left',
        shortcut: { default: 'ArrowLeft', modifiers: KeyModifiers.SHIFT | KeyModifiers.CONTROL },
        menu: {},
        command: (params: UiParams) => {
            params.view.rotateLeft()
        }
    }, {
        id: UiCommandId.TRANS_SWAP_START_FINISH,
        group: UiGroupId.TRANSFORM,
        label: $localize`Zamień start z metą`,
        icon: 'fa-solid fa-right-left',
        shortcut: { default: 'Enter', modifiers: KeyModifiers.SHIFT | KeyModifiers.CONTROL },
        menu: {},
        command: (params: UiParams) => {
            params.view.swapFinishStart()
        }
    }, {
        id: UiCommandId.SHOW_GUIDES,
        group: UiGroupId.HELP,
        label: $localize`Przewodniki`,
        icon: 'pi pi-book',
        shortcut: { default: 'F1' },
        menu: {},
        command: (params: UiParams) => {
            window.open(getWPWebLink('wiki/guides', this.locale), 'blank')
        }
    }, {
        id: UiCommandId.SHOW_SUPPORT_CHAT,
        group: UiGroupId.HELP,
        label: $localize`Wsparcie techniczne`,
        icon: 'pi pi-comment',
        shortcut: { default: 'F1', modifiers: KeyModifiers.SHIFT },
        menu: {},
        command: (params: UiParams) => {
            params.view.showSupportChat()
        }
    }, {
        id: UiCommandId.SHORTCUTS,
        group: UiGroupId.HELP,
        label: $localize`Skróty klawiszowe`,
        icon: 'fa-regular fa-keyboard',
        shortcut: { default: 'F2' },
        menu: {},
        command: (params: UiParams) => {
            params.view.editShortcuts()
        }
    }, {
        id: UiCommandId.OPEN_TERMS_AND_CONDITIONS,
        group: UiGroupId.HELP,
        label: $localize`Warunki użytkowania`,
        icon: 'fa-solid fa-file-signature',
        menu: {
            url: getWPWebLink('terms-and-conditions', this.locale),
            target: '_blank'
        },
    }, {
        id: UiCommandId.OPEN_PRIVACY_POLICY,
        group: UiGroupId.HELP,
        label: $localize`Polityka prywatności`,
        icon: 'pi pi-shield',
        menu: {
            url: getWPWebLink('privacy-policy', this.locale),
            target: '_blank'
        },
    }, {
        id: UiCommandId.VERSION_BACKLOG,
        group: UiGroupId.HELP,
        label: $localize`Historia wersji aplikacji`,
        icon: 'pi pi-history',
        menu: {
            url: getWPWebLink('application-changelog', this.locale),
            target: '_blank'
        },

    }, {
        id: UiCommandId.SUBSCRIPTION_PLANS,
        group: UiGroupId.HELP,
        label: $localize`Plany cenowe`,
        icon: 'fa-solid fa-layer-group',
        menu: {},
        command: (params: UiParams) => {
            params.view.goToSubscriptionPlans()
        }
    }]

    groups: { [id: string]: UiMenu } = {
        [UiGroupId.PROJECT]: {
            id: UiGroupId.PROJECT,
            label: $localize`Projekt`,
            icon: 'pi pi-file',
        },
        [UiGroupId.VIEW]: {
            id: UiGroupId.VIEW,
            icon: 'pi pi-eye',
            label: $localize`Widok`
        },
        [UiGroupId.EDIT]: {
            id: UiGroupId.EDIT,
            icon: 'pi pi-pencil',
            label: $localize`Edycja`
        },
        [UiGroupId.OBJECT]: {
            id: UiGroupId.OBJECT,
            icon: 'fa-solid fa-bullseye',
            label: $localize`Obiekt`,
            contextItems: () => this._view.contextMenuItems,
        },
        [UiGroupId.TRANSFORM]: {
            id: UiGroupId.TRANSFORM,
            icon: 'fa-solid fa-retweet',
            label: $localize`Transformacje`
        },
        [UiGroupId.HELP]: {
            id: UiGroupId.HELP,
            icon: 'pi pi-question-circle',
            label: $localize`Pomoc`
        }
    }

    menus: UiMenu[] = []
    shortcuts: KeyShortcuts
    togglers: { [id: string]: ToggleMenuItem } = {}
    buttons: { [id: string]: UiButton } = {}
    contextMenuItems: { [id: string]: UiContextMenuItem }
    contextMenu?: UiContextMenuItem[]
    contextMenuObjects?: Selectable[]

    constructor(private _view: DetailComponentInterface, private locale: string) {
        this.shortcuts = this._buildShortcuts()
        this.menus = this._buildMenus()
        this.contextMenuItems = this._buildContextMenuItems()
    }

    private allSelectedNotOnCourse(params: UiParams) {
        return params.view.cfg.isNoRouteMode() || !params.flatItems.some(s => s instanceof PathObject && s.useCountAll > 0)
    }

    private _buildShortcuts(): KeyShortcuts {
        const shortcutsDef: ShortcutGroup[] = []
        for (let c of this.commands) {
            const gN = this.groups[c.group]
            if (gN && c.shortcut) {
                let s = shortcutsDef.find(g => g.label === gN.label)
                if (!s) {
                    s = { label: gN.label, shortcuts: [] }
                    shortcutsDef.push(s)
                }
                s.shortcuts.push({
                    default: c.shortcut.default,
                    id: c.id,
                    ro: c.shortcut.ro,
                    modifiers: c.shortcut.modifiers,
                    descr: c.label ? toFirstLowerCase(c.label) : '',
                    keyDownAction: c.shortcut.keyDownAction ? c.shortcut.keyDownAction : (view: DetailComponentInterface) => {
                        if (c.command) {
                            c.command({
                                view: view,
                                items: view.selection.selectedItems,
                                flatItems: view.selection.selectedFlatItems
                            })
                        }
                    },
                    keyUpAction: c.shortcut.keyUpAction,
                    outOfFocusKeyDown: c.shortcut.outOfFocusKeyDown,
                    outOfFocusKeyUp: c.shortcut.outOfFocusKeyUp
                })
            }
        }
        return new KeyShortcuts(this._view, shortcutsDef)
    }

    private _buildMenus(old?: UiMenu[]): UiMenu[] {
        this.togglers = {}
        const menusDef: UiMenu[] = []
        for (let c of this.commands) {
            if (c.group === UiGroupId.NONE) {
                continue
            }
            const gN = this.groups[c.group]
            const label = c.menu?.label || c.label
            if (!gN) {
                continue
            }
            let s = menusDef.find(g => g.id === c.group)
            if (!s) {
                s = {
                    id: c.group,
                    label: gN.label,
                    icon: gN.icon,
                    items: [],
                    contextItems: gN.contextItems,
                }
                menusDef.push(s)
            }
            if (c.toggle) {
                const oldMenu = old?.find(m => m.id === gN.id)
                let state = c.toggle.onOffState
                if (oldMenu) {
                    const oldItem = oldMenu.items?.find(i => i.id === c.id)
                    if (oldItem && oldItem instanceof ToggleMenuItem) {
                        state = oldItem.onOffState
                    }
                }
                const t = new ToggleMenuItem(this.shortcuts, {
                    id: c.id,
                    onOffState: state,
                    labelOn: c.toggle.labelOn,
                    labelOff: c.toggle.labelOff,
                    icon: c.icon,
                    tooltip: c.toggle?.tooltip || c.label,
                    command: this.command.bind(c, this._view)
                })
                this.togglers[c.id] = t
                s.items?.push(t)
                if (c.toggle.separatorAfter) {
                    s.items?.push({ separator: true })
                }
            } else if (c.menu && label) {
                s.items?.push({
                    id: c.id,
                    label: this.shortcuts.getMenuLabel(label, c.id),
                    escape: false,
                    icon: c.icon,
                    tooltip: c.menu.tooltip || c.description,
                    routerLink: c.menu.routerLink,
                    url: c.menu.url,
                    target: c.menu.target,
                    disabled: c.menu.disabled,
                    command: this.command.bind(c, this._view)
                })
                if (c.menu.separatorAfter) {
                    s.items?.push({ separator: true })
                }
                if (c.icon) {
                    this.buttons[c.id] = {
                        id: c.id,
                        icon: c.icon,
                        disabled: c.menu.disabled || false,
                        command: this.command.bind(c, this._view),
                        tooltip: c.label + '\n\n' + $localize`Skrót` + ': ' + this.shortcuts.getShortcutLabelOrUndefined(c.id)
                    }
                }
            }
        }
        return menusDef
    }

    private _buildContextMenuItems(): { [id: string]: UiContextMenuItem } {
        const ret: { [id: string]: UiContextMenuItem } = {}
        for (let c of this.commands) {
            if (c.contextMenu && c.contextMenu.accepts && c.contextMenu.accepts) {
                const i: UiContextMenuItem = {
                    id: c.id,
                    rawLabel: c.label,
                    label: c.label ? this.shortcuts.getMenuLabel(c.label, c.id) : undefined,
                    icon: c.icon,
                    escape: false,
                    tooltip: c.contextMenu.tooltip || c.description,
                    order: c.contextMenu.order,
                    getDisabled: c.contextMenu.getDisabled,
                    command: this.command.bind(c, this._view),
                    options: c.contextMenu.options,
                    accepts: c.contextMenu.accepts,
                    buttonTip: c.label + '\n\n' + $localize`Skrót` + ': ' + this.shortcuts.getShortcutLabelOrUndefined(c.id)
                }
                ret[i.id as string] = i
            }
        }
        return ret
    }

    private command(this: UiCommand, view: DetailComponentInterface, event: any) {
        if (this.toggle?.command) {
            this.toggle.command(event)
        } else if (this.menu?.command) {
            this.menu.command(event)
        } else if (this.contextMenu?.command) {
            this.contextMenu.command(event)
        } else if (this.command) {
            this.command({
                view: view,
                items: view.selection.selectedItems,
                flatItems: view.selection.selectedFlatItems
            })
        } else {
            return
        }
        let target, element
        if (event instanceof PointerEvent) {
            element = event.currentTarget
        } else {
            element = event?.originalEvent?.currentTarget
        }
        while (element) {
            const node = element.nodeName?.toLowerCase()
            if (node === 'p-menu') {
                target = 'menu'
                break
            }
            if (node === 'p-contextmenusub') {
                target = 'context-menu'
                break
            }
            if (node === 'button' || node === 'p-button' || node === 'p-splitbutton') {
                target = 'button'
                break
            }
            element = element.parentNode
        }
        view.actionLog(this.id, target || 'other')
    }

    rebuildMenus() {
        this.menus = this._buildMenus(this.menus)
    }

    getToggler(id: UiCommandId): ToggleMenuItem | undefined {
        return this.togglers[id]
    }

    getButton(id: UiCommandId): UiButton | undefined {
        return this.buttons[id]
    }

    getMenu(id: UiGroupId): UiMenu | undefined {
        return this.menus.find(m => m.id === id)
    }

    getContextMenuItem(id: UiCommandId): UiContextMenuItem | undefined {
        return this.contextMenuItems[id]
    }

    updateContextMenu() {
        Object.values(this.contextMenuItems).forEach(o => o.disabled = true)
        const params: UiParams = {
            view: this._view,
            items: this._view.selection.selectedItems,
            flatItems: this._view.selection.selectedFlatItems,
        }
        this.contextMenu?.forEach(o => {
            o.disabled = o.getDisabled(params)
            o.options?.forEach(i => i.disabled = i.getDisabled?.(params))
        })
    }

    getContextMenuItems(objects: Selectable[]): UiContextMenuItem[] | undefined {
        if (objects.length === 0) {
            const i = this.contextMenuItems[UiCommandId.CHOOSE_OBJECT_PROMPT]
            this.contextMenu = i ? [i] : undefined
            this.contextMenuObjects = []
        } else {
            if (this.contextMenuObjects) {
                let equal = true
                for (let o of objects) {
                    if (!this.contextMenuObjects.includes(o)) {
                        equal = false
                        break
                    }
                }
                if (equal) {
                    this.updateContextMenu()
                    return this.contextMenu
                }
            }
            this.contextMenuObjects = objects.slice()
            this.contextMenu = new PriorityArray()
            for (let o of objects) {
                if (o.contextMenu) {
                    for (let i of o.contextMenu.items) {
                        const def = this.contextMenuItems[i.id]
                        const accepts = def.accepts &&
                            (objects.length === 1 && def.accepts & UiAccepts.SINGLE || objects.length > 1 && def.accepts & UiAccepts.MANY)
                        if (def && accepts && !this.contextMenu.includes(def)) {
                            this.contextMenu.push(def)
                        }
                    }
                }
            }
        }
        this.updateContextMenu()
        return this.contextMenu
    }
}
