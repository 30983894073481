import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';
import { DialogModule } from 'primeng/dialog'
import { ParkourDialog } from '../dialog.interface'
import { ParkourRenderer, ParkourRendererConfig } from "../parkour-renderer/parkour-renderer.component";
import { UserProfile, UserService } from '../services/user.service'
import { from, Subscription } from 'rxjs'
import { ParkourConfig } from '../parkour.config'
import { ParkourCanvas } from '../parkour-canvas/parkour-canvas'
import { DetailComponentInterface } from '../detail/detail.component.interface'
import { ParkourRendererWalkMode } from '../design.schema'

@Component({
    selector: 'app-parkour-3d-render-dialog',
    standalone: true,
    imports: [
        CommonModule,
        ButtonModule,
        TooltipModule,
        DialogModule,
        ParkourRenderer
    ],
    templateUrl: './parkour-renderer-dialog.component.html',
    styleUrl: './parkour-renderer-dialog.component.scss'
})
export class Parkour3dRenderComponent implements AfterViewInit, OnDestroy, ParkourDialog {
    @Input({ required: false }) view?: DetailComponentInterface
    @Input({ required: true }) cfg!: ParkourConfig
    @Input({ required: true }) canvas?: ParkourCanvas
    @Output() onOk = new EventEmitter<void>()
    @Output() onCancel = new EventEmitter<void>()
    @ViewChild('renderer') renderer?: ParkourRenderer
    @ViewChild('msgs') msgEl?: ElementRef<HTMLDivElement>
    @ViewChild('wrapper') wrapperEl?: ElementRef<HTMLDivElement>

    private subs: Subscription = new Subscription()

    visibility: boolean = false
    errorMsg: string = ''
    instructions: boolean = false
    
    recording: boolean = false
    message: string = ''
    timeout?: NodeJS.Timeout
    flyMode: boolean = false

    static is3dSupported: boolean = ParkourRenderer.is3dSupported() || false

    config: ParkourRendererConfig = {
         flySpeed: 5,
         walkSpeed: 10,
         shadows: true,
         routeLine: true,
         routeAnimation: 0,
         stands: true,
         walkMode: ParkourRendererWalkMode.FLY_FREELY,
    }

    private _walkModes: {
        mode: ParkourRendererWalkMode,
        label: string,
    }[] = [
        { mode: ParkourRendererWalkMode.FLY_FREELY, label: $localize`Wolny lot` },
        { mode: ParkourRendererWalkMode.WALK_ON_THE_GROUND, label: $localize`Chodzenie po ziemi` }
    ]

    private _walkModeIdx: number = 0
    private _walkModeOverride: ParkourRendererWalkMode | undefined = undefined

    keyDownBound = this._handleKeyDown.bind(this)
    keyUpBound = this._handleKeyUp.bind(this)

    constructor(private userService: UserService) {
    }

    ngAfterViewInit() {
        this.subs.add(
            this.userService.getUserProfile().subscribe({
                next: (profile: UserProfile | null | undefined) => {
                    if (profile?.flySpeed3d !== undefined) {
                        this.config.flySpeed = profile.flySpeed3d
                    }
                    if (profile?.walkSpeed3d !== undefined) {
                        this.config.walkSpeed = profile.walkSpeed3d
                    }
                    if (profile?.shadows3d !== undefined) {
                        this.config.shadows = profile.shadows3d
                    }
                    if (profile?.routeAnimation3d !== undefined) {
                        this.config.routeAnimation = profile.routeAnimation3d
                    }
                    if (profile?.routeLine3d !== undefined) {
                        this.config.routeLine = profile.routeLine3d
                    }
                    if (profile?.stands3d !== undefined) {
                        this.config.stands = profile.stands3d
                    }
                    if (this._walkModeOverride) {
                        this.config.walkMode = this._walkModeOverride
                    } else if (profile?.walkMode3d !== undefined) {
                        this.config.walkMode = profile.walkMode3d
                    }
                    const idx = this._walkModes.findIndex(m => m.mode === this.config.walkMode)
                    if (idx >= 0) {
                        this._walkModeIdx = idx
                    } else {
                        this._walkModeIdx = 0
                    }
                    this.renderer?.setConfig(this.config)
                }
            })
        )
    }

    ngOnDestroy() {
        this._destroy()
        this.subs.unsubscribe()    
    }

    addNewMessage(msg: string) {
        if (!this.msgEl) {
            return
        }
        if (this.timeout) {
            clearTimeout(this.timeout)
            this.timeout = undefined
        }
        const div = this.msgEl.nativeElement
        div.style.opacity = '1'
        div.style.transition = ''
        this.message = msg
        if (this.message !== '') {
            this.timeout = setTimeout(() => {
                this.timeout = undefined
                div.style.opacity = '0'
                div.style.transition = `opacity 0.5s ease-in-out`
            }, 1000)
        }
    }

    onCloseDialog() {
    }

    onShowDialog() {
        this.view?.saveData()
        this.renderer?.initialize(this.cfg.params, this.canvas?.obstaclePath)
        this.renderer?.setConfig(this.config)
        window.addEventListener('keydown', this.keyDownBound)
        window.addEventListener('keyup', this.keyUpBound)
        const w = this.onMouseWheel.bind(this)
        if ('onwheel' in document) {
            this.renderer?.canvas?.nativeElement.addEventListener('wheel', w)
        } else if ('onmousewheel' in document) {
            this.renderer?.canvas?.nativeElement.addEventListener('mousewheel', w)
        } else {
            this.renderer?.canvas?.nativeElement.addEventListener('MozMousePixelScroll', w)
        }
    }

    onMouseWheel(ev: any) {
        let delta = ev.deltaY || ev.detail || ev.wheelDelta
        if (delta) {
            this.renderer?.changeFieldOfView(delta / 30)
        }
        ev.preventDefault?.()
        ev.stopPropagation?.()
    }

    onHideDialog() {
        this._destroy()
        const patch: UserProfile = {
            flySpeed3d: this.config.flySpeed,
            walkSpeed3d: this.config.walkSpeed,
            shadows3d: this.config.shadows,
            routeAnimation3d: this.config.routeAnimation,
            routeLine3d: this.config.routeLine,
            stands3d: this.config.stands,
            walkMode3d: this.config.walkMode,
        }
        this.userService.updateUserProfile(patch)
    }
    
    onOkDialog() {
        this.visibility = false
        this.onOk.emit()
    }

    onCancelDialog() {
        this.visibility = false
        this.onCancel.emit()
    }

    onFlyModeChange(mode: boolean) {
        this.flyMode = mode
    }

    onHelp() {
        this.instructions = !this.instructions
    }
    
    open(mode?: ParkourRendererWalkMode) {
        this.visibility = true
        if (mode) {
            this._walkModeOverride = mode
            this.config.walkMode = mode
        }
    }

    closeOk() {
        this.onOkDialog()
    }

    closeCancel() {
        this.onCancelDialog()
    }

    private _destroy() {
        this.recording = false
        this.flyMode = false
        this.renderer?.destroy()
        window.removeEventListener('keydown', this.keyDownBound)
        window.removeEventListener('keyup', this.keyUpBound)
    }

    private _handleKeyDown(event: KeyboardEvent) {
        if (!this.renderer) {
            return
        }
        const k = event.key.toLowerCase()
        if (k === '?') {
            this.instructions = !this.instructions
        } else if (k === '1') {
            this.config.routeLine = !this.config.routeLine
            this.renderer?.setConfig({
                routeLine: this.config.routeLine
            })
            if (this.config.routeLine) {
                this.addNewMessage($localize`Linia trasy została włączona`)
            } else {
                this.addNewMessage($localize`Linia trasy została wyłączona`)
            }
        } else if (k === '2') {
            this.config.routeAnimation++
            this.renderer.setConfig({
                routeAnimation: this.config.routeAnimation
            })
            const [idx, name] = this.renderer.getCurrentAnimation()
            this.config.routeAnimation = idx
            if (name) {
                this.addNewMessage($localize`Animacja trasy została włączona` + ' - ' + name)
            } else {
                this.addNewMessage($localize`Animacja trasy została wyłączona`)
            }
        } else if (k === '3') {
            this.config.shadows = !this.config.shadows
            this.renderer.setConfig({
                shadows: this.config.shadows
            })
            if (this.config.shadows) {
                this.addNewMessage($localize`Cienie zostały włączone`)
            } else {
                this.addNewMessage($localize`Cienie zostały wyłączone`)
            }
        } else if (k === '4') {
            this.config.stands = !this.config.stands
            this.renderer.setConfig({
                stands: this.config.stands
            })
            if (this.config.stands) {
                this.addNewMessage($localize`Trybuny widoczne`)
            } else {
                this.addNewMessage($localize`Trybuny niewidoczne`)
            }
        } else if (k === '0') {
            if (document.fullscreenEnabled) {
                this.wrapperEl?.nativeElement.requestFullscreen().then(() => {
                })
            }
        } else if (k === 'p') {
            if (!this.recording) {
                this.recording = this.renderer?.startRecording() || false
            } else {
                this.renderer.stopRecording()
                this.recording = false
            }
        } else if (k === 'shift') {
            this.renderer.setConfig({
                walkSpeed: this.config.walkSpeed * 2
            })
        } else if (k === 'f') {
            this.flyMode = !this.flyMode
            if (this.flyMode) {
                this.addNewMessage($localize`Lot nad trasą`)
                this.renderer?.startFlyMode()
            } else {
                this.addNewMessage($localize`Stop`)
                this.renderer?.stopFlyMode()
            }
        } else if (k === 'g') {
            if (++this._walkModeIdx >= this._walkModes.length) {
                this._walkModeIdx = 0
            }
            this.config.walkMode = this._walkModes[this._walkModeIdx].mode
            this.flyMode = false
            this.renderer?.stopFlyMode()
            this.renderer?.setWalkMode(this.config.walkMode)
            this.addNewMessage(this._walkModes[this._walkModeIdx].label)
        } else if (k === '-') {
            if (this.flyMode) {
                this.config.flySpeed = Math.max(this.config.flySpeed - 5, 5)
                this.renderer.setConfig({
                    flySpeed: this.config.flySpeed
                })
                this.addNewMessage($localize`Prędkość przelotu` + ' ' + this.config.flySpeed)
            } else {
                this.config.walkSpeed = Math.max(this.config.walkSpeed - 5, 5)
                this.renderer.setConfig({
                    walkSpeed: this.config.walkSpeed
                })
                this.addNewMessage($localize`Prędkość chodzenia` + ' ' + this.config.walkSpeed)
            }
        } else if (k === '=' || k === '+') {
            if (this.flyMode) {
                this.config.flySpeed = Math.min(this.config.flySpeed + 5, 100)
                this.renderer.setConfig({
                    flySpeed: this.config.flySpeed
                })
                this.addNewMessage($localize`Prędkość przelotu` + ' ' + this.config.flySpeed)
            } else {
                this.config.walkSpeed = Math.min(this.config.walkSpeed + 5, 100)
                this.renderer.setConfig({
                    walkSpeed: this.config.walkSpeed
                })
                this.addNewMessage($localize`Prędkość chodzenia` + ' ' + this.config.walkSpeed)
            }
        } else if (k === '.' || k === '>') {
            const hours = (this.renderer.getHourOfDay() + 0.5) % 24
            this.renderer.setHourOfDay(hours)
            this._displayHours(hours)
        } else if (k === ',' || k === '<') {
            let hours = (this.renderer.getHourOfDay() - 0.5)
            if (hours < 0) {
                hours = 24 - Math.abs(hours) % 24
            }
            this.renderer.setHourOfDay(hours)
            this._displayHours(hours)
        } else if (k === ' ' && this._walkModes[this._walkModeIdx].mode === ParkourRendererWalkMode.WALK_ON_THE_GROUND) {
            this.renderer?.jump()
        }
        event.preventDefault()
        event.stopPropagation()
    }

    private _displayHours(h: number) {
        const m = Math.round(((h - Math.floor(h)) * 60))
        let t = m.toFixed(0)
        if (m < 10 && m >= 0) {
            t = '0' + t
        } else if (m === 0) {
            t = '00'
        }
        this.addNewMessage($localize`Godzina` + ' ' + Math.floor(h) + ':' + t)
    }

    private _handleKeyUp(event: KeyboardEvent) {
        const k = event.key.toLowerCase()
        if (k === 'shift') {
            this.renderer?.setConfig({
                walkSpeed: this.config.walkSpeed
            })
        }
        event.preventDefault()
        event.stopPropagation()
    }
}
