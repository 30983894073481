import paper from 'paper'
import { DesignPathObject, designPathPatternDistances, Direction, EditMode } from "../../design.schema"
import { angleTo360, clampDeltaToField, isAngleBetween } from "../../utils"
import { OutlinedText } from "../detail.paper.extensions"
import { Editable } from "../detail.selection"
import { Connector, PathObjectSelector, SelectorKind } from "../detail.selectors"
import { UiCommandId } from "../detail.ui.commands.defs"
import { ParkourObjectConfig } from "./parkour-object"
import { SvgImage } from "./svg-image"
import { Unit } from '../../pipes'
import { ObstacleWithBars } from './obstacle-with-bars'

export abstract class PathObject extends SvgImage implements Editable {
    // how many times obstacle is used in each round, index = round number - 1
    public useCount: number[] = [0, 0, 0]
    protected crossOut: paper.Group | undefined
    protected nameText: OutlinedText
    thisIsEditable: true = true
    editMode: boolean = false
    selector: PathObjectSelector
    static connectorDistance: number = 0
    static connectorDistanceCorr: number = 0
    connectorLine?: paper.Path
    connectorLineTween?: paper.Tween
    connectorShapeTween?: paper.Tween
    connectorMeasure?: OutlinedText
    connectorPos?: paper.Point
    connector?: Connector
    connectorIdx?: number
    dropOnConnector?: Connector
    enteredObject?: PathObject
    enteredPoint?: paper.Point
    userArrowDirection: Direction
    joker: boolean = false
    jokerNumber: number = 0
    jokerDisabled: boolean = false
    jokerTooltip: string = ''

    public get useCountAll(): number {
        return this.useCount.reduce((p, c) => p + c, 0)
    }

    // direction of jumping the obstacle in the first and second pass through the obstacle
    protected passes: Direction[][] = [
        [Direction.forward, Direction.backward], // in the first round
        [Direction.forward, Direction.backward], // in the second round
        [Direction.forward, Direction.backward], // in the third round
    ]

    // in which direction arrow should be pointing in each round
    private arrowDirections: Direction[] = [Direction.none, Direction.none, Direction.none]

    constructor(protected config: ParkourObjectConfig) {
        super(config)
        const object = config.object
        this.selector = new PathObjectSelector(this, this.cfg)

        if (object.directionRound1) {
            if (object.directionRound1 === Direction.backward) {
                this.passes[0] = [Direction.backward, Direction.forward]
            } else {
                this.passes[0] = [Direction.forward, Direction.backward]
            }
        }
        if (object.directionRound2) {
            if (object.directionRound2 === Direction.backward) {
                this.passes[1] = [Direction.backward, Direction.forward]
            } else {
                this.passes[1] = [Direction.forward, Direction.backward]
            }
        }
        if (object.directionRound3) {
            if (object.directionRound3 === Direction.backward) {
                this.passes[2] = [Direction.backward, Direction.forward]
            } else {
                this.passes[2] = [Direction.forward, Direction.backward]
            }
        } else {
            for (let i = 0; i < this.passes[0].length; i++) {
                this.passes[2] = this.passes[0].slice()
            }
        }
        this.nameText = new OutlinedText({
            content: object.name || '',
            strokeColor: new paper.Color(this.cfg.params.colors.obstacleName),
            strokeWidth: 3,
            fontSize: this.cfg.getFontSize(),
            position: this.initialPos
        })
        this.allGroup.addChild(this.nameText)
        this.userArrowDirection = object.userArrowDirection || Direction.both
        this.joker = object.joker || false
        this.jokerNumber = object.jokerNumber || 0

        if (this.view && this.contextMenu) {
            const c = this.view.uiCommands
            if (!this.isStart()) {
                this.contextMenu.add(
                    c.getContextMenuItem(UiCommandId.DETACH_OBJECT),
                    c.getContextMenuItem(UiCommandId.DELETE_ROUTE_BACKWARD),
                )
                if (!this.isFinish()) {
                    this.contextMenu.add(c.getContextMenuItem(UiCommandId.AUTO_ROTATE))
                    if (!this.isFinishStart()) {
                        this.contextMenu.add(c.getContextMenuItem(UiCommandId.REVERSE_DIRECTION))
                    }
                }
            }

            if (!this.isFinish()) {
                this.contextMenu.add(
                    c.getContextMenuItem(UiCommandId.DELETE_FORWARD_PATH),
                    c.getContextMenuItem(UiCommandId.DELETE_ROUTE_FORWARD),
                    c.getContextMenuItem(UiCommandId.SPLIT_PATH_AFTER),
                )
            }
        }
    }

    getKindName(): string {
        if (this.cfg.isJokerAllowed() && this.joker) {
            return 'Joker' + this.jokerNumber
        }
        return super.getKindName()
    }

    private _getHandleLength(distance: number) {
        distance = Math.min(distance, 2500)
        return distance / 1.5
    }

    private _buildHandle(distance: number, angle: number): paper.Point {
        return new paper.Point(0, this._getHandleLength(distance)).rotate(angle, [0, 0])
    }

    private _animateConnectorLineForward() {
        if (this.connectorLine) {
            const limit = this.connectorLine.dashArray.reduce((v, o) => v + o, 0)
            this.connectorLineTween = this.connectorLine.tween(
                { dashOffset: limit },
                { dashOffset: 0 },
                250
            ).then(() => {
                this._animateConnectorLineForward()
            })
        }
    }

    private _animateConnectorLineBackward() {
        if (this.connectorLine) {
            const limit = this.connectorLine.dashArray.reduce((v, o) => v + o, 0)
            this.connectorLine.dashOffset = 0
            this.connectorLineTween = this.connectorLine.tween(
                { dashOffset: 0 },
                { dashOffset: limit },
                250
            ).then(() => {
                this._animateConnectorLineBackward()
            })
        }
    }

    private _startAnimateConnectorLine(forward: boolean, sectionIdx: number | undefined) {
        if (!this.view || sectionIdx !== undefined && (this.canvas.obstaclePath.sections.length < sectionIdx || sectionIdx < 0)) {
            return
        }
        this.connectorLineTween?.stop()
        this.connectorLineTween = undefined
        if (this.connectorLine) {
            if (sectionIdx !== undefined) {
                this.connectorLine.dashArray = this.canvas.obstaclePath.sections[sectionIdx].dashArray
            } else {
                this.connectorLine.dashArray = designPathPatternDistances[this.view.cfg.params.pathPattern3]
            }
            if (forward) {
                this._animateConnectorLineForward()
            } else {
                this._animateConnectorLineBackward()
            }
        }
    }

    private _stopAnimateConnectorLine() {
        this.connectorLineTween?.stop()
        this.connectorLineTween = undefined
        if (this.connectorLine) {
            this.connectorLine.dashArray = []
        }
    }

    onMouseDown(point: paper.Point, internal?: boolean): boolean {
        if (!this.connector && (this.connectorIdx || this.connectorIdx === 0)) {
            if (this.connectorLine && this.connectorLine.isInserted()) {
                this.connectorLine.remove()
            }
            this.connector = this.selector.connectors[this.connectorIdx]
            if (this.connector) {
                this.connectorPos = this.connector.position.clone()
                const dist = point.getDistance(this.connector.position)
                const outHandle = this._buildHandle(dist, this.connector.angle)
                const segms = [
                    [this.connector.position, null, outHandle],
                    [point, null, null]
                ]
                this.connectorLine = new paper.Path.Line({
                    segments: segms,
                    strokeColor: new paper.Color(this.cfg.params.colors.pathRegular),
                    strokeWidth: this.cfg.params.lineWidth,
                    strokeScaling: false,
                    //fullySelected: true,
                })
                this.connectorLine.insertBelow(this.connector.shape)
                this.connector.position = point

                this.connectorMeasure = new OutlinedText({
                    content: '',
                    strokeWidth: 1,
                    strokeColor: new paper.Color(this.cfg.params.colors.pathRegular),
                    fontSize: this.cfg.getFontSize() * 1.1,
                    justification: 'right',
                })
                this.connectorMeasure.visible = false
                this.connectorMeasure.insertAbove(this.connector.shape)

                if (!internal) {
                    PathObject.connectorDistance = 0    
                    PathObject.connectorDistanceCorr = this.connectorPos.getDistance(this.getPosition())
                }

                // fade objects, which can't be connected to)
                for (let o of Object.values(this.canvas.objects)) {
                    if (o !== this) {
                        if (o instanceof PathObject) {
                            for (let c of o.selector.connectors) {
                                if (c && this.connector) {
                                    const [dir, _] = this.canvas.obstaclePath.isConnectionPossible(c, this.connector)
                                    if (dir) {
                                        o.unfade()
                                        break
                                    }
                                    o.fade()
                                }
                            }
                        } else {
                            o.fade()
                        }
                    }
                }
            }
        }
        return true
    }

    onMouseUp(point: paper.Point, connectThrough?: boolean): boolean {
        if (this.connector) {
            let animateTo: paper.Point | undefined = undefined
            if (this.dropOnConnector) {
                const result = this.canvas.obstaclePath.connectObstacles(this.connector, this.dropOnConnector, false, this.connectorLine, connectThrough)
                if (result[0]) {
                    animateTo = this.dropOnConnector.position
                    this.view?.selectionClear()
                    this.view?.selectFocusItem(this.dropOnConnector.parent.obj, point)
                }
                this.dropOnConnector.parent.connectors.forEach(c => {
                    if (c && !c.isColorDefault()) {
                        c.colorDefault()
                        c.grow(1)
                    }
                })    
            }
            this.onEditEnd(animateTo, 0)
        }
        return true
    }

    private _getPathObjects(): PathObject[] {
        return Object.values(this.canvas.objects).filter(o => o instanceof PathObject) as PathObject[]
    }

    // connect an obstacle, when in drawing edit mode mouse hovers over obstacle crossings its selector
    connectThroughObstacle(o: PathObject, point: paper.Point): boolean {
        if (!this.connector) {
            return false
        }
        const hysteresis = 0.1
        const cc = o.getPosition()
        const r = o.selector.radius
        const pr = (point.x - cc.x) ** 2 + (point.y - cc.y) ** 2
        if (this.enteredObject !== o && (pr < (r) ** 2)) {
            // entered object, remember point of entry
            this.enteredObject = o
            this.enteredPoint = point
        } else if (this.enteredObject === o && pr > (r * (1 + hysteresis)) ** 2 &&
            this.enteredPoint && this.connectorLine) {
            // left the object, connect it if possible
            // determine the connector closest to the entry point
            const [closest, closestIdx] = o.selector.getClosestConnector(this.enteredPoint)
            if (closest && closestIdx >= 0) {
                let [dir, ] = this.canvas.obstaclePath.isConnectionPossible(this.connector, closest)
                if (dir) {
                    // obstacle can be connected
                    // trim the connector line to the entry point
                    const path = this.connectorLine
                    const i = path.segments.findIndex(i => this.enteredPoint?.equals(i.point))
                    if (i > 0) {
                        path.removeSegments(i)
                    }
                    PathObject.connectorDistance += path.length + this.enteredPoint.getDistance(point)
                    this.dropOnConnector = closest
                    // finish current path section
                    this.onMouseUp(this.enteredPoint, true)
                    // and begin a new section for the other obstacle
                    o.onEditStart(point, closestIdx === 0 ? 1 : 0)
                    o.onMouseDown(point, true)
                    this.enteredObject = undefined
                    this.enteredPoint = undefined    
                    return true
                }
                this.enteredObject = undefined
                this.enteredPoint = undefined        
            }
        }
        return false
    }

    onMouseMove(point: paper.Point): boolean {
        if (!this.editMode || !this.connector || !this.connectorLine || !this.connectorPos) {
            return true
        }
        this.connector.position = point
        const dist = point.getDistance(this.connectorPos)
        this.connectorLine.segments[this.connectorLine.segments.length - 1].point = point
        this.dropOnConnector = undefined
        // calculate the pointer gravity vector to all path objects and use its angle as a handle
        const objects: PathObject[] = this._getPathObjects()
        let vec = new paper.Point(0, 0)
        this.dropOnConnector = undefined
        const wasGo = this.connector.isColorGo()
        this.connector.colorDefault()
        let dir = 0, conDir = 0, sectionIdx: number | undefined = -1, conSectionIdx: number | undefined = -1
        for (let o of objects) {
            const pos = o.getPosition()
            const d = pos.getDistance(point)
            const vector = pos.subtract(point)
            vector.length *= 150000 / (d * d * d)
            vec = vec.add(vector)

            if (o === this) {
                continue
            }
            const size = o.selector.getSize()
            if (d < (new paper.Point(size).length / 2) * 1.8) {
                o.selector.select()
                let connected = false
                if (this.cfg.params.editMode === EditMode.DRAWING) {
                    connected = this.connectThroughObstacle(o, point)
                }
                if (!connected) {
                    let closest: Connector | undefined = undefined
                    let dist = Infinity
                    let closestDir: number = 0
                    for (let c of o.selector.connectors) {
                        if (c) {
                            [dir, sectionIdx] = this.canvas.obstaclePath.isConnectionPossible(this.connector, c)
                            if (dir) {
                                c.colorGo()
                                const d = point.getDistance(c.position)
                                if (d < dist) {
                                    dist = d
                                    closest = c
                                    closestDir = dir
                                    conSectionIdx = sectionIdx
                                }
                            } else {
                                c.colorNoGo()
                            }
                            c.grow(1)
                        }
                    }
                    // check that we are inside the obstacle selector
                    if (closest && closestDir && this.connector.isColorDefault()) { 
                        let cond: boolean = false
                        if (o.selector.selectorKind === SelectorKind.CIRCLE) {
                            cond = point.getDistance(o.getPosition()) < (o.selector.radius + closest.radius + this.connector.radius) * 1.3
                        } else if (o.selector.selectorKind === SelectorKind.RECTANGLE) {
                            cond = dist < (closest.radius + this.connector.radius) * 3
                        }
                        if (cond) {
                            conDir = closestDir
                            this.dropOnConnector = closest
                            this.connector.colorGo()
                            closest.grow(2)
                        }
                    }
                }
            } else if (o.selector.isSelected()) {
                o.selector.deselect()
                o.selector.connectors.forEach(c => {
                    c?.colorDefault()
                    c?.grow(1)
                })
            }
        }
        if (wasGo && !this.dropOnConnector) {
            this._stopAnimateConnectorLine()
        }
        if (!wasGo && this.dropOnConnector && conDir) {
            this._startAnimateConnectorLine(conDir > 0, conSectionIdx)
        }
        if (this.connectorLine) {
            if (this.cfg.params.editMode === EditMode.CONNECTOR) {
                const len = this.connectorLine.segments.length
                const scale = Math.min(vec.length, 1)
                if (len == 2) {
                    this.connectorLine.segments[0].handleOut.length = this._getHandleLength(dist)
                }
                if (len >= 1) {
                    this.connectorLine.segments[len - 1].handleIn = this._buildHandle(dist * scale, vec.angle + 90)    
                }
                if (this.connectorMeasure) {
                    this.connectorMeasure.visible = false
                }
            } else {
                this.connectorLine.insert(this.connectorLine.segments.length - 1, point)
                this.connectorLine.smooth({
                    type: 'catmull-rom',
                    factor: 0.5,
                })
                if (this.connectorMeasure) {
                    const dist = this.connectorLine.length
                    const point = this.connectorLine.segments[this.connectorLine.segments.length - 1].point
                    if (dist > 100) {
                        let d = this.config.conversion.transform(dist + PathObject.connectorDistanceCorr, {
                            from: Unit.CM,
                            to: this.cfg.params.distanceUnit,
                            rules: this.config.conversion.rulesForDistanceOnPath
                        }) || ' ?'
                        if (PathObject.connectorDistance > 0) {
                            d += '\n' + this.config.conversion.transform(PathObject.connectorDistance + dist + PathObject.connectorDistanceCorr, {
                                from: Unit.CM,
                                to: this.cfg.params.distanceUnit,
                                rules: this.config.conversion.rulesForDistanceOnPath
                            }) || ''
                        }
                        this.connectorMeasure.content = d
                        const offset = point.subtract(this.connectorLine.getPointAt(dist - 100)).multiply(this.connectorMeasure.internalBounds.size.divide([110, 75]))
                        this.connectorMeasure.position = point.add(offset)
                        this.connectorMeasure.visible = true
                    } else {
                        this.connectorMeasure.visible = false
                    }            
                }
            }
        }
        return true
    }

    onMouseDrag(delta: paper.Point): boolean {
        return false
    }

    onMouseWheel(delta: number): boolean {
        return false
    }

    delete(): boolean {
        return true
    }

    onEditStart(point: paper.Point, idx: number): void {
        if (this.editMode) {
            return
        }
        this.connectorIdx = idx
        this.editMode = true
    }

    onEditEnd(animateTo?: paper.Point, idx?: number): boolean {
        if (!animateTo) {
            animateTo = this.connectorPos
        }
        if (idx === undefined) {
            idx = 1
        }
        this._stopAnimateConnectorLine()
        this._getPathObjects().forEach(o => {
            if (o !== this && o !== this.dropOnConnector?.parent.obj) {
                o.selector.deselect()
            }
        })
        this.dropOnConnector = undefined
        for (let o of Object.values(this.canvas.objects)) {
            o.unfade()
        }
        if (this.connectorLine && animateTo) {
            this.connectorLineTween?.stop()
            if (this.connectorLine.isInserted()) {
                this.connectorLine.remove()
            }
            this.connectorLine = undefined
            if (this.connectorMeasure?.isInserted()) {
                this.connectorMeasure.remove()
            }
            this.connectorMeasure = undefined
        }
        if (this.connector && animateTo) {
            this.connector.colorDefault()
            this.connectorShapeTween = this.connector.shape.tweenTo({
                position: animateTo.clone()
            }, 100).then(() => {
                if (this.connector && this.connectorPos) {
                    this.connector.position = this.connectorPos
                    this.connector.defocus()
                    this.connector = undefined
                }
                this.connectorShapeTween = undefined
            })
        }
        this.enteredObject = undefined
        this.enteredPoint = undefined    
        this.connectorIdx = undefined
        this.editMode = false
        return false
    }

    drawExtraElements() {
        super.drawExtraElements()
        const p = this.getPosition()
        if (!this.crossOut) {
            this.crossOut = new paper.Group()
            const defaultAttrs = {
                strokeColor: '#d3d8',
                strokeWidth: 30 * this.cfg.params.lineWidth,
                strokeScale: false,
                strokeCap: 'round'
            }
            this._addCross(this.crossOut, defaultAttrs, p, 95)
            defaultAttrs.strokeWidth = 25 * this.cfg.params.lineWidth
            defaultAttrs.strokeColor = '#6668'
            this._addCross(this.crossOut, defaultAttrs, p, 95)
            this.crossOut.rotate(this.angle)
            this.allGroup.addChild(this.crossOut)
        } else {
            this.crossOut.rotate(-this.angle)
            this.crossOut.position = p
        }
    }

    private _addCross(group: paper.Group, defaultAttrs: {}, p: paper.Point, size: number) {
        group.addChild(new paper.Path.Line({
            ...defaultAttrs,
            from: p.add([size, size]),
            to: p.subtract([size, size]),
        }))
        group.addChild(new paper.Path.Line({
            ...defaultAttrs,
            from: p.add([-size, size]),
            to: p.subtract([-size, size]),
        }))
    }

    fadeIfNotUsed() {
        if (this.crossOut && this.crossOut.visible) {
            this.fade()
        }
    }

    removeFading() {
        if (this.crossOut && this.crossOut.visible) {
            this.unfade()
        }
    }

    protected _move(delta: paper.Point) {
        if (this.cfg.params.limitToField) {
            // prevent from moving the object out of the field
            clampDeltaToField(this.canvas.field, this.getPosition(), delta)
        }
        // move object
        super._move(delta)
    }

    select(point?: paper.Point) {
        super.select(point)
        this.crossOut?.bringToFront()
    }

    destroy() {
        this.connectorLineTween?.stop()
        this.connectorLineTween = undefined
        this.connectorShapeTween?.stop()
        this.connectorShapeTween = undefined
        if (this.connectorLine && this.connectorLine.isInserted()) {
            this.connectorLine.remove()
        }
        this.connectorLine = undefined
        if (this.connectorMeasure?.isInserted()) {
            this.connectorMeasure.remove()
        }
        this.connectorMeasure = undefined
        if (this.crossOut && this.crossOut.isInserted()) {
            this.crossOut.remove()
        }
        this.crossOut = undefined
        super.destroy()
    }

    setNamePosition() {
        const dy = Math.max(this.objectSize.width, this.objectSize.height)
        this.nameText.point = this.getPosition().subtract([0, dy * 0.7])
    }

    reset() {
        super.reset()
        this.setArrowDirection(Direction.none)
        this.useCount = [0, 0, 0]
        this.nameText.setRotation(0)
    }

    set name(txt: string) {
        this.nameText.content = txt
    }

    get name(): string {
        return this.nameText.content
    }

    showName() {
        this.nameText.visible = true
    }

    hideName() {
        this.nameText.visible = false
    }

    // in which direction this obstacle should be taken in the given round and pass through it
    getDirection(roundNo: number | undefined, passNo: number): Direction {
        if (roundNo === undefined || roundNo < 1 || roundNo > 3) {
            return Direction.forward
        }
        return this.passes[roundNo - 1][passNo - 1]
    }

    getArrowDirection(roundNo?: number): Direction {
        if (roundNo === 1 || roundNo === 2 || roundNo === 3) {
            return this.arrowDirections[roundNo - 1]
        }
        let fwd: boolean = false
        let bwd: boolean = false
        this.arrowDirections.forEach(d => {
            fwd ||= (d === Direction.forward) || (d === Direction.both)
            bwd ||= (d === Direction.backward) || (d === Direction.both)
        })
        if (fwd && !bwd) {
            return Direction.forward
        }
        if (bwd && !fwd) {
            return Direction.backward
        }
        if (bwd && fwd) {
            return Direction.both
        }
        return Direction.none
    }

    // an angle adjusted to the direction in which the obstacle points
    getDirectionalAngle(roundNo: number): number {
        if (this.getDirection(roundNo, 1) === Direction.forward) {
            return this.angle
        }
        return angleTo360(this.angle - 180)
    }

    // set obstacle direction towards a given point
    setDirectionTowards(point: paper.Point, roundNo: number, opposite?: boolean) {
        // don't change the angle of the obstacle, but choose the orientation facing the point
        const angle = angleTo360(this.getDirectionalAngle(roundNo) + 90)
        const pa = angleTo360(point.subtract(this.getPosition()).angle)
        const facing = !isAngleBetween(pa, angle - 90, angle + 90)
        if (!opposite && !facing || opposite && facing) {
            this.switchDirections(roundNo)
        }
    }

    // set obstacle direction away from a given point
    setDirectionAway(point: paper.Point, roundNo: number) {
        this.setDirectionTowards(point, roundNo, true)
    }

    switchDirections(roundNo?: number) {
        if (roundNo !== 1 && roundNo !== 2 && roundNo !==3) {
            this.switchDirections(1)
            this.switchDirections(2)
            this.switchDirections(3)
        } else {
            const arr = this.passes[roundNo - 1];
            [arr[0], arr[1]] = [arr[1], arr[0]]
            this.copyDirectionsToOtherRoundsIfNotUsed(roundNo)
        }
    }

    copyDirectionsToOtherRoundsIfNotUsed(roundNo: number) {
        if (roundNo !== 1 && roundNo !== 2 && roundNo !== 3) {
            return
        }
        const otherRounds: number[] = [1, 2, 3]
        otherRounds.splice(roundNo - 1, 1)
        otherRounds.forEach(otherRound => {
            if (this.useCount[otherRound - 1] === 0) {
                // if the other round is not active, copy over the directions to it
                // so the obstacle has the same directions in both rounds
                this.passes[otherRound - 1] = this.passes[roundNo - 1].slice()
            }
        })
    }

    copyDirectionsToOtherRounds(roundNo: number) {
        if (roundNo !== 1 && roundNo !== 2 && roundNo !== 3) {
            return
        }
        const otherRounds: number[] = [1, 2, 3]
        otherRounds.splice(roundNo - 1, 1)
        otherRounds.forEach(otherRound => {
            this.passes[otherRound - 1] = this.passes[roundNo - 1].slice()
        })
    }

    private _getMiddlePoint(segment: number, external?: boolean): paper.Point {
        const frame = external ? this.externalFrame : this.objectFrame
        if (frame) {
            const p1 = frame.segments[segment].curve.point1
            const p2 = frame.segments[segment].curve.point2
            return p2.subtract(p1).divide(2).add(p1)
        }
        return this.getPosition()
    }

    setArrowDirection(direction: Direction, roundNo?: number) {
        if (this.cfg.isNoRouteMode()) {
            direction = Direction.both
        }
        if (this.cfg.canUserEditArrows()) {
            direction = this.userArrowDirection
        }
        if (roundNo === 1 || roundNo === 2 || roundNo === 3) {
            this.arrowDirections[roundNo - 1] = direction
        } else {
            this.arrowDirections.forEach((_, i) => this.arrowDirections[i] = direction)
        }
        if (this.crossOut) {
            if (this.getArrowDirection() === Direction.none &&
                !(this.cfg.isJokerAllowed() && this.joker)) {
                this.crossOut.visible = this.cfg.params.crossOutUnused
            } else {
                this.crossOut.visible = false
            }
        }
    }

    getEntryPoint(direction: Direction, external?: boolean): paper.Point {
        if (direction === Direction.forward) {
            return this._getMiddlePoint(3, external)
        } else {
            return this._getMiddlePoint(1, external)
        }
    }

    // angle to the front of obstacle, 0 = heads on
    getEntryAngle(direction: Direction): number {
        return 0
    }

    // angle from the rear of obstacle, 0 = heads off
    getExitAngle(direction: Direction): number {
        return 0
    }

    getExitPoint(direction: Direction, external?: boolean): paper.Point {
        if (direction === Direction.forward) {
            return this._getMiddlePoint(1, external)
        } else {
            return this._getMiddlePoint(3, external)
        }
    }

    doubleClick(point?: paper.Point): boolean {
        if (point && this.nameText && this.nameText.contains(point)) {
            this.view?.objectPanelEl?.selectObstacleNameInputArea()
        }
        return false
    }

    contains(point: paper.Point): boolean {
        let r = super.contains(point)
        if (!r && this.nameText) {
            r = this.nameText.contains(point)
        }
        return r
    }

    toJson(): DesignPathObject {
        return {
            ...super.toJson(),
            directionRound1: this.passes[0][0],
            directionRound2: this.passes[1][0],
            directionRound3: this.passes[2][0],
            name: this.nameText.content || '',
            userArrowDirection: this.userArrowDirection,
            joker: this.joker,
            jokerNumber: this.jokerNumber
        } as DesignPathObject
    }
}
