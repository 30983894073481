<div class="flex align-items-center">
    <p-menu #langsMenu
            [model]="langItems"
            [popup]="true"></p-menu>
    <p-button (click)="langsMenu.toggle($event)"
              class="hidden md:block"
              [text]="true"
              styleClass="mr-3"><span class="flag flag-{{ selectedCountry }}"></span></p-button>
    <img [src]="(auth.user | async)?.photoURL" *ngIf="(auth.user | async)?.photoURL"
         width="40" height="40" class="mr-3 hidden md:block"/>
    <a class="mr-3 hidden md:block text-white hover:text-indigo-100"
       routerLink="/user">
        {{ (auth.user | async)?.displayName || (auth.user | async)?.email }}
    </a>
    <button pButton type="button" (click)="logout()" *ngIf="auth.user | async" i18n>Wyloguj</button>

    <!-- TODO: apps does not work well for now -->
    <!--
    <p-menu #menu
            [style]="{width: '17rem'}"
            [model]="downloadItems"
            [popup]="true"></p-menu>
    <button pButton type="button" (click)="menu.toggle($event)" icon="pi pi-download" label="Pobierz" i18n-label class="ml-3"></button>
    -->
</div>
