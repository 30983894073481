import paper from 'paper'
import { DesignMidPoint } from '../design.schema'
import { clampDeltaToField } from '../utils'
import { ObstaclePathNode } from './detail.path.node'
import { Selectable } from './detail.selection'
import { UiCommandId, UiContextMenu } from './detail.ui.commands.defs'
import { ParkourConfig } from '../parkour.config'
import { ParkourCanvas } from '../parkour-canvas/parkour-canvas'
import { DetailComponentInterface } from './detail.component.interface'

export type PathMidPointLike = PathMidPoint | {
    x: number
    y: number
    handleFactor: number
}

export class PathMidPoint implements Selectable {
    midpointobject = true;
    midPointMarker?: paper.Shape.Circle
    selectedBeforeMouseDown: boolean = false;
    changed: boolean = false;
    radius: number = this.cfg.scaleAccordingToFieldSize(0.7);
    public levelItem: paper.Item
    priority: number = 0;
    focusLock: boolean = false;
    public parentSelector?: Selectable
    delCount: number = 0; // when it gets to 0 then it is ok to delete the midpoint
    private handleFactorInternal: number = PathMidPoint.DEFAULT_HANDLE_FACTOR;
    contextMenu?: UiContextMenu

    set handleFactor(f: number) {
        this.handleFactorInternal = Math.round(f * 10) / 10
    }
    get handleFactor(): number {
        return this.handleFactorInternal
    }

    update() {
    }

    getRotation(): number {
        return 0
    }

    scaleObject(scale: number): boolean {
        return false
    }

    get bounds(): paper.Rectangle {
        return this.levelItem.bounds
    }

    static DEFAULT_HANDLE_FACTOR = 1;

    constructor(
        public point: paper.Point,
        handleFactor: number,
        public parent: ObstaclePathNode,
        public color: paper.Color,
        private cfg: ParkourConfig,
        private canvas: ParkourCanvas,
        private view?: DetailComponentInterface,
        visible?: boolean
    ) {
        this.levelItem = this.midPointMarker = new paper.Shape.Circle({
            center: point,
            radius: this.radius,
            strokeColor: null,
            strokeWidth: this.cfg.params.lineWidth,
            strokeScaling: false
        })
        this.handleFactor = handleFactor
        if (!this.color) {
            this.color = new paper.Color('lightgreen')
        }
        if (visible) {
            this.show()
        }
        if (this.view) {
            this.contextMenu = new UiContextMenu(this.view)
            this.contextMenu.add(this.view.uiCommands.getContextMenuItem(UiCommandId.DELETE_OBJECT))
        }
    }

    contains(point: paper.Point) {
        return this.point.getDistance(point) < this.radius
    }

    select(point?: paper.Point): void {
        if (this.priority > 0) {
            this.priority = 0
        }
        this.priority--
        if (this.midPointMarker) {
            this.midPointMarker.fillColor = this.color
        }
        this.parent.highlight()
    }

    isSelected(): boolean {
        return this.midPointMarker !== undefined && this.midPointMarker.fillColor !== null
    }

    deselect(): void {
        this.priority = 0
        if (this.midPointMarker) {
            this.midPointMarker.fillColor = null
        }
        this.parent.dehighlight()
    }

    show(): void {
        if (this.midPointMarker) {
            this.midPointMarker.strokeColor = this.color
        }
    }

    hide(): void {
        if (this.midPointMarker) {
            this.midPointMarker.strokeColor = null
        }
    }

    getPosition(): paper.Point {
        return this.point
    }

    doubleClick(): boolean {
        this.handleFactor = PathMidPoint.DEFAULT_HANDLE_FACTOR
        return true
    }

    adjustHandleFactor(delta: number) {
        this.handleFactor += delta > 0 ? -0.1 : 0.1
        if (this.handleFactor < 0) {
            this.handleFactor = 0
        }
        if (this.handleFactor > 5) {
            this.handleFactor = 5
        }
    }

    setPosition(position: paper.Point) {
        this.point = position
        if (this.midPointMarker) {
            this.midPointMarker.position = this.point
        }
    }

    drag(delta: paper.Point, point: paper.Point) {
        if (this.cfg.params.limitToField) {
            clampDeltaToField(this.canvas.field, this.point, delta)
        }
        this.changed = !delta.equals([0, 0])
        this.point = this.point.add(delta)
        if (this.midPointMarker) {
            this.midPointMarker.position = this.point
        }
    }

    move(delta: paper.Point) {
        if (this.cfg.params.limitToField) {
            clampDeltaToField(this.canvas.field, this.point, delta)
        }
        this.changed = !delta.equals([0, 0])
        this.setPosition(this.point.add(delta))
    }

    rotate(angleDelta: number, base?: paper.Point) {
        if (base && this.midPointMarker) {
            this.changed = angleDelta != 0 || this.midPointMarker.position !== this.point
            this.point = this.point.rotate(angleDelta, base)
            this.midPointMarker.position = this.point
        }
    }

    destroy() {
        if (this.midPointMarker && this.midPointMarker.isInserted()) {
            this.midPointMarker.remove()
        }
        this.midPointMarker = undefined
    }

    toJson(): DesignMidPoint {
        return {
            x: this.point.x,
            y: this.point.y,
            handleFactor: this.handleFactor
        } as DesignMidPoint
    }
}
