import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { ParkourCanvas } from "../parkour-canvas/parkour-canvas";
import { ConversionService } from '../services/conversion.service'
import { LimitsService } from '../services/limits.service'
import { ParkourConfig } from '../parkour.config'
import { timeout } from 'rxjs'

@Component({
    selector: 'app-design-render',
    standalone: true,
    imports: [ParkourCanvas],
    templateUrl: './design-render.component.html',
    styleUrl: './design-render.component.scss'
})
export class DesignRenderComponent implements AfterViewInit {
    @ViewChild('canvas') canvas?: ParkourCanvas
    cfg: ParkourConfig
    status: string = ''
    constructor(
        limitsService: LimitsService,
        conversionService: ConversionService,
    ) {
        this.cfg = new ParkourConfig(limitsService, conversionService, {})
        this.status = 'waiting for file'
    }
    
    ngAfterViewInit() {
    }

    onFileSelected(event: any) {
        this.status = 'loading'
        const files = event?.target?.files
        if (files instanceof FileList && files.length > 0) {
            const file = files[0]
            const reader = new FileReader()
        
            reader.onloadend = (ev: ProgressEvent<FileReader>) => {
                if (!this.canvas?.paper) {
                    this.status = 'error: no canvas'
                    return
                }
                if (reader.result) {
                    try {
                        const design = JSON.parse(reader.result as string)
                        delete design['author']
                        delete design['authorName']
                        delete design['localId']
                        delete design['remoteId']
                        const idxs: number[] = []
                        design.objects.forEach((o: any, i: number) => {
                            if (o.kind && o.kind === 'user-image') {
                                idxs.push(i)
                            }
                        })
                        idxs.sort((a, b) => b - a).forEach(i => design.objects.splice(i, 1))
                        this.canvas.loadDesign(design).pipe(timeout(5000)).subscribe({
                            next: () => {
                                if (!this.canvas) {
                                    this.status = 'error: no canvas 2'
                                    return
                                }
                                this.canvas.removeBanners()
                                if (this.canvas.pageLimits) {
                                    this.canvas.pageLimits.visible = false
                                }
                                this.canvas.layoutLinesVisibility = false
                                if (this.canvas.grid) {
                                    this.canvas.grid.visible = false
                                }
                                this.canvas.updatePath()
                                this.canvas.resetView()
                                this.status = 'ok'        
                            },
                            error: (err) => {
                                this.status = 'error: ' + err
                            }
                        })
                    } catch (e) {
                        this.status = 'error: exception ' + e
                    }
                } else {
                    this.status = 'error: no result'
                }
            }
            reader.readAsText(file)
        } else {
            this.status = 'error: no files'
        }
    }
}
