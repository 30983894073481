import { MenuItem, MenuItemCommandEvent } from "primeng/api"
import { PriorityArray, PriorityItem } from "../utils"
import { Selectable } from "./detail.selection"
import { DetailComponentInterface } from "./detail.component.interface"

export enum KeyModifiers {
    NONE = 0x00,
    CONTROL = 0x01,
    META = 0x02,
    SHIFT = 0x04
}

type KeyCallback = (arg0: DetailComponentInterface, arg1: KeyboardEvent) => void

export enum UiCommandId {
    NONE = 'none',
    CHOOSE_OBJECT_PROMPT = 'choose_object_prompt',
    SELECT_ALL = 'select_all',
    SELECT_MULTIPLE = 'select_multiple',
    ROTATE_LEFT = 'rotate_left',
    ROTATE_RIGHT = 'rotate_right',
    MOVE_LEFT = 'move_left',
    MOVE_RIGHT = 'move_right',
    MOVE_UP = 'move_up',
    MOVE_DOWN = 'move_down',
    DELETE_OBJECT = 'delete_object',
    CLONE_OBJECT = 'clone_object',
    OPEN_DEBUG = 'open_debug',
    CONNECT_PATH = 'connect_path',
    AUTO_ROTATE = 'auto_rotate',
    DETACH_OBJECT = 'detach_object',
    ADD_OXER = 'add_oxer',
    UNDO = 'undo',
    REDO = 'redo',
    MAKE_COMBINATION = 'make_composition',
    CLEAR_PATH = 'clear_path',
    CLEAR_FIELD = 'clear_field',
    ESCAPE = 'escape',
    DELETE_FORWARD_PATH = 'delete_forward_path',
    DELETE_ALL_FORWARD_PATHS = 'delete_all_forward_paths',
    DELETE_ROUTE_FORWARD = 'delete_route_forward',
    DELETE_ROUTE_BACKWARD = 'delete_route_backward',
    ZOOM_IN = 'zoom_in',
    ZOOM_OUT = 'zoom_out',
    CENTER_VIEW = 'center_view',
    SAVE_PDF = 'save_pdf',
    PRINT = 'print',
    TRACE_PATH = 'trace_path',
    VALIDATE_PARKOUR = 'validate_parkour',
    COMP_PARAMS = 'comp_params',
    VIEW_PARAMS = 'display_options',
    STATS = 'stats',
    EDIT_MATERIALS = 'edit_materials',
    TOGGLE_ROUTE_1 = 'toggle_route_1',
    TOGGLE_ROUTE_2 = 'toggle_route_2',
    TOGGLE_ROUTE_25 = 'toggle_route_25',
    TOGGLE_ROUTE_3 = 'toggle_route_3',
    TOGGLE_LABELS_1 = 'toggle_labels_1',
    TOGGLE_LABELS_2 = 'toggle_labels_2',
    TOGGLE_LABELS_25 = 'toggle_labels_25',
    TOGGLE_DISTANCES_1 = 'toggle_distances_1',
    TOGGLE_DISTANCES_2 = 'toggle_distances_2',
    TOGGLE_DISTANCES_25 = 'toggle_distances_25',
    TOGGLE_DISTANCES_3 = 'toggle_distances_3',
    TOGGLE_PAGE_LIMITS = 'toggle_page_limits',
    TOGGLE_GRID = 'toggle_grid',
    TOGGLE_CONTROL_POINTS = 'toggle_control_points',
    TOGGLE_POSITION_LINES = 'toggle_position_lines',
    EXIT_TO_LIST_OF_DESIGNS = 'back_home',
    CHANGE_FIELD_SIZE = 'field_size',
    CREATE_NEW_DESIGN = 'new_design',
    ZOOM_100_PERCENT = 'zoom_100',
    REVERSE_DIRECTION = 'reverse_direction',
    LOAD_IMAGE = 'load_image',
    GROUP_OBJECTS = 'group_objects',
    UNGROUP_OBJECTS = 'ungroup_objects',
    REMOVE_FROM_GROUP = 'remove_from_group',
    BLOCK_OBJECT_SCALE = 'bloack_object_scale',
    BLOCK_ROTATE_SNAP = 'block_rotate_snap',
    PAN_FIELD = 'pan_field',
    CHANGE_PATH_EDIT_MODE = 'change_path_edit_mode',
    SPLIT_PATH_AFTER = 'split_path_after',
    CLEAR_MANUAL_LABELS = 'clear_manual_labels',
    CLEAR_ALL_MANUAL_LABELS = 'clear_all_manual_labels',
    TAPE_MEASURE = 'tape_measure',
    TRANS_FLIP_VERTICAL = 'trans_flip_vertical',
    TRANS_FLIP_HORIZONTAL = 'trans_flip_horizontal',
    TRANS_ROTATE_RIGHT = 'trans_rotate_right',
    TRANS_ROTATE_LEFT = 'trans_rotate_left',
    TRANS_SWAP_START_FINISH = 'trans_swap_start_finish',
    SHORTCUTS = 'shortcuts',
    SHOW_SUPPORT_CHAT = 'show_support_chat',
    SHOW_COOKIE_CONSENT = 'show_cookie_consent',
    OPEN_TERMS_AND_CONDITIONS = 'terms_and_conditions',
    OPEN_PRIVACY_POLICY = 'privacy_policy',
    VERSION_BACKLOG = 'version_backlog',
    CURVE_SMOOTH_ALL = 'curve_smooth_all',
    CURVE_STRAIGHTEN_ALL = 'curve_straighten_all',
    CURVE_SMOOTH_POINT = 'curve_smooth_point',
    CURVE_STRAIGHTEN_POINT = 'curve_straighten_point',
    IMAGE_SELECT = 'image_select',
    SUBSCRIPTION_PLANS = 'subscription_change',
    SHOW_GUIDES = 'show_guides',
    RENDER = 'render',
}

export enum UiGroupId {
    NONE = 0,
    PROJECT = 1,
    VIEW,
    EDIT,
    OBJECT,
    TRANSFORM,
    HELP,
}

export type UiParams = {
    view: DetailComponentInterface,
    items: Selectable[],
    flatItems: Selectable[],
}

export type UiShortcut = {
    readonly default: string
    readonly modifiers?: KeyModifiers
    readonly ro?: boolean
    outOfFocusKeyUp?: boolean
    outOfFocusKeyDown?: boolean
    keyUpAction?: KeyCallback
    keyDownAction?: KeyCallback
}

export enum UiAccepts {
    NONE = 0x00,
    SINGLE = 0x01,
    MANY = 0x02,
}

type UiDisabledCallback = (params: UiParams) => boolean

type UiContextMenuItemRef = PriorityItem & {
    id: UiCommandId
}

type UiContextMenuItemDef = MenuItem & PriorityItem & {
    accepts?: UiAccepts,
    options?: (MenuItem & {
        getDisabled: UiDisabledCallback
    })[],
    getDisabled: UiDisabledCallback,
}

export type UiContextMenuItem = UiContextMenuItemDef & UiContextMenuItemRef & {
    rawLabel?: string,
    command?(event: MenuItemCommandEvent | MouseEvent): void,
    buttonTip?: string,
}

type UiMenuItem = MenuItem & {
    separatorAfter?: boolean,
}

export type UiButton = {
    id: UiCommandId,
    command: (event: any) => void,
    icon: string,
    disabled: boolean,
    tooltip?: string,
}

export type UiToggleMenuItem = UiMenuItem & {
    onOffState: boolean
    labelOn: string
    labelOff: string
}

export type UiMenu = {
    id: UiGroupId,
    label: string,
    icon?: string,
    items?: UiMenuItem[],
    contextItems?: () => UiMenuItem[] | undefined,
}

export class UiContextMenu {
    items: PriorityArray<UiContextMenuItemRef> = new PriorityArray()

    constructor(private _view: DetailComponentInterface) {
    }

    add(...items: (UiContextMenuItem | undefined)[]) {
        items.forEach(i => {
            if (i) {
                this.items.push(i)
            }
        })
    }
}

export type UiCommand = {
    readonly id: UiCommandId,
    group: UiGroupId,
    label?: string,
    description?: string,
    icon?: string,
    shortcut?: UiShortcut,
    toggle?: UiToggleMenuItem,
    menu?: UiMenuItem,
    contextMenu?: UiContextMenuItemDef,
    command?: (params: UiParams) => void,
}
