import * as B from '@babylonjs/core'
import { DesignPathObject, DesignSchema, DesignTerminalObject } from '../design.schema'
import { RenderedObject } from './parkour-renderer.object'
import { MaterialManager } from './parkour-renderer.materials'

export class RenderedPhotocells extends RenderedObject {
    private _createStand(
        text: string, pos: B.Vector3, 
        standWidth: number, standHeight: number, standDepth: number): B.TransformNode
    {
        const standGroup = new B.TransformNode("standGroup", this.scene)
        const stand = B.MeshBuilder.CreateBox("stand", 
            { width: standWidth, height: standHeight, depth: standDepth }, this.scene)
        
        stand.material = this.materialManager.getMaterial('photocell-stand', (name: string) => {
            const m = new B.StandardMaterial(name, this.scene)
            m.diffuseColor = B.Color3.White()
            return m
        })
        stand.parent = standGroup
        this.shadowGenerator.addShadowCaster(stand)

        const textPlane = B.MeshBuilder.CreatePlane('textPlane', 
            { width: standWidth, height: standHeight * 0.5 }, this.scene)
        textPlane.position.y = 0
        textPlane.position.x = 0
        textPlane.position.z = -standDepth / 2 - 0.01
        textPlane.material = this.materialManager.getTextMaterial(this.scene, text)
        textPlane.parent = standGroup
        standGroup.position = pos.add(new B.Vector3(0, standHeight / 2, 0))
        return standGroup
    }

    private _createTripodWithPhotocell(pos: B.Vector3): B.TransformNode {
        const tripodHeight = 1.0
        const supportHeight = 0.3
        const legRadius = 0.02
        const legHeight = tripodHeight - supportHeight
        const baseRadius = 0.4
        const photocellRadius = 0.15
        const photocellThickness = 0.04        

        const photocellGroup = new B.TransformNode('photocellGroup', this.scene)

        const support = B.MeshBuilder.CreateCylinder('support', 
            { diameter: legRadius * 2, height: supportHeight }, this.scene)
        const metalMaterial = this.materialManager.getMaterial('photocell-metal', (name: string) => {
            const m = new B.StandardMaterial(name, this.scene)
            m.diffuseColor = new B.Color3(0.8, 0.8, 0.8)
            m.specularColor = new B.Color3(1, 1, 1)
            return m
        })
        support.material = metalMaterial
        support.parent = photocellGroup
        this.shadowGenerator.addShadowCaster(support)
        support.position.y = tripodHeight - supportHeight / 2 
        const legLength = Math.sqrt(legHeight ** 2 + baseRadius ** 2)

        for (let i = 0; i < 3; i++) {
            const leg = B.MeshBuilder.CreateCylinder("leg", 
                { diameter: legRadius * 2, height: legLength }, this.scene)
            leg.material = metalMaterial
            leg.position.set(baseRadius / 2, legLength / 2, 0)
            leg.rotate(new B.Vector3(0, 0, 1), Math.PI / 2 - Math.acos(baseRadius / legLength))
            leg.rotateAround(new B.Vector3(0, 0, 0), new B.Vector3(0, 1, 0), i * Math.PI * 2 / 3 + Math.PI / 6)
            leg.parent = photocellGroup
            this.shadowGenerator.addShadowCaster(leg)
        }

        const photocell = B.MeshBuilder.CreateDisc('photocell', 
            { radius: photocellRadius * 0.8, tessellation: 32 }, this.scene)
        photocell.position.set(0, tripodHeight + photocellRadius / 2, -photocellThickness / 2 - 0.01)
        photocell.material = this.materialManager.getMaterial('photocell-disc', (name: string) => {
            const m = new B.StandardMaterial(name, this.scene)
            m.diffuseColor = m.ambientColor = new B.Color3(1, 1, 1)
            return m
        })
        photocell.parent = photocellGroup
    
        const photocellBase = B.MeshBuilder.CreateCylinder("photocellBase", 
            { diameter: photocellRadius * 2, height: photocellThickness }, this.scene)
        photocellBase.position.set(0, tripodHeight + photocellRadius / 2, 0)
        photocellBase.rotation.x = Math.PI / 2
        photocellBase.material = this.materialManager.getMaterial('photocell-base', (name: string) => {
            const m = new B.StandardMaterial(name, this.scene)
            m.diffuseColor = new B.Color3(0.5, 0.5, 0.5)
            return m
        })
        photocellBase.parent = photocellGroup
        this.shadowGenerator.addShadowCaster(photocellBase)

        photocellGroup.position = pos.add(new B.Vector3(0, legHeight - legLength, 0))
        return photocellGroup
    }

    constructor(
        protected scene: B.Scene,
        private materialManager: MaterialManager,
        private shadowGenerator: B.ShadowGenerator,
        o: DesignTerminalObject | DesignPathObject,
        design: DesignSchema) 
    {
        super(scene, o, design)

        let text = '', cellsDistance = 0
        if (o.kind === 'start') {
            text = 'START'
            cellsDistance = (o as DesignTerminalObject).cellsDistance
        } else if (o.kind === 'finish') {
            text = 'FINISH'
            cellsDistance = (o as DesignTerminalObject).cellsDistance
        }
        if (o.kind === 'finish-start') {
            cellsDistance = design.obstacleLength * 1.5 || 500
        }
        if (cellsDistance > 0) {
            const angle = o.angle * 2 * Math.PI / 360
            const pos = new B.Vector3(o.x / 100, 0, design.parkourHeight - o.y / 100)
            const p1 = pos.add(new B.Vector3(-cellsDistance / 2 / 100, 0, 0))
            const p2 = pos.add(new B.Vector3(cellsDistance / 2 / 100, 0, 0))
            const pod1 = this._createTripodWithPhotocell(p1)
            pod1.rotate(new B.Vector3(0, 1, 0), -Math.PI / 2)
            const pod2 = this._createTripodWithPhotocell(p2)
            pod2.rotate(new B.Vector3(0, 1, 0), Math.PI / 2)
            let stand
            if (text) {
                const width = 1, height = 0.5, depth = 0.2
                stand = this._createStand(text, pos, width, height, depth)
                stand.position = p2.add(new B.Vector3(width / 2, height / 2, -depth * 3))
            }
            pod1.rotateAround(pos, new B.Vector3(0, 1, 0), angle)
            pod2.rotateAround(pos, new B.Vector3(0, 1, 0), angle)
            stand?.rotateAround(pos, new B.Vector3(0, 1, 0), angle)
        }
    }
}