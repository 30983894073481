import * as B from '@babylonjs/core'
import { MaterialManager } from './parkour-renderer.materials'

export class RenderedSky {
    mesh: B.Mesh | undefined

    constructor(
        scene: B.Scene,
        materialManager: MaterialManager)
    {
        const texture = materialManager.getTexture('sky-bluecloud', () => {
            return new B.CubeTexture('assets/textures/bluecloud', scene)
        })
        this.mesh = scene.createDefaultSkybox(texture, false) ?? undefined
    }
}
