import { ElementRef } from '@angular/core'
import * as B from '@babylonjs/core'
import { DesignSchema } from '../design.schema'

export class ParkourRendererCamera {
    private _camera: B.FreeCamera
    private _flyProgress: number = 0
    private _prevDirectionRotation?: B.Vector3

    private _flyMode: boolean = false
    private _flySpeed: number = 30
    private _flyHeight: number = 2.5

    private _walkSpeed: number = 15
    private _isJumping: boolean = false
    private _jumpTime: number = 0
    private _jumpInitialPosition: number = 0

    get fieldOfView(): number {
        return this._camera.fov
    }

    set fieldOfView(z: number) {
        this._camera.fov = z
    }

    private _onBeforeRenderObservable(eventData: B.Scene, eventState: B.EventState) {
        const maxRadius = 100, minHeight = 2, maxHeight = 100
        const camera = this._camera
        const x = camera.position.x
        const z = camera.position.z

        const distanceFromCenter = Math.sqrt(x * x + z * z)
        if (distanceFromCenter > maxRadius) {
            const scale = maxRadius / distanceFromCenter
            camera.position.x *= scale
            camera.position.z *= scale
        }
        if (camera.position.y < minHeight) {
            camera.position.y = minHeight
        }
        if (camera.position.y > maxHeight) {
            camera.position.y = maxHeight
        }

        const numPoints = this.flyPath.length
        let lookOffset = 100
        if (this._flyMode && numPoints > 3) {
            if (this._flyProgress >= numPoints - 2) {
                this._flyProgress = numPoints - 3
                this._flyMode = false
                this.onFlyModeChange(this._flyMode)
            }
            const index = Math.floor(this._flyProgress)
            if (index + lookOffset >= numPoints - 1) {
                lookOffset = numPoints - index - 2
            }
            const frac = Math.round((this._flyProgress - index) * 10) / 10
            const position = B.Vector3.Lerp(this.flyPath[index], this.flyPath[index + 1], frac)
            camera.position = position.add(new B.Vector3(0, this._flyHeight, 0))
            const dirPosition = B.Vector3.Lerp(this.flyPath[index + lookOffset], this.flyPath[index + lookOffset + 1], frac)
            const direction = dirPosition.subtract(position).normalize()
            const yaw = Math.atan2(direction.x, direction.z)
            const pitch = -Math.atan2(direction.y, Math.sqrt(direction.x * direction.x + direction.z * direction.z)) + Math.PI / 15
            const rot = new B.Vector3(pitch, yaw, 0)
            
            if (this._prevDirectionRotation) {
                const delta = rot.subtract(this._prevDirectionRotation)
                camera.rotation = camera.rotation.add(delta)
            } else {
                camera.rotation = rot
            }
            this._prevDirectionRotation = rot
            this._flyProgress += this._flySpeed / 20
            this._flyProgress = Math.round(this._flyProgress * 10) / 10
        }

        if (!this._flyMode && this._isJumping) {
            const speed = 5.5
            this._jumpTime += this.engine.getDeltaTime() / 1000
            let hd = speed * this._jumpTime - 0.5 * 9.81 * (this._jumpTime ** 2)
            if (hd < 0) {
                hd = 0
                this._jumpTime = 0
                this._isJumping = false
            }
            camera.position.y = this._jumpInitialPosition + hd
            console.log(camera.position.y)
        }
    }

    setFlySpeed(v: number) {
        this._flySpeed = v
    }

    setWalkSpeed(v: number) {
        this._walkSpeed = v
        this._camera.speed = this._walkSpeed / 50
    }

    startFlyMode() {
        this._flyMode = true
        this._flyProgress = 0
        this._prevDirectionRotation = undefined
    }

    stopFlyMode() {
        this._flyMode = false
    }

    jump() {
        if (!this._isJumping) {
            this._isJumping = true
            this._jumpTime = 0
            this._jumpInitialPosition = this._camera.position.y
        }
    }

    constructor(
        private engine: B.Engine,
        private scene: B.Scene,
        private flyPath: B.Vector3[],
        design: DesignSchema,
        private onFlyModeChange: (mode: boolean) => void)
    {
        this._flyProgress = 0
        this._prevDirectionRotation = undefined
        this._flyMode = false

        const camera = this._camera = new B.FreeCamera("camera1", new B.Vector3(10, 5, 10), scene)
        camera.setTarget(new B.Vector3(0, 5, 0))
        camera.attachControl(undefined, true)
        camera.keysUp.push(87)          // W
        camera.keysLeft.push(65)        // A
        camera.keysDown.push(83)        // S
        camera.keysRight.push(68)       // D
        camera.keysRotateLeft.push(81)  // Q
        camera.keysRotateRight.push(69) // E
        camera.keysUpward.push(32)      // Space
        camera.keysDownward.push(17)    // Control
        camera.speed = 0.25
        camera.angularSensibility = 3000
        camera.minZ = 0.5

        this.scene.onBeforeRenderObservable.add(this._onBeforeRenderObservable.bind(this))

        camera.checkCollisions = true
        camera.applyGravity = true
        this._camera.ellipsoid = new B.Vector3(0.01, 1, 0.01)
        this._camera.ellipsoidOffset = new B.Vector3(0, 0, 0)        
    }

    setCameraPositionAndTarget(pos: B.Vector3, target: B.Vector3) {
        this._camera.position = pos
        this._camera.setTarget(target)
    }
    destroy() {
    }
}
