import { SubscriptionLevel } from "../design.schema";


export enum SubscriptionInterval {
    YEARLY = 'yearly',
    MONTHLY = 'monthly'
}

export enum PaymentProvider {
    STRIPE = 'stripe',
    PADDLE = 'paddle'
}

export interface SubscriptionPlan {
    name: SubscriptionLevel;
    interval?: SubscriptionInterval;
    provider?: PaymentProvider;
    canceled?: boolean
}