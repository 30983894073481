import * as B from '@babylonjs/core'
import * as SunCalc from 'suncalc'

export class ParkourRendererLights {
    private _sunLight: B.DirectionalLight
    private _ambientLight: B.HemisphericLight
    shadowGenerator: B.ShadowGenerator

    private _latitude: number = 54.4288158
    private _longitude: number = 18.5854238
    private _date: Date = new Date('2024-06-15T12:00:00Z')

    constructor(scene: B.Scene) {
        this._sunLight = new B.DirectionalLight('sunLight', B.Vector3.Up(), scene)
        this._sunLight.intensity = 1.0
        this._sunLight.shadowEnabled = true
        this._sunLight.autoUpdateExtends = true
        this._sunLight.autoCalcShadowZBounds = true
        this.hourOfDay = 12        

        this._ambientLight = new B.HemisphericLight('ambient', new B.Vector3(0, 1, 0), scene)
        this._ambientLight.intensity = 0.7
        this.shadowGenerator = new B.ShadowGenerator(8192, this._sunLight)    
    }

    get hourOfDay(): number {
        return this._date.getHours() + this._date.getMinutes() / 60
    }

    set hourOfDay(h: number) {
        let m = Math.round((h - Math.floor(h)) * 60)
        h = h % 24
        this._date.setHours(h, m)
        const sunPosition = SunCalc.getPosition(this._date, this._latitude, this._longitude)
        const azimuth = sunPosition.azimuth    // Angle in radians (0 = North)
        const altitude = sunPosition.altitude  // Elevation in radians (0 = horizon, 90° overhead)
        const sunDirection = new B.Vector3(
            -Math.sin(azimuth) * Math.cos(altitude),
            -Math.sin(altitude),
            -Math.cos(azimuth) * Math.cos(altitude)
        ).normalize()
        this._sunLight.intensity = altitude < 0 ? 0 : altitude < Math.PI / 12 ? 0.5 : 1.0
        this._sunLight.direction = sunDirection
    }
}
