<div class="flex flex-column h-full max-h-full w-full max-w-full" (contextmenu)="false">

<p-toast></p-toast>

<div *ngIf="getLocationPath() != '/designer'"
    class="bg-indigo-400 text-white p-2 flex justify-content-between header-1-height">
    <div class="flex gap-1 align-items-center">
        <app-left-top-bar></app-left-top-bar>

        <div class="hidden md:flex gap-3 mt-1">
            <div *ngIf="!(auth.user | async)">
                <a class="text-white hover:text-indigo-100"
                   [href]="linkToWebsite" i18n>Wróć do strony serwisu</a>
            </div>

            <div *ngIf="(auth.user | async)">
                <a class="text-white hover:text-indigo-100"
                   [ngClass]="getLocationPath() == '/' ? 'font-bold' : ''"
                   routerLink="/" i18n>Strona Główna</a>
            </div>
            <div>
                <a class="text-white hover:text-indigo-100"
                   [ngClass]="getLocationPath() == '/designs-library' ? 'font-bold' : ''"
                   routerLink="/designs-library" i18n>Biblioteka Projektów</a>
            </div>
            <div>
                <a class="text-white hover:text-indigo-100"
                   [href]="linkToGuides" target="blank" i18n>Poradniki</a>
            </div>
            <div *ngIf="(auth.user | async)">
                <a class="text-white hover:text-indigo-100"
                   [ngClass]="getLocationPath() == '/user' ? 'font-bold' : ''"
                   routerLink="/user" i18n>Profil Użytkownika</a>
            </div>
            <div *ngIf="!(auth.user | async)">
                <a class="text-white hover:text-indigo-100"
                   [ngClass]="getLocationPath() == '/' ? 'font-bold' : ''"
                   routerLink="/" i18n>Zaloguj się</a>
            </div>
        </div>

    </div>
    <app-right-top-bar></app-right-top-bar>
</div>

<!-- splash screen showed before concluding if user is logged in or not -->
<div *ngIf="!auth.concluded" class="flex h-full w-full">
    <div class="text-900 text-8xl font-medium m-auto" i18n="@@WELCOME">Witaj w <b>Parkour</b></div>
</div>

<div *ngIf="nonDesktopWarning && !proceedAnyway" class="flex flex-column w-full h-full align-items-center justify-content-center text-center p-3">
    <img class="w-full max-w-15rem" src="assets/desktop.png"/>
    <div class="text-xl py-3" i18n>Uruchom mnie na komputerze typu desktop lub laptop.</div>
    <div class="text-l" i18n>Wykorzystanie wszystkich możliwości edycji i automatyzacji projektowania wymaga interfejsu użytkownika dostępnego na laptopie lub desktopie.</div>
    <div class="text-l" i18n>W czasie, gdy pracujemy nad uproszczoną wersją mobilną aplikacji, zapraszamy do uruchomienia jej na komputerze.</div>
    <p-button class="py-3" styleClass="text-sm" (click)="proceedAnyway = true" label="Uruchom mimo wszystko (nie zalecane)" i18n-label [text]="true"></p-button>
</div>

<!-- show pages content when loaded -->
<ng-container *ngIf="auth.concluded && (!nonDesktopWarning || proceedAnyway)">
    <router-outlet></router-outlet>
</ng-container>

<!-- confirmation popup for version update and app reload -->
<p-toast key="update-confirm" (onClose)="onUpdateReject()">
    <ng-template let-message pTemplate="message">
        <div class="flex flex-column w-full">
            <div class="mx-2 my-3" i18n>Nowa wersja aplikacji jest dostępna. Przeładować?</div>
            <div class="grid p-fluid">
                <div class="col-6">
                    <p-button (click)="onUpdateReject()" label="Później" i18n-label [text]="true"></p-button>
                </div>
                <div class="col-6">
                    <p-button (click)="onUpdateConfirm()" label="Przeładuj" i18n-label [text]="true"></p-button>
                </div>
            </div>
        </div>
    </ng-template>
</p-toast>


</div>
