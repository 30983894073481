<p-dialog *ngIf="object && object.materials as mat"
    [(visible)]="visibility"
    position="center" [modal]="true" [style]="{ 'width': 'auto', 'max-width': '70vw', 'height': 'auto', 'max-height': '50vw' }"
    (keydown.enter)="onOkDialog()" (keydown.escape)="onCancelDialog()"
    (onShow)="onShowDialog()" (onClose)="onCloseDialog()" (onHide)="onHideDialog()">
    <ng-template pTemplate="header" >
        <span class="p-dialog-title"><i class="fa-solid fa-cubes mr-2"></i>
            <span i18n>Konfiguracja materiałów</span><span> - {{ object.getKindName() }} </span>
        </span>
    </ng-template>
    <div class="flex flex-column">
        <div class="flex flex-row align-items-center justify-content-between">
            <table>
                <tr>
                    <th></th>
                    <th *ngFor="let m of mat.topRow; index as idx">
                        {{ idx + 1 }}
                    </th>
                    <th *ngIf="mat.topRow.length > 1"><span i18n>Ostatni drąg wyżej o</span><span> [{{ sizeUnit }}]</span></th>
                </tr>
                <tr>
                    <td><b>1</b></td>
                    <td class="p-1" *ngFor="let m of mat.topRow; index as idx">
                        <div class="flex flex-row align-items-center justify-content-start">
                            <p-selectButton styleClass="flex flex-row w-full px-0 py-1 m-0" [unselectable]="true"
                                            [options]="idx === 0 ? materialOptionsNoWall : topMaterialOptions" [(ngModel)]="m.material"
                                            [multiple]="false" optionLabel="name" optionValue="value" (onChange)="finishChangeMaterials(m, true)">
                            </p-selectButton>
                            <div #barPickerTop1 class="px-2 m-0">
                                <new-colorPicker [(ngModel)]="m.color1" [menuTarget]="barPickerTop1"
                                    pTooltip="Postawowy kolor dla wizualizacji 3D" i18n-pTooltip (onChange)="finishChangeMaterials(m, false)">
                                </new-colorPicker>
                            </div>
                            <div #barPickerTop2 class="px-0 m-0">
                                <new-colorPicker [(ngModel)]="m.color2" [menuTarget]="barPickerTop2" [disabled]="isNotBar(m)"
                                    pTooltip="Drugi kolor dla wizualizacji 3D" i18n-pTooltip (onChange)="finishChangeMaterials(m, false)">
                                </new-colorPicker>
                            </div>
                        </div>
                        <input class="w-full p-1 m-0 p-inputtext-sm" type="text" pInputText placeholder="Opis" i18n-placeholder [(ngModel)]="m.label" />
                    </td>
                    <td *ngIf="mat.topRow.length > 1" valign="top" class="p-1">
                        <p-inputNumber class="py-1 m-0" inputStyleClass="w-full py-2" [min]="1" [max]="100" [step]="stepInUnits(1, 0.1)" 
                            [showButtons]="true" [(ngModel)]="raisedHeight" [disabled]="!raisedHeightEnabled">
                        </p-inputNumber>
                    </td>
                </tr>
                <tr class="m-0" *ngFor="let m of mat.rows; index as idx">
                    <td>
                        <b>{{ idx + 2 }}</b>
                    </td>
                    <td class="p-1">
                        <div class="flex flex-row align-items-center justify-content-start">
                            <p-selectButton styleClass="w-full flex flex-row px-0 py-1 m-0" [unselectable]="true"
                                            [options]="idx < mat.rows.length - 1 ? materialOptionsNoWall: materialOptionsWithWall" [(ngModel)]="m.material"
                                            [multiple]="false" optionLabel="name" optionValue="value" (onChange)="finishChangeMaterials(m, true)">
                            </p-selectButton>
                            <div #barPicker1 class="px-2 m-0">
                                <new-colorPicker [(ngModel)]="m.color1" [menuTarget]="barPicker1"
                                    pTooltip="Postawowy kolor dla wizualizacji 3D" i18n-pTooltip (onChange)="finishChangeMaterials(m, false)">
                                </new-colorPicker>
                            </div>
                            <div #barPicker2 class="px-0 m-0">
                                <new-colorPicker [(ngModel)]="m.color2" [menuTarget]="barPicker2" [disabled]="isNotBar(m)"
                                    pTooltip="Drugi kolor dla wizualizacji 3D" i18n-pTooltip (ngModelChange)="finishChangeMaterials(m, false)">
                                </new-colorPicker>
                            </div>
                        </div>
                        <input class="w-full p-1 m-0 p-inputtext-sm" type="text" pInputText placeholder="Opis" i18n-placeholder [(ngModel)]="m.label" />
                    </td>
                    <td *ngIf="mat.svg && mat.topRow.length > 1 && mat.rows.length > 0 && idx == 0"
                        [attr.colspan]="mat.topRow.length - 1"
                        [attr.rowspan]="mat.rows.length"
                        class="text-center">
                        <div>
                            <svg width="100" [attr.viewBox]="mat.viewBox" xmlns="http://www.w3.org/2000/svg"
                                 [innerHTML]="mat.svg | safe:'html'"
                                 (contextmenu)="$event.preventDefault()">
                            </svg>
                        </div>
                    </td>
                    <td *ngIf="mat.topRow.length > 1">
                    </td>
                </tr>
            </table>
            <div *ngIf="mat.svg && mat.topRow.length == 1" class="p-4 w-15rem text-center">
                <svg width="50" [attr.viewBox]="mat.viewBox" xmlns="http://www.w3.org/2000/svg" [innerHTML]="mat.svg | safe:'html'"
                     (contextmenu)="$event.preventDefault()">
                </svg>
            </div>
        </div>
        <div *ngIf="mat.svg && mat.topRow.length > 1 && mat.rows.length == 0" class="text-center">
            <svg width="100" [attr.viewBox]="mat.viewBox" xmlns="http://www.w3.org/2000/svg"
                 [innerHTML]="mat.svg | safe:'html'"
                 (contextmenu)="$event.preventDefault()">
            </svg>
        </div>
        <div class="mt-2">
            <p-button class="mr-1" icon="pi pi-plus" [disabled]="mat.rows.length >= mat.maxRows - 1"
                      (click)="addRemoveMaterialsRow(true)">
            </p-button>
            <p-button class="mr-1" icon="pi pi-minus" [disabled]="mat.rows.length <= 0"
                      (click)="addRemoveMaterialsRow(false)">
            </p-button>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <div class="flex flex-row align-items-center justify-content-between">
            <p-button icon="pi pi-refresh" (click)="onRestoreDefaultColors()" label="Przywróć domyślne kolory" i18n-label></p-button>
            <div class="flex flex-row align-items-center justify-content-end">
                <p-button icon="pi pi-times" (click)="onCancelDialog()" label="Anuluj" i18n-label severity="danger"></p-button>
                <p-button icon="pi pi-check" (click)="onOkDialog()" label="Zapisz" i18n-label></p-button>
                <p-button icon="pi pi-trash" (click)="onDeleteDialog()" label="Usuń" i18n-label></p-button>
            </div>
        </div>
    </ng-template>
</p-dialog>
