import { APP_INITIALIZER, ApplicationConfig, ErrorHandler, isDevMode } from '@angular/core'
import { Router, provideRouter, withComponentInputBinding } from "@angular/router"

// firebase
import { getAnalytics, provideAnalytics } from '@angular/fire/analytics'
import { getApp, initializeApp, provideFirebaseApp } from '@angular/fire/app'
import { getAuth, provideAuth } from '@angular/fire/auth'
import { initializeFirestore, persistentLocalCache, persistentMultipleTabManager, provideFirestore } from '@angular/fire/firestore'
import { getStorage, provideStorage } from '@angular/fire/storage'
import { provideFunctions, getFunctions } from '@angular/fire/functions';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideServiceWorker } from '@angular/service-worker'

// Sentry
import * as Sentry from "@sentry/angular"

// PrimeNG
import { MessageService } from 'primeng/api'

import { APP_CONFIG } from '../environments/environment'
import { I18nModule } from './i18n.module'
import { provideHttpClient } from '@angular/common/http'
import { appRoutes } from './app.routes'

export const appConfig: ApplicationConfig = {
    providers: [
        I18nModule.setLocale(),
        I18nModule.setLocaleId(),
        MessageService,
        provideRouter(appRoutes, withComponentInputBinding()),
        provideHttpClient(),
        provideAnimationsAsync(),

        // firebase
        provideFirebaseApp(() => initializeApp(APP_CONFIG.firebase)),
        provideAuth(() => {
            const auth = getAuth()
            if (!APP_CONFIG.production) {
                //connectAuthEmulator(auth, 'http://localhost:9099', { disableWarnings: true });
            }
            return auth
        }),
        provideFirestore(() => {
            const firestore = initializeFirestore(getApp(), {
                localCache: persistentLocalCache({ tabManager: persistentMultipleTabManager() })
            })
            if (!APP_CONFIG.production) {
                //connectFirestoreEmulator(firestore, 'localhost', 9185);
            }
            return firestore;
        }),
        provideStorage(() => getStorage()),
        provideAnalytics(() => getAnalytics()),
        provideFunctions(() => getFunctions()),

        // Sentry
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: false, // was true
            }),
        }, {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => { },
            deps: [Sentry.TraceService],
            multi: true,
        },

        provideServiceWorker('ngsw-worker.js', {
            enabled: !isDevMode(),
            // Register the ServiceWorker as soon as the application is stable
            // or after 30 seconds (whichever comes first).
            //registrationStrategy: 'registerWhenStable:30000'
            registrationStrategy: 'registerImmediately'
        }),
    ],
}
