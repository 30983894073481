import * as B from '@babylonjs/core'
import { DesignBaseObject, DesignSchema, DesignSvgImageObject } from '../design.schema'
import { MaterialManager, ParkourMaterial } from './parkour-renderer.materials'

type Line = [B.Vector2, B.Vector2]

export class RenderedArena {
    private _fenceMaterial: ParkourMaterial
    mesh?: B.Mesh

    private _drawFenceBar(pos: B.Vector3, length: number, barSize: number) {
        const bar = B.MeshBuilder.CreateBox("bar", { width: length, height: barSize, depth: barSize }, this.scene)
        bar.position = pos.add(new B.Vector3(length / 2, 0, 0))
        bar.material = this._fenceMaterial
        bar.checkCollisions = true
        return bar
    }

    private _drawFencePost(pos: B.Vector2, height: number, postSize: number) {
        const post = B.MeshBuilder.CreateBox("post", { width: postSize, height: height, depth: postSize }, this.scene)
        post.position = new B.Vector3(pos.x, height / 2, pos.y)
        post.material = this._fenceMaterial
        post.checkCollisions = true
        return post
    }

    private _drawFenceSegment(pos: B.Vector2, height: number, width: number, close: boolean): B.Mesh[] {
        const barSize = 0.12
        const postSize = 0.2
        const bars = 4
        const mesh: B.Mesh[] = []

        const post = this._drawFencePost(pos, height, postSize)
        if (post) {
            mesh.push(post)
        }

        if (close) {
            const post2 = this._drawFencePost(pos.add(new B.Vector2(width, 0)), height, postSize)
            if (post2) {
                mesh.push(post2)
            }
        }

        const space = height / bars
        const p = new B.Vector3(pos.x, 0, pos.y)
        for (let i = 0; i < bars; i++) {
            const bar = this._drawFenceBar(p.add(new B.Vector3(0, space / 2 + space * i, 0)), width, barSize)
            if (bar) {
                mesh.push(bar)
            }
        }
        return mesh
    }

    private _drawFenceWall(line: Line, height: number): B.Mesh[] {
        const [pos1, pos2] = line
        const vl = pos2.subtract(pos1)
        const length = vl.length()
        let w = height * 2.5
        let n = Math.floor(length / w)
        w = length / n
        const mesh: B.Mesh[] = []
        for (let i = 0; i < n; i++) {
            mesh.push(...this._drawFenceSegment(pos1.add(new B.Vector2(i * w, 0)), height, w, (i === n - 1)))
        }
        const group = new B.TransformNode("fenceGroup", this.scene)
        mesh.forEach(n => n.parent = group)
        group.rotateAround(new B.Vector3(pos1.x, 0, pos1.y), new B.Vector3(0, 1, 0), -Math.atan2(vl.y, vl.x))
        return mesh
    }

    private _linesIntersect(l1: Line, l2: Line): boolean {
        const x1 = l1[0].x, y1 = l1[0].y, x2 = l1[1].x, y2 = l1[1].y // Line 1
        const x3 = l2[0].x, y3 = l2[0].y, x4 = l2[1].x, y4 = l2[1].y // Line 2

        const det = (x2 - x1) * (y4 - y3) - (y2 - y1) * (x4 - x3)

        if (det === 0) {
            return false
        }
        const t = ((x3 - x1) * (y4 - y3) - (y3 - y1) * (x4 - x3)) / det
        const u = ((x3 - x1) * (y2 - y1) - (y3 - y1) * (x2 - x1)) / det
        return t >= 0 && t <= 1 && u >= 0 && u <= 1
    }


    private _isLineCrossingBox(boxPos: B.Vector2, boxSize: B.Vector2, line: Line): boolean {
        const x = boxPos.x, y = boxPos.y, w = boxSize.x, h = boxSize.y
        const left: Line = [new B.Vector2(x, y), new B.Vector2(x, y + h)]
        const right: Line = [new B.Vector2(x + w, y), new B.Vector2(x + w, y + h)]
        const top: Line = [new B.Vector2(x, y), new B.Vector2(x + w, y)]
        const bottom: Line = [new B.Vector2(x, y + h), new B.Vector2(x + w, y + h)]

        return this._linesIntersect(line, left) ||
            this._linesIntersect(line, right) ||
            this._linesIntersect(line, top) ||
            this._linesIntersect(line, bottom)
    }

    private _findOrthogonalIntersection(line: Line, point: B.Vector2): B.Vector2 {
        const x1 = line[0].x, y1 = line[0].y, x2 = line[1].x, y2 = line[1].y, x = point.x, y = point.y
        if (x1 === x2) {
            return new B.Vector2(x1, y)
        }
        if (y1 === y2) {
            return new B.Vector2(x, y1)
        }
        const m = (y2 - y1) / (x2 - x1)
        const mp = -1 / m
        const ix = (m * x1 - y1 - mp * x + y) / (m - mp)
        const iy = y1 + m * (ix - x1)
        return new B.Vector2(ix, iy)
    }

    private _removeFenceFromLine(line: Line, fence: Line): Line[] {
        const x1 = line[0].x, y1 = line[0].y, x2 = line[1].x, y2 = line[1].y
        let ax = fence[0].x, ay = fence[0].y, bx = fence[1].x, by = fence[1].y

        const distanceA = Math.sqrt((ax - x1) ** 2 + (ay - y1) ** 2)
        const distanceB = Math.sqrt((bx - x1) ** 2 + (by - y1) ** 2)

        if (distanceB < distanceA) {
            [ax, ay, bx, by] = [bx, by, ax, ay]
        }
        const ret: Line[] = []
        if (!(ax === x1 && ay === y1)) {
            ret.push([new B.Vector2(x1, y1), new B.Vector2(ax, ay)])
        }
        if (!((bx === x2 && by === y2))) {
            ret.push([new B.Vector2(bx, by), new B.Vector2(x2, y2)])
        }
        if (ret.length === 0) {
            ret.push(line)
        }
        return ret
    }

    private _findObjectsInDesign(schema: DesignSchema, kind: string): DesignBaseObject[] {
        return schema.objects.filter(o => o.kind === kind)
    }
    
    constructor(
        private scene: B.Scene, 
        private materialManager: MaterialManager,
        private shadowGenerator: B.ShadowGenerator, 
        design: DesignSchema)
    {
        this._fenceMaterial = materialManager.getMaterial('arena-posts', (name: string) => {
            const m = new B.StandardMaterial(name, scene)
            m.diffuseColor = new B.Color3(1, 1, 1)
            m.specularColor = new B.Color3(0.5, 0.5, 0.5)    
            return m
        })

        const inOuts = this._findObjectsInDesign(design, 'in-out')
        const mesh: B.Mesh[] = []
        const postHeight = 1.5
        const ph = design.parkourWidth, pw = design.parkourHeight
        if (!design.hideFieldBorder) {
            const lines: Line[] = []
            lines.push([new B.Vector2(0, 0), new B.Vector2(ph, 0)])
            lines.push([new B.Vector2(ph, 0), new B.Vector2(ph, pw)])
            lines.push([new B.Vector2(ph, pw), new B.Vector2(0, pw)])
            lines.push([new B.Vector2(0, pw), new B.Vector2(0, 0)])

            for (let i = 0; i < lines.length; i++) {
                const sections = [lines[i]]
                for (let j = 0; j < inOuts.length; j++) {
                    const o = inOuts[j] as DesignSvgImageObject
                    if (o.imageSize) {
                        const isx = o.imageSize[0] / 150, isy = o.imageSize[1] / 150
                        const boxPos = new B.Vector2(o.x / 100 - isx / 2, pw - (o.y / 100 + isy / 2))
                        const boxSize = new B.Vector2(isx, isy)
                        for (let k = 0; k < sections.length; k++) {
                            const s = sections[k]
                            if (this._isLineCrossingBox(boxPos, boxSize, s)) {
                                const is1 = this._findOrthogonalIntersection(s, boxPos)
                                const is2 = this._findOrthogonalIntersection(s, boxPos.add(boxSize))
                                sections.splice(k, 1)
                                sections.push(...this._removeFenceFromLine(s, [is1, is2]))
                                break
                            }
                        }
                    }
                }
                sections.forEach(s => mesh.push(...this._drawFenceWall(s, postHeight)))
            }
        }
        if (mesh.length > 0) {
            this.mesh = B.Mesh.MergeMeshes(mesh, true) ?? undefined
            if (this.mesh) {
                this.shadowGenerator.addShadowCaster(this.mesh)
            }
        }
    }
}
