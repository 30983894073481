import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AbstractControl, FormGroup, FormsModule, ValidationErrors, Validators } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { InputNumberModule } from 'primeng/inputnumber';
import { CheckboxModule } from 'primeng/checkbox';
import { InputTextModule } from 'primeng/inputtext';
import { TooltipModule } from 'primeng/tooltip';
import { DropdownModule } from 'primeng/dropdown';
import { DetailComponent } from '../../detail.component';
import { PanelModule } from 'primeng/panel'
import { DialogModule } from 'primeng/dialog'
import { Article, CompetitionLocation, LimitRange, ShareMode } from '../../../design.schema'
import { CompetitionTable, SpeedLimits } from "../../../services/limits.service"
import { ClassNo } from "../../../services/limits.service"
import { ArticleOption, ClassNoNameValue, CompLocNameValue, LimitsService } from "../../../services/limits.service"
import { TabMenu, TabMenuModule } from 'primeng/tabmenu'
import { MenuItem } from 'primeng/api'
import { CalendarModule } from 'primeng/calendar'
import { MultiSelectModule } from 'primeng/multiselect'
import { SelectButtonModule } from 'primeng/selectbutton'
import { formatLimits, formatLimitsNew } from '../../../utils'
import { ParkourDialog } from '../../../dialog.interface'
import { UnitPipe, Unit } from "../../../pipes";
import { ConversionService } from '../../../services/conversion.service'


export interface AllValidationErrors {
    control_name: string;
    error_name: string;
    error_value: any;
}

export interface FormGroupControls {
    [key: string]: AbstractControl;
}

@Component({
    selector: 'app-parkour-competition-params',
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ButtonModule,
        InputNumberModule,
        CheckboxModule,
        InputTextModule,
        TooltipModule,
        DropdownModule,
        PanelModule,
        DialogModule,
        TabMenuModule,
        CalendarModule,
        MultiSelectModule,
        SelectButtonModule,
        UnitPipe,
    ],
    templateUrl: './parkour-competition-params.component.html',
    styleUrl: './parkour-competition-params.component.scss'
})
export class ParkourCompetitionParamsComponent implements ParkourDialog {
    @Input({ required: true }) view!: DetailComponent
    @Output() onOk = new EventEmitter<void>()
    @Output() onCancel = new EventEmitter<void>()

    @ViewChild('articleInParams') articleInParamsEl: ElementRef<HTMLElement> | undefined
    @ViewChild('paramsTabMenu') paramsTabMenuEl: TabMenu | undefined

    visibility: boolean = false
    paramsTabMenuItems: MenuItem[] = [
        { label: $localize`Podstawowe`, icon: 'pi pi-fw pi-sliders-h' },
        { label: $localize`Dystans, tempo i czas`, icon: 'pi pi-fw pi-stopwatch' },
        { label: $localize`Wysokości`, icon: 'pi pi-fw pi-arrows-v', tooltipOptions: { tooltipLabel: $localize`Wysokości wszystkich przeszkód, dla których nie ustawiono ich indywidualnie.` } },
        { label: $localize`Szerokości i inne`, icon: 'pi pi-fw pi-arrows-h', tooltipOptions: { tooltipLabel: $localize`Szerokości wszystkich przeszkód, dla których nie ustawiono ich indywidualnie oraz inne wymiary.` } },
        { label: $localize`Udostępnianie`, icon: 'pi pi-fw pi-share-alt' },
    ]
    paramsActiveItem: MenuItem = this.paramsTabMenuItems[0]

    tableOptions = [
        { name: $localize`:used in a list of items@@classNo.none:brak`, value: CompetitionTable.NONE },
        { name: 'A', value: CompetitionTable.TABLE_A },
        { name: 'C', value: CompetitionTable.TABLE_C }
    ]

    tableLanguageOptions = [
        { name: $localize`Polski`, value: 'pl' },
        { name: $localize`Angielski`, value: 'en' },
        { name: $localize`Niemiecki`, value: 'de' }
    ]

    shareModes = [
        { label: $localize`Publiczny`, value: ShareMode.PUBLIC },
        { label: $localize`Prywatny`, value: ShareMode.PRIVATE },
    ]

    errorMsg: string = ''
    articleTooltip = ''
    classNoOptions: ClassNoNameValue[]
    articleOptions: ArticleOption[]
    compLocOptions: CompLocNameValue[]
    speedOptions: any[] = []
    timeLimitTooltip?: string
    selectedItemsLabel: string = $localize`Wybrano {0} wartości`

    firstRoundTimeLimit: string = ''
    secondRoundTimeLimit: string = ''
    thirdRoundTimeLimit: string = ''
    timeLimitLimits: string = ''

    constructor(
        private changeDetectorRef: ChangeDetectorRef,
        public limitsService: LimitsService,
        public conversionService: ConversionService,
    ) {
        this.classNoOptions = structuredClone(limitsService.classNoOptions)
        this.articleOptions = structuredClone(limitsService.articleOptions)
        this.compLocOptions = structuredClone(limitsService.compLocOptions)
    }

    initialize() {
        const form = this.view.cfg.form
        const classes = form.controls.classes.value as ClassNo[]
        const loc: CompetitionLocation = form.controls.compLoc.value
        this.setBaseArticleAndTooltipForm()
        const article: Article = this.view.cfg.baseFeiRulesForm
        const unitSet = this.view.cfg.unitSet
        this.speedOptions = [
            { name: '300', value: 300, disabled: !this.limitsService.isSpeedValid(classes, loc, article, 300, unitSet) },
            { name: '325', value: 325, disabled: !this.limitsService.isSpeedValid(classes, loc, article, 325, unitSet) },
            { name: '350', value: 350, disabled: !this.limitsService.isSpeedValid(classes, loc, article, 350, unitSet) },
            { name: '375', value: 375, disabled: !this.limitsService.isSpeedValid(classes, loc, article, 375, unitSet) },
            { name: '400', value: 400, disabled: !this.limitsService.isSpeedValid(classes, loc, article, 400, unitSet) },
        ]

        this.view.cfg.updateCompatibleClassesInForm(this.classNoOptions)

        const currentLimits = this.conversionService.convertLimits(this.limitsService.getManyLimits(classes, article, unitSet) || {
            heights: {},
            ...this.limitsService.getDefaults(unitSet)
        }, unitSet)

        this.view.cfg.formLimits = currentLimits

        let singleObstacleHeightShouldBeValidated = true
        const f = form.controls.obstacleHeights
        if (f instanceof FormGroup) {
            for (const c of Object.values(ClassNo).filter(c => !!c)) {
                const v = f.controls[c]
                if (v !== undefined) {
                    const val = currentLimits.heights?.[c]
                    if (classes.includes(c) && val) {
                        v.setValidators([Validators.min(val.min!), Validators.max(val.max!), Validators.required])
                        v.updateValueAndValidity()
                        if (v.value === 0 && val.default) {
                            v.patchValue(val.default)
                        }
                        singleObstacleHeightShouldBeValidated = false
                    } else {
                        v.setValidators(Validators.nullValidator)
                    }
                }
            }
        }

        if (singleObstacleHeightShouldBeValidated) {
            let val = currentLimits.height!
            let c = form.controls.obstacleHeight.value
            if (c === undefined || (val.max && c > val.max || val.min && c < val.min) && val.default) {
                form.controls.obstacleHeight.patchValue(val.default)
            }
            form.controls.obstacleHeight.setValidators([Validators.min(val.min!), Validators.max(val.max!), Validators.required])
            form.controls.obstacleHeight.updateValueAndValidity()
        }

        let val = currentLimits.length!
        let c = form.controls.obstacleLength.value
        if (c === undefined || (val.max && c > val.max || val.min && c < val.min) && val.default) {
            form.controls.obstacleLength.patchValue(val.default)
        }
        form.controls.obstacleLength.setValidators([Validators.min(val.min!), Validators.max(val.max!), Validators.required])
        form.controls.obstacleLength.updateValueAndValidity()

        if (currentLimits.speeds?.[loc]?.default) {
            let val: SpeedLimits = currentLimits.speeds[loc]
            let c = form.controls.speed.value
            if (c === undefined || !val.values.includes(c)) {
                form.controls.speed.patchValue(val.default)
            }
            c = form.controls.speed2.value
            if (c === undefined || !val.values.includes(c)) {
                form.controls.speed2.patchValue(val.default)
            }
            c = form.controls.speed3.value
            if (c === undefined || !val.values.includes(c)) {
                form.controls.speed3.patchValue(val.default)
            }
        }
        const speedValidator = (control: AbstractControl): ValidationErrors | null => {
            const speeds = currentLimits.speeds![loc]
            if (speeds === undefined || !speeds.values.includes(control.value)) {
                if (speeds.values.length === 0 || speeds.default === 0) {
                    // no speed for this competition return validation passed
                    return null
                }
                return { speedNotAllowed: true }
            }
            return null
        }
        form.controls.speed.setValidators(speedValidator)
        form.controls.speed2.setValidators(speedValidator)
        form.controls.speed3.setValidators(speedValidator)
        const speeds = currentLimits.speeds![loc]
        if (speeds.values.length === 0 || speeds.default === 0) {
            this.speedOptions.unshift({ name: 'brak', value: 0, disabled: true })
            form.controls.speed.patchValue(0)
            form.controls.speed.disable()
            form.controls.speed2.patchValue(0)
            form.controls.speed2.disable()
            form.controls.speed3.patchValue(0)
            form.controls.speed3.disable()
        } else {
            if (form.controls.speed.value === 0) {
                form.controls.speed.patchValue(speeds.default)
            }
            form.controls.speed.enable()
            if (form.controls.overrideSpeed2.value) {
                if (form.controls.speed2.value === 0) {
                    form.controls.speed2.patchValue(speeds.default)
                }
                form.controls.speed2.enable()
            } else {
                form.controls.speed2.patchValue(form.controls.speed.value)
                form.controls.speed2.disable()
            }
            if (form.controls.overrideSpeed3.value) {
                if (form.controls.speed3.value === 0) {
                    form.controls.speed3.patchValue(speeds.default)
                }
                form.controls.speed3.enable()
            } else {
                if (form.controls.overrideSpeed2.value) {
                    form.controls.speed3.patchValue(form.controls.speed2.value)
                } else {
                    form.controls.speed3.patchValue(form.controls.speed.value)
                }
                form.controls.speed3.disable()
            }
        }
        form.controls.speed.updateValueAndValidity()
        form.controls.speed2.updateValueAndValidity()
        form.controls.speed3.updateValueAndValidity()

        if (currentLimits.width) {
            val = currentLimits.width.oxer
            c = form.controls.oxerWidth.value
            if (c === undefined || (val.max && c > val.max || val.min && c < val.min) && val.default) {
                form.controls.oxerWidth.patchValue(val.default)
            }
            form.controls.oxerWidth.setValidators([Validators.min(val.min!), Validators.max(val.max!), Validators.required])
            form.controls.oxerWidth.updateValueAndValidity()

            val = currentLimits.width.tripleBarre
            c = form.controls.tripleBarWidth.value
            if (c === undefined || (val.max && c > val.max || val.min && c < val.min) && val.default) {
                form.controls.tripleBarWidth.patchValue(val.default)
            }
            form.controls.tripleBarWidth.setValidators([Validators.min(val.min!), Validators.max(val.max!), Validators.required])
            form.controls.tripleBarWidth.updateValueAndValidity()

            val = currentLimits.width.ditch!
            c = form.controls.ditchWidth.value
            if (c === undefined || (val.max && c > val.max || val.min && c < val.min) && val.default) {
                form.controls.ditchWidth.patchValue(val.default)
            }
            form.controls.ditchWidth.setValidators([Validators.min(val.min!), Validators.max(val.max!), Validators.required])
            form.controls.ditchWidth.updateValueAndValidity()

            val = currentLimits.width.liverpool!
            c = form.controls.liverPoolWidth.value
            if (c === undefined || (val.max && c > val.max || val.min && c < val.min) && val.default) {
                form.controls.liverPoolWidth.patchValue(val.default)
            }
            form.controls.liverPoolWidth.setValidators([Validators.min(val.min!), Validators.max(val.max!), Validators.required])
            form.controls.liverPoolWidth.updateValueAndValidity()

            val = currentLimits.width.wall!
            c = form.controls.wallWidth.value
            if (c === undefined || (val.max && c > val.max || val.min && c < val.min) && val.default) {
                form.controls.wallWidth.patchValue(val.default)
            }
            form.controls.wallWidth.setValidators([Validators.min(val.min!), Validators.max(val.max!), Validators.required])
            form.controls.wallWidth.updateValueAndValidity()
        }

        const tl = currentLimits.timeLimits
        if (tl) {
            val = tl[loc]
            c = form.controls.timeLimit.value
            if (c === undefined || (val.max && c > val.max || val.min && c < val.min) && val.default) {
                form.controls.timeLimit.patchValue(val.default)
            }
            form.controls.timeLimit.setValidators([Validators.min(val.min!), Validators.max(val.max!)])
            form.controls.timeLimit.patchValue(
                this.view.canvas?.getAdjustedTimeLimit(form.controls.timeLimit.value, loc, currentLimits))
            if (val.max! > val.min!) {
                form.controls.timeLimit.enable()
            } else {
                form.controls.timeLimit.disable()
            }
            form.controls.timeLimit.updateValueAndValidity()
        } else {
            form.controls.timeLimit.setValidators(Validators.nullValidator)
            form.controls.timeLimit.patchValue(null)
            form.controls.timeLimit.disable()
            form.controls.timeLimit.updateValueAndValidity()
        }

        if (form.controls.overrideDistance1.value) {
            form.controls.distance1M.enable()
        } else {
            form.controls.distance1M.disable()
            const v = this.conversionService.convert(this.view.canvas?.obstaclePath.firstRound?.length || 0, {
                from: Unit.CM,
                to: unitSet.distance,
                rules: this.conversionService.rulesForCourseLength
            })
            form.controls.distance1M.patchValue(v)
        }
        if (form.controls.overrideDistance2.value) {
            form.controls.distance2M.enable()
        } else {
            form.controls.distance2M.disable()
            const v = this.conversionService.convert(this.view.canvas?.obstaclePath.secondRound?.length || 0, {
                from: Unit.CM,
                to: unitSet.distance,
                rules: this.conversionService.rulesForCourseLength
            })
        }
        if (form.controls.overrideDistance3.value) {
            form.controls.distance3M.enable()
        } else {
            form.controls.distance3M.disable()
            const v = this.conversionService.convert(this.view.canvas?.obstaclePath.thirdRound?.length || 0, {
                from: Unit.CM,
                to: unitSet.distance,
                rules: this.conversionService.rulesForCourseLength
            })
        }
        this.establishTimeLimitLimitsToDisplay()
    }

    establishTimeLimitLimitsToDisplay() {
        this.firstRoundTimeLimit = ''
        this.secondRoundTimeLimit = ''
        this.thirdRoundTimeLimit = ''
        this.timeLimitTooltip = undefined
        this.timeLimitLimits = ''

        const tl = this.view.cfg.formLimits.timeLimits
        const controls = this.view.cfg.form.controls
        if (tl) {
            const loc: CompetitionLocation =controls.compLoc.value
            const val = tl[loc]
            if (this.view.cfg.formLimits.table === CompetitionTable.TABLE_C) {
                this.timeLimitTooltip = $localize`Wartość zależna od długości trasy`
                this.firstRoundTimeLimit = '120 / 180 s'
                this.secondRoundTimeLimit = ''
                this.thirdRoundTimeLimit = ''
            } else if (val.max! === val.min!) {
                if (val.max! === 0) {
                    this.timeLimitTooltip = $localize`Brak limitu czasu`
                    this.firstRoundTimeLimit = $localize`(brak)`
                } else {
                    this.timeLimitTooltip = $localize`Dopuszczalna jest tylko jedna wartość`
                }
            } else {
                this.timeLimitLimits = formatLimits(val, 's')
            }
        } else {
            this.timeLimitTooltip = $localize`Wartość wyliczana na podstawie tempa i długości trasy`
            if (this.view.canvas?.obstaclePath.firstRound) {
                let dist = this.conversionService.convert(controls.distance1M.value, {
                    from: this.view.cfg.params.distanceUnit,
                    to: Unit.M,
                    rules: this.conversionService.fullPrecisionNoRounding
                })
                let s = this.getTimeLimitForm(1, controls.overrideDistance1.value ?
                    dist : undefined).toFixed(0) + ' s'
                this.firstRoundTimeLimit = s

                if (this.view.canvas.obstaclePath.secondRound) {
                    dist = this.conversionService.convert(controls.distance2M.value, {
                        from: this.view.cfg.params.distanceUnit,
                        to: Unit.M,
                        rules: this.conversionService.fullPrecisionNoRounding
                    })
                    this.secondRoundTimeLimit = this.getTimeLimitForm(2, controls.overrideDistance2.value ?
                        dist : undefined).toFixed(0) + ' s'
                }

                if (this.view.canvas.obstaclePath.thirdRound) {
                    dist = this.conversionService.convert(controls.distance3M.value, {
                        from: this.view.cfg.params.distanceUnit,
                        to: Unit.M,
                        rules: this.conversionService.fullPrecisionNoRounding
                    })
                    this.thirdRoundTimeLimit = this.getTimeLimitForm(3, controls.overrideDistance3.value ?
                        dist : undefined).toFixed(0) + ' s'
                }
            }
        }
        
        if (!controls.overrideSpeed2.value) {
            controls.speed2.patchValue(controls.speed.value)
            controls.speed2.updateValueAndValidity()
        }
        if (!controls.overrideSpeed3.value) {
            controls.speed3.patchValue(controls.speed.value)
            controls.speed3.updateValueAndValidity()
        }
    }

    getTimeLimitForm(roundNo: number, override?: number): number {
        if (!this.view.canvas || this.view.canvas.obstaclePath.sections.length < roundNo) {
            return 0
        }
        const form = this.view.cfg.form
        const section = this.view.canvas.obstaclePath.sections[roundNo - 1]
        const distance = override || (this.conversionService.isImperial(this.view.cfg.params.distanceUnit) ? section.length / 100 : section.roundedLengthTo5M)
        let tl = this.view.canvas.getAdjustedTimeLimit(form.controls.timeLimit.value || 0,
            form.controls.compLoc.value, this.view.cfg.formLimits)
        if (tl) {
            return tl
        }
        let speed
        if (roundNo === 2 && form.controls.overrideSpeed2.value) {
            speed = form.controls.speed2.value
        } else if (roundNo === 3 && form.controls.overrideSpeed3.value) {
            speed = form.controls.speed3.value
        } else {
            speed = form.controls.speed.value
        }
        if (speed) {
            tl = Math.ceil(distance / speed * 60)
        } else {
            tl = 0
        }
        return tl
    }

    getHeightLimitsForm(classNo?: ClassNo): string {
        if (!classNo || !this.view.cfg.formLimits.heights || !this.view.cfg.formLimits.heights[classNo]) {
            return formatLimitsNew(this.view.cfg.formLimits.height, this.conversionService, this.view.cfg.params.sizeUnit)
        }
        return formatLimitsNew(this.view.cfg.formLimits.heights[classNo], this.conversionService, this.view.cfg.params.sizeUnit)
    }

    getSortedClassesForm(): ClassNo[] {
        const classes: ClassNo[] | undefined = this.view.cfg.form.controls.classes?.value
        if (classes) {
            return this.classNoOptions.filter(c => classes.includes(c.value)).map(c => c.value)
        }
        return []
    }

    getLengthLimitsForm(): string {
        return formatLimitsNew(this.view.cfg.formLimits.length, this.conversionService, this.view.cfg.params.sizeUnit)
    }

    getTimeLimitLimitsRangeForm(): LimitRange {
        const loc: CompetitionLocation = this.view.cfg.form.controls.compLoc.value
        const limits = this.view.cfg.formLimits.timeLimits
        if (limits && loc) {
            return limits[loc]
        }
        return { min: 0, max: 0 }
    }

    getOxerWidthLimitsForm(): string {
        return formatLimitsNew(this.view.cfg.formLimits.width?.oxer, this.conversionService, this.view.cfg.params.sizeUnit)
    }

    getTripleBarreWidthLimitsForm(): string {
        return formatLimitsNew(this.view.cfg.formLimits.width?.tripleBarre, this.conversionService, this.view.cfg.params.sizeUnit)
    }

    getDitchWidthLimitsForm(): string {
        return formatLimitsNew(this.view.cfg.formLimits.width?.ditch, this.conversionService, this.view.cfg.params.sizeUnit)
    }

    getLiverPoolWidthLimitsForm(): string {
        return formatLimitsNew(this.view.cfg.formLimits.width?.liverpool, this.conversionService, this.view.cfg.params.sizeUnit)
    }

    getWallWidthLimitsForm(): string {
        return formatLimitsNew(this.view.cfg.formLimits.width?.wall, this.conversionService, this.view.cfg.params.sizeUnit)
    }

    getMinCombDistanceLimitsForm(): string {
        return formatLimitsNew({
            ...this.view.cfg.formLimits.minCombDistance,
            max: this.view.cfg.form.controls.compMaxDist.value,
        }, this.conversionService, this.view.cfg.params.distanceUnit,
        this.conversionService.rulesForDistanceParams)
    }

    getMaxCombDistanceLimitsForm(): string {
        return formatLimitsNew({
            ...this.view.cfg.formLimits.maxCombDistance,
            min: this.view.cfg.form.controls.compMinDist.value,
        }, this.conversionService, this.view.cfg.params.distanceUnit,
        this.conversionService.rulesForDistanceParams)
    }

    getJumpBeforeLenForm(): string {
        return formatLimitsNew(this.view.cfg.formLimits.jumpBeforeLen, this.conversionService, this.view.cfg.params.distanceUnit,
            this.conversionService.rulesForDistanceParams)
    }

    getLandAfterLenForm(): string {
        return formatLimitsNew(this.view.cfg.formLimits.landAfterLen, this.conversionService, this.view.cfg.params.distanceUnit,
            this.conversionService.rulesForDistanceParams)
    }

    setBaseArticleAndTooltipForm() {
        this.articleTooltip = this.limitsService.setBaseArticleAndTooltipForm(this.view.cfg, this.articleInParamsEl, this.view.cfg.unitSet)
    }

    getStrideMinLenForm(): string {
        return formatLimitsNew({
            ...this.view.cfg.formLimits.strideMinLen,
            max: this.view.cfg.form.controls.strideMaxLenM.value,
        }, this.conversionService, this.view.cfg.params.distanceUnit,
        this.conversionService.rulesForDistanceParams)
    }

    getStrideMaxLenForm(): string {
        return formatLimitsNew({
            ...this.view.cfg.formLimits.strideMaxLen,
            min: this.view.cfg.form.controls.strideMinLenM.value,
        }, this.conversionService, this.view.cfg.params.distanceUnit,
        this.conversionService.rulesForDistanceParams)
    }

    hideArticleTooltip() {
        this.articleInParamsEl?.nativeElement.dispatchEvent(new Event('mouseleave'));
        this.articleTooltip = ''
    }

    paramsFormSizesInvalid(item?: MenuItem): boolean {
        const c = this.view.cfg.form.controls
        return (!item || item === this.paramsTabMenuItems[1]) && this.view.cfg.form.invalid && (
            c.obstacleHeight.invalid ||
            c.obstacleLength.invalid ||
            c.speed.invalid ||
            c.oxerWidth.invalid ||
            c.tripleBarWidth.invalid ||
            c.ditchWidth.invalid ||
            c.liverPoolWidth.invalid ||
            c.wallWidth.invalid ||
            c.compMinDist.invalid ||
            c.compMaxDist.invalid ||
            c.strideMinLenM.invalid ||
            c.strideMaxLenM.invalid ||
            c.jumpBeforeLenM.invalid ||
            c.landAfterLenM.invalid
        )
    }

    resetParamsTabMenu() {
        this.paramsActiveItem = this.paramsTabMenuItems[0]
        this.paramsTabMenuEl?.itemClick(new Event('click'), this.paramsActiveItem)
        this.changeDetectorRef.detectChanges()
    }

    onCloseDialog() {
    }

    onShowDialog() {
        this.view.cfg.setValuesToForm()
        this.initialize()
        this.resetParamsTabMenu()
    }

    onHideDialog() {
        this.resetParamsTabMenu()
        this.hideArticleTooltip()
    }

    onOkDialog() {
        this.visibility = false
        this.onOk.emit()
    }

    onCancelDialog() {
        this.visibility = false
        this.onCancel.emit()
    }

    open() {
        this.visibility = true
    }

    closeOk() {
        this.onOkDialog()
    }

    closeCancel() {
        this.onCancelDialog()
    }

    formValueToClassNo(c: any): ClassNo {
        return c as ClassNo
    }

    isObstacleHeightDirty(c: ClassNo): boolean {
        const f = this.view.cfg.form.controls.obstacleHeights
        if (f instanceof FormGroup && f.controls[c]) {
            return f.controls[c].invalid
        }
        return false
    }

    stepInUnits(unit: string, stepMetric: number, stepFeet: number): number {
        if (this.conversionService.isImperial(unit)) {
            return stepFeet
        }
        return stepMetric
    }

    loadDefaults() {
        const form = this.view.cfg.form
        const currentLimits = this.view.cfg.formLimits

        let val = currentLimits.length
        if (val !== undefined) {
            form.controls.obstacleLength.patchValue(val.default)
        }
        if (currentLimits.width) {
            val = currentLimits.width.oxer
            if (val !== undefined) {
                form.controls.oxerWidth.patchValue(val.default)
            }
            val = currentLimits.width.tripleBarre
            if (val !== undefined) {
                form.controls.tripleBarWidth.patchValue(val.default)
            }
            val = currentLimits.width.ditch
            if (val !== undefined) {
                form.controls.ditchWidth.patchValue(val.default)
            }
            val = currentLimits.width.liverpool
            if (val !== undefined) {
                form.controls.liverPoolWidth.patchValue(val.default)
            }
            val = currentLimits.width.wall
            if (val !== undefined) {
                form.controls.wallWidth.patchValue(val.default)
            }
        }
        val = currentLimits.minCombDistance
        if (val !== undefined) {
            form.controls.compMinDist.patchValue(val.default)
        }
        val = currentLimits.maxCombDistance
        if (val !== undefined) {
            form.controls.compMaxDist.patchValue(val.default)
        }
        val = currentLimits.jumpBeforeLen
        if (val !== undefined) {
            form.controls.jumpBeforeLenM.patchValue(val.default)
        }
        val = currentLimits.landAfterLen
        if (val !== undefined) {
            form.controls.landAfterLenM.patchValue(val.default)
        }
        val = currentLimits.strideMinLen
        if (val !== undefined) {
            form.controls.strideMinLenM.patchValue(val.default)
        }
        val = currentLimits.strideMaxLen
        if (val !== undefined) {
            form.controls.strideMaxLenM.patchValue(val.default)
        }
        this.initialize()
    }
}
