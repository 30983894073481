import { inject } from '@angular/core'
import { CanActivateFn } from '@angular/router'
import { Router } from '@angular/router'

import { AuthService } from './services/auth.service'
import { UserSubscriptionService } from './services/user-subscription.service'

export const authGuard: CanActivateFn = (route, state) => {
    const auth: AuthService = inject(AuthService)
    const router: Router = inject(Router)
    const userSubscription: UserSubscriptionService = inject(UserSubscriptionService)

    if (!auth.isLogged()) {
        return router.parseUrl('/')
    }

    if (userSubscription.planState == 'trial-end' && state.url != '/user-plan') {
        return router.parseUrl('/user-plan')
    }

    return true
}

export const subscriptionGuard: CanActivateFn = (route, state) => {
    const auth: AuthService = inject(AuthService)
    const router: Router = inject(Router)
    const userSubscription: UserSubscriptionService = inject(UserSubscriptionService)

    if (!auth.isLogged()) {
        return true
    }

    if (userSubscription.planState == 'trial-end' && state.url != '/user-plan') {
        return router.parseUrl('/user-plan')
    }

    return true
}
