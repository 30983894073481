import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { User } from '@angular/fire/auth'
import { Firestore, doc, onSnapshot, Unsubscribe } from '@angular/fire/firestore'
import { initializePaddle, InitializePaddleOptions, Paddle, PaddleEventData } from '@paddle/paddle-js';
import { BehaviorSubject, Observable, Subject, distinctUntilChanged, filter, defer, from } from 'rxjs'

import { SubscriptionLevel } from '../design.schema';
import { isProduction, callGoogleFunc } from '../utils';

@Injectable({
    providedIn: 'root'
})
export class PaddleService {
    user?: User
    paddle?: Paddle

    private _products: any = { advanced: null, masters: null }
    private _subscriptionPlan = new BehaviorSubject<any>(undefined)
    private _checkoutStatus = new Subject<string>()
    private _newPlan = ''
    private _newPlanPeriod = ''
    private _customerPortalURL = ''
    private _customerId = undefined

    fsSnapshotUnsubcribeFunc: Unsubscribe | null = null

    constructor(
        private firestore: Firestore,
        private httpClient: HttpClient,
    ) {
        let initPaddleData: InitializePaddleOptions = { environment: 'sandbox', token: 'test_578f46fbda6920c1bb3b91ea60b' }
        this._products = {
            advanced: {
                yearly: 'pri_01j8srygjgm6cbp0tvq2c5b95v', // advanced yearly
                monthly: 'pri_01j8srxkx83ghzs43351qycmtf', // advanced monthly
            },
            masters: {
                yearly: 'pri_01j8srs4jbnvfk8sysckhqcf35', // masters yearly
                monthly: 'pri_01j8srjxy7ghmdptqy06t96zb5', // masters monthly
            }
        }
        this._customerPortalURL = 'https://sandbox-customer-portal.paddle.com/cpl_01j8sr8qv06d5750e85y54s8ra'

        if (isProduction()) {
            initPaddleData = { environment: 'production', token: 'live_345372d5190b93be8c4a3f76442' }
            this._products = {
                advanced: {
                    yearly: 'pri_01jc8bwvkfxtaagf0ddj6zmq2d', // advanced yearly
                    monthly: 'pri_01jc8c19pjrx1wf92mv6xkpcqx', // advanced monthly
                },
                masters: {
                    yearly: 'pri_01jcara7bc9b7w38skwrw5me0r', // masters yearly
                    monthly: 'pri_01jcarpg23ar8d551tytd6a8dm', // masters monthly
                }
            }
            this._customerPortalURL = 'https://customer-portal.paddle.com/cpl_01j8swa2wttjhcgepgxk03dbkg'
        }
        initPaddleData.eventCallback = (event) => this.handlePaddleEvent(event)

        initializePaddle(initPaddleData).then((paddleInstance: Paddle | undefined) => {
            if (!paddleInstance) {
                return
            }
            this.paddle = paddleInstance
        }).catch((e) => console.error('error', e.message))
    }

    handlePaddleEvent(event: PaddleEventData) {
        console.info('paddle event', event)
        if (event.name === 'checkout.completed') {
            this._checkoutStatus.next('completed')

            const plan = { name: this._newPlan, interval: this._newPlanPeriod }
            this._subscriptionPlan.next(plan)

            this.paddle?.Checkout.close()
        } else if (event.name === 'checkout.closed') {
            this._checkoutStatus.next('completed')
        }
    }

    private _getUserSubsriptionPlan() {
        const subsRef = doc(this.firestore, `paddle-customers/${this.user!.uid}`)
        this.fsSnapshotUnsubcribeFunc = onSnapshot(subsRef, (snap) => {
            // this is invoked every time the record changes on server-side
            const subs = snap.data();
            console.info('paddle SUBS', subs)
            let plan = { name: SubscriptionLevel.STARTER, canceled: false }
            if (subs) {
                if (subs.subscription_status == "active") {
                    plan = subs.plan
                } else {
                    plan.canceled = true
                }
                if (subs.customer_id) {
                    this._customerId = subs.customer_id
                    this.paddle?.Update({
                        pwCustomer: {
                            id: this._customerId
                        }
                    })
                }
            }
            this._subscriptionPlan.next(plan)
        });
    }

    init(user: User) {
        this.user = user
        this._getUserSubsriptionPlan()
    }

    finit() {
        if (!this.user) {
            return
        }
        this._customerId = undefined
        this.user = undefined
        this._subscriptionPlan.next({ name: SubscriptionLevel.STARTER })
        if (this.fsSnapshotUnsubcribeFunc) {
            this.fsSnapshotUnsubcribeFunc()
            this.fsSnapshotUnsubcribeFunc = null
        }
    }

    getSubscriptionPlan() {
        return this._subscriptionPlan.pipe(
            filter((plan: any) => plan !== undefined),
            distinctUntilChanged((prev, curr) => (prev === curr || (prev?.name === curr?.name && prev?.interval === curr?.interval)))
        )
    }

    subscribeToPlan(plan: string, interval: string): Observable<string> {
        this._newPlan = plan
        this._newPlanPeriod = interval
        this.paddle?.Checkout.open({
            items: [{ priceId: this._products[plan][interval], quantity: 1 }],
            customer: {
                email: this.user?.email || '',
            },
            settings: {
                displayMode: "overlay",
                variant: "one-page",
            },
            customData: {
                email: this.user?.email || '',
                userId: this.user!.uid,
            }
        });
        return this._checkoutStatus
    }

    async teleportToCustomerPortal() {
        if (!this._customerId) {
            window.open(this._customerPortalURL, '_blank')?.focus()
            return
        }
        const f = callGoogleFunc('paddle_create_portal_session')
        defer(() => from(f({ customer_id: this._customerId }))).subscribe({
            next: (response) => {
                const data: any = response.data
                if (data.url) {
                    window.open(data.url, '_blank')?.focus()
                }
            },
            error: (error: Error) => {
                console.error('Error accessing customer portal:', error)
            }
        })
    }
}
