import { Injectable } from '@angular/core';
import { Unit, UnitParams, UnitPipe, UnitRulesSet } from '../pipes'
import { LimitRange } from '../design.schema'
import { ClassNo, LimitSetCombined } from './limits.service'

export type UnitSet = {
    distance: string,
    size: string
}

@Injectable({
    providedIn: 'root'
})
export class ConversionService {
    private _pipe: UnitPipe = new UnitPipe()

    public readonly fullPrecisionNoRounding: UnitRulesSet = {
        [Unit.M]: { rounding: undefined, precision: undefined },
        [Unit.CM]: { rounding: undefined, precision: undefined },
        [Unit.FT]: { rounding: undefined, precision: undefined }
    }

    public readonly integersOnly: UnitRulesSet = {
        [Unit.M]: { rounding: 1, precision: 0 },
        [Unit.CM]: { rounding: 1, precision: 0 },
        [Unit.FT]: { rounding: 1, precision: 0 }
    }

    public readonly roundToHundreds: UnitRulesSet = {
        [Unit.M]: { rounding: 0.01, precision: 2 },
        [Unit.CM]: { rounding: 0.01, precision: 2 },
        [Unit.FT]: { rounding: 0.01, precision: 2 }
    }

    public readonly rulesForCourseLength: UnitRulesSet = {
        [Unit.M]: { rounding: 5, precision: 0 },
        [Unit.CM]: { rounding: 1, precision: 0 },
        [Unit.FT]: { rounding: 0.01, precision: 2 }
    }

    public readonly rulesForArenaPosition: UnitRulesSet = {
        [Unit.M]: { rounding: 1, precision: 0 },
        [Unit.CM]: { rounding: 1, precision: 0 },
        [Unit.FT]: { rounding: 1, precision: 0 }
    }
    
    public readonly rulesForDistance: UnitRulesSet = {
        [Unit.M]: { rounding: 1, precision: 0 },
        [Unit.CM]: { rounding: 1, precision: 0 },
        [Unit.FT]: { rounding: 0.01, precision: 2 }
    }
    
    public readonly rulesForDistanceParams: UnitRulesSet = {
        [Unit.M]: { rounding: 0.1, precision: 1 },
        [Unit.CM]: { rounding: 1, precision: 0 },
        [Unit.FT]: { rounding: 0.01, precision: 2 }
    }

    public readonly rulesForDistanceNarrow: UnitRulesSet = {
        [Unit.M]: { rounding: 1, precision: 0 },
        [Unit.CM]: { rounding: 1, precision: 0 },
        [Unit.FT]: { rounding: 1, precision: 0 }
    }

    public readonly rulesForDistanceOnPath: UnitRulesSet = {
        [Unit.M]: { rounding: 0.1, precision: 1 },
        [Unit.CM]: { rounding: 1, precision: 0 },
        [Unit.FT]: { rounding: 0.01, precision: 2 }
    }

    public readonly rulesForLayoutLines: UnitRulesSet = {
        [Unit.M]: { rounding: 0.01, precision: 2 },
        [Unit.CM]: { rounding: 1, precision: 0 },
        [Unit.FT]: { rounding: 0.01, precision: 2 }
    }

    public readonly rulesForCombinationDistance: UnitRulesSet = {
        [Unit.M]: { rounding: 0.1, precision: 1 },
        [Unit.CM]: { rounding: 10, precision: 0 },
        [Unit.FT]: { rounding: 0.01, precision: 2 }
    }

    public readonly rulesForObstacleHeight: UnitRulesSet = {
        [Unit.M]: { rounding: 0.01, precision: 2 },
        [Unit.CM]: { rounding: 1, precision: 0 },
        [Unit.FT]: { rounding: 0.01, precision: 2 }
    }

    public readonly rulesForObstacleWidth: UnitRulesSet = {
        [Unit.M]: { rounding: 0.01, precision: 2 },
        [Unit.CM]: { rounding: 1, precision: 0 },
        [Unit.FT]: { rounding: 0.01, precision: 2 }
    }

    public readonly rulesForObstacleLength: UnitRulesSet = {
        [Unit.M]: { rounding: 0.01, precision: 2 },
        [Unit.CM]: { rounding: 1, precision: 0 },
        [Unit.FT]: { rounding: 0.01, precision: 2 }
    }

    constructor() {
    }

    public transform(value: string | number, params: Partial<UnitParams>): string | undefined {
        return this._pipe.transform(value, params)
    }

    public convert(value: string | number, params: Partial<UnitParams>): number | undefined {
        const rules = {
            [Unit.M]: {
                rounding: 0.01,
                precision: 2,
                ...params.rules?.m,
            },
            [Unit.CM]: {
                rounding: 1,
                precision: 0,
                ...params.rules?.cm,
            },
            [Unit.FT]: {
                rounding: 0.01,
                precision: 2,
                ...params.rules?.ft,
            }
        }
        return this._pipe.convert(value, {
            rules,
            ...params
        })
    }

    public getUnit(unit: string): string {
        return UnitPipe.getUnit(unit)
    }

    public getUnitSuffix(to: string): string {
        return UnitPipe.getUnitSuffix(to)
    }

    public isImperial(unit: string) {
        const sections = unit.split('-')
        if (sections.length > 0) {
            return (sections[0] === Unit.FT)
        }
        return unit === Unit.FT
    }

    public convertLimits(limits: LimitSetCombined, toUnit: UnitSet): LimitSetCombined {
        const n = structuredClone(limits)
        if (n.height) {
            n.height = this._convertRange(n.height, Unit.CM, toUnit.size)
        }
        if (n.width) {
            if (n.width.liverpool) {
                n.width.liverpool = this._convertRange(n.width.liverpool, Unit.CM, toUnit.size)
            }
            if (n.width.ditch) {
                n.width.ditch = this._convertRange(n.width.ditch, Unit.CM, toUnit.size)
            }
            n.width.oxer = this._convertRange(n.width.oxer, Unit.CM, toUnit.size)
            n.width.tripleBarre = this._convertRange(n.width.tripleBarre, Unit.CM, toUnit.size)
            if (n.width.wall) {
                n.width.wall = this._convertRange(n.width.wall, Unit.CM, toUnit.size)
            }
            if (n.width.vertical) {
                n.width.vertical = this._convertRange(n.width.vertical, Unit.CM,toUnit.size)
            }
        }
        if (n.length) {
            n.length = this._convertRange(n.length, Unit.CM, toUnit.size)
        }
        if (n.distance) {
            n.distance = this._convertRange(n.distance, Unit.CM, toUnit.distance)
        }
        if (n.twoVerticalsHeightLimit) {
            n.twoVerticalsHeightLimit = this._convertNumber(n.twoVerticalsHeightLimit, Unit.CM, toUnit.size)
        }
        if (n.sixOtherHeightLimit) {
            n.sixOtherHeightLimit = this._convertNumber(n.sixOtherHeightLimit, Unit.CM, toUnit.size)
        }
        if (n.twoSpreadHeightLimit) {
            n.twoSpreadHeightLimit = this._convertNumber(n.twoSpreadHeightLimit, Unit.CM, toUnit.size)
        }
        if (n.twoSpreadWidthLimit) {
            n.twoSpreadWidthLimit = this._convertNumber(n.twoSpreadWidthLimit, Unit.CM, toUnit.size)
        }
        if (n.heights) {
            for (let [k, v] of Object.entries(n.heights)) {
                n.heights[k as ClassNo] = this._convertRange(v, Unit.CM, toUnit.size)
            }
        }
        if (n.minCombDistance) {
            n.minCombDistance = this._convertRange(n.minCombDistance, Unit.M, toUnit.distance)
        }
        if (n.maxCombDistance) {
            n.maxCombDistance = this._convertRange(n.maxCombDistance, Unit.M, toUnit.distance)
        }
        if (n.jumpBeforeLen) {
            n.jumpBeforeLen = this._convertRange(n.jumpBeforeLen, Unit.M, toUnit.distance)
        }
        if (n.landAfterLen) {
            n.landAfterLen = this._convertRange(n.landAfterLen, Unit.M, toUnit.distance)
        }
        if (n.strideMinLen) {
            n.strideMinLen = this._convertRange(n.strideMinLen, Unit.M, toUnit.distance)
        }
        if (n.strideMaxLen) {
            n.strideMaxLen = this._convertRange(n.strideMaxLen, Unit.M, toUnit.distance)
        }
        return n
    }

    private _convertRange(range: LimitRange, from: Unit, toUnit: string): LimitRange {
        return {
            min: this._convertNumber(range.min, from, toUnit),
            max: this._convertNumber(range.max, from, toUnit),
            default: this._convertNumber(range.default, from, toUnit), 
            banned: range.banned
        } as LimitRange
    }

    private _convertNumber(v: number | undefined, from: Unit, toUnit: string): number | undefined {
        if (v === undefined) {
            return undefined
        }
        return this.convert(v, {
            from: from,
            to: toUnit,
            rules: this.fullPrecisionNoRounding
        })
    }
}
