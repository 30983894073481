import { DesignSchema, DesignTextBoxObject } from "../design.schema"
import { MaterialManager, ParkourMaterial } from "./parkour-renderer.materials"
import { RenderedObject } from "./parkour-renderer.object"
import * as B from '@babylonjs/core'

export class RenderedJury extends RenderedObject {

    private _createWindow(
        material: ParkourMaterial,
        name: string, width: number, height: number, position: B.Vector3) 
    {
        const window = B.MeshBuilder.CreatePlane(name, { width, height }, this.scene)
        window.material = material
        window.position = position
        return window
    }

    constructor(
        protected scene: B.Scene, 
        protected materialManager: MaterialManager,
        protected object: DesignTextBoxObject, 
        protected design: DesignSchema, 
        protected shadowGenerator: B.ShadowGenerator)
    {
        super (scene, object, design)

        const boxHeight = 3.5
        const roofExcess = 0.3, roofThickness = 0.2
        const windowMargin = 0.25, windowHeightRatio = 0.5

        let boxWidth = 5, boxDepth = 2
        if (object.boxSize && object.boxSize.length >= 2) {
            boxWidth = (object.boxSize[0] / 100) || 5
            boxDepth = (object.boxSize[1] / 100) || 5
        }

        const doorMaterial = materialManager.getMaterial('jury-walls', (name: string) => {
            const m = new B.StandardMaterial(name, scene)
            m.diffuseColor = new B.Color3(0.3, 0.3, 0.3)    
            return m
        })

        const juryBox = B.MeshBuilder.CreateBox('juryBox', {
            width: boxWidth,
            height: boxHeight,
            depth: boxDepth,
        }, scene)
        juryBox.position.y = boxHeight / 2
        juryBox.material = doorMaterial
        juryBox.checkCollisions = true
        shadowGenerator.addShadowCaster(juryBox)
        
        const roof = B.MeshBuilder.CreateBox('roof', {
            width: boxWidth + roofExcess,
            height: roofThickness,
            depth: boxDepth + roofExcess,
        }, scene)
        roof.position.y = boxHeight + roofThickness / 2
        roof.material = doorMaterial
        roof.checkCollisions = true
        shadowGenerator.addShadowCaster(roof)

        const texture = materialManager.getTexture('jury-sky-reflection', () => {
            return new B.CubeTexture('assets/textures/bluecloud', scene)
        })

        const windowMaterial = materialManager.getMaterial('jury-window', (name: string) => {
            const m = new B.StandardMaterial(name, scene)
            m.alpha = 0.5
            m.diffuseColor = new B.Color3(0.8, 0.9, 1)
            m.indexOfRefraction = 1
            m.refractionTexture = texture
            m.refractionTexture.level = 0.5
            m.specularPower = 128
            m.roughness = 0.1
            return m
        })
        
        const windowHeight = boxHeight * windowHeightRatio
        const windowY = (boxHeight - windowMargin) - windowHeight / 2
        const frontWindow = this._createWindow(windowMaterial, 'frontWindow', 
            boxWidth - windowMargin * 2, windowHeight, new B.Vector3(0, windowY, -boxDepth / 2 - 0.01))
        const backWindow = this._createWindow(windowMaterial, 'backWindow',
            boxWidth - windowMargin * 2, windowHeight, new B.Vector3(0, windowY, boxDepth / 2 + 0.01))
        backWindow.rotation.y = Math.PI
        const leftWindow = this._createWindow(windowMaterial, 'leftWindow',
            boxDepth - windowMargin * 2, windowHeight, new B.Vector3(-boxWidth / 2 - 0.01, windowY, 0))
        leftWindow.rotation.y = Math.PI / 2
        const rightWindow = this._createWindow(windowMaterial, 'rightWindow',
            boxDepth - windowMargin * 2, windowHeight, new B.Vector3(boxWidth / 2 + 0.01, windowY, 0))
        rightWindow.rotation.y = -Math.PI / 2

        const juryBoxNode = new B.TransformNode('juryBoxGroup', scene)
        juryBox.parent = juryBoxNode
        roof.parent = juryBoxNode
        frontWindow.parent = juryBoxNode
        leftWindow.parent = juryBoxNode
        rightWindow.parent = juryBoxNode
        backWindow.parent = juryBoxNode
        juryBoxNode.position = new B.Vector3(this.x, 0, this.y)
        juryBoxNode.rotate(new B.Vector3(0, 1, 0), this.angle)
    }
}
