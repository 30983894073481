import { DesignBaseObject, DesignSchema, DesignSvgImageObject, DesignTextBoxObject } from "../design.schema"
import { MaterialManager, ParkourMaterial } from "./parkour-renderer.materials"
import { RenderedObject } from "./parkour-renderer.object"
import * as B from '@babylonjs/core'

export class RenderedTent extends RenderedObject {

    constructor(
        protected scene: B.Scene, 
        protected materialManager: MaterialManager,
        object: DesignBaseObject, 
        design: DesignSchema, 
        protected shadowGenerator: B.ShadowGenerator)
    {
        super (scene, object, design)

        const img = object as DesignSvgImageObject
        let baseRadius
        if (img.imageSize) {
            baseRadius = Math.max(img.imageSize[0], img.imageSize[1])
        } else {
            baseRadius = 550
        }
        if (baseRadius < 100) {
            baseRadius = 100
        }
        baseRadius = baseRadius * 2.5 / 550
        const ratio = baseRadius / 2.5
        const wallHeight = 2 * ratio
        const roofHeight = 1 * ratio
        const sides = 8
        const angleStep = (2 * Math.PI) / sides
        const basePoints = []
        for (let i = 0; i < sides; i++) {
            let angle = i * angleStep;
            basePoints.push(new B.Vector3(baseRadius * Math.cos(angle), 0, baseRadius * Math.sin(angle)))
        }

        const wallMeshes = []
        for (let i = 0; i < sides; i++) {
            const nextIndex = (i + 1) % sides
            const positions = [
                basePoints[i], new B.Vector3(basePoints[i].x, -wallHeight, basePoints[i].z),
                basePoints[nextIndex], new B.Vector3(basePoints[nextIndex].x, -wallHeight, basePoints[nextIndex].z)
            ]
            const indices = [0, 1, 2, 1, 3, 2]
            const wallMesh = new B.Mesh('tent-wall' + i, scene);
            const vertexData = new B.VertexData()
            vertexData.positions = positions.flatMap(v => [v.x, v.y, v.z])
            vertexData.indices = indices
            vertexData.applyToMesh(wallMesh)
            wallMeshes.push(wallMesh)
            wallMesh.checkCollisions = true
        }

        let roofMeshes = []
        const roofPeak = new B.Vector3(0, roofHeight, 0)
        for (let i = 0; i < sides; i++) {
            const nextIndex = (i + 1) % sides
            const positions = [
                basePoints[i],
                basePoints[nextIndex],
                roofPeak
            ]
            const indices = [0, 1, 2]

            const roofMesh = new B.Mesh('tent-roof' + i, scene)
            const vertexData = new B.VertexData()
            vertexData.positions = positions.flatMap(v => [v.x, v.y, v.z])
            vertexData.indices = indices
            vertexData.applyToMesh(roofMesh)
            roofMeshes.push(roofMesh)
        }

        const material = materialManager.getMaterial('tent-mat', (name: string) => {
            const m = new B.StandardMaterial(name, scene)
            m.diffuseColor = new B.Color3(0.8, 0.5, 0.2)
            m.specularColor = new B.Color3(0, 0, 0)
            m.emissiveColor = new B.Color3(0.1, 0.05, 0)
            return m
        })
        const tent = B.Mesh.MergeMeshes([...wallMeshes, ...roofMeshes], true)
        if (tent) {
            tent.material = material
            tent.rotation.y = this.angle + Math.PI / 8
            tent.position = new B.Vector3(this.x, wallHeight, this.y)
            this.shadowGenerator.addShadowCaster(tent)
        }
    }
}
