import { HostListener, Injectable } from '@angular/core'

import { User } from '@angular/fire/auth'
import { CollectionReference, DocumentData, Firestore, OrderByDirection, Query, addDoc, collection, doc, getDoc, getDocs, limit, orderBy, query, setDoc, updateDoc, where, startAfter, QuerySnapshot } from '@angular/fire/firestore'
import { Storage, StorageReference, getBlob, getDownloadURL, listAll, ref, uploadBytes } from '@angular/fire/storage'
import Dexie from 'dexie'
import { del as idbDel, entries as idbEntries, get as idbGet, set as idbSet } from 'idb-keyval'
import { MessageService } from 'primeng/api'
import { Observable, Subject, Subscription, debounceTime, defer, filter, from, map, of, switchMap, throwError, zip, forkJoin } from 'rxjs'

import { DesignSchema, DesignSchemaWithPreview, ShareMode } from '../design.schema'
import { showErrorBox, isProduction } from '../utils'
import { AuthService } from './auth.service'
import { UserProfile } from './user.service'


interface UserImage {
    id: string;
    date: Date;
    format: string;
    image: Uint8Array;
}

interface GlobalSettings {
    name: string,
    value: any
}

interface UserDeletedImage {
    id: string;
    date: Date;
    deleted: boolean
}

interface PngPreviewUrl {
    id: string;
    url: string;
}

class DexieDb extends Dexie {
    userImages!: Dexie.Table<UserImage, string>;
    userProfiles!: Dexie.Table<UserProfile, string>;
    userDeletedImages!: Dexie.Table<UserDeletedImage, string>;
    pngPreviewUrls!: Dexie.Table<PngPreviewUrl, string>;

    constructor(dbName: string) {
        super(dbName)

        this.version(1).stores({
            userImages: "++id,date,url",
        })
        this.version(2).stores({
            userProfiles: "uid",
        })
        this.version(3).stores({
            userDeletedImages: "id",
        })
        this.version(4).stores({
            pngPreviewUrls: "id",
        })
    }
}

class DexieDbGlobal extends Dexie {
    globalSettings!: Dexie.Table<GlobalSettings, string>

    constructor(dbName: string) {
        super(dbName)
        this.version(1).stores({
            globalSettings: "name",
        })
    }
}

type DesignQueue = Subject<[DesignSchema, string | undefined]>

@Injectable({
    providedIn: 'root'
})
export class StorageService {
    private subs: Subscription = new Subscription()

    private saveQueues: { [id: string]: DesignQueue } = {}

    designsCollection?: CollectionReference<DocumentData, DocumentData>
    userProfilesCollection?: CollectionReference<DocumentData, DocumentData>
    userDeletedImagesCollection?: CollectionReference<DocumentData, DocumentData>

    user: User | null = null

    dexieDb: DexieDb | null = null
    dexieDbGlobal: DexieDbGlobal

    constructor(
        private auth: AuthService,
        private firestore: Firestore,
        private storage: Storage,
        private msgSvc: MessageService,
    ) {
        this.designsCollection = collection(this.firestore, 'designs')
        this.userProfilesCollection = collection(this.firestore, 'userProfiles')
        this.dexieDbGlobal = new DexieDbGlobal('parkour-db-global')
        this.subs.add(
            this.auth.user.subscribe({
                next: (aUser: User | null) => {
                    this.user = aUser
                    if (!aUser) {
                        this.dexieDb = null
                        return
                    }
                    this.dexieDb = new DexieDb('parkour-db-' + aUser.uid)
                    this.userDeletedImagesCollection = collection(this.firestore, `userDeletedImages/${aUser.uid}/deletedImages`)
                },
                error: (err) => {
                    console.error('error occured', err)
                    showErrorBox(this.msgSvc, $localize`Pobieranie informacji o użytkowniku`, $localize`Wystąpił nieznany błąd`)
                }
            })
        )
    }

    allocateQueue(design: DesignSchema): DesignQueue | undefined {
        const id = design.remoteId
        if (!id) {
            return undefined
        }
        let saveQueue = this.saveQueues[id]
        if (saveQueue) {
            return saveQueue
        }
        saveQueue = this.saveQueues[id] = new Subject<[DesignSchema, string | undefined]>
        let subscription = saveQueue.pipe(debounceTime(1000)).subscribe({
            next: (d: [DesignSchema, string?]) => {
                const [design, pngPreview] = d
                this._updateDesignDelayed(design, pngPreview).subscribe({
                    next: (doc) => {
                        console.info('saveData2', id)
                        subscription.unsubscribe()
                        delete this.saveQueues[id]
                    },
                    error: (err) => {
                        console.error('error occured', err)
                        showErrorBox(this.msgSvc, $localize`Zapisywanie projektu`, $localize`Wystąpił nieznany błąd`)
                    }
                })
            },
            error: (err) => {
                console.error('error occured', err)
                showErrorBox(this.msgSvc, $localize`Zapisywanie projektu`, $localize`Wystąpił nieznany błąd`)
            }
        })
        return saveQueue
    }

    _saveUserImageLocaly(imageId: string, imageFormat: string, bytes: Uint8Array): Observable<any> {
        const p = this.dexieDb!.userImages.put({
            id: imageId,
            date: new Date(),
            format: imageFormat,
            image: bytes,
        })
        return defer(() => from(p))
    }

    saveUserImage(imageId: string, imageFormat: string,
        file: Blob | null,
        dataUrl: string | null,
        arrayBuf: ArrayBuffer | null): Observable<any> {

        const obsList = []
        if (dataUrl) {
            // legacy path
            const obs = new Subject<any>()
            fetch(dataUrl)
                .then(res => res.arrayBuffer())
                .then(buffer => {
                    this.saveUserImage(imageId, imageFormat, null, null, buffer).subscribe(obs)
                }).catch((error) => {
                    console.error('problem with reading data', error)
                })
            return obs
        }

        // save locally in indexeddb/dexie
        if (file) {
            const obs = new Subject<any>()
            obsList.push(obs)
            const reader = new FileReader()
            reader.onload = (ev: any) => {
                this._saveUserImageLocaly(imageId, imageFormat, new Uint8Array(ev.target.result)).subscribe(obs)
            }
            reader.onerror = (ev: any) => {
                console.error('ERR', ev)
            }
            reader.readAsArrayBuffer(file)
        } else if (arrayBuf) {
            const obs = this._saveUserImageLocaly(imageId, imageFormat, new Uint8Array(arrayBuf))
            obsList.push(obs)
        } else {
            console.error('incorrect args')
            return throwError(() => new Error('incorrect args'))
        }

        if (!this.user) {
            return throwError(() => new Error('no user, cannot save remotely'))
        }
        // save remotely in cloud storage
        const author = this.user.uid
        const path = `user-images/${author}/user-image-${imageId}.img`
        const imgRef = ref(this.storage, path)
        let data: Blob | ArrayBuffer
        let md: any
        if (file) {
            data = file
        } else {
            data = arrayBuf!
            md = {
                contentType: imageFormat
            }
        }
        const p = uploadBytes(imgRef, data, md).then(
            (resp) => getDownloadURL(imgRef).then((url) => url)
        ).catch((error) => {
            console.error('problem with getting PNG bytes', error)
        })
        const obs = defer(() => from(p))
        obsList.push(obs)

        return zip(obsList)
    }

    _bytesToBase64(bytes: Uint8Array): string {
        let binary = ''
        const len = bytes.byteLength
        for (var i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i])
        }
        return window.btoa(binary)
    }

    _bytesToDataUrl(bytes: Uint8Array, format: string): string {
        let dataUrl = 'data:' + format + ';base64, '
        dataUrl += this._bytesToBase64(bytes)
        return dataUrl
    }

    loadGeneralFile(fileName: string): Observable<string | undefined> {
        const obs = new Subject<string | undefined>()
        const path = `general/${fileName}`
        const fileRef = ref(this.storage, path)
        getBlob(fileRef).then(
            (blob) => {
                blob.text().then(
                    (txt) => {
                        obs.next(txt)
                    }
                ).catch((error) => {
                    console.error('problem with converting blob to text', error)
                    obs.next(undefined)
                })
            }
        ).catch((error) => {
            console.error('problem with getting general file', fileName, error)
            obs.next(undefined)
        })
        return obs
    }

    _getUserDeletedImages() {
        const deletedImagesObs = new Subject<UserDeletedImage[]>()

        const deletedImgsProm = this.dexieDb!.userDeletedImages.toArray()
        const remoteDeletedImgsProm = getDocs(this.userDeletedImagesCollection!)

        forkJoin({
            local: from(deletedImgsProm),
            remote: from(remoteDeletedImgsProm)
        }).subscribe({
            next: (resp) => {
                const allImgs: { [id: string]: UserDeletedImage } = {}
                const localImgs: { [id: string]: UserDeletedImage } = {}
                for (let i of resp.local) {
                    if (!i.deleted) {
                        continue
                    }
                    localImgs[i.id] = i
                    allImgs[i.id] = i
                }
                const remoteImgs: { [id: string]: UserDeletedImage } = {}
                resp.remote.forEach((doc) => {
                    const i: any = doc.data()
                    if (!i.deleted) {
                        // TODO add checking conflicts
                        return
                    }
                    remoteImgs[i.id] = i

                    if (!localImgs[i.id]) {
                        allImgs[i.id] = i
                        // save remote locally
                        this.dexieDb?.userDeletedImages.put(i).then(() => {
                        }).catch((error) => {
                            console.error('problem deleting user image 2', error)
                        })
                    }
                })

                // check if some locals should be saved remotely
                for (let i of resp.local) {
                    if (!remoteImgs[i.id]) {
                        const ref = doc(this.firestore, 'userDeletedImages', this.user!.uid, 'deletedImages', i.id)
                        setDoc(ref, i).then(() => {
                        }).catch((error) => {
                            console.error('problem deleting user image 3', error)
                        })
                    }
                }

                deletedImagesObs.next(Object.values(allImgs))
                deletedImagesObs.complete()
            },
            error: (err) => {
                console.error('unexpected error', err)
                deletedImagesObs.next([])
                deletedImagesObs.complete()
            }
        })

        return deletedImagesObs
    }

    listUserImages(): Subject<{}[]> {
        const imagesObs = new Subject<{}[]>()
        if (!this.user) {
            imagesObs.next([])
            imagesObs.complete()
            return imagesObs
        }

        const author = this.user.uid
        const listRef = ref(this.storage, `user-images/${author}`)
        const imgsProm = listAll(listRef)

        forkJoin({
            images: from(imgsProm),
            deletedImages: this._getUserDeletedImages()
        }).subscribe({
            next: (resp) => {
                const images: any[] = []
                let cnt = resp.images.items.length
                resp.images.items.forEach((itemRef) => {
                    const imgId = itemRef.name.split("-")[2].slice(0, -4)
                    for (let i of resp.deletedImages) {
                        if (imgId === i.id) {
                            // skip deleted images
                            cnt -= 1
                            return
                        }
                    }
                    getDownloadURL(itemRef).then((url) => {
                        const imgId2 = itemRef.name.split("-")[2].slice(0, -4)
                        images.push({ id: imgId2, url: url })
                        cnt -= 1
                        if (cnt === 0) {
                            imagesObs.next(images)
                            imagesObs.complete()
                        }
                    }).catch((error) => {
                        console.error('unexpected error', error)
                    })
                });
            },
            error: (err) => {
                console.error('unexpected error', err)
                imagesObs.next([])
                imagesObs.complete()
            }
        })
        return imagesObs
    }

    loadUserImage(imageId: string, origAuthor: string | null): Observable<string | undefined> {
        const imageObs = new Subject<string | undefined>()
        this.dexieDb?.userImages.get({ id: imageId }).then((image) => {
            if (image) {
                const dataUrl = this._bytesToDataUrl(image.image, image.format)
                imageObs.next(dataUrl)
            } else {
                if (!this.user) {
                    imageObs.next(undefined)
                    return
                }
                const author = origAuthor ? origAuthor : this.user.uid
                const path = `user-images/${author}/user-image-${imageId}.img`
                const imgRef = ref(this.storage, path)
                getBlob(imgRef).then(
                    (blob) => {
                        blob.arrayBuffer().then(
                            (arrayBuf) => {
                                const bytes = new Uint8Array(arrayBuf)
                                const dataUrl = this._bytesToDataUrl(bytes, blob.type)
                                imageObs.next(dataUrl)
                                this._saveUserImageLocaly(imageId, blob.type, bytes)
                            }
                        ).catch((error) => {
                            console.error('problem with getting converting blob', error)
                            imageObs.next(undefined)
                        })
                    }
                ).catch((error) => {
                    console.error('problem with getting PNG bytes', error)
                    imageObs.next(undefined)
                })
            }
        }).catch((err) => {
            console.error('problem with loading user image locally', err)
            imageObs.next(undefined)
        })

        return imageObs
    }

    _deleteOrRestoreUserImage(imageId: string, deleted: boolean): Observable<any> {
        const obs = new Subject<boolean>()
        if (!this.dexieDb) {
            obs.next(false)
            return obs
        }

        const delImg = {
            id: imageId,
            date: new Date(),
            deleted: deleted
        }

        this.dexieDb?.userDeletedImages.put(delImg).then(() => {
            obs.next(true)
            obs.complete()
        }).catch((error) => {
            console.error('problem deleting user image', error)
            obs.next(false)
            obs.complete()
        })

        const ref = doc(this.firestore, 'userDeletedImages', this.user!.uid, 'deletedImages', imageId)
        const p = setDoc(ref, delImg)

        return forkJoin({ localDel: obs, remoteDel: from(p) })
    }

    deleteUserImage(imageId: string): Observable<any> {
        return this._deleteOrRestoreUserImage(imageId, true)
    }

    restoreUserImage(imageId: string) {
        return this._deleteOrRestoreUserImage(imageId, false)
    }

    private _getDesignLocalKey(localId: string): string {
        return `parkour-design-${this.user?.uid}-${localId}`
    }

    private _getDesignPreviewLocalKey(localId: string): string {
        return `parkour-png-${localId}`
    }

    private _getDesignLocally(localId: string): Observable<DesignSchema | null> {
        const designKey = this._getDesignLocalKey(localId)

        return defer(() => from(idbGet(designKey)).pipe(
            map(s => {
                if (s) {
                    const data: any = JSON.parse(s)
                    if (data.localId != localId) {
                        data.localId = localId
                    }
                    const fixedData = new DesignSchema()
                    fixedData.copy(data)
                    return fixedData
                }
                return null
            })
        ))
    }

    savePngPreview(design: DesignSchemaWithPreview) {
        if (design.png) {
            this.savePngPreviewLocally(design.localId, design.png)
            if (design.remoteId) {
                this._savePngPreviewRemotely(design.remoteId, design.png)
            }
        }
    }

    savePngPreviewLocally(designLocalId: any, pngPreview: string) {
        const pngKey = this._getDesignPreviewLocalKey(designLocalId)
        idbSet(pngKey, pngPreview).then(() => {
        }).catch((err) => {
            console.error('problem with saving design png preview locally', err)
        })
    }

    private _savePngPreviewRemotely(remoteId: string, pngPreview: string) {
        const pngRef = this._getStoragePngRef(remoteId)
        if (!pngRef) {
            console.error('cannot get storage png ref')
            return
        }
        const md = {
            contentType: 'image/png',
            // cacheControl: 'public, max-age=300',
        }
        fetch(pngPreview).then(
            res => res.arrayBuffer()
        ).then(buffer => {
            uploadBytes(pngRef, buffer, md).then(
                (resp) => {
                    //console.info('png saved', resp)
                    this.dexieDb!.pngPreviewUrls.get(remoteId).then((pngUrl) => {
                        if (!pngUrl) {
                            getDownloadURL(pngRef).then((url) => {
                                this.dexieDb!.pngPreviewUrls.put({
                                    id: remoteId,
                                    url: url
                                }).then((key) => {
                                    console.info('png url saved at', key)
                                })
                            })
                        }
                    })
                }
            ).catch((error) => {
                console.error('problem with getting PNG bytes', error)
            })
        }).catch((error) => {
            console.error('problem with getting PNG bytes', error)
        })
    }

    private _saveDesignLocally(design: DesignSchema, pngPreview?: string) {
        let designToSave = design
        this._getDesignLocally(design.localId).subscribe({
            next: (localDesign: DesignSchema | null) => {
                if (localDesign) {
                    if (localDesign.updatedAt > design.updatedAt) {
                        designToSave = localDesign
                    }

                    if (localDesign.remoteId && !design.remoteId) {
                        designToSave.remoteId = localDesign.remoteId
                    } else if (design.remoteId && !localDesign.remoteId) {
                        designToSave.remoteId = design.remoteId
                    } else if (design.remoteId && design.remoteId != localDesign.remoteId) {
                        console.info('error: remoteIds differ', design, localDesign)
                        return
                    }
                } else {
                    //console.info('save locally fresh', design)
                }

                const s = JSON.stringify(designToSave)
                const designKey = this._getDesignLocalKey(designToSave.localId)
                idbSet(designKey, s).then(() => {
                }).catch((err) => {
                    console.error('problem with saving design locally', err)
                })
                if (pngPreview) {
                    this.savePngPreviewLocally(designToSave.localId, pngPreview)
                }
            },
            error: (err) => {
                console.error('error occured', err)
                showErrorBox(this.msgSvc, $localize`Zapisywanie projektu`, $localize`Wystąpił nieznany błąd`)
            }
        })
    }

    /**
     * Creates a new design and saves it both locally and in Firestore
     *
     * This method handles saving the design to local storage first, then to Firestore.
     * It removes the PNG preview data before sending to Firestore to save bandwidth,
     * and sets appropriate timestamp fields.
     *
     * @param design The design to add
     * @returns A tuple containing the local ID and an Observable for the creation operation
     */
    addDesign(design: DesignSchema): [string, Observable<any>] {
        this._saveDesignLocally(design)

        if (!this.designsCollection) {
            return [design.localId, throwError(() => new Error('designsCollection is undefined'))]
        }

        // prevent from saving PNG preview in firestore
        const designData = structuredClone(design) as any
        if (designData.png) {
            delete designData.png
        }

        designData.createdAt = (new Date()).toISOString()
        designData.updatedAt = designData.createdAt

        const p = addDoc(this.designsCollection, designData)

        const obs = defer(() => from(p).pipe(
            map(docRef => {
                design.remoteId = docRef.id
                design.saved = true
                this._saveDesignLocally(design)
                return docRef
            })))

        return [design.localId, obs]
    }

    cloneDesign(oldDesign: any, title?: string, overrideAuthor?: boolean): [string, DesignSchema, Observable<any>] {
        // duplicate old design, strip some fields and create new design base on old data
        const oldLocalId = oldDesign.localId
        const designCopy = JSON.parse(JSON.stringify(oldDesign))
        delete designCopy.createdAt
        delete designCopy.updatedAt
        delete designCopy.remoteId
        delete designCopy.localId
        designCopy.saved = false
        if (title) {
            designCopy.title = title
        } else {
            designCopy.title = designCopy.title + ' ' + $localize`(kopia)`
        }
        if (overrideAuthor && this.user) {
            designCopy.author = this.user.uid
        }
        const newDesign = new DesignSchema()
        newDesign.copy(designCopy)

        // copy preview from old design to new one
        idbGet(this._getDesignPreviewLocalKey(oldLocalId)).then(pngPreview => {
            if (pngPreview) {
                this.savePngPreviewLocally(newDesign.localId, pngPreview)
            }
        }).catch((error) => {
            console.error('problem with getting PNG preview', error)
        })

        // store newly prepared design
        const [localId, obs] = this.addDesign(newDesign)
        return [localId, newDesign, obs]
    }

    importDesign(designData: any): [string, Observable<any>] {
        const designCopy = JSON.parse(JSON.stringify(designData))
        delete designCopy.createdAt
        delete designCopy.updatedAt
        delete designCopy.remoteId
        delete designCopy.localId
        designCopy.saved = false
        const newDesign = new DesignSchema()
        newDesign.copy(designCopy)

        // store newly prepared design
        const [localId, obs] = this.addDesign(newDesign)
        return [localId, obs]
    }

    _getStoragePngRef(remoteId: string, author?: string): StorageReference | undefined {
        if (!author) {
            if (!this.user) {
                return undefined
            }
            author = this.user.uid
        }
        const path = `designs/${author}/design-${remoteId}.png`
        return ref(this.storage, path)
    }

    /**
     * Updates an existing design both locally and in Firestore
     *
     * This method saves the design locally first for instant feedback and offline support,
     * then schedules an update to Firestore via the design queue to optimize network usage.
     * If a PNG preview is provided, it will also be saved.
     *
     * @param design The design to update
     * @param pngPreview Optional PNG preview image data
     * @returns An Observable for the update operation
     */
    updateDesign(design: DesignSchema, pngPreview?: string): Observable<any> {
        this._saveDesignLocally(design, pngPreview)
        this.allocateQueue(design)?.next([design, pngPreview])
        return of(true)
    }

    _updateDesignDelayed(design: DesignSchema, pngPreview?: string): Observable<void> {
        if (pngPreview && design.remoteId) {
            this._savePngPreviewRemotely(design.remoteId, pngPreview)
        }

        // prevent from saving PNG preview in firestore
        const designData = structuredClone(design) as any
        if (designData.png) {
            delete designData.png
        }

        designData.updatedAt = (new Date()).toISOString()

        const dRef = doc(this.firestore, 'designs', design.remoteId!)
        return defer(() => from(setDoc(dRef, designData)).pipe(
            map(docRef => {
                design.saved = true
                this._saveDesignLocally(design)
                return docRef
            })))
    }

    private _loadRemoteDesign(localId: string, author: string | null): Observable<DesignSchema | null> {
        if (!author) {
            author = this.user ? this.user.uid : null
        }

        if (!this.designsCollection) {
            return throwError(() => new Error('designsCollection is undefined'))
        }
        const q = query(this.designsCollection,
            where('author', '==', author),
            where('localId', '==', localId),
            where('deletedAt', '==', null))
        return defer(() => from(getDocs(q)).pipe(
            map(records => {
                const designs: any[] = []
                records.forEach((doc) => {
                    const design: any = doc.data()
                    design.remoteId = doc.id
                    if (doc.metadata.hasPendingWrites) {
                        design.saved = false
                    } else {
                        design.saved = true
                    }
                    designs.push(design)
                })
                if (designs.length === 1) {
                    return designs[0]
                } else if (designs.length === 0) {
                    return null
                } else {
                    // TODO too many designs, return some error
                    return null
                }
            })))
    }

    loadDesign(localId: string, author: string | null): Observable<DesignSchema | null> {
        // get the design from local storage
        return this._getDesignLocally(localId).pipe(
            switchMap((design: DesignSchema | null) => {
                if (design) {
                    return of(design)
                }

                // if the design is not in local storage then retrieve it from the server
                return this._loadRemoteDesign(localId, author)
            }))
    }

    getLocalDesigns(eventName?: string): Observable<DesignSchemaWithPreview[]> {
        return defer(() => from(idbEntries()).pipe(
            map((entries) => {
                const designs: DesignSchemaWithPreview[] = []
                for (let [key, val] of entries) {
                    const designKeyPrefix = this._getDesignLocalKey('')
                    if (!key.toString().startsWith(designKeyPrefix)) {
                        continue
                    }
                    if (!val) {
                        continue
                    }
                    const data = JSON.parse(val)

                    if (eventName && data.eventName != eventName) {
                        continue
                    }

                    const fixedData: DesignSchemaWithPreview = new DesignSchema()
                    fixedData.copy(data)

                    idbGet(this._getDesignPreviewLocalKey(fixedData.localId)).then(pngPreview => {
                        if (pngPreview) {
                            fixedData.png = pngPreview
                        }
                    }).catch((error) => {
                        console.error('problem with getting PNG preview', error)
                    })
                    designs.push(fixedData)
                }

                designs.sort((a: any, b: any) => a.updatedAt > b.updatedAt ? -1 : 1)

                return designs
            })
        ))
    }

    public setGlobalValue(name: string, value: any): Observable<string> {
        const p = this.dexieDbGlobal.globalSettings.put({
            name: name,
            value: value
        })
        return defer(() => from(p))
    }

    public getGlobalValue(name: string): Observable<GlobalSettings | undefined> {
        const p = this.dexieDbGlobal.globalSettings.get({
            name: name
        })
        return defer(() => from(p))
    }

    private _setLocalUserProfile(userProfile: UserProfile): Observable<string> {
        if (!this.user) {
            return throwError(() => new Error('no user'))
        }
        userProfile.uid = this.user.uid
        const p = this.dexieDb!.userProfiles.put(userProfile)
        return defer(() => from(p))
    }

    private _setRemoteUserProfile(userProfile: UserProfile): Observable<void> {
        const obs = new Subject<void>()
        if (!this.user) {
            obs.next()
            return obs
        }
        const userId = this.user.uid
        const ref = doc(this.firestore, 'userProfiles', userId)
        updateDoc(ref, userProfile).then(() => {
            obs.next()
        }).catch(error => {
            defer(() => from(setDoc(ref, userProfile))).subscribe(obs)
        })
        return obs
    }

    setUserProfile(userProfile: UserProfile) {
        userProfile.updatedAt = (new Date()).toISOString()
        const localObs = this._setLocalUserProfile(userProfile)
        const remoteObs = this._setRemoteUserProfile(userProfile)
        return zip([localObs, remoteObs])
    }

    private _getOldLocalUserData() {
        const p = idbGet('user-data-' + (this.user?.uid || 'unknown'))
        return defer(() => from(p))
    }

    private _getLocalUserProfile() {
        return this.auth.user.pipe(
            filter((user: User | null) => (user !== null)),
            switchMap((user: User | null) => {
                const userId = user!.uid
                const p = this.dexieDb!.userProfiles.get({ uid: userId })
                return defer(() => from(p))
            })
        )
    }

    private _getRemoteUserProfile(): Observable<DocumentData | undefined> {
        // TODO: return NULL if in offline mode
        return this.auth.remoteUser.pipe(
            filter((user: User | null) => (user !== null)),
            switchMap((user: User | null) => {
                if (!this.user) {
                    return throwError(() => new Error('no user'))
                }
                const userId = this.user.uid
                const ref = doc(this.firestore, 'userProfiles', userId)
                return defer(() => from(getDoc(ref)).pipe(
                    map(docRef => docRef.data())))
            })
        )
    }

    getAllUserProfilesStripped(): Observable<UserProfile[]> {
        if (!this.userProfilesCollection) {
            return throwError(() => new Error('userProfilesCollection is undefined'))
        }
        const q = query(this.userProfilesCollection)
        return defer(() => from(getDocs(q)).pipe(
            map(records => {
                const profiles: any[] = []
                records.forEach((doc) => {
                    let profile: UserProfile = doc.data()
                    // skip designs with no author
                    if (profile.uid !== null) {
                        const profile2 = structuredClone(profile)
                        if (profile2.designDisplayOptions) {
                            delete (profile2.designDisplayOptions)
                        }
                        if (profile2.shortcuts) {
                            delete (profile2.shortcuts)
                        }
                        if (profile2.undoLimit) {
                            delete (profile2.undoLimit)
                        }
                        if (profile2.zoomWithWheel !== undefined) {
                            delete (profile2.zoomWithWheel)
                        }
                        profiles.push(profile2)
                    }
                })
                return profiles
            })))
    }

    /**
     * Returns user profile as observable.
     *
     * @returns Either UserProfile object is returned or undefined if the profile is not present locally not remotely.
     */
    getUserProfile(): Observable<UserProfile | null | undefined> {
        const oldLocalObs = this._getOldLocalUserData()
        const localObs = this._getLocalUserProfile()
        const remoteObs = this._getRemoteUserProfile()
        return zip([oldLocalObs, localObs, remoteObs]).pipe(
            map((userProfiles: [any, any, any]) => {
                const oldLocalProfile = userProfiles[0]
                const localProfile = userProfiles[1]
                const remoteProfile = userProfiles[2]

                let userProfile = remoteProfile
                for (let p of [localProfile, oldLocalProfile]) {
                    if (!p) {
                        continue
                    }
                    if (!userProfile) {
                        userProfile = p
                        continue
                    }
                    if (p.updatedAt && (!userProfile.updatedAt || p.updatedAt > userProfile.updatedAt)) {
                        userProfile = p
                        continue
                    }
                }
                if (oldLocalProfile) {
                    idbDel('user-data-' + (this.user?.uid || 'unknown'))
                }

                return userProfile
            }))
    }

    _getListQuery(scope: string, limitNum: number, sortField: string, sortDir: string): Query<unknown, DocumentData> | undefined {
        if (!this.designsCollection) {
            return undefined
        }
        // prepare query to firestore
        const qArgs: any[] = [where('deletedAt', '==', null)]
        if (scope === 'public') {
            // it is not possible to filter out current user and sort by updatedAt in the same query
            // so it is filtered locally
            //qArgs.push(where('author', '!=', this.user?.uid))

            qArgs.push(where('shareMode', '==', ShareMode.PUBLIC))
            if (isProduction()) {
                qArgs.push(where('author', '==', 'vehpjwooBReHb3VJQ7RXf4zlH2l2'))
            } else {
                qArgs.push(where('author', '==', 'lXzkD7G1r5ctSOUDywPsDzBqXLh2'))
            }
        } else if (scope === 'my') {
            qArgs.push(where('author', '==', this.user?.uid))
        }
        if (!sortDir) {
            sortDir = 'desc'
        }
        if (!sortField) {
            sortField = 'updatedAt'
        }
        qArgs.push(orderBy(sortField, sortDir as OrderByDirection))
        qArgs.push(limit(limitNum))
        const q = query(this.designsCollection, ...qArgs)
        return q
    }

    loadAllDesigns(limitNum: number, sortField: string, sortDir: string): Observable<DesignSchema[]> {
        // prepare query to firestore
        const q = this._getListQuery('all', limitNum, sortField, sortDir)
        if (!q) {
            return throwError(() => new Error('cannot get query'))
        }
        // load from firestore
        return defer(() => from(getDocs(q)).pipe(
            map(records => {
                const designs: DesignSchema[] = []
                // prepare received designs
                records.forEach((doc) => {
                    let design: any = doc.data()
                    // skip designs with no author
                    if (design.author === null) {
                        return
                    }
                    design.remoteId = doc.id
                    const design2 = new DesignSchema()
                    design2.copy(design)
                    designs.push(design2)
                })
                return designs
            })))
    }

    /**
     * Loads a PNG preview for a design from cache or Firebase Storage
     *
     * This method attempts to load the design preview from IndexedDB cache first.
     * If not found in cache, it will fetch from Firebase Storage and cache the result.
     * It handles both online and offline scenarios and updates the design object with
     * the preview URL.
     *
     * @param design The design to load a preview for
     */
    loadPreview(design: DesignSchemaWithPreview) {
        if (!design.remoteId || !design.author) {
            design.pngUrl = null
            return
        }
        const pngRef = this._getStoragePngRef(design.remoteId, design.author)
        if (!pngRef) {
            design.pngUrl = null
            return
        }

        // prevent showing skeleton when offline, still queue loading the previews
        if (navigator.onLine) {
            design.pngUrl = 'progress'
        }

        this.dexieDb!.pngPreviewUrls.get(design.remoteId).then((pngUrl) => {
            if (pngUrl) {
                design.pngUrl = pngUrl.url
            } else {
                getDownloadURL(pngRef).then((url) => {
                    design.pngUrl = url
                    this.dexieDb!.pngPreviewUrls.put({
                        id: design.remoteId!,
                        url: url
                    }).then((key) => {
                        console.info('png url saved at', key)
                    })
                }).catch((error) => {
                    design.pngUrl = null
                })
            }
        })
    }

    loadPublicDesigns(limitNum: number, sortField: string, sortDir: string): Observable<any[]> {
        // prepare query to firestore
        const q = this._getListQuery('public', limitNum, sortField, sortDir)
        if (!q) {
            return throwError(() => new Error('cannot get query'))
        }

        // load from firestore
        return defer(() => from(getDocs(q)).pipe(
            map(records => {
                console.info('processing loaded public designs from server', records)

                const designs: any[] = []

                // prepare received designs
                records.forEach((doc) => {
                    let design: any = doc.data()

                    design.remoteId = doc.id

                    const design2 = new DesignSchema()
                    design2.copy(design)
                    design = design2

                    this.loadPreview(design)

                    designs.push(design)
                })

                return designs
            })))
    }

    /**
     * Loads a page of designs from Firestore using pagination
     *
     * This method recursively loads all designs in batches but presents them to the user
     * as a single complete list. It includes safety limits to prevent excessive recursion
     * and adds timeout intervals between page loads to improve UI responsiveness.
     *
     * The implementation also includes error handling with specific recovery for network
     * issues, allowing retries when connectivity problems occur.
     *
     * @param obs Subject that receives each page of query results
     * @param startUpdateDate Only load designs updated after this date
     * @param lastVisible The last document from the previous page (for pagination)
     * @param pageCount Current count of pages loaded (for safety limits)
     * @returns Subscription that can be used to unsubscribe from the query
     */
    _loadMyDesignsPage(obs: Subject<QuerySnapshot>, startUpdateDate: string | null, lastVisible?: any, pageCount: number = 1) {
        // Safety check - limit maximum number of pages to prevent excessive recursion
        // 20 pages at 200 items per page = 4000 designs maximum
        const MAX_PAGE_COUNT = 20
        if (pageCount > MAX_PAGE_COUNT) {
            console.warn(`Reached maximum page count (${MAX_PAGE_COUNT}), stopping pagination`)
            obs.complete()
            return
        }

        // prepare query to firestore
        const limitNum = 200
        const qArgs: any[] = []
        qArgs.push(where('deletedAt', '==', null))
        qArgs.push(where('author', '==', this.user?.uid))
        if (startUpdateDate) {
            qArgs.push(where('updatedAt', '>', startUpdateDate))
        }
        qArgs.push(orderBy('updatedAt', 'desc' as OrderByDirection))
        if (lastVisible) {
            qArgs.push(startAfter(lastVisible))
        }
        qArgs.push(limit(limitNum))
        const q = query(this.designsCollection!, ...qArgs)

        // load from firestore with timeout and retry logic
        const subscription = from(getDocs(q)).subscribe({
            next: (records) => {
                // Emit the current page to the observer
                obs.next(records)

                // If we have a full page, there might be more data
                if (records.docs.length === limitNum) {
                    const newLastVisible = records.docs[records.docs.length - 1]

                    // Use timeout to prevent deep recursion and give UI time to breathe
                    setTimeout(() => {
                        this._loadMyDesignsPage(obs, startUpdateDate, newLastVisible, pageCount + 1)
                    }, 10) // Small timeout to avoid blocking the main thread
                } else {
                    // No more pages, complete the observable
                    console.info('loaded designs', (pageCount - 1) * limitNum + records.docs.length)
                    obs.complete()
                }
            },
            error: (err) => {
                // Handle network errors specifically
                if (err.code === 'failed-precondition' || err.code === 'unavailable') {
                    console.warn('Network issues during pagination, will retry shortly', err)

                    // Try to recover with a longer timeout if network issues
                    setTimeout(() => {
                        this._loadMyDesignsPage(obs, startUpdateDate, lastVisible, pageCount)
                    }, 3000) // 3 second retry for network issues
                } else {
                    // Other errors are passed to the observer
                    console.error('Error occurred during designs page query', err)
                    obs.error(new Error(`Failed to load designs page: ${err.message || 'Unknown error'}`))
                    obs.complete()
                }
            }
        })

        // Handle cleanup if the observable is unsubscribed during loading
        return subscription
    }

    /**
     * Loads all designs for the current user, combining both remote and local designs
     *
     * This method fetches designs from Firestore using pagination to retrieve large sets,
     * and combines them with any locally stored designs. It handles synchronization
     * between local and remote designs, resolving conflicts based on timestamps.
     *
     * The designs are loaded in pages from Firestore and emitted as a single complete list
     * to the observer.
     *
     * @returns An Observable that emits arrays of DesignSchemaWithPreview objects
     */
    loadMyDesigns(forceRemoteReload: boolean): Observable<DesignSchemaWithPreview[]> {
        if (!this.designsCollection) {
            return throwError(() => Error('cannot get query'))
        }

        const obs = new Subject<DesignSchemaWithPreview[]>()

        this.getLocalDesigns().subscribe({
            next: (localDesigns: DesignSchema[]) => {
                const lastMyDesignsLoadTime = forceRemoteReload ? null : localStorage.getItem('lastMyDesignsLoadTime')

                const internalObs = new Subject<QuerySnapshot>()
                this._loadMyDesignsPage(internalObs, lastMyDesignsLoadTime, undefined, 1)

                internalObs.subscribe({
                    next: (records: QuerySnapshot) => {
                        // prepare received localDesigns
                        const remoteDesigns: DesignSchemaWithPreview[] = []
                        records.forEach((doc: any) => {
                            let design: DesignSchemaWithPreview = doc.data()
                            design.remoteId = doc.id
                            if (doc.metadata.hasPendingWrites) {
                                design.saved = false
                            } else {
                                design.saved = true
                            }

                            const design2: DesignSchemaWithPreview = new DesignSchema()
                            design2.copy(design)
                            design = design2

                            if (!design.remoteId) {
                                return
                            }

                            remoteDesigns.push(design)

                            this.loadPreview(design)

                            // find design stored locally, if not present then same remote one locally
                            // if present locally then
                            const idx = localDesigns.findIndex((d: any) => d.localId === design.localId)
                            if (idx >= 0) {
                                const localDesign = localDesigns[idx]
                                // if the design stored locally is newer than the one from server
                                // then mark it stale and drop the one from server
                                if (localDesign.updatedAt > design.updatedAt) {
                                    localDesign.saved = false
                                    //console.info('local is newer', design.remoteId, design.updatedAt, localDesign.updatedAt)
                                } else {
                                    // if the design from server is newer than remove local one
                                    // and push to the list the server one.
                                    localDesigns.splice(idx, 1)
                                    //console.info('remote is newer', design.remoteId, design.updatedAt, localDesign.updatedAt)

                                    // check if local preview is present and use it if remote preview is missing
                                    idbGet(this._getDesignPreviewLocalKey(design.localId)).then(pngPreview => {
                                        if (pngPreview) {
                                            design.png = pngPreview
                                        }
                                    }).catch((error) => {
                                        console.error('problem with getting PNG preview', error)
                                    })
                                }
                            } else {
                                // if there is no local design for remote on then save it locally
                                this._saveDesignLocally(design)
                                //console.info('fresh remote design', design.remoteId, design)
                            }
                        })

                        if (remoteDesigns.length > 0) {
                            obs.next(remoteDesigns)
                        }
                    },
                    complete: () => {
                        obs.next(localDesigns)
                        // this is an end of data loading from the server
                        obs.complete()

                        // save lastMyDesignsLoadTime, next time load designs only modified after that time
                        const lastMyDesignsLoadTime = (new Date()).toISOString()
                        localStorage.setItem('lastMyDesignsLoadTime', lastMyDesignsLoadTime)

                        // do this but not immediatelly to not bother user
                        setTimeout(() => {
                            this.saveNotSavedDesigns()
                        }, 10000)
                    },
                    error: (err) => {
                        console.error('Error occurred during records processing', err)
                        obs.error(new Error(`Failed to process design records: ${err.message || 'Unknown error'}`))
                        obs.complete()
                    }
                })
            },
            error: (err) => {
                console.error('Error occurred loading local designs', err)
                obs.error(new Error(`Failed to load local designs: ${err.message || 'Unknown error'}`))
                obs.complete()
            }
        })

        return obs
    }

    /**
     * Deletes a design from both local storage and Firestore
     *
     * This performs a soft delete by setting the deletedAt timestamp rather than
     * actually removing the record. This allows for potential recovery and
     * maintains history. The design is removed from local storage immediately
     * and marked as deleted in Firestore.
     *
     * @param design The design to delete
     * @returns An Observable that completes when the delete operation is finished
     */
    deleteDesign(design: any) {
        const designKey = this._getDesignLocalKey(design.localId)
        idbDel(designKey)

        if (design.remoteId) {
            // prevent from saving PNG preview in firestore
            const designData = structuredClone(design)
            if (designData.png) {
                delete designData.png
            }

            designData.updatedAt = (new Date()).toISOString()
            designData.deletedAt = designData.updatedAt

            const ref = doc(this.firestore, 'designs', design.remoteId)
            return defer(() => from(setDoc(ref, designData)).pipe(
                map(d => {
                    idbDel(designKey)
                    return true
                })))
        } else {
            return of(true)
        }
    }

    /**
     * Synchronizes local designs with Firestore
     *
     * This method finds all designs that are marked as not saved (modified locally)
     * and saves them to Firestore. It handles both updates to existing designs and
     * creation of new designs. This is automatically called when the application
     * comes back online after being offline.
     */
    saveNotSavedDesigns() {
        this.getLocalDesigns().subscribe({
            next: (designs) => {
                for (const design of designs) {
                    if (design.saved) {
                        continue
                    }
                    if (design.remoteId) {
                        this.subs.add(
                            this.updateDesign(design).subscribe({
                                next: (d: any) => {
                                    //console.info('updated not saved', design)
                                },
                                error: (err: any) => {
                                    console.info('error while updating not saved', err, design)
                                }
                            })
                        )
                    } else {
                        this._loadRemoteDesign(design.localId, null).subscribe({
                            next: (remoteDesign) => {
                                if (remoteDesign) {
                                    this._saveDesignLocally(remoteDesign)
                                } else {
                                    const [localId, obs] = this.addDesign(design)
                                    this.subs.add(
                                        obs.subscribe({
                                            next: (d: any) => {
                                                console.info('added not saved', design)
                                            },
                                            error: (err: any) => {
                                                console.info('error while adding not saved', err, design)
                                            }
                                        })
                                    )
                                }
                            },
                            error: (error) => {
                                console.info('DESIGN EXISTS? error', error)
                                showErrorBox(this.msgSvc, $localize`Zapisywanie projektu`, $localize`Wystąpił nieznany błąd`)
                            }
                        })
                    }
                }
            },
            error: (err) => {
                console.error('error occured', err)
                showErrorBox(this.msgSvc, $localize`Zapisywanie projektu`, $localize`Wystąpił nieznany błąd`)
            }
        })
    }

    @HostListener('window:offline')
    setNetworkOffline(): void {
    }

    @HostListener('window:online')
    setNetworkOnline(): void {
        this.saveNotSavedDesigns()
    }
}
