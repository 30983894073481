import * as B from '@babylonjs/core'

export type ParkourMaterial = B.Material
export type ParkourTexture = B.BaseTexture
export type ParkourMesh = Promise<B.Mesh>

export class MaterialManager {
    private _materials: { [id: string]: ParkourMaterial } = {}
    private _textures: { [id: string]: ParkourTexture } = {}
    private _meshes: { [id: string]: ParkourMesh } = {}

    constructor() {
    }

    getMaterial(key: string, create: (name: string) => ParkourMaterial) {
        let m = this._materials[key]
        if (m) {
            return m
        }
        m = this._materials[key] = create(key)
        return m
    }

    getMesh(key: string, create: () => ParkourMesh): ParkourMesh {
        let m = this._meshes[key]
        if (m) {
            return m
        }
        return this._meshes[key] = create()
    }

    getTexture(key: string, create: (name: string) => ParkourTexture) {
        let t = this._textures[key]
        if (t) {
            return t
        }
        t = this._textures[key] = create(key)
        return t
    }
    
    
     getTextMaterial(scene: B.Scene, text: string): ParkourMaterial {
        const fontSize = 120
        const size = this._calculateOptimalTextureSize(text, fontSize)
        const key = `${text}-${size.textureWidth}-${size.textureHeight}`

        const texture = this.getTexture(key, () => {
            const t = new B.DynamicTexture('dynamicTexture', { width: size.textureWidth, height: size.textureHeight }, scene)
            t.hasAlpha = true
            const ctx = t.getContext()
            ctx.font = `bold ${fontSize}px Arial`
            ctx.fillStyle = "#000"
            const metrics = ctx.measureText(text)
            const textHeight = metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent
            ctx.fillText(text, (size.textureWidth - metrics.width) / 2, size.textureHeight / 2 + textHeight / 2)
            t.update()
            return t
        })
    
        return this.getMaterial(key, (name: string) => {
            const m = new B.StandardMaterial(name, scene)
            m.diffuseTexture = texture
            m.emissiveColor = B.Color3.White()
            return m
        })
    }
    
    destroy() {
        Object.values(this._materials).forEach(m => m.dispose())
        this._materials = {}
        this._textures = {}
        this._meshes = {}
    } 

    private _calculateOptimalTextureSize(text: string, fontSize: number) {
        const tempCanvas = document.createElement("canvas");
        const ctx = tempCanvas.getContext("2d");
        if (!ctx) {
            return { textureWidth: 0, textureHeight: 0 }
        }
        ctx.font = `bold ${fontSize}px Arial`
        const metrics = ctx.measureText(text)
        const textHeightPixels = metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent
        const textureWidth = Math.pow(2, Math.ceil(Math.log2(metrics.width)))
        const textureHeight = Math.pow(2, Math.ceil(Math.log2(textHeightPixels)))

        return { textureWidth, textureHeight }
    }
}