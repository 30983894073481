import paper from 'paper'
import { Direction, NodeFlags } from '../design.schema'
import { areInLine } from '../utils'
import { DetailComponent } from './detail.component'
import { LayerId, ParkourCanvas } from '../parkour-canvas/parkour-canvas'
import { ParkourConfig } from '../parkour.config'
import { OutlinedText } from './detail.paper.extensions'
import { PathMidPoint, PathMidPointLike } from './detail.path.midpoint'
import { Section } from './detail.path.section'
import { Obstacle } from './parkour-objects/obstacle'
import { ObstacleWithBars } from './parkour-objects/obstacle-with-bars'
import { PathObject } from './parkour-objects/path-object'
import { Unit } from '../pipes'
import { DetailComponentInterface } from './detail.component.interface'


export class ObstaclePathNode {
    distFromPrev: number = 0;
    distToNext: number = 0;
    next?: ObstaclePathNode
    prev?: ObstaclePathNode
    distFromStart: number = 0;
    label: string = '';
    field: paper.Rectangle
    color: paper.Color
    pointIn: paper.Point
    angleIn: number
    pointOut: paper.Point
    angleOut: number
    forwardPath?: paper.Path
    thickPath?: paper.Path
    defaultHandleLength: number = 1000;
    public midPoints: PathMidPoint[] = [];
    timeout?: NodeJS.Timeout
    direction: Direction = Direction.forward;
    pathGroup?: paper.Group
    distance?: paper.Item
    section?: Section
    pass: number = 1;
    approachCurvature: number = 0;
    warnAboutStrides: boolean = false;
    flags: NodeFlags
    static readonly smoothAttributes = {
        type: 'catmull-rom',
        factor: 0.5,
    };

    private static INIT_HANDLE_LEN = 1000;

    get manualLabel(): string {
        if (this.obstacle instanceof Obstacle) {
            const roundNo = this.getRound123()
            if (roundNo) {
                return this.obstacle.getManualLabel(roundNo, this.pass)
            }
        }
        return ''
    }

    constructor(
        public obstacle: PathObject, 
        midPoints: PathMidPointLike[],
        private cfg: ParkourConfig,
        private canvas: ParkourCanvas,
        private view?: DetailComponentInterface,
        flags?: NodeFlags)
    {
        this.field = this.canvas.field
        this.flags = flags || NodeFlags.NONE
        this.color = new paper.Color(this.cfg.params.colors.pathRegular)
        this.pointIn = obstacle.getEntryPoint(this.direction).clone()
        this.angleIn = obstacle.getEntryAngle(this.direction)
        this.pointOut = obstacle.getExitPoint(this.direction).clone()
        this.angleOut = obstacle.getExitAngle(this.direction)
        this.setMidPoints(midPoints)
    }

    private _getPathColor(): paper.Color {
        if (this.section?.isSecondRound()) {
            return new paper.Color(this.cfg.params.colors.pathRegular2)
        } else if (this.section?.isThirdRound()) {
            return new paper.Color(this.cfg.params.colors.pathRegular25)
        }
        return new paper.Color(this.cfg.params.colors.pathRegular)
    }

    isRound123(roundNo?: number): boolean {
        if (roundNo !== undefined) {
            return this.section?.round123 === roundNo
        }
        return this.section !== undefined && this.section.round123 !== undefined
    }

    getRound123(): number | undefined {
        return this.section?.round123
    }

    isSectionOpening(): boolean {
        if (this.section && this.section.route.length > 0) {
            return this.section.route[0] === this
        }
        return false
    }

    setSection(round?: Section) {
        this.section = round
        this.color = this._getPathColor()
    }

    getPosition(): paper.Point {
        return this.obstacle.getPosition()
    }

    onMouseEnter(ev: any) {
        if (this.midPoints.length > 0 && this.view) {
            for (let mp of this.midPoints) {
                mp.show()
            }
            this._drawDistance()
            clearTimeout(this.timeout)
        }
    }

    onMouseLeave(ev: any) {
        this.timeout = setTimeout(() => {
            if (this.midPoints.length > 0 && this.view) {
                for (let mp of this.midPoints) {
                    mp.hide()
                }
                this._drawDistance()
            }
        }, 1000)
    }

    highlight() {
        if (this.forwardPath) {
            this.color = new paper.Color(this.cfg.params.colors.midPoint)
            this.forwardPath.strokeColor = this.color
        }
    }

    dehighlight() {
        if (this.forwardPath) {
            this.color = this._getPathColor()
            this.forwardPath.strokeColor = this.color
        }
        this._drawDistance()
    }

    clearMidPoints() {
        clearTimeout(this.timeout)
        for (let mp of this.midPoints) {
            mp.destroy()
        }
        this.midPoints = []
    }

    setMidPoints(midPoints: PathMidPointLike[]) {
        this.clearMidPoints()
        for (let p of midPoints) {
            if (p instanceof PathMidPoint) {
                p.parent = this
                this.midPoints.push(p)
            } else {
                this.midPoints.push(
                    new PathMidPoint(new paper.Point(p.x, p.y), p.handleFactor, this,
                        new paper.Color(this.cfg.params.colors.midPoint), 
                        this.cfg, this.canvas, this.view))
            }
        }
    }

    private _buildHandle(length: number, obj: PathObject, angleOfAttack: number): paper.Point {
        return new paper.Point(0, length).rotate(obj.angle + angleOfAttack, [0, 0])
    }

    private _smoothForwardPath(thisHandleOut: paper.Point, nextHandleIn: paper.Point): void {
        if (!this.forwardPath) {
            return
        }

        this.forwardPath.smooth(ObstaclePathNode.smoothAttributes)

        // restore handles stengths
        for (let i = 0; i < this.midPoints.length; i++) {
            const mp = this.midPoints[i]
            this.forwardPath.segments[i + 1].handleIn = this.forwardPath.segments[i + 1].handleIn.multiply(mp.handleFactor)
            this.forwardPath.segments[i + 1].handleOut = this.forwardPath.segments[i + 1].handleOut.multiply(mp.handleFactor)
        }

        // restore object handles, because 'continous' smoothing algorythm changes them
        if (Math.abs(thisHandleOut.length) != ObstaclePathNode.INIT_HANDLE_LEN) {
            this.forwardPath.segments[0].handleOut = thisHandleOut
        }
        if (Math.abs(nextHandleIn.length) != ObstaclePathNode.INIT_HANDLE_LEN) {
            this.forwardPath.segments[this.forwardPath.segments.length - 1].handleIn = nextHandleIn
        }
    }

    buildForwardPath(next: ObstaclePathNode, pathAttributes?: { [id: string]: any} , changeMidPointsCount?: boolean) {
        if (!pathAttributes) {
            if (!this.forwardPath) {
                return
            }
            pathAttributes = {
                strokeWidth: this.forwardPath.strokeWidth,
                strokeScaling: this.forwardPath.strokeScaling,
                strokeCap: this.forwardPath.strokeCap,
                dashArray: this.forwardPath.dashArray,
                selected: this.forwardPath.selected,
                fullySelected: this.forwardPath.fullySelected
            }
        }

        const MIDPOINT_DISTANCE = 2000 // 2000 cm = 20m ie. default distance between midpoints

        if (this.forwardPath && this.forwardPath.isInserted()) {
            this.forwardPath.remove()
        }
        if (this.thickPath && this.thickPath.isInserted()) {
            this.thickPath.remove()
        }
        this.pointOut = this.obstacle.getExitPoint(this.direction)
        this.angleOut = this.obstacle.getExitAngle(this.direction)
        next.pointIn = next.obstacle.getEntryPoint(next.direction)
        next.angleIn = next.obstacle.getEntryAngle(next.direction)

        const strokeColor = this.view?.connectMode ? this.cfg.params.colors.pathConnect : this.color

        let thisHandleOut: paper.Point, nextHandleIn: paper.Point

        // update forwardPath based on adjusted midPoints
        const segms = []
        if (this.midPoints.length === 0) {
            const dist = this.pointOut.getDistance(next.pointIn) * 0.4
            const handleInLen = (this.direction == Direction.forward ? -1 : 1) * dist
            thisHandleOut = this._buildHandle(handleInLen, this.obstacle, this.angleOut)
            const handleOutLen = (next.direction == Direction.forward ? 1 : -1) * dist
            nextHandleIn = this._buildHandle(handleOutLen, next.obstacle, next.angleIn)
            segms.push([this.pointOut, [0, 0], thisHandleOut])
            segms.push([next.pointIn, nextHandleIn, [0, 0]])
            changeMidPointsCount = true
        } else {
            for (let i = 0; i <= this.midPoints.length; i++) {
                if (i == 0) {
                    // first
                    const handleLen = (this.direction == Direction.forward ? -1 : 1) *
                        this.pointOut.getDistance(this.midPoints[i].point) * 0.4
                    thisHandleOut = this._buildHandle(handleLen, this.obstacle, this.angleOut)
                    segms.push([this.pointOut, [0, 0], thisHandleOut])
                    segms.push([this.midPoints[i].point, [0, 0], [0, 0]])
                } else if (i < this.midPoints.length) {
                    // intermediate
                    segms.push([this.midPoints[i].point, [0, 0], [0, 0]])
                } else {
                    // last
                    const handleLen = (next.direction == Direction.forward ? 1 : -1) *
                        next.pointIn.getDistance(this.midPoints[i - 1].point) * 0.4
                    nextHandleIn = this._buildHandle(handleLen, next.obstacle, next.angleIn)
                    segms.push([next.pointIn, nextHandleIn, [0, 0]])
                }
            }
        }

        this.forwardPath = new paper.Path({
            ...pathAttributes,
            strokeColor: strokeColor,
            segments: segms
        })

        this._smoothForwardPath(thisHandleOut!, nextHandleIn!)

        // find moved midpoint and do not allow removing it from forwardPath
        for (let i = 0; i < this.midPoints.length; i++) {
            const mp = this.midPoints[i]
            if (this.view?.selection.isSelected(mp)) {
                this.forwardPath.segments[i + 1].selected = true
            }
        }

        if (changeMidPointsCount) {
            // add midpoints in fragments that are too long
            const newOffsets = []
            for (let si = 1; si < this.forwardPath.segments.length; si++) {
                const s0 = this.forwardPath.segments[si - 1]
                const s1 = this.forwardPath.segments[si]
                const dist = s1.location.offset - s0.location.offset
                // if fragment is too long then estimate offset in the middle
                // and remember it to add a midpoint for it later
                if (dist / MIDPOINT_DISTANCE > 1) {
                    const ofs = s0.location.offset + dist / 2
                    newOffsets.push([si, ofs])
                }
            }
            // first add new points to the forwardPath
            const newMidPoints: [number, paper.Point][] = []
            for (let [idx, ofs] of newOffsets) {
                const sgm = this.forwardPath.divideAt(ofs)
                newMidPoints.push([idx - 1, sgm.point.clone()])
            }
            // and then add midpoints but from the last one going back to the beginning
            // to avoid moving indexes
            for (let [idx, pt] of newMidPoints.reverse()) {
                const mp = new PathMidPoint(pt, PathMidPoint.DEFAULT_HANDLE_FACTOR,
                    this, new paper.Color(this.cfg.params.colors.midPoint), 
                    this.cfg, this.canvas, this.view, true)
                this.midPoints.splice(idx, 0, mp)
            }
        }

        // reduce redundant midpoints where a midpoint does not matter
        if (this.forwardPath.segments.length > 3) {
            const segmsToDel = []
            for (let si = 0; si < this.forwardPath.segments.length - 2; si++) {
                // take 3 consecutive segment points
                const s0 = this.forwardPath.segments[si]
                const s1 = this.forwardPath.segments[si + 1]
                const s2 = this.forwardPath.segments[si + 2]

                if (s1.selected) {
                    continue
                }

                let toDel = false

                // if to subsequent segments are too close to each other then delete one
                if (changeMidPointsCount && (s1.point.getDistance(s0.point) < 100 || s1.point.getDistance(s2.point) < 100)) {
                    toDel = true
                }

                // if not deleted yet then check another deletion condition
                if (!toDel) {
                    // take 3 consecutive segment points, make from them new path and remember its length
                    // remove from that path the middle point and check its new length
                    // if the lenghts are the same then it means that the point in the middle does not matter
                    // and can be removed
                    const p = new paper.Path({
                        segments: [s0.clone(), s1.clone(), s2.clone()]
                    })
                    const len1 = p.length
                    p.removeSegment(1)
                    const len2 = p.length
                    if (p.isInserted()) {
                        p.remove()
                    }
                    if (Math.abs(len2 - len1) < 10) {
                        toDel = true
                    }
                }

                if (toDel) {
                    const idxToDel = si + 1
                    const mp = this.midPoints[idxToDel - 1]
                    // do not remove 2 consecutive points (skip to next)
                    if ((segmsToDel.length == 0 || idxToDel - segmsToDel[0] > 1) && mp.delCount == 0) {
                        // put the index to delete on the front
                        // later delete from the last one going back to the beginning
                        // to avoid problem with moving indexes
                        segmsToDel.unshift(idxToDel)
                    }
                    if (mp.delCount > 0) {
                        mp.delCount -= 1
                    }
                }
            }
            // delete points from the last one going back to the beginning
            // to avoid problem with moving indexes
            for (let idx of segmsToDel) {
                this.forwardPath.removeSegment(idx)
                const removed = this.midPoints.splice(idx - 1, 1)
                removed[0].destroy()
            }
        }

        // calculate approach curvature starting from the last point of the path
        // and taking the curvature of the lastmost segment that is not in the straight line
        // with the obstacle
        // curvature > 0 is right approach, < 0 is left approach, 0 is straight line
        let seg = this.forwardPath.lastSegment
        let curvature = 0
        const straightLimit = 0.0005
        while (seg && Math.abs(curvature) < straightLimit) {
            curvature = this.forwardPath.getCurvatureAt(seg.location.offset)
            seg = seg.previous
        }
        if (Math.abs(curvature) < straightLimit) {
            curvature = 0
        }
        next.approachCurvature = curvature

        // fullySelected is not properly passed from pathAttributes, need to set it explicitly
        this.forwardPath.fullySelected = pathAttributes.fullySelected

        this.pathGroup?.addChild(this.forwardPath)

        const path = this.forwardPath.clone()
        path.strokeScaling = false
        path.strokeWidth = 50 * this.cfg.params.lineWidth
        path.dashArray = []
        path.strokeColor = new paper.Color(0, Number.EPSILON)

        path.onMouseEnter = this.onMouseEnter.bind(this)
        path.onMouseLeave = this.onMouseLeave.bind(this)
        path.bringToFront()
        this.thickPath = path
        this.pathGroup?.addChild(this.thickPath)

        this.distToNext = this.pathLengthFromExit()
        next.distFromPrev = this.distToNext
        next.distFromStart = this.distFromStart + this.pathLengthFromEntry()
        this._drawDistance()
    }

    pathLengthFromEntry(): number {
        return this.pathLengthFromExit() + this.obstacle.objectSize.height
    }

    pathLengthFromExit(): number {
        return this.forwardPath?.length || 0
    }

    private _drawDistance() {
        const drawn = this._drawDistanceInternal(this.cfg.params.showHorseStepLen)
        if (!drawn && this.cfg.params.showHorseStepLen) {
            // try to draw the distance without the horse step lenght, maybe it will fit
            this._drawDistanceInternal(false)
        }
    }

    private _drawDistanceInternal(showHorseStepLength: boolean): boolean {
        if (this.distance && this.distance.isInserted()) {
            this.distance.remove()
        }
        this.distance = undefined

        if (!this.forwardPath) {
            return false
        }
        const path = this.forwardPath
        const lenM = this.distToNext / 100
        let lenTxt = ' ' + this.canvas.conversionService.transform(this.distToNext, {
            from: Unit.CM,
            to: this.cfg.params.distanceUnit,
            rules: this.canvas.conversionService.rulesForDistanceOnPath
        }) + ' '
        let color = this._getPathColor()

        // if distance is not a multiplication of horse step (3.5-4m) plus 1.5m for landing 
        // plus 1.5m for jumping then color it as red ie. warning
        if (this.prev && this.next && !this.next.obstacle.isFinish()) {
            let l = lenM
            let prefix = '', suffix = ''
            let o = this.obstacle
            let n: PathObject | undefined = this.next.obstacle
            // if this object is finish/start, extend the distance with the distance before F/S
            if (o.isFinishStart()) {
                l += this.distFromPrev / 100
                o = this.prev.obstacle
                prefix = '➔'
                this.prev._drawDistance()
            }
            // landing behind current obstacle
            if (o instanceof ObstacleWithBars && o.obstacleWidth > 0) {
                l -= this.cfg.params.landAfterLenM
            }
            // if next obstacle is finish/start, extend the distance with the distance after F/S
            if (n.isFinishStart()) {
                l += this.next.distToNext / 100
                n = this.next.next?.obstacle
                suffix = '➔'
            }
            // jumping in front of the next obstacle
            if (n instanceof ObstacleWithBars && n.obstacleWidth > 0) {
                l -= this.cfg.params.jumpBeforeLenM
            }
            const sMin = Math.ceil(l / this.cfg.params.strideMaxLenM)
            const sMax = Math.floor(l / this.cfg.params.strideMinLenM)
            if (showHorseStepLength) {
                const sMinT = sMin.toFixed(0)
                const sMaxT = sMax.toFixed(0)
                lenTxt += '(' + prefix
                if (sMin > sMax) {
                    lenTxt += '-'
                } else if (sMin < sMax) {
                    lenTxt += sMinT + '-' + sMaxT
                } else {
                    lenTxt += sMinT
                }
                lenTxt += suffix + ')'
            }
            if (sMin > sMax) {
                if (this.cfg.params.warnNotAlignedHorseStep) {
                    color = new paper.Color(this.cfg.params.colors.pathWarn)
                }
                this.warnAboutStrides = true
            } else {
                this.warnAboutStrides = false
            }
        }

        let txt = new OutlinedText({
            content: lenTxt,
            strokeColor: color,
            fontSize: this.cfg.getDistanceFontSize()
        })
        const halfW = (txt.internalBounds.width * 0.85) / 2
        if (halfW * 2 > path.length) {
            if (txt.isInserted()) {
                txt.remove()
            }
            return false
        }

        let offsetBox = 0.5 * path.length
        const pad = 1.1 // padding to midpoint as fraction of text box width
        for (let i = 0; i < this.midPoints.length; i++) {
            const midPoint = this.midPoints[i]
            const marker = midPoint.midPointMarker
            if (marker && marker.visible && (marker.strokeColor || marker.fillColor)) {
                const offsetMp = path.getOffsetOf(midPoint.getPosition())
                const leftMp = offsetMp - midPoint.radius
                const rightMp = offsetMp + midPoint.radius
                const leftBox = offsetBox - halfW
                const rightBox = offsetBox + halfW
                if (leftMp <= rightBox && rightMp >= leftBox) {
                    // overlapping, try to move to the right of the midpoint, if
                    // no space, try to move to the left or resign
                    offsetBox = rightMp + halfW * pad
                    if (offsetBox + halfW > path.length) {
                        offsetBox = leftMp - halfW
                        if (offsetBox < halfW) {
                            if (txt.isInserted()) {
                                txt.remove()
                            }
                            return false
                        }
                    }
                }
            }
        }

        let point
        const testOptions = {
            match: (o: paper.HitResult) => (o.item instanceof paper.Shape || o.item instanceof paper.PointText),
            fill: true,
            stroke: true,
        }
        const w = halfW * 2
        const d = Math.max(path.length - w - offsetBox, offsetBox - w)
        if (d > 0) {
            for (let i = 0; i < d; i += w) {
                if (offsetBox + i < path.length - w) {
                    const p = path.getPointAt(offsetBox + i)
                    const res = this.canvas.getLayer(LayerId.OBSTACLES).hitTestAll(p, testOptions)
                    if (!res || !res.length) {
                        point = p
                        break
                    }
                }
                if (offsetBox - i > w) {
                    const p = path.getPointAt(offsetBox - i)
                    const res = this.canvas.getLayer(LayerId.OBSTACLES).hitTestAll(p, testOptions)
                    if (!res || !res.length) {
                        point = p
                        break
                    }
                }
            }
        }

        if (point) {
            let angle = path.getTangentAt(offsetBox).angle
            angle += (Math.abs(angle) > 90) ? 180 * Math.sign(angle) : 0
            txt.position = point
            txt.rotate(angle)
            this.distance = txt
            this.distance.visible = this.section?.config.distancesVisible || false
            this.distance.insertAbove(this.forwardPath)
        } else {
            if (txt.isInserted()) {
                txt.remove()
            }
            return false
        }
        return true
    }

    clearForwardPath() {
        if (this.thickPath && this.thickPath.isInserted()) {
            if (this.thickPath.onMouseEnter) {
                this.thickPath.onMouseEnter = null
            }
            if (this.thickPath.onMouseLeave) {
                this.thickPath.onMouseLeave = null
            }
            this.thickPath.remove()
            this.thickPath = undefined
        }
        if (this.forwardPath && this.forwardPath.isInserted()) {
            this.forwardPath.remove()
        }
        this.forwardPath = undefined
        if (this.distance && this.distance.isInserted()) {
            this.distance.remove()
        }
        this.distance = undefined
        this.clearMidPoints()
    }

    setLabel(label: string, roundNo: number | undefined) {
        this.label = label
        if (!label || roundNo === undefined || !(this.obstacle instanceof Obstacle)) {
            return
        }
        this.obstacle.addLabel(label, roundNo, this.direction, this.pass)
    }

    private _getDirectionalAngle() {
        return (this.obstacle.angle - (this.direction == Direction.forward ? 0 : 180)) % 360
    }

    isSectionClosing(): boolean {
        return this.obstacle.isFinish() || ((this.flags & NodeFlags.CLOSING) !== 0)
    }

    isLastInSection(): boolean {
        if (this.section && this.section.route.length > 0) {
            return this.section.route[this.section.route.length - 1] === this
        }
        return false
    }

    isInLineAndAlignedAngle(next: ObstaclePathNode): boolean {
        const angle = this._getDirectionalAngle()
        const nextAngle = next._getDirectionalAngle()
        for (let mp of this.midPoints) {
            const mpInLine = areInLine(this.obstacle.getPosition(), angle, mp.getPosition(), angle)
            if (!mpInLine) {
                return false
            }
        }
        const inLine = areInLine(this.obstacle.getPosition(), angle, next.obstacle.getPosition(), nextAngle)
        return inLine
    }

    isInCombinationWith(next: ObstaclePathNode): boolean {
        const dist = Math.round(next.distFromPrev)
        return this.isInLineAndAlignedAngle(next) &&
            (dist >= this.cfg.params.compMinDist * 100) && (dist <= this.cfg.params.compMaxDist * 100)
    }

    adjustPosition(base: paper.Point, old: paper.Point, curr: paper.Point) {
        const dOld = old.subtract(base)
        const dCur = curr.subtract(base)
        const angle = -dCur.getDirectedAngle(dOld)
        for (let i = 0; i < this.midPoints.length; i++) {
            const midPoint = this.midPoints[i]

            // skip midpoints that are in the current selection
            if (this.view?.selection.selectedItems.includes(midPoint)) {
                continue
            }
            // rotate midpoint around base point by the same angle that the current point rotated
            midPoint.rotate(angle, base)

            // move the midpoint along the line between base point and current point proportionally
            // to the distance the current point moved in relation to the base point,
            // keeping the same distance to that line
            const dMid = midPoint.point.subtract(base)
            const mpToCurAngle = dMid.getDirectedAngle(dCur) * Math.PI / 180
            const baseToMpCast = dMid.length * Math.cos(mpToCurAngle)
            const deltaFactor = dCur.length / dOld.length - 1
            let delta = dCur.normalize(deltaFactor * baseToMpCast)

            if (dCur.length < dOld.length) {
                // move the midpoint closer to the line between base point and current point
                const midToLineBaseToCurr = dMid.length * Math.sin(mpToCurAngle)
                const delta2 = dCur.rotate(-90, [0, 0]).normalize(deltaFactor * midToLineBaseToCurr)
                delta = delta.add(delta2)
            }

            midPoint.move(delta)
        }
    }

    destroy() {
        this.clearForwardPath()
        if (this.pathGroup && this.pathGroup.isInserted()) {
            this.pathGroup.remove()
        }
        this.pathGroup = undefined
    }
}
