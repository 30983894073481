import paper from 'paper'
import { DesignNode, Direction, NodeFlags } from '../design.schema'
import { ParkourCanvas } from '../parkour-canvas/parkour-canvas'
import { ParkourConfig } from '../parkour.config'
import { findArray, moveToFrontOfArray, showErrorBox } from '../utils'
import { ParkourObjectGroup } from './detail.group'
import { PathMidPoint, PathMidPointLike } from './detail.path.midpoint'
import { ObstaclePathNode } from './detail.path.node'
import { Section, SectionConfig } from './detail.path.section'
import { Selectable, Selection } from './detail.selection'
import { CircleWithHandle, Connector } from './detail.selectors'
import { UiCommandId } from "./detail.ui.commands.defs"
import { ParkourObjectFactory } from './parkour-object-factory/parkour-object-factory.service'
import { ObstacleWithBars } from './parkour-objects/obstacle-with-bars'
import { PathObject } from './parkour-objects/path-object'
import { Terminal } from './parkour-objects/terminal'
import { DetailComponentInterface } from './detail.component.interface'

type RoutePosition = {
    position: paper.Point,
    entry: paper.Point,
    exit: paper.Point,
}

export function canBeInPath(o: any): boolean {
    if (o instanceof ParkourObjectGroup) {
        for (let c of o.children) {
            if (canBeInPath(c)) {
                return true
            }
        }
    }
    return (o instanceof PathObject) || (o instanceof PathMidPoint)
}

export enum SecondRoundType {
    NONE = 0,
    TWO_PHASE = 1,
    JUMP_OFF = 2
}

type PathConfig = {
    firstRound?: SectionConfig,
    secondRound?: SectionConfig,
    thirdRound?: SectionConfig,
    others?: SectionConfig,
}

export class ObstaclePath {
    // route is the master source of information about the course
    route: ObstaclePathNode[] = []

    // rounds are calculated based on route in each call to updatePath
    sections: Section[] = []
    firstRound?: Section
    secondRound?: Section
    thirdRound?: Section
    secondRoundType: SecondRoundType = SecondRoundType.NONE
    private config: PathConfig

    constructor(
        private cfg: ParkourConfig,
        private canvas: ParkourCanvas,
        private objectFactory: ParkourObjectFactory,
        private view?: DetailComponentInterface) {
        this.config = {
            firstRound: structuredClone(Section.defaultRoundConfig),
            secondRound: structuredClone(Section.defaultRoundConfig),
            thirdRound: structuredClone(Section.defaultRoundConfig),
            others:  structuredClone(Section.defaultRoundConfig),
        }
        this.config.firstRound!.labelsVisible = true
        this.config.secondRound!.labelsVisible = true
        this.config.thirdRound!.labelsVisible = true
    }

    toObstacles(): PathObject[] {
        return this.route.map((x) => x.obstacle)
    }

    includes(o: ObstaclePathNode | PathObject): boolean {
        if (o instanceof ObstaclePathNode) {
            return this.route.includes(o)
        } else {
            return (this.getIndex(o) >= 0)
        }
    }

    toJson(): DesignNode[] {
        return this.route.map(x => {
            return {
                uuid: x.obstacle.uuid as string,
                midPoints: x.midPoints.map(mp => mp.toJson()),
                flags: x.flags,
            } as DesignNode
        })
    }

    add(o: PathObject, midPoints: PathMidPointLike[], clearBackPath: boolean, toTail: boolean, flags: NodeFlags) {
        const n = new ObstaclePathNode(o, midPoints, this.cfg, this.canvas, this.view, flags)
        const lastIdx = this.route.length - 1
        if (this.route.length > 0 && this.route[lastIdx].obstacle.isFinish() && !toTail) {
            this.insertNode(lastIdx, n, clearBackPath)
        } else {
            this.insertNode(this.route.length, n, clearBackPath)
        }
        this.canvas.updateBanners()
    }

    getIndex(o: PathObject, fromIdx?: number): number {
        return this.toObstacles().indexOf(o, fromIdx)
    }

    getNodeIndex(node: ObstaclePathNode, fromIdx?: number): number {
        return this.route.indexOf(node, fromIdx)
    }

    getPathNode(o: PathObject, occurance: number, roundNo?: number): [ObstaclePathNode | null, number] {
        for (let i = 0, n = 0; i < this.route.length; i++) {
            if (this.route[i].obstacle === o && (!roundNo || this.route[i].isRound123(roundNo))) {
                if (++n >= occurance) {
                    return [this.route[i], i]
                }
            }
        }
        return [null, -1]
    }

    getPathNodes(o: PathObject): [ObstaclePathNode, number][] {
        const r: [ObstaclePathNode, number][] = []
        for (let i = 0; i < this.route.length; i++) {
            if (this.route[i].obstacle === o) {
                r.push([this.route[i], i])
            }
        }
        return r
    }

    replaceObject(from: PathObject, to: PathObject) {
        if (from.isStart() || to.isStart()) {
            return
        }
        for (let node of this.route.filter(x => x.obstacle === from)) {
            node.obstacle = to
        }
    }

    // calculate angle that is a tangent to a line pointing from p1
    // to a middle of the angle between p2 and p3
    private _getAlignedAngle(p1: paper.Point, p2: paper.Point, p3: paper.Point) {
        // move p2 and p3 to a coordinate system with p1 in the center of it
        const p2p1 = p2.subtract(p1)
        const p3p1 = p3.subtract(p1)
        const da = p2p1.getDirectedAngle(p3p1)
        return (da > 0 ? 180 : 0) + p2p1.angle + da / 2
    }

    clearPath() {
        for (let i = 0; i < this.route.length;) {
            this.deleteNode(i, false)
        }
    }

    clearPathForward(objects: Selectable[]) {
        for (let o of objects) {
            if (o instanceof PathObject) {
                const nodes = this.getPathNodes(o)
                for (let [n, idx] of nodes) {
                    for (let i = idx + 1; i < this.route.length;) {
                        const d = this.route[i]
                        // stop deleting if second round is here
                        if (d.obstacle.isStart()) {
                            break
                        }
                        const closing = this.route[i].isSectionClosing()
                        this.deleteNode(i, false)
                        if (closing) {
                            break
                        }
                    }
                }
            }
        }
    }

    clearPathBackward(objects: Selectable[]) {
        for (let o of objects) {
            if (o instanceof PathObject) {
                const nodes = this.getPathNodes(o)
                for (let [n, idx] of nodes) {
                    for (let i = idx - 1; i >= 0; i--) {
                        const d = this.route[i]
                        if (d.isSectionClosing()) {
                            break
                        }
                        this.deleteNode(i, false)
                    }
                }
            }
        }
    }

    splitPathAfter(objects: Selectable[]): boolean {
        let ret: boolean = false
        for (let o of objects) {
            if (o instanceof PathObject) {
                const nodes = this.getPathNodes(o)
                // split after last pass only but not if there is no forward path
                if (nodes.length > 0) {
                    for (let idx = nodes.length - 1; idx >= 0; idx--) {
                        const [n, i] = nodes[idx]
                        if (n.forwardPath) {
                            ret = this.splitPathAfterNode(i) || ret
                            break
                        }
                    }
                }
            }
        }
        return ret
    }

    autoRotate(o: PathObject) {
        const [n, idx] = this.getPathNode(o, 1)
        if (!n || idx <= 0 || idx >= this.route.length - 1) {
            return
        }
        const p0 = this.route[idx - 1]
        const p1 = this.route[idx]
        const p2 = this.route[idx + 1]
        const last0 = p0.midPoints.length - 1
        let angle = this._getAlignedAngle(o.getPosition(),
            p0.midPoints.length > 0 ? p0.midPoints[last0].point : p0.obstacle.getPosition(),
            p1.midPoints.length > 0 ? p1.midPoints[0].point : p2.obstacle.getPosition())
        if (o.getDirection(p1.getRound123() || 1, 1) === Direction.backward) {
            angle = (angle - 180) % 360
        }
        o.rotate(angle - o.angle)
    }

    delete(o: PathObject, roundNo?: number) {
        let i, j = 0
        while ((i = this.getIndex(o, j)) >= 0) {
            if (!roundNo || this.route[i].getRound123() === roundNo) {
                this.deleteNode(i, true)
                j = i
            } else {
                j = i + 1
            }
        }
        this.canvas.updateBanners()
    }

    deleteNode(idx: number, mergeForwardPaths: boolean) {
        if (!mergeForwardPaths) {
            this.route[idx].clearForwardPath()
            if (idx > 0) {
                this.route[idx - 1].clearForwardPath()
            }
        } else if (idx > 0) {
            const n0 = this.route[idx - 1]
            const n1 = this.route[idx]
            if (n0.forwardPath && n1.forwardPath) {
                // add a midpoint where the the deleted node was
                n0.midPoints.push(
                    new PathMidPoint(n1.obstacle.getPosition(),
                        PathMidPoint.DEFAULT_HANDLE_FACTOR, n0, new paper.Color(this.cfg.params.colors.midPoint), 
                        this.cfg, this.canvas, this.view))
                // merge forward paths of previous node and this node into the previous node
                n1.midPoints.forEach(p => p.parent = n0)
                n0.midPoints = n0.midPoints.concat(n1.midPoints)
                n1.midPoints = []
            } else if (n0.forwardPath && !n1.forwardPath) {
                // n0 becomes last in the path after n1 is removed
                n0.clearForwardPath()
            }
            n1.clearForwardPath()
        }

        const n = this.route[idx]
        if (n.isSectionClosing() && idx > 0) {
            const p = this.route[idx - 1]
            if (!p.isSectionClosing()) {
                p.flags |= NodeFlags.CLOSING
            }
        }
        this.route.splice(idx, 1)
        this.cleanupRoute()
    }

    // for a given node, connects the rest of the obstacles in the group, if:
    // - the group is a combination
    // - the node is on the either end of the combination
    // returns true if group was connected
    //
    private connectGroup(node: ObstaclePathNode, toConnector?: Connector, connectFrom?: boolean): boolean {
        // if obstacle is first or last in a combination group, connect the whole group
        const group = node.obstacle.parentSelector
        const idx = this.getNodeIndex(node)
        let comb = undefined
        const round = node.getRound123() || 1
        if (group instanceof ParkourObjectGroup && group.combination.length > 1) {
            if (node.obstacle === group.combination[0]) {
                comb = group.combination.slice()
            } else if (node.obstacle === group.combination[group.combination.length - 1]) {
                comb = group.combination.slice().reverse()
            }
            if (comb && comb.length > 1) {
                const use = comb.reduce((p, o) => p + o.useCount[round - 1], 0)
                if (use === 0) {
                    const pos = node.getPosition()
                    const opposite = pos.add(pos.subtract(comb[1].getPosition()))
                    // if a connection is to a connector, check that it is the one at the edge of the group
                    let minDist = 0, conDist = 0
                    if (toConnector) {
                        conDist = toConnector.position.getDistance(opposite)
                        minDist = node.obstacle.selector.connectors.reduce((d, c) =>
                            Math.min(c ? c.position.getDistance(opposite) : Infinity, d), Infinity)
                    }
                    if (!toConnector || conDist <= minDist) {
                        for (let i = comb.length - 1; i >= 0; i--) {
                            if (!connectFrom) {
                                if (i >= 1) {
                                    this.insertAfterNode(idx, new ObstaclePathNode(comb[i], [], this.cfg, this.canvas), false)
                                }
                                comb[i].setDirectionAway(opposite, round)
                            } else {
                                if (i >= 1) {
                                    this.insertBeforeNode(idx, new ObstaclePathNode(comb[i], [], this.cfg, this.canvas), false)
                                }
                                comb[i].setDirectionTowards(opposite, round)
                            }
                        }
                        // select all obstacles in the combination in sequence, so user can continue connecting
                        if (this.view && this.view.selection.isSelected(comb[0])) {
                            for (let j = 1; j < comb.length; j++) {
                                if (!connectFrom) {
                                    this.view.selection.selectItem(comb[j], undefined, (j === comb.length - 1), comb[j - 1])
                                } else {
                                    this.view.selection.selectItem(comb[j], undefined, false, undefined, comb[j - 1])
                                }
                            }
                            this.view.selection.keepSelected = true
                        }
                        return true
                    }
                }
            }
        }

        return false
    }
    
    connectToGroup(node: ObstaclePathNode, toConnector?: Connector): boolean {
        return this.connectGroup(node, toConnector, false)
    }

    connectFromGroup(node: ObstaclePathNode, toConnector?: Connector): boolean {
        return this.connectGroup(node, toConnector, true)
    }

    // if obstacle of the node has not been connected yet, try to set its direction in a way that
    // the entry of the obstacle faces the previous obstacle on the path
    //
    setOrientationToPrev(node: ObstaclePathNode) {
        const idx = this.getNodeIndex(node)
        const round = node.getRound123() || 1
        if (node.obstacle.isObstacle() && idx > 0) {
            const prev = this.route[idx - 1]
            if (prev !== node && node.obstacle.useCount[round - 1] === 0) {
                node.obstacle.setDirectionAway(prev.obstacle.getPosition(), round)
            }
        }
    }

    setOrientationToNext(node: ObstaclePathNode) {
        const idx = this.getNodeIndex(node)
        const round = node.getRound123() || 1
        if (node.obstacle.isObstacle() && idx < this.route.length - 1) {
            const next = this.route[idx + 1]
            if (next !== node && node.obstacle.useCount[round - 1] === 0) {
                node.obstacle.setDirectionTowards(next.obstacle.getPosition(), round)
            }
        }
    }

    // default behavior of insertNode is to insert node after previous node, i.e.
    // if the previous node is last is section, the node being inserted will extend this section
    // and will become the last node
    // this behavior can be changed by setting beforeCurrent to true, in that case, if the previous
    // node is last in section, the node being inserted will extend the next section and will become
    // the first node in that section
    private insertNode(idx: number, node: ObstaclePathNode, clearBackPath: boolean)
    {
        const o = node.obstacle
        // allow inserting F/S only to the first round
        if (o.isFinishStart() && idx > 0) {
            const p = this.route[idx - 1]
            if (p.forwardPath && p.getRound123() !== 1) {
                return
            }
        }

        // the node previous to the inserted will have a new path and midpoint
        if (idx > 0) {
            const prev = this.route[idx - 1]
            if (clearBackPath) {
                prev.clearForwardPath()
            }
        }
        this.route.splice(idx, 0, node)
        // when F/S is inserted, or second Start, all nodes after it will change round from 1 to 2
        // copy the pass directions from round 1 to round 2 and 3
        if (o.isFinishStart() || o.isStart() && this.firstRound && !this.secondRound && !this.thirdRound) {
            for (let i = idx; i < this.route.length; i++) {
                const obj = this.route[i].obstacle
                if (!obj.isTerminal()) {
                    obj.copyDirectionsToOtherRounds(1)
                }
                // stop when reached end of section/round
                if (node.isSectionClosing()) {
                    break
                }
            }
        }
    }

    // insert node after idx node, the new node will belong to the section idx node belongs to
    // when idx node is the last in section, the inserted node will become last node
    // when idx points to route.length, the new node will be inserted at the end of route
    // and will begin a new sectiom
    insertAfterNode(afterIdx: number, newNode: ObstaclePathNode, clearBackPath: boolean) {
        if (afterIdx >= 0 && afterIdx < this.route.length) {
            this.insertNode(afterIdx + 1, newNode, clearBackPath)
            const node = this.route[afterIdx]
            // if prev has closing flag, move it to this node
            if (node.flags & NodeFlags.CLOSING) {
                newNode.flags |= NodeFlags.CLOSING
                node.flags &= ~NodeFlags.CLOSING
            }
        } else if (afterIdx === 0 && this.route.length === 0) {
            // first node on route
            this.insertNode(afterIdx, newNode, clearBackPath)
        } else if (afterIdx === this.route.length) {
            this.insertBeforeNode(afterIdx, newNode, clearBackPath)
        }
    }

    // insert node before idx node, the new node will belong to the section idx node belongs to
    // when idx node is first in section, the inserted node will become first node
    // when idx points to route.length, the new node will be inserted at the end of route
    // and will begin a new sectiom
    insertBeforeNode(beforeIdx: number, newNode: ObstaclePathNode, clearBackPath: boolean) {
        const len = this.route.length
        if (beforeIdx >= 0 && beforeIdx <= len) {
            this.insertNode(beforeIdx, newNode, clearBackPath)
            if (len > 0) {
                const node = this.route[len - 1]
                if (beforeIdx === len && !node.isSectionClosing()) {
                    node.flags |= NodeFlags.CLOSING
                }
            }
        }
    }

    splitPathAfterNode(afterIdx: number): boolean {
        if (afterIdx >= 0 && afterIdx < this.route.length - 1) {
            const node = this.route[afterIdx]
            if (!node.isSectionClosing()) {
                // find if current obstacle is in cycle - i.e. any obstacle after it
                // has a path going backwards to one of the obstacles before or the current obstacle
//                if (this.isInCycle(node)) {
//                    showErrorBox(this.view.msgSvc, $localize`Dzielenie ścieżki`, 
//                    $localize`Nie można podzielić trasy, gdy istnieją jeszcze inne trasy między oddzielanymi częściami.`)
//                    return false
//                }
                node.flags |= NodeFlags.CLOSING
                node.clearForwardPath()
                this.cleanupRoute()
                return true
            }
        }
        return false
    }

    clear() {
        this.sections.forEach(r => r.clear())
        this.sections = []
        this.route.forEach(n => n.destroy())
        this.route.length = 0
        this.secondRoundType = SecondRoundType.NONE
    }

    private _getRoundLabels(route: ObstaclePathNode[] | undefined): string[] {
        return route?.filter(v => v.obstacle.isObstacle()).map(n => n.label) || []
    }

    getSecondRoundLabels(): string[] {
        return this._getRoundLabels(this.secondRound?.route)
    }

    getThirdRoundLabels(): string[] {
        return this._getRoundLabels(this.thirdRound?.route)
    }

    private _setRoundConfig(from?: SectionConfig, to?: SectionConfig) {
        if (to && from) {
            for (let k of Object.keys(from)) {
                (to as any)[k] = (from as any)[k]
            }
        }
    }

    setConfig(config: PathConfig) {
        this._setRoundConfig(config.firstRound, this.config.firstRound)
        this.firstRound?.setConfig(this.config.firstRound)
        this._setRoundConfig(config.secondRound, this.config.secondRound)
        this.secondRound?.setConfig(this.config.secondRound)
        this._setRoundConfig(config.thirdRound, this.config.thirdRound)
        this.thirdRound?.setConfig(this.config.thirdRound)
        this._setRoundConfig(config.others, this.config.others)
        for (let r of this.sections) {
            if (r !== this.firstRound && r !== this.secondRound && r !== this.thirdRound) {
                r.setConfig(this.config.others)
            }
        }
    }

    getConfig(): PathConfig {
        return this.config
    }

    updateLabelsVisibility() {
        this.setConfig({
            firstRound: {
                labelsVisible: this.config.firstRound?.labelsVisible
            },
            secondRound: {
                labelsVisible: this.config.secondRound?.labelsVisible
            },
            thirdRound: {
                labelsVisible: this.config.thirdRound?.labelsVisible
            },
            others: {
                labelsVisible: this.config.others?.labelsVisible
            }
        })
    }

    private finishConnectingTo(node: ObstaclePathNode) {
        // adjust the rest of obstacles in the group with the added node or adjust obstacle orientation
        const ret = this.connectToGroup(node)
        if (!ret) {
            // only when there is no group adjustment
            this.setOrientationToPrev(node)
        }
    }

    private finishConnectingFrom(node: ObstaclePathNode) {
        // adjust the rest of obstacles in the group with the added node or adjust obstacle orientation
        const ret = this.connectFromGroup(node)
        if (!ret) {
            // only when there is no group adjustment
            this.setOrientationToNext(node)
        }
    }

    // The connection algorithm is the following:
    // - if FROM not on path
    //     - if TO on path
    //         - insert new FROM node before TO
    //     - if TO not on path (else)
    //         - create new section with FROM->TO nodes at the end of route
    // - if FROM on path (else)
    //     - if TO not on path
    //         - insert new TO node after FROM
    //     - if TO on path (else)
    //         - if FROM in round 1 or 2
    //             - if TO not in that round
    //                 - if TO in other round
    //                     - insert new TO node after FROM
    //                 - if TO is opening a non-round section (else)
    //                     - merge that section nodes after FROM
    //             - if TO in that round once
    //                 - insert new TO node after FROM
    //             - if TO in that round twice (else)
    //                 - don't connect, all passes exhausted
    //         - if FROM in non-round section (else)
    //             - if TO in one or both rounds
    //                 - if FROM is closing its section
    //                     - merge that section nodes before TO
    //             - if FROM in non-round section (else)
    //                 - if FROM and TO are from two different sections
    //                     - if FROM is closing the section and TO is opening the section
    //                         - merge FROM nodes after TO nodes
    //
    connectTwo(o1: PathObject, o2: PathObject) {
        const nodes1 = this.getPathNodes(o1)
        const nodes2 = this.getPathNodes(o2)
        // o1 is not on path
        if (nodes1.length === 0) {
            // o1 is not on path, o2 on path
            // insert o1 before o2 in the first round, first pass
            if (nodes2.length > 0) {
                const i2 = nodes2[0][1]
                if (i2 >= 0) {
                    const node =  new ObstaclePathNode(o1, [], this.cfg, this.canvas)
                    this.insertBeforeNode(i2, node, true)
                    this.finishConnectingTo(node)
                    return
                }
            }
            // both obstacles not on path - initiate a new section with the two obstacles
            const node1 = new ObstaclePathNode(o1, [], this.cfg, this.canvas)
            const node2 = new ObstaclePathNode(o2, [], this.cfg, this.canvas)
            const idx = this.route.length
            this.insertAfterNode(idx, node1, true)
            this.insertAfterNode(idx, node2, true)
            if (o1.parentSelector !== o2.parentSelector) {
                this.finishConnectingTo(node2)
                this.finishConnectingFrom(node1)
            } else {
                this.setOrientationToPrev(node2)
                this.setOrientationToNext(node1)
            }
            return
        }
        // determine node for the last round and last pass for o1
        const [n1, i1] = nodes1[nodes1.length - 1]
        // o1 is on path, o2 is not on path
        if (nodes2.length === 0) {
            // insert o2 after o1
            const node =  new ObstaclePathNode(o2, [], this.cfg, this.canvas)
            this.insertAfterNode(i1, node, true)
            this.finishConnectingTo(node)
            return
        }
        // o1 and o2 are both on path
        // allow connecting between round 1 and 2 or only within the same section
        const r1 = n1.getRound123()
        if (r1 !== undefined) {
            // o1 is in some round, try to find o2 nodes already on the same round
            const nr2 = nodes2.filter(i => i[0] && i[0].getRound123() === r1)
            if (nr2.length === 0) {
                // o2 not in r1
                // find if o2 belongs to the other round
                const nr2o = nodes2.filter(i => i[0] && i[0].getRound123())
                if (nr2o.length > 0) {
                    // o2 is in the other round, insert a new node for r1
                    const node =  new ObstaclePathNode(o2, [], this.cfg, this.canvas)
                    this.insertAfterNode(i1, node, true)
                    return
                } else {
                    // o2 is in some other section
                    const n2 = nodes2[0][0]
                    if (n2.isSectionOpening() && n1.section && n2.section) {
                        // n2 is opening a section, merge section after n1
                        this.merge(n1.section, n2.section, n1)
                    }
                    // don't connect to other node than opening a section
                    return
                }
            } else if (nr2.length === 1) {
                // o2 has one pass in r1
                // insert a new node for the second pass
                const node =  new ObstaclePathNode(o2, [], this.cfg, this.canvas)
                this.insertAfterNode(i1, node, true)
            }
            // o2 has two passes in r1 already - don't connect
            return
        }
        // o1 is not in round, it must be in some other section
        const nr2 = nodes2.filter(i => i[0] && i[0].getRound123())
        if (nr2.length > 0) {
            // o2 is in one or both rounds
            // take the node in last round and last pass
            const [n2, ] = nr2[nr2.length - 1]
            if (n2 && n2.prev && n2.prev.section && n1.section) {
                // if n1 is closing its section, merge the section before n2
                if ((n1.isSectionClosing() || n1.isLastInSection()) && !n1.obstacle.isTerminal() && !n1.obstacle.isFinishStart()) {
                    this.merge(n2.prev.section, n1.section, n2.prev)
                }
            }
            return
        }
        // o2 and o1 both are in non-round section
        // if o1 is closing its section and o2 is opening its section and these are different sections
        const [n2, ] = nodes2[0]
        if (n1.section && n2.section && n1.section !== n2.section) {
            if ((n1.isSectionClosing() || n1.isLastInSection()) && n2.isSectionOpening()) {
                this.merge(n1.section, n2.section, n1)
            }
        }
    }

    connect(selection: Selection): boolean {
        // it is possible to select start only as first in route
        // it is possible to select finish only as last in route, no more obstacles will be
        // selected after finish
        let finish = false
        const s = selection.getSelectedOfType(PathObject).filter((x, i, a) => {
            if (!finish) {
                if (x.isFinish()) {
                    finish = true
                    return true
                }
                if (canBeInPath(x) && !x.isTerminal() || x.isStart() && i == 0) {
                    return true
                }
            }
            selection.deselectItem(x)
            selection.keepSelected = true
            return false
        })
        if (s.length <= 1) {
            return false
        }
        if (s.length > 1) {
            this.connectTwo(s[s.length - 2], s[s.length - 1])
        }
        return true
    }

    buildMidPointsFromPath(forwardPath: paper.Path): PathMidPointLike[] {
        const len = forwardPath.length
        const numPoints = Math.floor(len / 1000)
        if (numPoints > 0) {
            const midPoints: PathMidPointLike[] = []
            const path = forwardPath.clone()
            const step = 1 / (numPoints + 1)
            for (let i = step; i < 1; i += step) {
                path.divideAt(i * len)
            }
            for (let i = 1; i < path.segments.length - 1; i++) {
                const s = path.segments[i]
                midPoints.push({
                    x: s.point.x,
                    y: s.point.y,
                    handleFactor: PathMidPoint.DEFAULT_HANDLE_FACTOR
                })
            }
            if (path.isInserted()) {
                path.remove()
            }
            return midPoints
        }
        return []
    }

    isConnectionPossible(from: Connector, to: Connector): [number, number | undefined] {
        return this.connectObstacles(from, to, true)
    }

    private _connectObstaclesWithNewNode(fromNode: ObstaclePathNode, fromNodeIdx: number, 
        toObj: PathObject, to: Connector, forwardPath?: paper.Path, connectThrough?: boolean) {
        if (forwardPath) {
            fromNode.setMidPoints(this.buildMidPointsFromPath(forwardPath))
        }
        const newNode = new ObstaclePathNode(toObj, [], this.cfg, this.canvas)
        this.insertAfterNode(fromNodeIdx, newNode, false)

        let ret = false
        if (!connectThrough) {
            ret = this.connectToGroup(newNode, to)
        }

        // when obstacle is not connected at all in this round, align its direction with the 'to' connector, so that direction
        // becomes entry of the obstacle in that round
        if (!ret) {
            to.alignDirectionAsEntry(fromNode.section?.round123 || 1)
        }
    }

    private _connectObstaclesInRound(fromNode: ObstaclePathNode, fromNodeIdx: number,
        from: Connector, to: Connector, checkOnly?: boolean, forwardPath?: paper.Path, connectThrough?: boolean): boolean {

        const toObj = to.parent.obj
        const toNodes = this.getPathNodes(toObj)
        const toIsOnPath = (toNodes.length > 0)
        const fromRound = fromNode.section?.round123
        const toIsEntry = to.isEntry(fromRound)
        const toIsFS = toObj.isFinishStart()
        let toInRounds: boolean = false
        for (let [n, ] of toNodes) {
            if (n.isRound123()) {
                toInRounds = true
            } else {
                toInRounds = false
                break
            }
        }

        // don't allow to first obstacle in its section - open round route without a start
        // because it is not natural to connect something to the beginning of a section as a different round
        for (let [n, ] of toNodes) {
            if (n.isRound123() && n.section) {
                if (n.section.route.length > 0 && n.section.route[0] === n && toInRounds && to.isEntry()) {
                   return false
                }
            }
        }
        // allow only from the exit connector in round 1 or 2
        if (!fromRound || !from.isExit(fromRound)) {
            return false
        }

        // 1.2: -> completely disconnected finish
        let q: boolean = !toIsOnPath && toObj.isFinish()

        // 1.3: -> completely disconnected finish/start in round 1
        q ||= !toIsOnPath && toIsFS && toIsEntry && fromRound === 1

        // 1.1: -> disconnected obstacle in that round
        // 1.4: -> obstacle connected in that round, but free in the opposite direction
        q ||= !toObj.isTerminal() && !toIsFS && !toIsEntry && (toInRounds || !toIsOnPath)

        if (q) {
            if (checkOnly) {
                return true
            }
            this._connectObstaclesWithNewNode(fromNode, fromNodeIdx, toObj, to, forwardPath, connectThrough)
            return true
        }
        return false
    }

    private _connectObstaclesInSection(fromNode: ObstaclePathNode, fromNodeIdx: number,
        from: Connector, to: Connector, checkOnly?: boolean, forwardPath?: paper.Path,
        connectThrough?: boolean): boolean {

        const toObj = to.parent.obj
        const toNodes = this.getPathNodes(toObj)
        const toIsOnPath = (toNodes.length > 0)
        const fromRound = fromNode.section?.round123
        const toIsEntry = to.isEntry(fromRound)
        const fromSectionIdx = fromNode.section?.index || -1
        let toSectionIdx: number = -1
        for (let [n, ] of toNodes) {
            if (!n.section) {
                toSectionIdx = -1
                break
            }
            if (toSectionIdx < 0) {
                toSectionIdx = n.section.index
            } else if (toSectionIdx !== n.section.index) {
                toSectionIdx = -1
                break
            }
        }
        let toInAnyRound: boolean = false
        for (let [n, ] of toNodes) {
            if (n.isRound123()) {
                toInAnyRound = true
                break
            }
        }

        // allow only from the exit connector in a section that is not a round and to a node that is not a round
        if (fromRound || toInAnyRound || !from.isExit()) {
            return false
        }

        // don't allow if these are two different sections
        if (toSectionIdx >= 0 && fromSectionIdx >= 0 && toSectionIdx !== fromSectionIdx) {
            return false
        }

        // 2.1: -> completely disconnected finish
        let q: boolean = !toIsOnPath && toObj.isFinish()

        // 2.2: -> disconnected obstacle
        // 2.3: -> obstacle connected in that section, but free in the opposite direction
        q ||= !toObj.isTerminal() && (!toObj.isFinishStart() && !toIsEntry)
        q ||= toObj.isFinishStart() && toObj.useCount[0] === 0 && to.isEntry()

        if (q) {
            if (checkOnly) {
                return true
            }
            this._connectObstaclesWithNewNode(fromNode, fromNodeIdx, toObj, to, forwardPath, connectThrough)
            return true
        }
        return false
    }

    private _connectSeparateSections(fromNode: ObstaclePathNode, fromNodeIdx: number,
        from: Connector, to: Connector, checkOnly?: boolean, forwardPath?: paper.Path, connectThrough?: boolean): boolean {

        const toObj = to.parent.obj
        const toNodes = this.getPathNodes(toObj)
        const fromRound = fromNode.getRound123()
        const fromSectionIdx = fromNode.section ? fromNode.section.index : -1
        // we're interested in the first occurance of to node, i.e. the entry into a section
        const [toNode, ] = toNodes.length > 0 ? toNodes[0] : [undefined, ]
        const toSectionIdx = toNode?.section?.index !== undefined ? toNode.section.index : -1
        const toInAnyRound = toNode?.isRound123() || false

        // 3.x: from end node of any section (including rounds) to entry node of a non-round section
        // allow only from the exit connector of any section and to a section that is not a round
        if (!from.isExit(fromRound) || toNode?.obstacle.isFinishStart() && toNode?.obstacle.useCount[0] > 0) {
            return false
        }

        // allow only to the entry of the to obstacle of another section
        if (toNode !== undefined && toNode.isSectionOpening() && to.isEntry() && 
            toNode.section && toSectionIdx >= 0 && fromNode.section && fromSectionIdx >= 0 && 
            toSectionIdx !== fromSectionIdx) {
            if (checkOnly) {
                return true
            }
            if (forwardPath) {
                fromNode.setMidPoints(this.buildMidPointsFromPath(forwardPath))
            }
            // merge two sections
            this.merge(fromNode.section, toNode.section)
            return true
        }
        return false
    }

    private _connectDisconnectedObstacles(from: Connector, to: Connector, checkOnly?: boolean, forwardPath?: paper.Path, connectThrough?: boolean): boolean {
        const toObj = to.parent.obj
        const fromObj = from.parent.obj
        if (toObj.useCountAll > 0 || fromObj.useCountAll > 0 || fromObj.isFinish() || toObj.isStart() || to.isExit()) {
            return false
        }
        if (checkOnly) {
            return true
        }
        // both obstacles not on path - initiate a new section with the two obstacles
        const toNode = new ObstaclePathNode(toObj, [], this.cfg, this.canvas)
        const fromNode = new ObstaclePathNode(fromObj, [], this.cfg, this.canvas)
        const idx = this.route.length
        this.insertAfterNode(idx, fromNode, true)
        this.insertAfterNode(idx, toNode, true)
        if (forwardPath) {
            fromNode.setMidPoints(this.buildMidPointsFromPath(forwardPath))
        }
        if (fromObj.parentSelector !== toObj.parentSelector && !connectThrough) {
            if (!this.connectToGroup(toNode)) {
                to.alignDirectionAsEntry(1)
            }
            if (!this.connectFromGroup(fromNode)) {
                from.alignDirectionAsExit(1)                
            }
        } else {
            to.alignDirectionAsEntry(1)
            //toObj.copyDirectionsToOtherRoundsIfNotUsed(1)
            from.alignDirectionAsExit(1)
            //fromObj.copyDirectionsToOtherRoundsIfNotUsed(1)
        }
        return true
    }

    private _connectDisconnectedToSection(toNode: ObstaclePathNode, toNodeIdx: number,
        from: Connector, to: Connector, checkOnly?: boolean, forwardPath?: paper.Path, connectThrough?: boolean): boolean {

        const fromObj = from.parent.obj
        const toObj = toNode.obstacle
        if (fromObj.useCountAll > 0 || !toNode.isSectionOpening() || !to.isEntry() ||
            (fromObj.isFinishStart() && !from.isExit()) || 
            (toObj.isFinishStart() && toNode.obstacle.useCount[0] > 0) ||
            fromObj.isFinish()) {
            return false
        }
        if (checkOnly) {
            return true
        }
        const fromNode = new ObstaclePathNode(fromObj, [], this.cfg, this.canvas)
        if (forwardPath) {
            fromNode.setMidPoints(this.buildMidPointsFromPath(forwardPath))
        }
        this.insertBeforeNode(toNodeIdx, fromNode, true)
        let ret = false
        if (!connectThrough) {
            ret = this.connectFromGroup(fromNode)
        }
        if (!ret) {
            from.alignDirectionAsExit(1)
        }
        return true
    }

    // Scenario 1.x: from end-of-round (round 1 or 2) obstacle ->
    //          1.1:    -> disconnected obstacle in that round
    //          1.2:    -> completely disconnected finish
    //          1.3:    -> completely disconnected finish/start in round 1
    //          1.4:    -> obstacle connected in that round, but free in the opposite direction
    //
    // Scenario 2.x: from end of non-round section to the same section or to acompletely disconnected obstacle
    //
    // Scenario 3.x: from end node of any section (including rounds) to entry node of a non-round section
    //
    // Scenario 4.x: from a disconnected obstacle
    //          4.1: from disconnected obstacle to disconnected obstacle
    //          4.2: from disconnected obstacle to opening of a non-round section
    //
    // returns [0]: 0 when no connection is possible
    //              1 when connection forward is possible
    //             -1 when connection backwards is possible
    // returns [1]: section index or -1 when no section
    //
    connectObstacles(from: Connector, to: Connector, checkOnly?: boolean, forwardPath?: paper.Path, connectThrough?: boolean): [number, number | undefined] {
        let sectionIdx = this._connectObstacles(from, to, checkOnly, forwardPath, connectThrough)
        if (sectionIdx === undefined || sectionIdx >= 0) {
            return [1, sectionIdx]
        }
        const reversedPath = forwardPath?.clone()
        reversedPath?.reverse()
        sectionIdx = this._connectObstacles(to, from, checkOnly, reversedPath, connectThrough)
        if (reversedPath && reversedPath.isInserted()) {
            reversedPath.remove()
        }
        if (sectionIdx === undefined || sectionIdx >= 0) {
            return [-1, sectionIdx]
        }
        return [0, -1]
    }

    // returns section index or undefined where connection is made or -1 if connection not possible
    private _connectObstacles(from: Connector, to: Connector, checkOnly?: boolean, forwardPath?: paper.Path, connectThrough?: boolean): number | undefined {
        const fromObj = from.parent.obj
        const fromNodes = this.getPathNodes(fromObj)
        const fromNodesAsLast = fromNodes.filter(n => !n[0].forwardPath) // nodes which are last in some round

        // scenarios 1.x, 2.x, 3.x
        let result = false, sectionIdx = -1
        for (let node of fromNodesAsLast) {
            result ||= this._connectObstaclesInRound(node[0], node[1], from, to, checkOnly, forwardPath, connectThrough)
            result ||= this._connectObstaclesInSection(node[0], node[1], from, to, checkOnly, forwardPath, connectThrough)
            result ||= this._connectSeparateSections(node[0], node[1], from, to, checkOnly, forwardPath, connectThrough)
            if (result) {
                sectionIdx = node[0].section ? node[0].section.index : - 1
                if (checkOnly) {
                    return sectionIdx
                }
                break
            }
        }
        if (!checkOnly && result) {
            this.updateSimplifyPathAndSave()
            return sectionIdx
        }

        // scenarios 4.x
        const toObj = to.parent.obj
        if (fromObj.useCountAll === 0) {
            result ||= this._connectDisconnectedObstacles(from, to, checkOnly, forwardPath, connectThrough)
            if (result) {
                if (!checkOnly) {
                    this.updateSimplifyPathAndSave()
                }
                return undefined
            }
            const toNodes = this.getPathNodes(toObj)
            if (toNodes.length === 0) {
                return -1
            }
            const node = toNodes[0]
            result ||= this._connectDisconnectedToSection(node[0], node[1], from, to, checkOnly, forwardPath)
            if (result) {
                if (!checkOnly) {
                    this.updateSimplifyPathAndSave()
                }
                return node[0].section?.index || undefined
            }
        }
        return -1
    }

    private updateSimplifyPathAndSave() {
        for (let i = 0; i < 6; i++) {
            this.canvas.updatePath()
        }
        this.view?.saveData()
    }

    update(changeMidPointsCount?: boolean) {
        //
        // Go through route nodes and determine sections. A section:
        // - begins with one of:
        //     - beginning of the route
        //     - Start object
        //     - F/S object
        //     - node next to a node with CLOSING flag
        // - ends with one of:
        //     - end of the route
        //     - Finish object
        //     - F/S object
        //     - node with CLOSING flag
        //
        this.sections.forEach(s => s.destroy())
        this.sections.length = 0
        this.firstRound = this.secondRound = this.thirdRound = undefined
        this.secondRoundType = SecondRoundType.NONE
        if (this.route.length === 0) {
            return
        }
        let startIdx = 0, nextStartIdx = -1, endIdx = -1
        for (let i = 0; i < this.route.length; i++) {
            const n = this.route[i]
            const o = n.obstacle
            if (n.isSectionClosing() || 
                i < this.route.length - 1 && this.route[i + 1].obstacle.isStart() ||
                i === this.route.length - 1) {
                // Close section including current node when: it is finish, it has closing flag set, it is before start or it is end of route
                endIdx = i
                nextStartIdx = i + 1
            } else if (o.isFinishStart()) {
                // Close section including current node and set the next section to start with it too
                 if (startIdx < i) {
                    endIdx = i
                    nextStartIdx = i
                }
            }
            if (endIdx >= 0 && endIdx >= startIdx) {
                // if single object, add only start or F/S
                const objects = this.route.slice(startIdx, endIdx + 1)
                const num = endIdx - startIdx + 1
                if (num > 1) {
                    const round = new Section(this.cfg, this.canvas)
                    round.initialize(objects)
                    this.sections.push(round)
                }
                startIdx = nextStartIdx
                endIdx = -1
            }
        }
        // Determine rounds
        // first round - section ending with F/S or section with first Start
        // second round - section starting with F/S (two phase) or section with second Start (jump-off)
        // third round - section starting with next Start after determining first and second round (always jump-off)
        const ss = this.sections.slice()
        const fsIdx = ss.findIndex(n => n.route[n.route.length - 1].obstacle.isFinishStart())
        if (fsIdx >= 0) {
            this.firstRound = ss[fsIdx]
            this.firstRound.setRoundConfig(1, this.config.firstRound)
            const next = ss.length > fsIdx ? ss[fsIdx + 1] : undefined
            if (next && next.route.length > 0 && next.route[0].obstacle.isFinishStart()) {
                this.secondRound = next
                this.secondRound.setRoundConfig(2, this.config.secondRound)
                this.secondRoundType = SecondRoundType.TWO_PHASE
                ss.splice(fsIdx, 2)
            } else {
                ss.splice(fsIdx, 1)
            }
        } else {
            const startIdx = ss.findIndex(n => n.route.length > 0 && (n.route[0].obstacle.isStart()))
            if (startIdx >= 0) {
                this.firstRound = ss[startIdx]
                this.firstRound.setRoundConfig(1, this.config.firstRound)
                ss.splice(startIdx, 1)
            }
        }
        if (!this.secondRound) {
            const firstFSIdx = ss.findIndex(n => n.route.length > 0 && n.route[0].obstacle.isFinishStart())
            if (firstFSIdx >= 0) {
                this.secondRound = ss[firstFSIdx]
                this.secondRound.setRoundConfig(2, this.config.secondRound)
                this.secondRoundType = SecondRoundType.TWO_PHASE
                ss.splice(firstFSIdx, 1)
            } else {
                const secondStartIdx = ss.findIndex(n => n.route.length > 0 && n.route[0].obstacle.isStart())
                if (secondStartIdx >= 0) {
                    this.secondRound = ss[secondStartIdx]
                    this.secondRound.setRoundConfig(2, this.config.secondRound)
                    this.secondRoundType = SecondRoundType.JUMP_OFF
                    ss.splice(secondStartIdx, 1)
                }
            }
        }
        if (this.firstRound && this.secondRound) {
            const thirdStartIdx = ss.findIndex(n => n.route.length > 0 && n.route[0].obstacle.isStart())
            if (thirdStartIdx >= 0) {
                this.thirdRound = ss[thirdStartIdx]
                this.thirdRound.setRoundConfig(3, this.config.thirdRound)
            }
        }        
        
        // sort so they are in order from first to third
        if (this.thirdRound) {
            moveToFrontOfArray(this.sections, this.thirdRound)
        }
        if (this.secondRound) {
            moveToFrontOfArray(this.sections, this.secondRound)
        }
        if (this.firstRound) {
            moveToFrontOfArray(this.sections, this.firstRound)
        }

        // update distances in rounds
        let distFromStart = 0, distFromPrev = 0
        this.sections.forEach((r, idx) => {
            if (r.route.length > 0) {
                // check if second round and two phase (f/s)
                let lastObstacleInFirstRound
                if (this.firstRound && this.firstRound.route.length > 0) {
                    lastObstacleInFirstRound = this.firstRound.route[this.firstRound.route.length - 1].obstacle
                }
                if (this.secondRound === r && this.secondRoundType === SecondRoundType.TWO_PHASE && this.firstRound && lastObstacleInFirstRound?.isFinishStart()) {
                    // last object of round1 and first object of round2 is the same F/S
                    distFromStart = this.firstRound.route[this.firstRound.route.length - 1].distFromStart
                    distFromPrev = this.firstRound.route[this.firstRound.route.length - 2].distToNext
                } else {
                    distFromStart = distFromPrev = 0
                }
                r.updateStep1(idx, distFromStart, distFromPrev, this.secondRoundType)
                r.length = r.route[r.route.length - 1].distFromStart - distFromStart
            }
        })

        let labelIdx = 1
        this.sections.forEach((r, idx) => {
            if (r.route.length > 0) {
                labelIdx = r.updateStep2(idx, labelIdx, this.secondRoundType, changeMidPointsCount, this.firstRound, this.secondRound)
            }
        })

        // reconstruct the route from sections so it is possible for the user to change the rounds by reconnecting the starts
        const newRoute: ObstaclePathNode[] = []
        this.sections.forEach(s => {
            if (s.route.length > 0) {
                const last = s.route[s.route.length - 1]
                if (!last.obstacle.isFinishStart()) {
                    last.flags |= NodeFlags.CLOSING
                }
            }
            newRoute.push(...s.route)
        })
        for (let i = 0; i < newRoute.length; i++) {
            if (i > 0 && newRoute[i].obstacle.isFinishStart() && newRoute[i - 1].obstacle.isFinishStart()) {
                newRoute.splice(i, 1)
                break
            }
        }
        
        // sanity checks not break the previous route
        const v1 = this._routeSanityCounters(this.route)
        const v2 = this._routeSanityCounters(newRoute)
        let update = true
        for (let i = 0; i < v1.length; i++) {
            if (i === 0 && v1[i] < v2[i] ||
                i > 0 && v1[i] != v2[i]) {
                console.error('New route incorrect', i, v1[i], v2[i])
                update = false
                break
            }
        }
        if (update) {
            this.route = newRoute
        }
    }

    private _routeSanityCounters(route: ObstaclePathNode[]): number[] {
        const v = [0, 0, 0, 0, 0]
        for (let n of route) {
            v[0] += n.obstacle.isStart() ? 1 : 0
            v[1] += n.obstacle.isFinish() ? 1 : 0
            v[2] += n.obstacle.isFinishStart() ? 1 : 0
            v[3] += n.obstacle instanceof ObstacleWithBars ? n.obstacle.bars : 0
            v[4] += !n.obstacle.isStart() ? n.direction : 0
        }
        return v
    }

    // return true when the obstacle associated with the current node is in a cycle, i.e.
    // there is a path from one of the obstacles after it to one of the obstacles before it (including current obstacle)
    isInCycle(node: ObstaclePathNode): boolean {
        // collect obstacles that are before the current node, including it
        // if current node is in round 2, these would be all obstacles in rounds 1 and 2 up to this,
        // because rounds 1 and 2 can be interconnected
        // if current node is in a non-round section, these would be all obstacles in that section up to this
        let search: ObstaclePathNode[]
        if (node.isRound123() && this.secondRoundType === SecondRoundType.TWO_PHASE) {
            search = this.firstRound?.route.concat(this.secondRound?.route || []) || []
            let fs = 0
            for (let i = search.length - 1; i >= 0; i--) {
                if (search[i].obstacle.isFinishStart()) {
                    fs++
                    if (fs > 1) {
                        search.splice(i, 1)
                    }
                }
            }
        } else {
            search = node.section?.route || []
        }
        const idx = search.indexOf(node)
        if (idx < 0 || !node.section) {
            return false
        }
        // split into part to search in and to search for
        const searchFor: PathObject[] = search.splice(idx + 1, search.length - idx - 1).map(n => n.obstacle)
        const searchIn: PathObject[] = search.map(n => n.obstacle)
        // traverse the section behind the node
        for (let i of searchFor) {
            if (searchIn.indexOf(i) >= 0) {
                return true   
            }
        }
        return false
    }

    cleanupRoute() {
        // traverse back to front to allow removing items in iterations
        for (let idx = this.route.length - 1; idx >= 0; idx--) {
            const node = this.route[idx]
            // check if two neighboring nodes point to the same obstacle
            // (a bidirectional obstacle pointing at itself)
            if (idx > 0 && this.route[idx - 1].obstacle === this.route[idx].obstacle && !this.route[idx - 1].isSectionClosing()) {
                // get rid of one of the nodes - better the previous one, so forward path from idx can be preserved
                this.route[idx - 1].clearForwardPath()
                this.route.splice(idx - 1, 1)
                continue
            }
            const route = this.route
            // if node is a single node (section ends before it), remove it, but not if this is Start
            if ((node.isSectionClosing() || idx === route.length - 1 || (idx < route.length - 1 && route[idx + 1].obstacle.isStart())) && 
                (idx === 0 || idx > 0 && route[idx - 1].isSectionClosing())) {
                this.route.splice(idx, 1)
                continue    
            }
        }
    }
  
    merge(to: Section, from: Section, after?: ObstaclePathNode) {
        if (to.route.length === 0 || from.route.length === 0 || (after && after.section !== to)) {
            return
        }
        let fromRoute
        if (from.route[from.route.length - 1].obstacle.isFinishStart()) {
            fromRoute = this.getTwoPhases() || from.route
        } else {
            fromRoute = from.route
        }
        const fromLen = fromRoute.length
        const firstFromIdx = findArray(fromRoute, this.route)
        if (firstFromIdx < 0) {
            return
        }
        this.route.splice(firstFromIdx, fromLen)
        const lastFrom = fromRoute[fromLen - 1]
        const lastTo = to.route[to.route.length - 1]
        const afterTo = after || lastTo
        const lastToIdx = this.route.lastIndexOf(afterTo)
        if (lastToIdx < 0) {
            return
        }
        this.route.splice(lastToIdx + 1, 0, ...fromRoute)
        lastTo.flags &= ~ NodeFlags.CLOSING
        if (!after || after === lastTo) {
            lastFrom.flags |= NodeFlags.CLOSING
        }
    }

    putInCombination(selection: ObstacleWithBars[], distance?: number) {
        let length = selection.length
        if (length < 2) {
            if (this.view) {
                showErrorBox(this.view.msgSvc, $localize`Nie mogę stworzyć szeregu`,
                    $localize`Wybierz przynajmniej dwie przeszkody używając klawisza` + ' ' +
                    this.view.uiCommands.shortcuts.getShortcutLabel(UiCommandId.SELECT_MULTIPLE))
            }
            return
        }
        selection.sort((a, b) => {
            const ia = this.getIndex(a)
            const ib = this.getIndex(b)
            if (ia >= 0 && ib >= 0) {
                return ia - ib
            }
            if (ia >= 0 && ib < 0 || ia < 0 && ib >= 0) {
                return 1
            }
            return -1
        })
        let i0 = selection[0]
        let i1 = selection[length - 1]
        // distance between centers of first and last obstacle in the combination
        let delta = i1.getPosition().subtract(i0.getPosition())
        if (distance !== undefined && distance > 0) {
            delta.length = distance
        } else {
            for (let idx = 1; idx < length; idx++) {
                delta.length -= (selection[idx - 1].obstacleWidth + selection[idx].obstacleWidth) / 2
            }
            delta.length /= length - 1
        }
        let angle = Math.atan2(delta.x, -delta.y)
        angle = angle * 180 / Math.PI;
        for (let idx = 0; idx < length; idx++) {
            let item = selection[idx]
            item.rotate((angle + (item.getArrowDirection() == Direction.forward ? 0 : 180) % 360) - item.angle)
        }
        let pos = i0.getPosition()
        let itemDelta = new paper.Point(0, 0)
        for (let idx = 1; idx < length; idx++) {
            let item0 = selection[idx - 1]
            let item1 = selection[idx]
            const obstacleExtra = (item0.obstacleWidth + item1.obstacleWidth) / 2
            if (distance !== undefined && distance === 0 && itemDelta.length === 0) {
                itemDelta = delta.clone()
                itemDelta.length = obstacleExtra
            } else {
                if (distance !== 0) {
                    itemDelta = itemDelta.add(delta)
                }
                itemDelta.length += obstacleExtra
            }
            item1.setPosition(pos.add(itemDelta))
            const [node, _] = this.getPathNode(item0, 1)
            node?.clearForwardPath()
        }
    }

    // Find node which has a forward path that contains the given point
    whichPathContains(point: paper.Point): [ObstaclePathNode, Section] | undefined {
        for (let r of this.sections) {
            for (let n of r.route) {
                if (n.forwardPath && n.thickPath?.hitTest(point)) {
                    return [n, r]
                }
            }
        }
        return undefined
    }

    //
    // Divide the forward path of a node into two paths, in a place designated by the point.
    // It is assumed the point lays on the path (should be assured prior).
    //  - Removes midpoints which lay inside the obstacle
    //  - Finds midpoint M which lays before the given point on the forwardpath
    //  - Keeps all midpoints up to M in the node
    //  - Returns all midpoints including M and all after it
    //
    divideForwardPath(node: ObstaclePathNode, point: paper.Point, width: number): PathMidPoint[] {
        if (!node.forwardPath) {
            return []
        }
        // offset on path that is nearest to the given point
        const nearestOffset = node.forwardPath.getNearestLocation(point).offset
        // index of the last midpoint before the obstacle
        let lastMpBeforeObstacleIdx
        for (let i = 0; i < node.midPoints.length;) {
            // offset on path of the analysed midpoint
            const mpOffset = node.forwardPath.getOffsetOf(node.midPoints[i].getPosition())
            if (mpOffset) {
                if (mpOffset < nearestOffset - width / 2) {
                    // this midpoint is before the beginning of the obstacle
                    lastMpBeforeObstacleIdx = i
                } else if (mpOffset < nearestOffset + width / 2) {
                    // this midpoint is inside the obstacle, get rid of it (in place)
                    node.midPoints[i].destroy()
                    node.midPoints.splice(i, 1)
                    continue
                } else {
                    // this midpoint is after the obstacle, return the last stored midpoint
                    // before the obstacle
                    break
                }
            }
            i++
        }
        // index where to divide the path
        let sliceIdx = (lastMpBeforeObstacleIdx === undefined) ? 0 : lastMpBeforeObstacleIdx + 1
        // midpoints that go to the new node
        const midPoints = node.midPoints.slice(sliceIdx)
        // midpoints that stay with the previous node
        node.midPoints = node.midPoints.slice(0, sliceIdx)
        return midPoints
    }

    flattenToOneRound() {
        // remove nodes for third and fourth occurances of obstacles on this path
        this.toObstacles().forEach(o => {
            if (o.useCountAll > 2) {
                let node, idx
                while (([node, idx] = this.getPathNode(o, 3))[0]) {
                    this.deleteNode(idx, false)
                }
            }
        })
    }

    detachObstaclesInRound(roundNo: number) {
        for (let i = 0; i < this.route.length;) {
            if (this.route[i].section?.round123 === roundNo) {
                this.deleteNode(i, false)
            } else {
                i++
            }
        }
    }

    tryToAddMidPoint(pv: paper.Point): PathMidPoint | undefined {
        if (!this.canvas.paper) {
            return undefined
        }
        for (let n of this.route) {
            if (!n.forwardPath) {
                continue
            }

            const result = n.forwardPath.hitTest(pv, {
                tolerance: paper.settings.handleSize / (2 * this.canvas.paper.view.zoom) * 2,
                fill: true,
                stroke: true,
                segments: false, // this must be false otherwise segment is returned instead of CurveLocation
                curves: true,
            })
            if (result) {
                const sgm = n.forwardPath.divideAt(result.location)
                if (sgm && sgm.point && sgm.index > 0) {
                    const mp = new PathMidPoint(sgm.point.clone(),
                        PathMidPoint.DEFAULT_HANDLE_FACTOR,
                        n, new paper.Color(this.cfg.params.colors.midPoint),
                        this.cfg, this.canvas, this.view)
                    mp.delCount = 1
                    n.midPoints.splice(sgm.index - 1, 0, mp)
                    return mp
                }
            }
        }
        return undefined
    }

    calculateTurnsCount(roundNo: number): [number, number] {
        let totalLeftTurnsCount = 0
        let totalRightTurnsCount = 0
        for (const n of this.route) {
            if (n.section?.round123 !== roundNo || !n.forwardPath) {
                continue
            }
            let leftTurnsCount = 0
            let rightTurnsCount = 0
            let turnSide = 0
            let totalChangeSincePrevTurn = 0
            //console.info('node', n.forwardPath.length)
            const parts = Math.round(n.forwardPath.length / 500)
            const step = Math.floor(n.forwardPath.length / parts)
            let prevTg = n.forwardPath.getTangentAt(0)
            for (let ofs = step; ofs < n.forwardPath.length; ofs += step) {
                const tg = n.forwardPath.getTangentAt(ofs)
                const diffAngle = prevTg.getDirectedAngle(tg)
                //console.info('ofs', ofs, 'da', diffAngle, prevTg.angle, prevTg.x, prevTg.y, '|', tg.angle, tg.x, tg.y)
                prevTg = tg
                totalChangeSincePrevTurn += diffAngle
                if (Math.abs(diffAngle) > 8 && turnSide * diffAngle < 0) {
                    //console.info('change dir', -turnSide, diffAngle, totalChangeSincePrevTurn)
                    turnSide = 0
                    totalChangeSincePrevTurn = diffAngle
                }
                if (turnSide == 0 && Math.abs(totalChangeSincePrevTurn) > 20) {
                    if (totalChangeSincePrevTurn < 0) {
                        turnSide = -1
                        leftTurnsCount += 1
                    } else {
                        turnSide = 1
                        rightTurnsCount += 1
                    }
                    //console.info('turn', turnSide, totalChangeSincePrevTurn)
                }
            }
            //console.info('left', leftTurnsCount, 'right', rightTurnsCount)
            totalLeftTurnsCount += leftTurnsCount
            totalRightTurnsCount += rightTurnsCount
        }
        return [totalLeftTurnsCount, totalRightTurnsCount]
    }

    getRoutePositions(): RoutePosition[] {
        return this.route.map(n => ({
            position: n.getPosition(),
            entry: n.obstacle.getEntryPoint(n.direction),
            exit: n.obstacle.getExitPoint(n.direction),
        } as RoutePosition))
    }

    // adjust midpoints between selected objects and two neighboring objects on the path
    adjustGroupMidPoints(routeOldPos: RoutePosition[], items: Selectable[]) {
        let adjusting: boolean = false, nextAdjusting
        for (let idx = 0; idx < routeOldPos.length; idx++) {
            const node = this.route[idx]
            const item = node.obstacle
            if (idx == 0) {
                adjusting = items.includes(item)
                continue
            }

            const prev = this.route[idx - 1]
            const prevPos = prev.obstacle.getPosition()
            const itemOldEntryPos = routeOldPos[idx].entry
            nextAdjusting = items.includes(item)
            if (nextAdjusting) {
                if (!adjusting) {
                    // item = first selected on path segment
                    const itemEntryPos = item.getEntryPoint(node.direction)
                    prev.adjustPosition(prevPos, itemOldEntryPos, itemEntryPos)
                }
            } else if (adjusting) {
                // item = first not selected on path segment, prev is last selected
                const itemPos = item.getPosition()
                const prevOldExitPos = routeOldPos[idx - 1].exit
                const prevExitPos = prev.obstacle.getExitPoint(prev.direction)
                prev.adjustPosition(itemPos, prevOldExitPos, prevExitPos)
            }
            adjusting = nextAdjusting
        }
    }

    moveGroup(items: Selectable[], delta: paper.Point) {
        let pathNeedsUpdate: boolean = false
        for (let o of items) {
            o.move(delta)
            // if two consecutive (on path) obstacles are selected, move also midpoints between them
            if (o instanceof PathObject) {
                const nodes: [ObstaclePathNode, number][] = this.getPathNodes(o)
                nodes.forEach(n => {
                    if (n[1] < this.route.length - 1 &&
                        items.includes(this.route[n[1] + 1].obstacle)) {
                        for (let mp of n[0].midPoints) {
                            if (!items.includes(mp)) {
                                mp.move(delta)
                            }
                        }
                    }
                })
            }
            pathNeedsUpdate ||= canBeInPath(o)
        }
        return pathNeedsUpdate
    }

    // handle - object which handle was clicked and dragged
    // group - all objects to rotate
    // angle - angle by which to rotate
    rotateAndScaleGroup(group: Selectable[], center: paper.Point, angle: number, scale: number): boolean {
        if (group.length === 0) {
            return false
        }

        const rotateAndScale = (p: paper.Point, center: paper.Point, angle: number, scale: number): paper.Point => {
            const s = p.rotate(angle, center).subtract(center)
            s.length *= scale
            return center.add(s)
        }

        let pathNeedsUpdate: boolean = false

        for (let o of group) {
            // rotate object position around the group center
            o.setPosition(rotateAndScale(o.getPosition(), center, angle, scale))
            // and rotate the object orientation
            o.rotate(angle)
            // and its midpoints if next object also is selected to rotate
            if (o instanceof PathObject) {
                const nodes: [ObstaclePathNode, number][] = this.getPathNodes(o)
                nodes.forEach(n => {
                    if (n[1] < this.route.length - 1 &&
                        group.includes(this.route[n[1] + 1].obstacle)) {
                        n[0].midPoints.forEach(mp => {
                            if (!group.includes(mp)) {
                                mp.setPosition(rotateAndScale(mp.getPosition(), center, angle, scale))
                            }
                        })
                    }
                })
            }
            pathNeedsUpdate ||= canBeInPath(o)
        }
        return pathNeedsUpdate
    }

    rotateGroup(group: Selectable[], center: paper.Point, angle: number): boolean {
        if (group.length === 0) {
            return false
        }

        const routeOldPos = this.getRoutePositions()
        let pathNeedsUpdate: boolean = false
        for (let o of group) {
            o.setPosition(o.getPosition().rotate(angle, center))
            o.rotate(angle)
            if (o instanceof PathObject) {
                const nodes: [ObstaclePathNode, number][] = this.getPathNodes(o)
                nodes.forEach(n => {
                    if (n[1] < this.route.length - 1 &&
                        group.includes(this.route[n[1] + 1].obstacle)) {
                        n[0].midPoints.forEach(mp => {
                            if (!group.includes(mp)) {
                                mp.setPosition(mp.getPosition().rotate(angle, center))
                            }
                        })
                    }
                })
            }
            pathNeedsUpdate ||= canBeInPath(o)
        }
        this.adjustGroupMidPoints(routeOldPos, group)
        return pathNeedsUpdate
    }

    scaleGroup(group: Selectable[], center: paper.Point, scale: number): boolean {
        if (group.length === 0) {
            return false
        }

        const scalePoint = (p: paper.Point, center: paper.Point, scale: number): paper.Point => {
            const s = p.subtract(center)
            s.length *= scale
            return center.add(s)
        }

        const routeOldPos = this.getRoutePositions()
        let pathNeedsUpdate: boolean = false
        for (let o of group) {
            o.setPosition(scalePoint(o.getPosition(), center, scale))
            if (o instanceof PathObject) {
                const nodes: [ObstaclePathNode, number][] = this.getPathNodes(o)
                nodes.forEach(n => {
                    if (n[1] < this.route.length - 1 &&
                        group.includes(this.route[n[1] + 1].obstacle)) {
                        n[0].midPoints.forEach(mp => {
                            if (!group.includes(mp)) {
                                mp.setPosition(scalePoint(mp.getPosition(), center, scale))
                            }
                        })
                    }
                })
            }
            pathNeedsUpdate ||= canBeInPath(o)
        }
        this.adjustGroupMidPoints(routeOldPos, group)
        return pathNeedsUpdate
    }

    getTwoPhases(): ObstaclePathNode[] | undefined {
        if (this.secondRoundType === SecondRoundType.TWO_PHASE && 
            this.firstRound && this.firstRound.route.length > 0 &&
            this.secondRound && this.secondRound.route.length > 0) {
            const second = this.secondRound.route[0].obstacle.isFinishStart() ? this.secondRound.route.slice(1) : this.secondRound.route
            return this.firstRound.route.concat(second)
        }
        return undefined
    }

    reverseSubRoute(subRoute: ObstaclePathNode[] | undefined, rotated: PathObject[]) {
        if (!subRoute || subRoute.length === 0) {
            return
        }
        const startIdx = findArray(subRoute, this.route)
        if (startIdx < 0) {
            return
        }
        const endIdx = startIdx + subRoute.length - 1
        for (let i = endIdx; i >= startIdx; i--) {
            const n = this.route[i]
            if (n.flags & NodeFlags.CLOSING) {
                // move closing flag to the next node, so the gap in route remains in the same place
                // if flag is set on last obstacle, just remove it
                if (i < endIdx) {
                    this.route[i + 1].flags |= NodeFlags.CLOSING
                }
                n.flags &= ~NodeFlags.CLOSING
            }
            if (n.obstacle.isStart()) {
                n.obstacle.kind = this.objectFactory.getDefinition('finish')               
            } else if (n.obstacle.isFinish()) {
                n.obstacle.kind = this.objectFactory.getDefinition('start')
            }
        }
        const reversed = subRoute.reverse()
        for (let i = startIdx; i <= endIdx; i++) {
            this.route[i] = reversed[i - startIdx]
        }
        for (let i = startIdx; i <= endIdx; i++) {
            const n = this.route[i]
            if (!rotated.includes(n.obstacle)) {
                rotated.push(n.obstacle)
                n.obstacle.rotate(180)
                if (i < this.route.length - 1) {
                    const n2 = this.route[i + 1]
                    n.midPoints = n2.midPoints.reverse()
                    n2.midPoints = []
                    for (let mp of n.midPoints) {
                        mp.parent = n
                    }
                }
            }
        }
    }

    reverseRounds() {
        const rotated: PathObject[] = []
        switch (this.secondRoundType) {
            case SecondRoundType.NONE:
                if (this.firstRound?.complete) {
                    this.reverseSubRoute(this.firstRound?.route, rotated)
                }
                break
            case SecondRoundType.TWO_PHASE:
                if (this.firstRound?.complete && this.secondRound?.complete) {
                    this.reverseSubRoute(this.getTwoPhases(), rotated)
                }
                if (this.thirdRound?.complete) {
                    this.reverseSubRoute(this.thirdRound?.route, rotated)
                }
                break
            case SecondRoundType.JUMP_OFF:
                if (this.firstRound?.complete) {
                    this.reverseSubRoute(this.firstRound?.route, rotated)
                }
                if (this.secondRound?.complete) {
                    this.reverseSubRoute(this.secondRound?.route, rotated)
                }
                if (this.thirdRound?.complete) {
                    this.reverseSubRoute(this.thirdRound?.route, rotated)
                }
                break
        }
    }

    private _getRoundSection(r: number): Section | undefined {
        if (r === 1) {
            return this.firstRound
        } else if (r === 2) {
            return this.secondRound
        } else if (r === 3) {
            return this.thirdRound
        }
        return undefined
    }

    swapRounds(r1: number, r2: number) {
        if (r1 === r2 || r1 < 1 || r1 > 3 || r2 < 1 || r2 > 3) {
            return
        }
        let s1 = this._getRoundSection(r1)
        let s2 = this._getRoundSection(r2)
        if (!s1 || !s2) {
            return
        }
        let i1 = findArray(s1.route, this.route)
        let l1 = s1.route.length
        let i2 = findArray(s2.route, this.route)
        let l2 = s2.route.length
        if (i1 < 0 || i2 < 0) {
            return
        }
        if (i1 > i2) {
            [i1, s1, l1, i2, s2, l2] = [i2, s2, l2, i1, s1, l1]
        }
        if (i1 + l1 > i2) {
            return
        }
        this.route.splice(i2, l2, ...s1.route)
        this.route.splice(i1, l1, ...s2.route)
    }

    // returns true when object was inserted to path
    public dropObjectOnPath(item: PathObject, point: paper.Point): boolean {
        if (item instanceof Terminal) {
            return false
        }
        // object can be included on path but currently is not
        if (!this.includes(item)) {
            const result = this.whichPathContains(point)
            if (result) {
                const [node, round] = result
                // allow dropping obstacle only on a path that is edited now
                if (!round.config.pathVisible) {
                    return false
                }

                // divide the forwardpath where the point is
                const idx = this.getNodeIndex(node)
                if (idx < this.route.length - 1) {
                    // midpoints that will go to the new node
                    let width = item.objectSize.height
                    if (item.selector instanceof CircleWithHandle) {
                        width = item.selector.radius * 2
                    }
                    const midPoints = this.divideForwardPath(node, point, width)
                    this.insertAfterNode(idx, new ObstaclePathNode(item, midPoints, this.cfg, this.canvas, this.view), false)
                    this.autoRotate(item)
                    return true
                }
            }
        }
        return false
    }

    public connectNodeToObjects(pathNode: ObstaclePathNode, items: Selectable[]): boolean {
        const pathNodeIdx = this.getNodeIndex(pathNode)
        if (!pathNode.section || pathNodeIdx >= this.route.length - 1 || pathNodeIdx < 0) {
            return false
        }
        const pathRound = pathNode.section.round123
        if (!pathRound) {
            return false
        }
        let ret = false
        for (let i = 0; i <= items.length - 1; i++) {
            const toObject: PathObject = items[i] as PathObject
            // don't link to itself
            if (pathNode.obstacle === toObject || toObject.isTerminal() ||
                (pathNodeIdx < this.route.length - 1 && this.route[pathNodeIdx + 1].obstacle === toObject)) {
                continue
            }
            const count = toObject.useCount[pathRound - 1]
            if (count === 0 || count === 1) {
                // obstacle not used yet or used once
                this.insertAfterNode(pathNodeIdx, new ObstaclePathNode(toObject, [], this.cfg, this.canvas, this.view), true)

                if (pathNodeIdx + 2 < this.route.length) {
                    const next = this.route[pathNodeIdx + 2]
                    toObject.setDirectionTowards(next.getPosition(), pathRound)
                }
                ret = true
            }
        }
        return ret
    }

}
