import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';
import { DialogModule } from 'primeng/dialog'
import { ParkourDialog } from '../../../dialog.interface'
import { TableModule } from 'primeng/table'
import { ParkourValidator, Warning } from '../../detail.validator'

@Component({
    selector: 'app-parkour-validation',
    standalone: true,
    imports: [
        CommonModule,
        ButtonModule,
        TooltipModule,
        DialogModule,
        TableModule,
    ],
    templateUrl: './parkour-validation.component.html',
    styleUrl: './parkour-validation.component.scss'
})
export class ParkourValidationComponent implements ParkourDialog {
    @Input({ required: true }) validator!: ParkourValidator
    @Input() callbackMode: boolean = false
    @Output() onOk = new EventEmitter<void>()
    @Output() onCancel = new EventEmitter<void>()
    @Output() onSelected = new EventEmitter<Warning>()

    visibility: boolean = false
    selectedProblem?: Warning

    onSelectedProblem() {
        if (this.selectedProblem) {
            this.onSelected.emit(this.selectedProblem)
        }
    }

    onCloseDialog() {
    }

    onShowDialog() {
    }

    onHideDialog() {
    }

    onOkDialog() {
        this.visibility = false
        this.onOk.emit()
    }

    onCancelDialog() {
        this.visibility = false
        this.onCancel.emit()
    }

    open() {
        this.visibility = true
    }

    closeOk() {
        this.onOkDialog()
    }

    closeCancel() {
        this.onCancelDialog()
    }
}