import { MessageService } from 'primeng/api'
import packageJson from '../../package.json';
import { LimitRange } from './design.schema'
import { AllValidationErrors, FormGroupControls } from './detail/dialogs/parkour-competition-params/parkour-competition-params.component'
import { FormGroup, ValidationErrors } from '@angular/forms'
import { UnitRulesSet } from './pipes'
import { ConversionService } from './services/conversion.service'

export type DynamicValue<T> = T | (() => T)

// Functions for presenting message box
export function showInfoBox(msgSvc: MessageService, summary: string, msg: string, interval?: number) {
    if (!interval) {
        interval = 7000
    }
    msgSvc.add({
        severity: 'info',
        summary: summary,
        detail: msg,
        life: interval,
    })
}

export function showSuccessBox(msgSvc: MessageService, summary: string, msg: string) {
    msgSvc.add({
        severity: 'success',
        summary: summary,
        detail: msg,
    })
}

export function showErrorBox(msgSvc: MessageService, summary: string, msg: string) {
    msgSvc.add({
        severity: 'error',
        summary: summary,
        detail: msg,
        life: 10000,
    })
}

export function showItemHierarchy(i: paper.Item, level?: number, count?: number): number {
    let l = 0, n = 1
    if (level || level === 0) {
        l = level + 1
    }
    if (count || count === 0) {
        n = count + 1
    }
    console.log('----'.repeat(l), i.className, n)
    if (i.children) {
        for (let c of i.children) {
            n = showItemHierarchy(c, l, n)
        }
    }
    return n
}

// Parkour build version and date
export function getBuildVersion() {
    return packageJson.version.split('.')[0]
}

export function getBuildDate() {
    let bldDate = packageJson.buildDate
    if (bldDate === 'bldDate') {
        bldDate = '1984-01-01'
    }
    return bldDate
}

function roundAngle(angle: number, ignoreDirection?: boolean): number {
    return (Math.round(angle + Number.EPSILON) + 360) % (ignoreDirection ? 180 : 360)
}

export function angleTo360(angle: number) {
    return angle - Math.floor(angle / 360) * 360
}

export function isAngleBetween(angle: number, min: number, max: number): boolean {
    max = angleTo360(max)
    min = angleTo360(min)
    if (max > min) {
        return angle > min && angle < max
    }
    return angle < max || angle > min
}

export function areInLine(p0: paper.Point, a0: number, p1: paper.Point, a1: number, ignoreDirection?: boolean) {
    const precision = 2.5
    let delta = p1.subtract(p0)
    let angle = Math.atan2(delta.x, -delta.y)
    angle = roundAngle(angle * 180 / Math.PI, ignoreDirection)
    return Math.abs(roundAngle(a0, ignoreDirection) - angle) < precision && Math.abs(roundAngle(a1, ignoreDirection) - angle) < precision
}

export function clampDeltaToField(field: paper.Rectangle, pos: paper.Point | paper.Point[], delta: paper.Point): void {
    let minX = Infinity, maxX = -Infinity, minY = Infinity, maxY = -Infinity
    if (pos instanceof Array) {
        for (const p of pos) {
            minX = Math.min(minX, p.x)
            minY = Math.min(minY, p.y)
            maxX = Math.max(maxX, p.x)
            maxY = Math.max(maxY, p.y)
        }
    } else {
        minX = maxX = pos.x
        minY = maxY = pos.y
    }

    let trimX = 0, trimY = 0
    if (minX + delta.x < field.x) {
        trimX = field.x - (minX + delta.x)
    }
    if (maxX + delta.x > field.right) {
        trimX = field.right - (maxX + delta.x)
    }
    if (minY + delta.y < field.y) {
        trimY = field.y - (minY + delta.y)
    }
    if (maxY + delta.y > field.bottom) {
        trimY = field.bottom - (maxY + delta.y)
    }
    delta.x = delta.x + trimX
    delta.y = delta.y + trimY
}

export function moveToFrontOfArray<T>(a: Array<T>, e: T) {
    const i = a.indexOf(e)
    if (i >= 0) {
        const q = a.splice(i, 1)
        a.unshift(...q)
    }
}

export function findArray<T>(arrayToFind: Array<T>, arrayToSearchIn: Array<T>): number {
    return arrayToSearchIn.findIndex((_, i, a) => {
        if (a.length - i < arrayToFind.length) {
            return false
        }
        for (let j = i; j < a.length && j - i < arrayToFind.length; j++) {
            if (a[j] !== arrayToFind[j - i]) {
                return false
            }
        }
        return true
    })
}

export type PriorityItem = {
    order: number
}

export class PriorityArray<T extends PriorityItem> extends Array<T> {
    push(...items: T[]): number {
        items.forEach(i => {
            let idx = this.findIndex(o => o.order >= i.order)
            if (idx < 0) {
                idx = this.length
            }
            super.splice(idx, 0, i)
        })
        return this.length
    }
}

export function formatLimitsNew(val: LimitRange | undefined, conversion: ConversionService, toUnit: string, rules?: UnitRulesSet): string {
    if (val) {
        if (val.min !== undefined && val.max !== undefined && val.min === val.max) {
            return '(' + conversion.transform(val.min, {
                from: toUnit,
                to: toUnit,
                rules: rules || conversion.rulesForDistance
            }) + ')'
        } else if (val.min !== undefined && val.max !== undefined) {
            return '(' +
                conversion.transform(val.min, {
                    from: toUnit,
                    to: toUnit,
                    skipSuffix: true,
                    rules: rules || conversion.rulesForDistance
                }) + ' - ' +
                conversion.transform(val.max, {
                    from: toUnit,
                    to: toUnit,
                    rules: rules || conversion.rulesForDistance
                }) + ')'
        } else if (val.min !== undefined) {
            return '(>= ' + conversion.transform(val.min, {
                from: toUnit,
                to: toUnit,
                rules: rules || conversion.rulesForDistance
            }) + ')'
        } else if (val.max !== undefined) {
            return '(<= ' + conversion.transform(val.max, {
                from: toUnit,
                to: toUnit,
                rules: rules || conversion.rulesForDistance
            }) + ')'
        }
    }
    return ''
}

export function formatLimits(val?: LimitRange, units?: string, precision?: number): string {
    if (val) {
        const suffix = units ? ' ' + units + ')' : ')'
        if (val.min !== undefined && val.max !== undefined && val.min === val.max) {
            return '(' + val.min.toFixed(precision || 0) + suffix
        } else if (val.min !== undefined && val.max !== undefined) {
            return '(' + val.min.toFixed(precision || 0) + ' - ' + val.max.toFixed(precision || 0) + suffix
        } else if (val.min !== undefined) {
            return '(>= ' + val.min.toFixed(precision || 0) + suffix
        } else if (val.max !== undefined) {
            return '(<= ' + val.max.toFixed(precision || 0) + suffix
        }
    }
    return ''
}

function isObject(obj: any) {
    return obj != null && typeof obj === "object"
}

export function isDeepEqual(object1: any, object2: any) {
    if (!isObject(object1) || !isObject(object2)) {
        return false
    }
    const objKeys1 = Object.keys(object1)
    const objKeys2 = Object.keys(object2)

    var allKeys = new Set([...objKeys1, ...objKeys2])

    for (var key of allKeys) {
        const value1 = object1[key]
        const value2 = object2[key]

        const isObjects = isObject(value1) && isObject(value2)
        if ((isObjects && !isDeepEqual(value1, value2)) ||
            (!isObjects && value1 !== value2)) {
            return false
        }
    }
    return true
}

export function formatBytes(b: number, decs: number = 2) {
    if (!+b) return '0 Bytes'
    const k = 1024
    const dm = decs < 0 ? 0 : decs
    const sizes = ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']
    const i = Math.floor(Math.log(b) / Math.log(k))
    return `${parseFloat((b / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

export function toFirstLowerCase(s: string): string {
    return s.charAt(0).toLowerCase() + s.slice(1)
}

export function toFirstUpperCase(s: string): string {
    return s.charAt(0).toUpperCase() + s.slice(1)
}

// joins strings with separator and justifies it to max charLimit in line, keeping one string together
export function justifyStrings(txt: string[], charLimit: number, separator: string): string {
    let s = '', line = ''
    for (let i = 0; i < txt.length; i++) {
        const l = line.length + txt[i].length
        if (!line && l > charLimit) {
            s += txt[i] + '\n'
        } else if (l > charLimit) {
            s += s ? '\n' + line : line
            line = txt[i]
        } else {
            line += line ? separator + txt[i] : txt[i]
        }
    }
    if (line) {
        s += s ? '\n' + line : line
    }
    return s
}

export function getWPWebLink(path: string, locale?: string): string {
    const l = locale?.toLowerCase()
    const base = 'https://parkour.design/'
    let u
    if (l && l === 'pl') {
        u = new URL(path, base + 'pl/')
    } else {
        u = new URL(path, base)
    }
    return u.href
}

export function dateToLocalizedString(date: string | Date | undefined | null, locale: string, options?: Intl.DateTimeFormatOptions) {
    if (!date) {
        return ''
    }
    let d
    if (date instanceof Date) {
        d = date
    } else if (typeof date === 'string') {
        d = new Date(date)
    }
    if (d) {
        return d.toLocaleDateString(locale, options || { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })
    }
    return ''
}

export function metersToFeet(m: number): number {
    return m * 3.28084
}

export function feetToMeters(m: number): number {
    return m / 3.28084
}

export function feetToCm(m: number): number {
    return feetToMeters(m) * 100
}

export function findIndexOfMin(a: number[]) {
    return a.reduce((p, c, i) => c < p[0] ? [c, i] : p, [Infinity, 0])[1]
}

export function findIndexOfMax(a: number[]) {
    return a.reduce((p, c, i) => c > p[0] ? [c, i] : p, [-Infinity, 0])[1]
}

export function toPrecision(n: number, p: number): number {
    return Math.round(n / p) * p
}

export function getFormValidationErrors(controls: FormGroupControls): AllValidationErrors[] {
    let errors: AllValidationErrors[] = []
    Object.keys(controls).forEach(key => {
        const control = controls[key]
        if (control instanceof FormGroup) {
            errors = errors.concat(getFormValidationErrors(control.controls))
        }
        const controlErrors: ValidationErrors | null = controls[key].errors
        if (controlErrors !== null) {
            Object.keys(controlErrors).forEach(keyError => {
                errors.push({
                    control_name: key,
                    error_name: keyError,
                    error_value: controlErrors[keyError]
                })
            })
        }
    })
    return errors;
}


export function isProduction() {
    return window.location.hostname === "parkour.design" || window.location.hostname === "app.parkour.design"
}


import { getApp } from '@angular/fire/app'
import { getFunctions, httpsCallable } from '@firebase/functions'

export function callGoogleFunc(funcName: string) {
    const app = getApp()
    const instance = getFunctions(app, 'europe-west1')
    return httpsCallable(instance, funcName)
}
