<p-dialog header="Nowy projekt" [modal]="true" i18n-header [(visible)]="newDesignDlgVisible" (keydown.enter)="createDesign()"
    [style]="{'min-width': '40rem'}" [resizable]="false">
    <form *ngIf="cfg" [formGroup]="cfg.form">
        <div class="flex mb-3">
            <div class="flex-auto">
                <label for="title" class="block mb-2" i18n>Wydarzenie:</label>
                <p-dropdown styleClass="w-full"
                    [options]="events" optionLabel="eventName" optionValue="eventName" [editable]="true" [filter]="true"
                            placeholder="Wybierz wydarzenie z listy lub wpisz nowe" i18n-placeholder formControlName="eventName">
                    <ng-template let-event pTemplate="item">
                        <div class="flex justify-content-between">
                            <div>{{ event.eventName }}</div>
                            <div class="text-500">{{ event.eventDate }}</div>
                        </div>
                    </ng-template>
                </p-dropdown>
            </div>
        </div>
        <div class="flex mb-3">
            <div class="flex-auto">
                <label for="title" class="block mb-2" i18n>Nazwa konkursu:</label>
                <input pInputText autofocus id="title" class="w-full" type="text" formControlName="title" [class.ng-dirty]="cfg.form.controls.title.invalid"/>
            </div>
        </div>
        <div class="flex flex-wrap gap-3 mt-3 mb-5">
            <div class="flex-auto">
                <label for="classes" class="block mb-2" i18n>Klasa:</label>
                <p-multiSelect id="classes" styleClass="w-full" (onChange)="updateClasses()"
                    [options]="classNoOptions" formControlName="classes" [showToggleAll]="false" [showHeader]="false" [showClear]="false"
                    [selectedItemsLabel]="selectedItemsLabel"
                    [filter]="false" optionLabel="name" optionValue="value" appendTo="body">
                </p-multiSelect>
            </div>
            <div #articleInHome class="flex-auto" [pTooltip]="articleTooltip" tooltipPosition="top">
                <label for="article" class="block mb-2" i18n>Przepisy FEI:</label>
                <p-dropdown styleClass="w-full"
                    [options]="articleOptions" formControlName="feiRules" [editable]="true" [filter]="false"
                    optionLabel="article" optionValue="article" appendTo="body" (onChange)="setBaseArticleAndTooltipForm()"
                    placeholder="Wybierz z listy lub wpisz" i18n-placeholder>
                    <ng-template let-item pTemplate="item">
                        <span>{{ item.descr }}</span>
                    </ng-template>
                </p-dropdown>
            </div>
            <div class="flex-auto">
                <label for="compLoc" class="block mb-2" i18n>Typ:</label>
                <p-dropdown id="compLoc" styleClass="w-full"
                    [options]="compLocOptions" formControlName="compLoc"
                    [filter]="false" optionLabel="name" optionValue="value" appendTo="body">
                </p-dropdown>
            </div>
        </div>

        <p-divider></p-divider>

        <div id="sizesDropdown" class="grid w-full my-3">
            <div class="col-4" pTooltip="Dotyczy odległości między przeszkodami, długości trasy i pozycji na polu." i18n-pTooltip tooltipPosition="top">
                <label for="distanceUnit" class="block mb-2 flex align-items-center">
                    <span class="mr-2" i18n>Jednostki odległości</span>
                    <i class="fa-solid fa-arrows-left-right-to-line"></i>
                </label>
                <p-dropdown inputId="distanceUnit" appendTo="body" styleClass="w-full"
                    [options]="lengthUnitOptions" optionLabel="name" optionValue="value" formControlName="distanceUnit"
                    (onChange)="onUnitChange()">
                </p-dropdown>
            </div>
            <div class="col-4">
                <label for="parkourHeight" class="block mb-2 flex align-items-center">
                    <span class="mr-2" i18n>Szerokość:</span>
                    <i class="pi pi-arrows-v"></i>
                </label>
                <p-inputNumber
                    inputId="parkourHeight" formControlName="parkourHeight" mode="decimal"
                    [min]="getFieldHeightLimits()[0]" [max]="getFieldHeightLimits()[1]" [useGrouping]="false"
                    [showButtons]="true" [step]="getFieldHeightLimits()[2]">
                </p-inputNumber>
            </div>
            <div class="col-4">
                <label for="parkourWidth" class="block mb-2 flex align-items-center">
                    <span class="mr-2" i18n>Długość:</span>
                    <i class="pi pi-arrows-h"></i>
                </label>
                <p-inputNumber
                    inputId="parkourWidth" formControlName="parkourWidth" mode="decimal"
                    [min]="getFieldWidthLimits()[0]" [max]="getFieldWidthLimits()[1]" [useGrouping]="false"
                    [showButtons]="true" [step]="getFieldWidthLimits()[2]">
                </p-inputNumber>
            </div>
        </div>
        <div class="grid w-full mb-3">
            <div class="col-4" pTooltip="Dotyczy szerokości, wysokości i długości przeszkód." i18n-pTooltip tooltipPosition="top">
                <label for="sizeUnit" class="block mb-2 flex align-items-center">
                    <span class="mr-2" i18n>Jednostki rozmiarów</span>
                    <i class="fa-solid fa-ruler-combined"></i>
                </label>
                <p-dropdown inputId="sizeUnit" appendTo="body" styleClass="w-full"
                    [options]="sizeUnitOptions" optionLabel="name" optionValue="value" formControlName="sizeUnit">
                </p-dropdown>
            </div>
        </div>
        <div class="flex flex-wrap gap-3">
            <div class="flex-auto">
                <label for="shareMode" class="block mb-2" i18n>Udostępnianie:</label>
                <p-selectButton [options]="shareModeOptions" formControlName="shareMode" optionLabel="name" optionValue="value">
                    <ng-template let-item pTemplate>
                        <span [pTooltip]="item.tooltip" i18n-pTooltip tooltipPosition="bottom">
                            {{ item.name }}
                        </span>
                    </ng-template>
                </p-selectButton>
            </div>
        </div>
    </form>
    <div class="mt-3 mb-1 text-red-500">
        {{ errorMsg }}
    </div>
    <ng-template pTemplate="footer">
        <p-button icon="pi pi-times" (click)="hideNewDesignDialog()" label="Anuluj" i18n-label severity="danger"></p-button>
        <p-button icon="pi pi-check" (click)="createDesign()" label="Utwórz konkurs" i18n-label></p-button>
    </ng-template>
</p-dialog>

<p-dialog header="Importuj projekt" [modal]="true" i18n-header [(visible)]="importDesignDlgVisible" (keydown.enter)="createDesign()"
          [style]="{'width': '55rem'}" [resizable]="false">
    <div *ngIf="!importData && !importError">
        <ul class="w-50rem">
            <li i18n>Wybierz plik ze swojego dysku</li>
            <li i18n>Plik powinien pochodzić z programu "PPD SOFTWARE – PROFESSIONAL PARCOURS DESIGN".</li>
            <li i18n>Powinien mieć rozszerzenie .vsdx.</li>
        </ul>
        <div class="flex">
            <p-fileUpload #fileUpload
                          styleClass="p-button-outlined"
                          mode="basic" name="file" [auto]="true" [maxFileSize]="10000000"
                          [disabled]="importing"
                          (onUpload)="onImportUploadDone($event)"
                          (onBeforeUpload)="onImportUploadStart()"
                          (onError)="onImportError($event)"
                          [url]="importUrl"
                          chooseIcon="pi pi-file-import"
                          chooseLabel="Wybierz plik" i18n-chooseLabel></p-fileUpload>
            <div *ngIf="importing" class="pl-3">
                <p-progressSpinner styleClass="w-4rem h-4rem" strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s"></p-progressSpinner>
            </div>
        </div>
    </div>
    <div *ngIf="importData">
        <div class="pb-3" i18n>Dane wczytane!</div>
        <div class="font-bold">{{ importData.eventName }}</div>
        <ol>
            <li *ngFor="let d of importData.designs" class="pb-1">
                {{ d.title }} {{ d.importStatus }}
            </li>
        </ol>
    </div>
    <div *ngIf="importError">
        {{ importError }}
    </div>
    <ng-template pTemplate="footer">
        <p-button icon="pi pi-times" (click)="closeDesignsImport()" label="Anuluj" i18n-label severity="danger"></p-button>
        <p-button *ngIf="importData" icon="pi pi-check" (click)="acceptDesignsImport()" [label]="acceptImportPhase" i18n-label></p-button>
    </ng-template>
</p-dialog>

<p-dialog [(visible)]="previewDlgVisible">
    <div class="w-full h-full max-w-full max-h-full">
        <img class="big-preview-png" *ngIf="bigPreviewPngUrl" [src]="bigPreviewPngUrl" (click)="togglePreview('')"/>
    </div>
</p-dialog>


<div id="toolbar" class="flex px-2 py-1 m-0 w-100 border-100 border-solid border-1 flex-wrap gap-1 align-items-center">
    <p-button
        *ngIf="user"
        icon="pi pi-plus"
        label="Nowy konkurs"
        i18n-label
        (click)="showNewDesignDialog()"
        [outlined]="true"></p-button>
    <p-button
        *ngIf="user"
        icon="pi pi-file-import"
        label="Importuj projekt"
        i18n-label
        (click)="importDesignDlgVisible = true"
        [outlined]="true"></p-button>
</div>


<div class="flex">

    <app-my-designs-list
        #myDesignsList
        *ngIf="user"
        (events)="onEvents($event)"
        (preview)="togglePreview($event)"
        (newDesign)="createNewDesign($event)"
        style="width: 100%;"></app-my-designs-list>

</div>
