import { CommonModule } from '@angular/common'
import { Component, OnDestroy, OnInit } from '@angular/core'
import { Router, RouterModule } from '@angular/router'

import { User } from '@angular/fire/auth'
import { Subscription } from 'rxjs'

import { MenuItem, MessageService } from 'primeng/api'
import { ButtonModule } from 'primeng/button'
import { DialogModule } from 'primeng/dialog'
import { DropdownModule } from 'primeng/dropdown'
import { SkeletonModule } from 'primeng/skeleton'
import { Table, TableModule } from 'primeng/table'
import { TooltipModule } from 'primeng/tooltip'
import { ChartModule } from 'primeng/chart'
import { TabMenuModule } from 'primeng/tabmenu'
import { RadioButtonModule } from 'primeng/radiobutton'

import { DesignSchema, SubscriptionLevel, UserRole } from '../design.schema'
import { AuthService } from '../services/auth.service'
import { StorageService } from '../services/storage.service'
import { UserProfile, UserService } from '../services/user.service'
import { showErrorBox, showInfoBox, showSuccessBox } from '../utils'
import { FormsModule } from '@angular/forms'

type DesignDisplay = DesignSchema & {
    createdAtDate?: Date,
    pngUrl?: string,
}

type EventDisplay = {
    name: string,
    date?: Date,
    createdAt?: Date,
    designs: DesignDisplay[]
}

type UserDisplay = {
    uid: string,
    email?: string,
    name?: string,
    role?: UserRole,
    createdAt?: Date,
    lastLoginAt?: Date,
    photoURL?: string,
    events?: EventDisplay[]
    designsCount?: number
    designsCountLastWeek?: number
    newsletterConsent?: boolean
    isTrial?: boolean,
    planSelected?: boolean,
    subscriptionLevel?: SubscriptionLevel,
}

@Component({
    selector: 'app-admin-home',
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        ButtonModule,
        DropdownModule,
        SkeletonModule,
        TooltipModule,
        TableModule,
        RouterModule,
        DialogModule,
        ChartModule,
        TabMenuModule,
        RadioButtonModule,
    ],
    templateUrl: './admin-home.component.html',
    styleUrls: ['./admin-home.component.scss']
})
export class AdminHomeComponent implements OnInit, OnDestroy {
    private subs: Subscription = new Subscription()

    user: any = null
    designs: DesignSchema[] = []
    users: UserDisplay[] = []

    readonly inProgressMessage = $localize`Ładowanie listy...`
    listMsg: string = ''
    loadingList = false
    userProfile: any
    readonly noEventName: string = $localize`(bez nazwy)`
    preview?: string
    previewVisible: boolean = false
    userCountData: any
    userCountOptions: any
    chartMode: string = "Cumulative"
    private readonly userData: { [id: string]: {
        count: number,
        subs: number,
    } } = {}

    items: MenuItem[] = [{
        label: $localize`Użytkownicy`,
        icon: 'pi pi-user',
    }, {
        label: $localize`Wykresy`,
        icon: 'pi pi-chart-line',
    }]
    activeItem: MenuItem = this.items[0]

    constructor(
        private auth: AuthService,
        private store: StorageService,
        private msgSvc: MessageService,
        public userService: UserService,
        private router: Router,
    ) {
    }

    ngOnInit(): void {
        this.subs.add(
            this.auth.user.subscribe({
                next: (aUser: User | null) => {
                    if (!aUser) {
                        this.user = aUser
                        return
                    }
                    if (!this.user) {
                        this.user = aUser
                        this.loadUsersFromProfiles()
                    } else {
                        this.user = aUser
                    }
                },
                error: (err) => {
                    console.error('error occured', err)
                    showErrorBox(this.msgSvc, $localize`Pobieranie informacji o użytkowniku`, $localize`Wystąpił nieznany błąd`)
                }
            })
        )
        this.subs.add(
            this.userService.getUserProfile().subscribe({
                next: (userProfile: any) => {
                    this.userProfile = userProfile
                },
                error: (err) => {
                    console.error('error occured', err)
                }
            })
        )
    }

    ngOnDestroy() {
        this.subs.unsubscribe()
    }

    onActiveItemChange(event: MenuItem) {
        this.activeItem = event
    }

    loadPreviews(designs: DesignDisplay[]) {
        designs.forEach(d => {
            if (!d.pngUrl) {
                this.store.loadPreview(d)
            }
        })
    }

    private _getDate(date?: string): Date | undefined {
        if (!date) {
            return undefined
        }
        return new Date(date)
    }

    handlePreview(d: any) {
        this.preview = d.pngUrl || d.png || undefined
        this.previewVisible = true
    }

    cloneDesign(oldDesign: any) {
        oldDesign.origAuthor = oldDesign.author
        oldDesign.author = this.user.uid
        const [localId, _, obs] = this.store.cloneDesign(oldDesign)
        obs.subscribe({
            next: (docRef: any) => {
                showSuccessBox(this.msgSvc, $localize`Duplikowanie projektu`, $localize`Projekt zapisano zdalnie, na serwerze`)
            },
            error: (err: any) => {
                console.error("Error adding design: ", err)
                showErrorBox(this.msgSvc, $localize`Duplikowanie projektu`, $localize`Wystąpił nieznany błąd`)
            }
        })
        showInfoBox(this.msgSvc, $localize`Duplikowanie projektu`, $localize`Projekt zapisano lokalnie`)
        this.router.navigate(['/designer'], { queryParams: { id: localId } })
    }

    closePreview() {
        this.previewVisible = false
        this.preview = undefined
    }

    filterValue(ev: Event, table: Table) {
        if (ev.target) {
            table.filterGlobal((ev.target as HTMLInputElement).value, 'contains')
        }
    }

    private addIfAfterDate(after: Date, from: DesignSchema, to: UserDisplay) {
        if (to.designsCountLastWeek === undefined) {
            to.designsCountLastWeek = 0
        }
        if (new Date(from.createdAt) >= after) {
            to.designsCountLastWeek++
        }
    }

    loadUsersFromProfiles() {
        const addZero = (s: number): string => {
            if (s > 9) {
                return s.toFixed(0)
            }
            return '0' + s.toFixed(0)
        }

        if (this.loadingList) {
            return
        }
        this.loadingList = true
        let obs = this.store.getAllUserProfilesStripped()
        this.subs.add(
            obs.subscribe({
                next: (profiles: UserProfile[]) => {
                    this.loadingList = false
                    let user
                    profiles.forEach(p => {
                        if (p.uid) {
                            const idx = this.users.findIndex(u => u.uid === p.uid)
                            if (idx < 0) {
                                this.users.push(user = {
                                    uid: p.uid,
                                    email: p.email ? p.email : undefined,
                                    name: p.name ? p.name : undefined,
                                    role: p.role ? p.role : undefined,
                                    createdAt: this._getDate(p.createdAt),
                                    lastLoginAt: this._getDate(p.lastLoginAt),
                                    photoURL: p.photoURL ? p.photoURL : undefined,
                                    newsletterConsent: p.newsletterConsent,
                                    isTrial: p.trialStart !== undefined && p.trialEnd !== undefined && (new Date(Date.now()) < new Date(p.trialEnd)),
                                    planSelected: p.planSelected,
                                    subscriptionLevel: p.subscriptionLevel ? SubscriptionLevel[p.subscriptionLevel as keyof typeof SubscriptionLevel] : undefined,
                                })
                            } else {
                                const u = user = this.users[idx]
                                u.email = p.email || u.email || undefined
                                u.name = p.name || u.name || undefined
                                u.role = p.role || u.role || undefined
                                u.createdAt = this._getDate(p.createdAt) || u.createdAt || undefined
                                u.lastLoginAt = this._getDate(p.lastLoginAt) || u.lastLoginAt || undefined
                                u.photoURL = p.photoURL || u.photoURL || undefined
                                u.newsletterConsent = p.newsletterConsent !== undefined ? p.newsletterConsent : u.newsletterConsent
                                u.isTrial = p.trialStart !== undefined && p.trialEnd !== undefined && (new Date(Date.now()) < new Date(p.trialEnd))
                                u.planSelected = p.planSelected
                                u.subscriptionLevel = p.subscriptionLevel ? SubscriptionLevel[p.subscriptionLevel as keyof typeof SubscriptionLevel] : undefined
                            }
                            const date = user.createdAt
                            if (date) {
                                const week = date.getFullYear() + '.' + addZero(date.getMonth() + 1) + '.' + addZero(Math.floor(date.getDate() / 7) * 7 + 1)
                                const ud = this.userData[week]
                                if (ud) {
                                    ud.count++
                                    if (user.planSelected) {
                                        ud.subs++
                                    }
                                } else {
                                    this.userData[week] = {
                                        count: 1,
                                        subs: user.planSelected ? 1 : 0
                                    }
                                }
                            }
                        }
                    })
                    this.setCharts()
                },
                error: (err) => {
                    this.loadingList = false
                    this.listMsg = $localize`Pobieranie profili nie powiodło się`
                    console.error('error occured', err)
                    showErrorBox(this.msgSvc, $localize`Pobieranie profili`, $localize`Wystąpił nieznany błąd`)
                }
            })
        )
    }

    setCharts() {
        const values = structuredClone(Object.entries(this.userData).sort(([a, ], [b, ]) => a.localeCompare(b)))
        const data = values.map(([, a]) => a)
        if (this.chartMode === 'Cumulative') {
            for (let i = 1; i < data.length; i++) {
                data[i].count = data[i - 1].count + data[i].count
                data[i].subs = data[i - 1].subs + data[i].subs
            }
        }
        this.userCountData = {
            labels: values.map(([a, ]) => a),
            datasets: [
                {
                    label: $localize`Użytkownicy`,
                    data: data.map(m => m.count),
                    backgroundColor: ['rgba(54, 162, 235, 0.2)'],
                    borderColor: ['rgb(54, 162, 235)'],
                    borderWidth: 1
                },
                {
                    label: $localize`Subskrypcje`,
                    data: data.map(m => m.subs),
                    backgroundColor: ['rgb(120, 172, 141)'],
                    borderColor: ['rgba(54, 162, 235, 0.2)'],
                    borderWidth: 1
                }
            ]
        }

        const documentStyle = getComputedStyle(document.documentElement)
        const textColor = documentStyle.getPropertyValue('--text-color')
        const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary')
        const surfaceBorder = documentStyle.getPropertyValue('--surface-border')        
        this.userCountOptions = {
            plugins: {
                legend: {
                    labels: {
                        color: textColor
                    }
                }
            },
            scales: {
                y: {
                    beginAtZero: true,
                    ticks: {
                        color: textColorSecondary
                    },
                    grid: {
                        color: surfaceBorder,
                        drawBorder: false
                    }
                },
                x: {
                    ticks: {
                        color: textColorSecondary
                    },
                    grid: {
                        color: surfaceBorder,
                        drawBorder: false
                    }
                }
            }
        }
    }

    loadUsersFromDesignsList() {

        const newDesign = (design: DesignSchema): DesignDisplay => {
            const d = structuredClone(design) as DesignDisplay
            if (d.createdAt) {
                d.createdAtDate = new Date(d.createdAt)
            }
            return d
        }

        const newEvent = (design: DesignSchema): EventDisplay => {
            return {
                name: design.eventName,
                date: design.eventDate ? new Date(design.eventDate) : undefined,
                createdAt: design.createdAt ? new Date(design.createdAt) : undefined,
                designs: [newDesign(design)]
            } as EventDisplay
        }

        if (this.loadingList) {
            return
        }
        this.loadingList = true
        this.users.forEach(u => u.events = undefined)
        let obs = this.store.loadAllDesigns(10000, '', '')
        this.subs.add(
            obs.subscribe({
                next: (designs: any[]) => {
                    this.loadingList = false
                    this.designs = designs
                    const lastWeek = new Date()
                    lastWeek.setDate(lastWeek.getDate() - 7)
                    this.designs.forEach(d => {
                        if (d.author) {
                            const idx = this.users.findIndex(u => u.uid === d.author)
                            if (idx < 0) {
                                const n = {
                                    uid: d.author,
                                    name: d.authorName ? d.authorName : undefined,
                                    events: [newEvent(d)],
                                    designsCount: 1
                                } as UserDisplay
                                this.users.push(n)
                                this.addIfAfterDate(lastWeek, d, n)
                            } else {
                                const u = this.users[idx]
                                u.name = d.authorName || u.name || undefined
                                if (u.events) {
                                    const idx = u.events.findIndex(e => e.name === d.eventName)
                                    if (idx < 0) {
                                        u.events.push(newEvent(d))
                                    } else {
                                        u.events[idx].designs.push(newDesign(d))
                                    }
                                } else {
                                    u.events = [newEvent(d)]
                                    u.designsCount = 0
                                    u.designsCountLastWeek = 0
                                }
                                if (u.designsCount !== undefined) {
                                    u.designsCount++
                                    this.addIfAfterDate(lastWeek, d, u)
                                }
                            }
                        }
                    })
                },
                error: (err) => {
                    this.loadingList = false
                    this.listMsg = $localize`Pobieranie projektów nie powiodło się`
                    console.error('error occured', err)
                    showErrorBox(this.msgSvc, $localize`Pobieranie projektów`, $localize`Wystąpił nieznany błąd`)
                }
            })
        )
    }

    exportDesignToFile(design: DesignSchema) {
        const obj = structuredClone(design)
        obj['author'] = ''
        obj['authorName'] = ''
        obj['localId'] = ''
        obj['remoteId'] = ''

        const blob = new Blob([JSON.stringify(obj, null, 2)], {
            type: 'application/json',
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `design-${design.remoteId}.json`;
        a.click();
        window.URL.revokeObjectURL(url);
    }

    _addDesign(design: any) {
        design.author = this.user.uid
        const [localId, obs] = this.store.addDesign(design)
        obs.subscribe({
            next: (docRef: any) => {
                showSuccessBox(this.msgSvc, $localize`Duplikowanie projektu`, $localize`Projekt zapisano zdalnie, na serwerze`)
            },
            error: (err: any) => {
                console.error("Error adding design: ", err)
                showErrorBox(this.msgSvc, $localize`Duplikowanie projektu`, $localize`Wystąpił nieznany błąd`)
            }
        })
        showInfoBox(this.msgSvc, $localize`Duplikowanie projektu`, $localize`Projekt zapisano lokalnie`)
        this.router.navigate(['/designer'], { queryParams: { id: localId } })
    }

    importDesignFromFile(ev: any) {
        const file = ev.target.files[0]
        const reader = new FileReader()

        reader.onloadend = (ev: any) => {
            console.log(reader.result)
            if (reader.result) {
                const design = JSON.parse(reader.result as string)
                delete design['author']
                delete design['authorName']
                delete design['localId']
                delete design['remoteId']

                const newDesign = new DesignSchema()
                newDesign.copy(design)
                this._addDesign(newDesign)
            }
        }

        reader.readAsText(file);
    }
}
