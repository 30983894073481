import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms'

import paper from 'paper'
import { Article, DesignBaseObject, DesignColors, DesignFormParams, DesignNode, DesignObjectGroup, DesignSchema } from './design.schema'
import { ClassNo, ClassNoNameValue, CompetitionTable, LimitSet, LimitSetCombined, LimitsService, ObstacleHeights } from "./services/limits.service"
import { ConversionService, UnitSet } from './services/conversion.service'
import { Unit } from './pipes'

export type ConversionParams = {
    from: Unit, 
    to: () => string, params: string[]
}

export class ParkourConfig {
    public form: FormGroup
    public params: DesignSchema

    // a value that assists form when parsing article numbers when config window is open and form loaded
    public baseFeiRulesForm: Article = Article.NONE

    // a value of the base FEI rules article that is configured - derived from params.feiRules
    public baseFeiRules: Article = Article.NONE

    public formLimits: LimitSetCombined = {}
    public currentLimits: LimitSetCombined
    public regulationsLimits?: LimitSet

    public debugParams: { [id: string]: any } = {
        imgBoundingBox: false
    }

    private readonly _fb: FormBuilder = new FormBuilder()

    private _distanceUnit: string = ''
    private _sizeUnit: string = ''
    private _conversionParams: ConversionParams[]= [
        {
            from: Unit.M,
            to: () => this._distanceUnit,
            params: [ 'parkourHeight', 'parkourWidth', 'compMinDist', 'compMaxDist', 'jumpBeforeLenM', 'landAfterLenM',
                'strideMinLenM', 'strideMaxLenM', 'distance1M', 'distance2M', 'distance3M' ]
        }, {
            from: Unit.CM,
            to: () => this._sizeUnit,
            params: [ 'obstacleHeight', 'obstacleLength', 'oxerWidth', 'tripleBarWidth', 'ditchWidth', 'liverPoolWidth', 'wallWidth' ]
        }
    ]

    public get unitSet(): UnitSet {
        return {
            size: this.params.sizeUnit,
            distance: this.params.distanceUnit
        }
    }
    
    constructor(
        public limitsService: LimitsService,
        private conversionService: ConversionService,
        initialData: Partial<DesignSchema>
    ) {
        this.params = new DesignSchema()
        this.params.copy(initialData)
        this.form = this._buildControls(this.params, new DesignFormParams())
        this.currentLimits = {
            heights: limitsService.getHeightLimits(this.params.classes, this.unitSet),
            ...limitsService.getDefaults(this.unitSet)
        }
    }

    public setRoute(route: DesignNode[]) {
        this.params.route = route
        this._updateDate()
    }

    public setObjects(objects: DesignBaseObject[]) {
        this.params.objects = objects
        this._updateDate()
    }

    public setGroups(groups: DesignObjectGroup[]) {
        this.params.groups = groups
        this._updateDate()
    }

    public setValuesToForm() {
        this._removeAllValidators()
        this.form.patchValue(this.params)
        this._distanceUnit = this.params.distanceUnit
        this._sizeUnit = this.params.sizeUnit
        for (let g of this._conversionParams) {
            for (let c of g.params) {
                const v = this.form.get(c)
                v?.patchValue(
                    this.conversionService.convert(v.value, {
                        from: g.from,
                        to: g.to(),
                        rules: this.conversionService.fullPrecisionNoRounding
                    })    
                )    
            }
        }            
        for (let c of Object.keys(this.params.obstacleHeights)) {
            const v = this.form.controls.obstacleHeights.get(c)
            v?.patchValue(
                this.conversionService.convert(v.value, {
                    from: Unit.CM,
                    to: this.params.sizeUnit,
                    rules: this.conversionService.fullPrecisionNoRounding
                })    
            )    
        }    
        this.form.updateValueAndValidity()
    }

    public getValuesFromForm(conversionParams?: ConversionParams[]) {
        const cParams = conversionParams || this._conversionParams
        this._removeAllValidators()

        this._distanceUnit = this.params.distanceUnit
        this._sizeUnit = this.params.sizeUnit
        this._getValuesFromForm(this.params, this.form)

        for (let g of cParams) {
            for (let c of g.params) {
                const v = this.form.get(c)
                if (v && this.params.hasOwnProperty(c) && v.valid) {
                    (this.params as any)[c] = this.conversionService.convert(v.value, {
                        from: g.to(),
                        to: g.from,
                        rules: this.conversionService.fullPrecisionNoRounding
                    })
                }
            }
        }
        for (let [k, v] of Object.entries(this.form.controls.obstacleHeights.value)) {
            if (v !== 0) {
                const r = this.conversionService.convert(v as number, {
                    from: this._sizeUnit,
                    to: Unit.CM,
                    rules: this.conversionService.fullPrecisionNoRounding
                })
                if (r !== undefined) {
                    this.params.obstacleHeights[k as ClassNo] = r
                }
            }
        }
    }

    public importDesign(design: any) {
        this.params = new DesignSchema()
        this.params.copy(design)
        this._loadLimits()
        // for configured classes, if height is not configured, set it to default for that class
        for (let c of this.params.classes) {
            const v = this.params.obstacleHeights[c] || this.params.obstacleHeight
            const l = this.currentLimits.heights?.[c]
            if (l && l.min && l.max && v <= l.max && v >= l.min) {
                this.params.obstacleHeights[c] = v
            } else if (l?.default) {
                this.params.obstacleHeights[c] = l.default
            }
        }
        this.setValuesToForm()
        this._updateDate()
    }

    public getSpeed(roundNo: number): number {
        if (roundNo < 1 || roundNo > 3) {
            return 0
        }
        let v
        if (roundNo === 2 && this.params.overrideSpeed2) {
            v = this.params.speed2
        } else if (roundNo === 3 && this.params.overrideSpeed3) {
            v = this.params.speed3
        } else {
            v = this.params.speed
        }
        if (typeof (v) === 'string') {
            return +v
        } else if (typeof (v) === 'number') {
            return v
        }
        return 0
    }

    public canUserEditArrows(): boolean {
        return this.currentLimits.userCanEditArrows || false
    }

    public isNoRouteMode(): boolean {
        return this.currentLimits.noRoute || false
    }

    public isJokerAllowed(): boolean {
        return (this.baseFeiRules === Article.ART_269_ACCUMULATOR)
    }

    public isScoreAllowed(): boolean {
        return (this.baseFeiRules === Article.ART_270_TOP_SCORE)
    }

    public isMaxTimeAllowed(): boolean {
        if (this.params.table === CompetitionTable.TABLE_C) {
            // Art.239.3 - no time allowed under Table C
            return false
        }
        return !this.currentLimits.noMaxTime
    }

    public noFinishInFirstRound(): boolean {
        return (this.baseFeiRules === Article.ART_267_HIT_AND_HURRY)
    }

    public updateCompatibleClassesInForm(classNoOptions: ClassNoNameValue[]) {
        const article: Article = this.baseFeiRulesForm
        const classes = this.form.controls.classes.value as ClassNo[]

        for (let c of classNoOptions) {
            c.disabled = false
        }
        const toRemove: ClassNo[] = []
        for (let v of classes) {
            for (let c of classNoOptions) {
                if (c.value !== v) {
                    if (!toRemove.includes(c.value) && this.limitsService.getManyLimits([v, c.value], article, this.unitSet) === null) {
                        c.disabled = true
                        if (classes.includes(c.value)) {
                            toRemove.push(c.value)
                        }
                    }
                }
            }
        }
        if (toRemove.length > 0) {
            for (let c of toRemove) {
                const i = classes.indexOf(c)
                if (i >= 0) {
                    classes.splice(i, 1)
                }
            }
            this.form.controls.classes.patchValue(classes)
        }
    }

    public scaleAccordingToFieldSize(value: number) {
        return value * 0.6 * Math.max(this.params.parkourWidth, this.params.parkourHeight) + 40
    }

    private _calcFontSize(size: number) {
        return this.scaleAccordingToFieldSize(size / 100)
    }

    public getFontSize(): number {
        return this._calcFontSize(this.params.fontSize)
    }

    public getTableFontSize(): number {
        return this._calcFontSize(this.params.tableFontSize)
    }

    public getDistanceFontSize(): number {
        return this._calcFontSize(this.params.distFontSize)
    }

    public getRulerFontSize(): number {
        return this._calcFontSize(this.params.rulerFontSize)
    }

    public getLabelFontSize(): number {
        return this._calcFontSize(this.params.labelFontSize)
    }

    public getPageMarginPx(): number {
        return this.params.paperMarginsCm * 10 * 2.835
    }

    public getPageSizePx(): paper.Size {
        return new paper.Size(841.995, 595.35)
    }

    private _buildControls(params: { [id: string]: any }, refObj: any): FormGroup {
        const controls: { [id: string]: any } = {}
        // go through only from params, skip non-form params
        for (let p of Object.keys(refObj)) {
            if (params.hasOwnProperty(p)) {
                const pVal = params[p as keyof DesignFormParams]
                if (p === 'colors') {
                    controls[p] = this._buildControls(pVal, new DesignColors())
                } else if (p === 'obstacleHeights') {
                    const hs: ObstacleHeights = Object.fromEntries(Object.values(ClassNo).filter(c => !!c).map(c => [c, 0]))
                    // create all form controls for all classes
                    controls[p] = this._buildControls(hs, hs)
                } else {
                    controls[p] = [pVal, Validators.nullValidator]
                }
            }
        }
        return this._fb.group(controls)
    }

    private _updateDate() {
        this.params.updatedAt = (new Date()).toISOString()
        this.params.saved = false
    }

    private _getValuesFromForm(params: any, form: FormGroup) {
        let control: any, key: string
        for ([key, control] of Object.entries(form.controls)) {
            if (control instanceof FormGroup) {
                this._getValuesFromForm(params[key], control)
            } else {
                params[key] = control.value
            }
        }
        this._updateDate()
    }

    private _loadLimits() {
        const classes = this.params.classes
        const article = this.params.feiRules as Article
        this.baseFeiRules = this.limitsService.getArticleBase(article)
        this.currentLimits = this.limitsService.getManyLimits(classes, this.baseFeiRules, this.unitSet) || {
            heights: this.limitsService.getHeightLimits(classes, this.unitSet),
            ...this.limitsService.getDefaults(this.unitSet)
        }
        this.regulationsLimits = this.limitsService.getManyRegulationsLimits(classes, this.baseFeiRules, this.unitSet) || this.limitsService.getDefaults(this.unitSet)
    }

    private _removeAllValidators(controls?: {[id: string]: AbstractControl}) {
        if (!controls) {
            controls = { 'top': this.form }
        }
        for (let v of Object.values(controls)) {
            if (v instanceof FormGroup) {
                this._removeAllValidators(v.controls)
            }
            v.clearValidators()
            v.clearAsyncValidators()
            v.updateValueAndValidity()
        }
    }
}
