import { CommonModule } from '@angular/common'
import { Component, ElementRef, EventEmitter, OnDestroy, Output, ViewChild } from '@angular/core'
import * as B from '@babylonjs/core'
import { registerBuiltInLoaders } from "@babylonjs/loaders/dynamic"
import paper from 'paper'
import { Subscription } from 'rxjs'
import { DesignBaseObject, DesignDrawnObstacleObject, DesignPathObject, DesignSchema, DesignTerminalObject, DesignTextBoxObject, ParkourRendererWalkMode } from '../design.schema'
import { ObstaclePath } from '../detail/detail.path'
import { ObstaclePathNode } from '../detail/detail.path.node'
import { RenderedArena } from './parkour-renderer.arena'
import { RenderedBush } from './parkour-renderer.bush'
import { ParkourRendererCamera } from './parkour-renderer.camera'
import { RenderedGround } from './parkour-renderer.ground'
import { RenderedJury } from './parkour-renderer.jury'
import { ParkourRendererLights } from './parkour-renderer.lights'
import { RenderedObject } from './parkour-renderer.object'
import { RenderedObstacle } from './parkour-renderer.obstacle'
import { RenderedPhotocells } from './parkour-renderer.photocells'
import { RenderedRoute } from './parkour-renderer.route'
import { RenderedSky } from './parkour-renderer.sky'
import { RenderedTree } from './parkour-renderer.tree'
import { MaterialManager } from './parkour-renderer.materials'
import { RenderedStands } from './parkour-renderer.stands'
import { RenderedTent } from './parkour-renderer.tent'
import { RenderedCar } from './parkour-renderer.car'

export type ParkourRendererConfig = {
    walkSpeed: number,
    flySpeed: number,
    shadows: boolean,
    routeAnimation: number,
    routeLine: boolean,
    stands: boolean,
    walkMode: ParkourRendererWalkMode,
}

function rotateUVs(newUVs: B.FloatArray, oldUVs: B.FloatArray, idx: number) {
    // Swap UV mapping for 90-degree rotation (swap U and V coordinates)
    newUVs[idx + 0] = oldUVs[idx + 6]  // Top-left U
    newUVs[idx + 1] = oldUVs[idx + 7]  // Top-left V
    newUVs[idx + 2] = oldUVs[idx + 0]  // Bottom-left U
    newUVs[idx + 3] = oldUVs[idx + 1]  // Bottom-left V
    newUVs[idx + 4] = oldUVs[idx + 2]  // Top-right U
    newUVs[idx + 5] = oldUVs[idx + 3]  // Top-right V
    newUVs[idx + 6] = oldUVs[idx + 4]  // Bottom-right U
    newUVs[idx + 7] = oldUVs[idx + 5]  // Bottom-right V
}

// 0 - Right face
// 1 - Left face
// 2 - Top face
// 3 - Bottom face
// 4 - Front face (where we rotate the texture)
// 5 - Back face
export function rotateTextureUV(m: B.Mesh, faces: number[]) {
    const uvData = m.getVerticesData(B.VertexBuffer.UVKind)
    if (uvData) {
        const newUVs = uvData.slice()
        faces.forEach(f => {
            rotateUVs(newUVs, uvData, f * 8)
        })
        m.setVerticesData(B.VertexBuffer.UVKind, newUVs)
    }
}    

@Component({
    selector: 'app-3d-render',
    standalone: true,
    imports: [
        CommonModule,
    ],
    templateUrl: './parkour-renderer.component.html',
    styleUrls: ['./parkour-renderer.component.scss']
})
export class ParkourRenderer implements OnDestroy {
    @ViewChild('renderCanvas', { static: false }) canvas?: ElementRef<HTMLCanvasElement>
    @Output() onFlyModeChange = new EventEmitter<boolean>()

    private _subs: Subscription = new Subscription()

    private _engine?: B.Engine
    private _scene?: B.Scene
    private _materialManager: MaterialManager
    private _camera?: ParkourRendererCamera
    private _lights?: ParkourRendererLights
    private _sky?: RenderedSky
    private _ground?: RenderedGround
    private _arena?: RenderedArena
    private _stands?: RenderedStands
    private _objects: RenderedObject [] = []
    private _routes: RenderedRoute[] = []
    private _flyPath: B.Vector3[] = []
    private _shadowMesh?: B.AbstractMesh[]
    private _recorder?: B.VideoRecorder
    private _walkMode: ParkourRendererWalkMode = ParkourRendererWalkMode.FLY_FREELY
    private _design?: DesignSchema

    private static _builtInLoadersRegistered: boolean = false

    constructor() {
        this._materialManager = new MaterialManager()
    }

    ngOnDestroy() {
        this.destroy()
        this._subs.unsubscribe()
    }

    private _findObjectsInDesign(schema: DesignSchema, kind: string): DesignBaseObject[] {
        return schema.objects.filter(o => o.kind === kind)
    }

    private _onPointerLockChange() {
        if (document.pointerLockElement === null) {
            // TBD
        }
    }

    private _findRenderedObject(uuid: string): RenderedObject | undefined {
        return this._objects.find(o => o.uuid === uuid)
    }

    private _processRoute(route: ObstaclePathNode[] | undefined, design: DesignSchema, round: number) {
        const vectors: B.Vector3[] = []
        const normals: B.Vector2[] = []
        if (!this._scene || !route) {
            return
        }
        const path: paper.Path = new paper.Path()
        route?.forEach(o => {
            const r = this._findRenderedObject(o.obstacle.uuid)
            if (r instanceof RenderedObstacle) {
                r.addLabel(o.manualLabel || o.label, round, o.direction)
            }
            if (o.forwardPath) {
                path.addSegments(o.forwardPath.segments)
            }
        })
        const len = path.length
        const points = path.length / 10
        if (points > 1) {
            for (let i = 0; i < points; i++) {
                const loc = len * i / (points - 1)
                const p = path.getPointAt(loc)
                const t = path.getNormalAt(loc)
                if (p) {
                    vectors.push(new B.Vector3(p.x / 100, 0.4, design.parkourHeight - p.y / 100))
                    normals.push(new B.Vector2(t.x, -t.y))
                }
            }
        }
        const r = new RenderedRoute(this._scene, this._materialManager, vectors, path.length, normals, round)
        this._routes.push(r)
        const pointsToFly = 150, flyOver = 20
        const mpow = Math.pow(2, 2) // value of yy for max i
        if (this._flyPath.length === 0 && vectors.length > pointsToFly) {
            // add a little path before start to fly over start
            const vz = vectors[0]
            for (let i = 1; i < pointsToFly; i++) {
                const v = vectors[i]
                const yy = flyOver * Math.pow(2 * i / pointsToFly, 2) / mpow
                const p = new B.Vector3(vz.x - (v.x - vz.x), v.y + yy, vz.z - (v.z - vz.z))
                this._flyPath.unshift(p)
            }
        }
        this._flyPath.push(...vectors)
    }

    changeFieldOfView(delta: number) {
        if (this._camera) {
            let fov = this._camera.fieldOfView
            fov = fov + delta / 10
            fov = Math.min(fov, 1.8)
            fov = Math.max(fov, 0.1)
            this._camera.fieldOfView = fov
        }
    }

    onClick() {
        this.canvas?.nativeElement.requestPointerLock?.()
        this.canvas?.nativeElement.focus()       
    }

    startRecording(): boolean {
        if (this._engine && B.VideoRecorder.IsSupported(this._engine)) {
            this._recorder = new B.VideoRecorder(this._engine, {
                fps: 60,
            })
            this._recorder.startRecording('parkour.design.webm', 60)
            return true
        }
        return false
    }

    stopRecording(): void {
        this._recorder?.stopRecording()
        this._recorder = undefined
    }

    startFlyMode() {
        this._camera?.startFlyMode()
    }

    stopFlyMode() {
        this._camera?.stopFlyMode()
    }

    getCurrentAnimation(): [number, string] {
        const r = this._routes[0]
        return [r.getMaterial(), r.getActiveMaterialName()]
    }

    setConfig(config: Partial<ParkourRendererConfig>) {
        if (config.flySpeed !== undefined) {
            this._camera?.setFlySpeed(config.flySpeed)
        }
        if (config.walkSpeed) {
            this._camera?.setWalkSpeed(config.walkSpeed)
        }
        if (config.shadows !== undefined) {
            const sm = this._lights?.shadowGenerator.getShadowMap()
            if (sm?.renderList) {
                if (sm.renderList.length > 0 && !config.shadows) {
                    this._shadowMesh = sm.renderList
                    sm.renderList = []
                } else if (sm.renderList.length === 0 && config.shadows && this._shadowMesh) {
                    sm.renderList = this._shadowMesh
                }
            }
        }
        if (config.routeAnimation !== undefined) {
            const a = config.routeAnimation
            this._routes.forEach(r => r.setMaterial(a))
        }
        if (config.routeLine !== undefined) {
            const l = config.routeLine
            this._routes.forEach(r => r.setLineVisibility(l))
        }
        if (config.stands !== undefined && this._stands) {
            this._stands.visible = config.stands
        }
        if (config.walkMode !== undefined) {
            this.setWalkMode(config.walkMode)
        }
    }

    setWalkMode(mode: ParkourRendererWalkMode) {
        this._walkMode = mode
        if (!this._scene || !this._design) {
            return
        }
        const ph = this._design.parkourWidth, pw = this._design.parkourHeight
        if (mode === ParkourRendererWalkMode.FLY_FREELY) {
            this._camera?.setCameraPositionAndTarget(
                new B.Vector3(ph > pw ? ph / 2 : -ph * 0.5, 30, ph > pw ? -pw * 0.5 : pw / 2),
                new B.Vector3(ph / 2, 0, pw / 2)
            )
            this._scene.collisionsEnabled = false
        } else if (mode === ParkourRendererWalkMode.WALK_ON_THE_GROUND) {
            this._camera?.setCameraPositionAndTarget(
                new B.Vector3(ph / 2, 2, pw / 2),
                new B.Vector3(ph / 2, 2, pw / 2 + 5)
            )
            this._scene.collisionsEnabled = true
        }
    }
    
    getHourOfDay(): number {
        const t = this._lights?.hourOfDay
        if (t !== undefined) {
            return t
        }
        return 12
    }

    setHourOfDay(h: number) {
        if (this._lights) {
            this._lights.hourOfDay = h
        }
    }

    jump() {
        this._camera?.jump()
    }

    initialize(design: DesignSchema, path?: ObstaclePath) {
        if (!this.canvas) {
            console.error('3d renderer canvas not available')
            return
        }
        const mm = this._materialManager
        if (!ParkourRenderer._builtInLoadersRegistered) {
            registerBuiltInLoaders()
            ParkourRenderer._builtInLoadersRegistered = true
        }

        this._design = design
        const engine = this._engine = new B.Engine(this.canvas.nativeElement)
        const scene = this._scene = new B.Scene(engine)

        const lights = this._lights = new ParkourRendererLights(scene)
        const shadowGenerator = lights.shadowGenerator

        document.addEventListener('pointerlockchange', this._onPointerLockChange)
        this.canvas?.nativeElement.requestPointerLock?.()
        this.canvas?.nativeElement.focus()

        this._sky = new RenderedSky(scene, mm)
        this._ground = new RenderedGround(scene, mm)
        this._arena = new RenderedArena(scene, mm, shadowGenerator, design)
        this._stands = new RenderedStands(scene, mm, shadowGenerator, design)

        this._objects = []
        const obs = this._findObjectsInDesign(design, 'vertical-vector')
        obs.push(...this._findObjectsInDesign(design, 'oxer-vector'))
        obs.push(...this._findObjectsInDesign(design, 'triple-barre-vector'))
        obs.push(...this._findObjectsInDesign(design, 'gate'))
        obs.push(...this._findObjectsInDesign(design, 'corner-oxer'))
        obs.push(...this._findObjectsInDesign(design, 'corner-triple-barre'))
        obs.push(...this._findObjectsInDesign(design, 'ditch'))
        obs.push(...this._findObjectsInDesign(design, 'wall'))
        obs.forEach(v => {
            this._objects.push(new RenderedObstacle(scene, mm, shadowGenerator, v as DesignDrawnObstacleObject, 
                design, this._sky?.mesh, this._ground?.mesh))
        })

        const terminals = this._findObjectsInDesign(design, 'start')
        terminals.push(...this._findObjectsInDesign(design, 'finish'))
        terminals.push(...this._findObjectsInDesign(design, 'finish-start'))
        terminals.forEach(v => {
            this._objects.push(new RenderedPhotocells(scene, mm, shadowGenerator,
                v.kind === 'finish-start' ? v as DesignPathObject : v as DesignTerminalObject, design))
        })

        this._processRoute(path?.firstRound?.route.filter((o, i, l) => !(o.obstacle.kind.kind === 'finish-start' && i === l.length - 1)), design, 1)
        this._processRoute(path?.secondRound?.route, design, 2)
        this._processRoute(path?.thirdRound?.route, design, 3)

        design.objects.forEach(o => {
            if (o.kind === 'grass-2') {          
                this._objects.push(new RenderedTree(scene, mm, o, design, shadowGenerator, {
                    trunkHeight: 20,
                    trunkTaper: 0.6,
                    trunkSlices: 5,
                    boughs: 2,
                    forks: 4,
                    forkAngle: Math.PI / 4,
                    forkRatio: 2 / (1 + Math.sqrt(5)),
                    branches: 10,
                    branchAngle: Math.PI / 3,
                    bowFreq: 2,
                    bowHeight: 3.5,
                    leavesOnBranch: 5,
                    leafWHRatio: 0.4,
                }))
            } else if (o.kind === 'grass-1') {
                this._objects.push(new RenderedBush(scene, mm, o, design, shadowGenerator))
            } else if (o.kind === 'car') {
                this._objects.push(new RenderedCar(scene, mm, o, design, shadowGenerator))
            } else if (o.kind === 'text-box') {
                const box = o as DesignTextBoxObject
                if (box.content.toLowerCase() === 'jury') {
                    this._objects.push(new RenderedJury(scene, mm, box, design, shadowGenerator))
                }
            } else if (o.kind === 'service-hut') {
                this._objects.push(new RenderedTent(scene, mm, o, design, shadowGenerator))
            }
        })

        this._camera = new ParkourRendererCamera(engine, scene, this._flyPath, design, (mode: boolean) => {
            this.onFlyModeChange.emit(mode)
        })

        this.setWalkMode(this._walkMode)
        
        window.addEventListener('resize', () => {
            engine?.resize() 
        })

        engine.runRenderLoop(() => {
            scene.render()
        })
    }

    static is3dSupported(): boolean {
        return B.Engine.isSupported()
    }

    destroy() {
        this.stopRecording()
        this._engine?.stopRenderLoop()
        document.removeEventListener('pointerlockchange', this._onPointerLockChange)
        this._sky = undefined
        this._ground = undefined
        this._arena = undefined
        this._stands = undefined
        this._objects.forEach(o => o.destroy())
        this._objects = []
        this._materialManager.destroy()
        this._scene?.dispose()
        this._scene = undefined
        this._engine?.dispose()
        this._engine = undefined
        this._routes = []
        this._flyPath = []
        this._shadowMesh = []
    }
}
