import { Injectable } from '@angular/core';
import { Observable, combineLatest, map, filter } from 'rxjs';
import { SubscriptionLevel } from '../design.schema';
import { PayService } from './pay.service';
import { BrevoService } from './brevo.service';
import { UserService, UserProfile } from './user.service';

export type UserFeatures = {
    obstacleLimit: number,
    customImagesAndLogos: boolean,
    saveToPDFAndPrint: boolean,
    materialsEditor: boolean,
    printMasterPlanAndTables: boolean,
    workOffline: boolean,
    parkourDesignLabelChangable: boolean,
}

@Injectable({
    providedIn: 'root'
})
export class UserSubscriptionService {
    readonly levels: { [id: string]: UserFeatures } = {
        [SubscriptionLevel.STARTER]: {
            obstacleLimit: 10,
            customImagesAndLogos: false,
            saveToPDFAndPrint: false,
            materialsEditor: false,
            printMasterPlanAndTables: false,
            workOffline: false,
            parkourDesignLabelChangable: false,
        },
        [SubscriptionLevel.ADVANCED]: {
            obstacleLimit: 100,
            customImagesAndLogos: true,
            saveToPDFAndPrint: true,
            materialsEditor: false,
            printMasterPlanAndTables: false,
            workOffline: false,
            parkourDesignLabelChangable: false,
        },
        [SubscriptionLevel.MASTERS]: {
            obstacleLimit: 100,
            customImagesAndLogos: true,
            saveToPDFAndPrint: true,
            materialsEditor: true,
            printMasterPlanAndTables: true,
            workOffline: true,
            parkourDesignLabelChangable: true,
        },
        [SubscriptionLevel.FULL]: {
            obstacleLimit: 100,
            customImagesAndLogos: true,
            saveToPDFAndPrint: true,
            materialsEditor: true,
            printMasterPlanAndTables: true,
            workOffline: true,
            parkourDesignLabelChangable: true,
        }
    }

    planState: string = ''

    constructor(
        private userService: UserService,
        protected pay: PayService,
        private brevoService: BrevoService,
    ) { }

    getStartingFeatures(): UserFeatures {
        return this.levels[SubscriptionLevel.STARTER]
    }

    getPlanName(plan: any, profile: any): SubscriptionLevel {
        let planName: SubscriptionLevel = SubscriptionLevel.STARTER

        if ((plan?.name == 'advanced') ||
            profile?.testerPlan == 'advanced') {
            planName = SubscriptionLevel.ADVANCED
        } else if ((plan?.name == 'masters') ||
            profile?.testerPlan == 'masters') {
            planName = SubscriptionLevel.MASTERS
        }

        if (planName == SubscriptionLevel.STARTER && this.isTrial(profile)) {
            planName = SubscriptionLevel.MASTERS
        }

        return planName
    }

    getFeatures(): Observable<UserFeatures> {
        return combineLatest([this.pay.getSubscriptionPlan(), this.userService.getUserProfile()]).pipe(
            map(([plan, profile]) => {
                const planName = this.getPlanName(plan, profile)
                let feats = this.levels[planName]
                return feats
            })
        )
    }

    isTrial(profile: UserProfile): boolean {
        if (!profile || profile.planSelected || !profile.trialEnd) {
            return false
        }
        return new Date(Date.now()) < new Date(profile.trialEnd)
    }

    getPlanState(): Observable<string> {
        return combineLatest([this.pay.getSubscriptionPlan(), this.userService.getUserProfile()]).pipe(
            filter(([plan, profile]) => !!(plan && profile)),
            map(([plan, profile]) => {
                if (!profile) {
                    this.planState = ''
                    return ''
                }
                if (!profile.trialStart || !profile.trialEnd) {
                    const patch = {
                        trialStart: new Date(Date.now()).toISOString(),
                        trialEnd: new Date(Date.now() + 14 * 24 * 60 * 60 * 1000).toISOString(), // two weeks trial
                    }
                    this.userService.updateUserProfile(patch).subscribe()
                    this.planState = 'trial-begin'
                    return 'trial-begin'
                }

                if (!profile.planSelected && new Date(Date.now()) < new Date(profile.trialEnd)) {
                    this.planState = 'trial'
                    return 'trial'
                }

                if (!profile.planSelected) {
                    this.planState = 'trial-end'
                    return 'trial-end'
                }

                if (plan && plan.canceled) {
                    this.planState = 'trial-end'
                    return 'trial-end'
                }

                this.planState = 'active'
                return 'active'
            })
        )
    }
}
