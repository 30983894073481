<p-dialog [(visible)]="visibility"
    [style]="{'min-width': '70rem', 'max-width': '70rem', 'min-height': '50rem', 'max-height': '100vh'}"
    contentStyleClass="py-0"
    (keydown.enter)="onOkDialog()" (keydown.escape)="onCancelDialog()"
    (onShow)="onShowDialog()" (onClose)="onCloseDialog()" (onHide)="onHideDialog()">
    <ng-template pTemplate="header">
        <span class="p-dialog-title"><i class="fa-solid fa-list-check mr-2"></i> <span i18n>Opcje wyświetlania</span></span>
    </ng-template>

    <p-tabMenu #displayOptionsTabMenu [model]="displayOptionsTabMenuItems" [(activeItem)]="displayOptionsActiveItem">
    </p-tabMenu>

    <form [formGroup]="view.cfg.form">

        <!-- Tab: General -->

        <div *ngIf="displayOptionsActiveItem === displayOptionsTabMenuItems[0]">
            <div class="formgrid grid pt-3 pb-0">
                <div class="col-12 md:col-6 field-checkbox">
                    <p-checkbox formControlName="showHorseStepLen" [binary]="true" inputId="showHorseStepLen"></p-checkbox>
                    <label for="showHorseStepLen" i18n>Pokaż długość kroku konia</label>
                </div>
                <div class="col-12 md:col-6 field-checkbox">
                    <p-checkbox formControlName="warnNotAlignedHorseStep" [binary]="true" inputId="warnNotAlignedHorseStep"></p-checkbox>
                    <label for="warnNotAlignedHorseStep" i18n>Ostrzegaj o odleglościach nie wyrównanych do kroku konia</label>
                </div>
                <div class="col-12 md:col-6 field-checkbox">
                    <p-checkbox formControlName="showLinesOfSight" [binary]="true" inputId="showLinesOfSight"></p-checkbox>
                    <label for="showLinesOfSight" i18n>Wyróżnij teren za przeszkodą</label>
                </div>
                <div class="col-12 md:col-6 field-checkbox">
                    <p-checkbox formControlName="displayEmptyTableItems" [binary]="true" inputId="displayEmptyTableItems"></p-checkbox>
                    <label for="displayEmptyTableItems" i18n>Pokazuj puste pola w tabelce</label>
                </div>
                <div class="col-12 md:col-6 field-checkbox">
                    <p-checkbox formControlName="hideFieldBorder" [binary]="true" inputId="hideFieldBorder"></p-checkbox>
                    <label for="hideFieldBorder" i18n>Ukryj granice pola</label>
                </div>
                <div class="col-12 md:col-6 field-checkbox">
                    <p-checkbox formControlName="hideFieldRuler" [binary]="true" inputId="hideFieldRuler"></p-checkbox>
                    <label for="hideFieldRuler" i18n>Ukryj wymiary pola</label>
                </div>
                <div class="col-12 md:col-6 field-checkbox">
                    <p-checkbox formControlName="crossOutUnused" [binary]="true" inputId="crossOutUnused"></p-checkbox>
                    <label for="crossOutUnused" i18n>Przekreśl nieużywane przeszkody</label>
                </div>
                <div class="col-12 md:col-6 field-checkbox">
                    <p-checkbox formControlName="limitToField" [binary]="true" inputId="limitToField"></p-checkbox>
                    <label for="limitToField" i18n>Ogranicz poruszanie przeszkodami do placu</label>
                </div>
                <div class="col-12 md:col-6 field-checkbox">
                    <p-checkbox formControlName="showDimensions" [binary]="true" inputId="showDimensions"></p-checkbox>
                    <label for="showDimensions" i18n>Pokaż wymiary nad przeszkodami</label>
                </div>
                <div class="col-12 md:col-6 field-checkbox"
                     [pTooltip]="showParkourDesignLabelTooltip" tooltipPosition="left">
                    <p-checkbox formControlName="showParkourDesignLabel" [binary]="true" inputId="showParkourDesignLabel"></p-checkbox>
                    <label for="showParkourDesignLabel" i18n>Pokaż etykietę 'Designed in https://parkour.design' na wydruku</label>
                </div>
                <div class="col-12 md:col-6 field-checkbox">
                    <p-checkbox formControlName="colorfulMaterials" [binary]="true" inputId="colorfulMaterials"></p-checkbox>
                    <label for="colorfulMaterials" i18n>Koloruj materiały</label>
                </div>
            </div>
            <p-divider class="w-full pt-0"/>
            <div class="formgrid grid">
                <div class="col-12 md:col-3 field py-0 my-0" pTooltip="Dotyczy odległości między przeszkodami, długości trasy i pozycji na polu." i18n-pTooltip tooltipPosition="top">
                    <label for="distanceUnit" class="block mb-2" i18n>Jednostki odległości</label>
                    <p-dropdown inputId="distanceUnit" appendTo="body" styleClass="w-full"
                        [options]="distanceUnitOptions" optionLabel="name" optionValue="value" formControlName="distanceUnit">
                    </p-dropdown>
                </div>
                <div class="col-12 md:col-3 field py-0 my-0" pTooltip="Dotyczy szerokości, wysokości i długości przeszkód." i18n-pTooltip tooltipPosition="top">
                    <label for="sizeUnit" class="block mb-2" i18n>Jednostki rozmiarów</label>
                    <p-dropdown inputId="sizeUnit" appendTo="body" styleClass="w-full"
                        [options]="sizeUnitOptions" optionLabel="name" optionValue="value" formControlName="sizeUnit">
                    </p-dropdown>
                </div>
            </div>
        </div>

        <!-- Tab: Rozmiary i wzory -->

        <div *ngIf="displayOptionsActiveItem === displayOptionsTabMenuItems[1]">
            <p-panel styleClass="mt-2 shadow-none border-1 border-200">
                <ng-template pTemplate="header">
                    <span class="text-base font-bold" i18n>Rozmiary czcionek</span>
                </ng-template>
                <div class="formgrid grid row-gap-2">
                    <div class="col-12 md:col-3 field py-0 my-0">
                        <label for="labelFontSize" class="block mb-2" i18n>Numery przeszkód [%]</label>
                        <p-inputNumber inputId="labelFontSize" inputStyleClass="w-full" formControlName="labelFontSize"
                                    [min]="10" [max]="300" [step]="10" [showButtons]="true"></p-inputNumber>
                    </div>
                    <div class="col-12 md:col-3 field py-0 my-0">
                        <label for="tableFontSize" class="block mb-2" i18n>Tabelka [%]</label>
                        <p-inputNumber inputId="tableFontSize" inputStyleClass="w-full" formControlName="tableFontSize"
                                    [min]="10" [max]="300" [step]="10" [showButtons]="true"></p-inputNumber>
                    </div>
                    <div class="col-12 md:col-3 field py-0 my-0">
                        <label for="distFontSize" class="block mb-2" i18n>Odległości [%]</label>
                        <p-inputNumber inputId="distFontSize" inputStyleClass="w-full" formControlName="distFontSize"
                                    [min]="10" [max]="300" [step]="10" [showButtons]="true"></p-inputNumber>
                    </div>
                    <div class="col-12 md:col-3 field py-0 my-0">
                        <label for="rulerFontSize" class="block mb-2" i18n>Miarka ramki [%]</label>
                        <p-inputNumber inputId="rulerFontSize" inputStyleClass="w-full" formControlName="rulerFontSize"
                                    [min]="10" [max]="300" [step]="10" [showButtons]="true"></p-inputNumber>
                    </div>
                    <div class="col-12 md:col-3 field py-0 my-0">
                        <label for="fontSize" class="block mb-2" i18n>Pozostałe napisy [%]</label>
                        <p-inputNumber inputId="fontSize" inputStyleClass="w-full" formControlName="fontSize"
                                    [min]="10" [max]="300" [step]="10" [showButtons]="true"></p-inputNumber>
                    </div>
                </div>
            </p-panel>
            <p-panel styleClass="mt-2 shadow-none border-1 border-200">
                <ng-template pTemplate="header">
                    <span class="text-base font-bold" i18n>Ścieżka</span>
                </ng-template>
                <div class="formgrid grid row-gap-2">
                    <div class="col-12 md:col-3 field py-0 my-0">
                        <label for="pathPattern1" class="block mb-2" 
                            tooltipPosition="top" pTooltip="Dotyczy przebiegu podstawowego" i18n-pTooltip i18n>Wzór w rundzie 1</label>
                        <pathPatternSelector [patterns]="pathPatterns" formControlName="pathPattern1" id="pathPattern1"
                            [width]="view.cfg.form.get('pathWidth')?.value"
                            [color]="view.cfg.form.get('colors.pathRegular')?.value">
                        </pathPatternSelector>
                    </div>
                    <div class="col-12 md:col-3 field py-0 my-0">
                        <label for="pathPattern2" class="block mb-2" 
                            tooltipPosition="top" pTooltip="Dotyczy rozgrywki lub drugiej fazy" i18n-pTooltip i18n>Wzór w rundzie 2</label>
                        <pathPatternSelector [patterns]="pathPatterns" formControlName="pathPattern2" id="pathPattern2"
                            [width]="view.cfg.form.get('pathWidth')?.value"
                            [color]="view.cfg.form.get('colors.pathRegular2')?.value">
                        </pathPatternSelector>
                    </div>
                    <div class="col-12 md:col-3 field py-0 my-0">
                        <label for="pathPattern25" class="block mb-2"
                            tooltipPosition="top" pTooltip="Dotyczy pierwszej rozgrywki po drugiej fazie lub drugiej rozgrywki" i18n-pTooltip i18n>Wzór w rundzie 3</label>
                        <pathPatternSelector [patterns]="pathPatterns" formControlName="pathPattern25" id="pathPattern25"
                            [width]="view.cfg.form.get('pathWidth')?.value"
                            [color]="view.cfg.form.get('colors.pathRegular25')?.value">
                        </pathPatternSelector>
                    </div>
                    <div class="col-12 md:col-3 field py-0 my-0">
                        <label for="pathPattern3" class="block mb-2"
                            tooltipPosition="top" pTooltip="Dotyczy odcinków na planie bez startu i mety" i18n-pTooltip i18n>Wzór poza rundami</label>
                        <pathPatternSelector [patterns]="pathPatterns" formControlName="pathPattern3" id="pathPattern3"
                            [width]="view.cfg.form.get('pathWidth')?.value"
                            [color]="view.cfg.form.get('colors.pathRegular')?.value">
                        </pathPatternSelector>
                    </div>
                    <div class="col-12 md:col-3 field py-0 my-0">
                        <label for="pathWidth" class="block mb-2" i18n>Grubość [piksele]</label>
                        <p-inputNumber inputId="pathWidth" inputStyleClass="w-full" formControlName="pathWidth"
                            [min]="0.2" [max]="10" [step]="0.1" [showButtons]="true"></p-inputNumber>
                    </div>
                </div>
            </p-panel>
            <p-panel styleClass="mt-2 shadow-none border-1 border-200">
                <ng-template pTemplate="header">
                    <span class="text-base font-bold" i18n>Pozostałe</span>
                </ng-template>
                <div class="formgrid grid row-gap-2">
                    <div class="col-12 md:col-3 field py-0 my-0">
                        <label for="fieldRadius" class="block mb-2" i18n>Zaokrąglenie ramki pola [cm]</label>
                        <p-inputNumber inputId="fieldRadius" inputStyleClass="w-full" formControlName="fieldRadius"
                                    [min]="0" [max]="100000" [step]="10" [showButtons]="true"></p-inputNumber>
                    </div>
                    <div class="col-12 md:col-3 field py-0 my-0">
                        <label for="paperMarginsCm" class="block mb-2" i18n>Marginesy kartki [cm]</label>
                        <p-inputNumber inputId="paperMarginsCm" inputStyleClass="w-full" formControlName="paperMarginsCm"
                                    [min]="0" [max]="10" [step]="0.1" [showButtons]="true"></p-inputNumber>
                    </div>
                    <div class="col-12 md:col-3 field py-0 my-0">
                        <label for="lineWidth" class="block mb-2" i18n>Grubość linii [piksele]</label>
                        <p-inputNumber inputId="lineWidth" inputStyleClass="w-full" formControlName="lineWidth"
                                    [min]="0.2" [max]="10" [step]="0.1" [showButtons]="true"></p-inputNumber>
                    </div>
                    <div class="col-12 md:col-3 field py-0 my-0">
                        <label for="tapeWidth" class="block mb-2" i18n>Grubość miarki [piksele]</label>
                        <p-inputNumber inputId="tapeWidth" inputStyleClass="w-full" formControlName="tapeWidth"
                                    [min]="0.2" [max]="10" [step]="0.1" [showButtons]="true"></p-inputNumber>
                    </div>
                    <div class="col-12 md:col-3 field py-0 my-0">
                        <label for="tapePattern" class="block mb-2" i18n>Wzór ścieżki miarki</label>
                        <pathPatternSelector [patterns]="pathPatterns" formControlName="tapePattern" id="tapePattern"
                            [width]="view.cfg.form.get('tapeWidth')?.value"
                            [color]="view.cfg.form.get('colors.tape')?.value">
                        </pathPatternSelector>
                    </div>
                </div>
            </p-panel>
        </div>

        <!-- Tab: Colors -->

        <div *ngIf="displayOptionsActiveItem === displayOptionsTabMenuItems[2]">
            <div class="grid pt-3" formGroupName="colors">
                <div class="col-12 md:col-4 flex flex-column gap-1">
                    <div #pathRegular>
                        <new-colorPicker formControlName="pathRegular" [menuTarget]="pathRegular"></new-colorPicker>
                        <span class="pl-2" tooltipPosition="top" pTooltip="Dotyczy przebiegu podstawowego" 
                            i18n-pTooltip i18n>Ścieżka i odległości - runda 1</span>
                    </div>
                    <div #pathRegular2>
                        <new-colorPicker formControlName="pathRegular2" [menuTarget]="pathRegular2"></new-colorPicker>
                        <span class="pl-2" tooltipPosition="top" pTooltip="Dotyczy rozgrywki lub drugiej fazy" 
                            i18n-pTooltip i18n>Ścieżka i odległości - runda 2</span>
                    </div>
                    <div #pathRegular25>
                        <new-colorPicker formControlName="pathRegular25" [menuTarget]="pathRegular25"></new-colorPicker>
                        <span class="pl-2" tooltipPosition="top" pTooltip="Dotyczy pierwszej rozgrywki po drugiej fazie lub drugiej rozgrywki" 
                            i18n-pTooltip i18n>Ścieżka i odległości - runda 3</span>
                    </div>
                    <div #pathConnect>
                        <new-colorPicker formControlName="pathConnect" [menuTarget]="pathConnect"></new-colorPicker>
                        <span class="pl-2" i18n>Ścieżka podczas łączenia</span>
                    </div>
                    <div #midPoint>
                        <new-colorPicker formControlName="midPoint" [menuTarget]="midPoint"></new-colorPicker>
                        <span class="pl-2" i18n>Punkty edycji ścieżki</span>
                    </div>
                    <div #tape>
                        <new-colorPicker formControlName="tape" [menuTarget]="tape"></new-colorPicker>
                        <span class="pl-2" i18n>Miarka odległości</span>
                    </div>
                    <div #dimensions>
                        <new-colorPicker formControlName="dimensions" [menuTarget]="dimensions"></new-colorPicker>
                        <span class="pl-2" i18n>Rozmiary nad przeszkodami</span>
                    </div>
                    <div #pathWarn>
                        <new-colorPicker formControlName="pathWarn" [menuTarget]="pathWarn"></new-colorPicker>
                        <span class="pl-2" i18n>Ostrzeżenia</span>
                    </div>
                </div>
                <div class="col-12 md:col-4 flex flex-column gap-1">
                    <div #objectLabel>
                        <new-colorPicker formControlName="objectLabel" [menuTarget]="objectLabel"></new-colorPicker>
                        <span class="pl-2" tooltipPosition="top" pTooltip="Dotyczy przebiegu podstawowego" i18n-pTooltip
                            i18n>Numery przeszkód - runda 1</span>
                    </div>
                    <div #objectLabel2>
                        <new-colorPicker formControlName="objectLabel2" [menuTarget]="objectLabel2"></new-colorPicker>
                        <span class="pl-2" tooltipPosition="top" pTooltip="Dotyczy rozgrywki lub drugiej fazy" i18n-pTooltip
                            i18n>Numery przeszkód - runda 2</span>
                    </div>
                    <div #objectLabel25>
                        <new-colorPicker formControlName="objectLabel25" [menuTarget]="objectLabel25"></new-colorPicker>
                        <span class="pl-2" tooltipPosition="top" pTooltip="Dotyczy pierwszej rozgrywki po drugiej fazie lub drugiej rozgrywki" i18n-pTooltip
                            i18n>Numery przeszkód - runda 3</span>
                    </div>
                    <div #obstacleName>
                        <new-colorPicker formControlName="obstacleName" [menuTarget]="obstacleName"></new-colorPicker>
                        <span class="pl-2" i18n>Nazwy przeszkód</span>
                    </div>
                    <div #materials>
                        <new-colorPicker formControlName="materials" [menuTarget]="materials"></new-colorPicker>
                        <span class="pl-2" i18n>Materiały</span>
                    </div>
                    <div #obstacleArrows>
                        <new-colorPicker formControlName="obstacleArrows" [menuTarget]="obstacleArrows"></new-colorPicker>
                        <span class="pl-2" i18n>Strzałki na przeszkodach</span>
                    </div>
                    <div #bankArrow>
                        <new-colorPicker formControlName="bankArrow" [menuTarget]="bankArrow"></new-colorPicker>
                        <span class="pl-2" i18n>Strzałka bankietu</span>
                    </div>
                </div>
                <div class="col-12 md:col-4 flex flex-column gap-1">
                    <div #objectLabelFill>
                        <new-colorPicker formControlName="objectLabelFill" [menuTarget]="objectLabelFill"></new-colorPicker>
                        <span class="pl-2" tooltipPosition="top" pTooltip="Dotyczy przebiegu podstawowego" i18n-pTooltip
                            i18n>Wypełnienie numerów - runda 1</span>
                    </div>
                    <div #objectLabel2Fill>
                        <new-colorPicker formControlName="objectLabel2Fill" [menuTarget]="objectLabel2Fill"></new-colorPicker>
                        <span class="pl-2" tooltipPosition="top" pTooltip="Dotyczy rozgrywki lub drugiej fazy" i18n-pTooltip
                            i18n>Wypełnienie numerów - runda 2</span>
                    </div>
                    <div #objectLabel25Fill>
                        <new-colorPicker formControlName="objectLabel25Fill" [menuTarget]="objectLabel25Fill"></new-colorPicker>
                        <span class="pl-2" tooltipPosition="top" pTooltip="Dotyczy pierwszej rozgrywki po drugiej fazie lub drugiej rozgrywki" i18n-pTooltip
                            i18n>Wypełnienie numerów - runda 3</span>
                    </div>
                    <div #fieldFrame>
                        <new-colorPicker formControlName="fieldFrame" [menuTarget]="fieldFrame"></new-colorPicker>
                        <span class="pl-2" i18n>Ramka pola</span>
                    </div>
                    <div #rulerMarks>
                        <new-colorPicker formControlName="rulerMarks" [menuTarget]="rulerMarks"></new-colorPicker>
                        <span class="pl-2" i18n>Miarka na ramce pola</span>
                    </div>
                    <div #grid>
                        <new-colorPicker formControlName="grid" [menuTarget]="grid"></new-colorPicker>
                        <span class="pl-2" i18n>Siatka pola</span>
                    </div>
                    <div #paperLimits>
                        <new-colorPicker formControlName="paperLimits" [menuTarget]="paperLimits"></new-colorPicker>
                        <span class="pl-2" i18n>Granice kartki</span>
                    </div>
                </div>
                <div class="col-12 field-checkbox py-3 my-0">
                    <p-checkbox formControlName="grayscale" [binary]="true" inputId="grayscale"></p-checkbox>
                    <label for="grayscale" i18n>W skali szarości</label>
                </div>
            </div>
        </div>
    </form>
    <div class="mt-3 mb-1 text-red-500">
        {{ errorMsg }}
    </div>
    <ng-template pTemplate="footer">
        <div class="flex justify-content-between ">
            <p-splitButton
                icon="pi pi-undo"
                menuStyleClass="w-18rem"
                label="Załaduj moje ustawienia" i18n-label
                (onClick)="loadDisplayOptionsFromUserProfile()"
                [model]="displayOptionsDefaultActions"></p-splitButton>

            <div class="flex justify-content-end">
                <p-button icon="pi pi-times" (click)="onCancelDialog()" label="Anuluj" i18n-label severity="danger"></p-button>
                <p-button icon="pi pi-check" (click)="onOkDialog()" [disabled]="!view.cfg.form.valid" label="Zapisz opcje" i18n-label></p-button>
            </div>
        </div>
    </ng-template>
</p-dialog>
