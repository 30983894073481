import paper from 'paper'
import { CombinationType, DesignObjectGroup } from "../design.schema"
import { areInLine, clampDeltaToField } from "../utils"
import { Selectable } from "./detail.selection"
import { CircleWithHandle, Selector, SelectorKind } from "./detail.selectors"
import { Obstacle } from "./parkour-objects/obstacle"
import { ParkourObject } from "./parkour-objects/parkour-object"
import { PathObject } from "./parkour-objects/path-object"
import { ParkourCanvas } from '../parkour-canvas/parkour-canvas'
import { ParkourConfig } from '../parkour.config'
import { ObstacleWithBars } from './parkour-objects/obstacle-with-bars'
import { Unit } from '../pipes'


export class ParkourObjectGroup implements Selectable {
    selectedBeforeMouseDown: boolean = false
    changed: boolean = false
    focusLock: boolean = false
    children: Selectable[] = []
    strokeWidth: number
    angle: number = 0
    selector: Selector = new CircleWithHandle(this, this.cfg)
    levelItem?: paper.Item
    parentSelector?: Selectable
    size: paper.Size = new paper.Size(0, 0)
    center: paper.Point
    combination: Obstacle[] = []
    combinationType: CombinationType
    combinationDistance?: number = undefined
    readonly groupobject = true

    get name(): string {
        return $localize`Grupa` + ' ' + (this.index + 1)
    }

    private static _getDistance(o1: ParkourObject, o2: ParkourObject): number {
        let d = o1.getPosition().getDistance(o2.getPosition())
        if (o1 instanceof ObstacleWithBars) {
            d -= o1.obstacleWidth / 2
        }
        if (o2 instanceof ObstacleWithBars) {
            d -= o2.obstacleWidth / 2
        }
        return d / 100
    }

    private static _getDistanceRounded(o1: ParkourObject, o2: ParkourObject): number {
        return Math.max(Math.round(this._getDistance(o1, o2) * 10) / 10, 0)
    }

    private _updateCombinationDistance() {    
        this.combinationDistance = undefined
        if (this.combination.length > 1) {
            let d = ParkourObjectGroup._getDistance(this.combination[1], this.combination[0])
            const dr = Math.round(d * 10) / 10
            d = this.canvas.conversionService.convert(d, {
                from: Unit.M,
                to: this.cfg.params.distanceUnit,
                rules: this.canvas.conversionService.rulesForCombinationDistance
            }) || d
            const allEqual = this.combination.every((c, i, a) => i > 0 ? ParkourObjectGroup._getDistanceRounded(c, a[i - 1]) === dr : true)
            if (allEqual) {
                this.combinationDistance = Math.max(d, 0)
            }
        }
    }

    getMaxCombinationDistance(): number {
        if (this.canvas.conversionService.isImperial(this.cfg.params.distanceUnit)) {
            return 400
        }
        return 100
    }


    setCombinationDistance(d?: number) {
        if (d === undefined) {
            return
        }
        const obstacles: ObstacleWithBars[] = this.combination.filter(s => s instanceof ObstacleWithBars) as ObstacleWithBars[]
        if (obstacles.length > 1) {
            if (this.combinationDistance === undefined) {
                d = ParkourObjectGroup._getDistance(this.combination[1], this.combination[0])
            } else {
                d = this.canvas.conversionService.convert(d, {
                    from: this.cfg.params.distanceUnit,
                    to: Unit.CM,
                    rules: this.canvas.conversionService.fullPrecisionNoRounding
                }) || d
            }
            this.combinationDistance = Math.max(d, 0)
            this.canvas.obstaclePath.putInCombination(obstacles, this.combinationDistance)
            this.findBestAngle()
            this.update()
        }
    }

    constructor(items: Selectable[], private cfg: ParkourConfig, private canvas: ParkourCanvas, public index: number, combinationType?: CombinationType) {
        this.children = [...items]
        this.children.forEach(o => {
            if (o.parentSelector && o.parentSelector instanceof ParkourObjectGroup) {
                o.parentSelector.remove(o)
            }
            o.parentSelector = this
        })
        this.strokeWidth = this.cfg.params.lineWidth
        this.center = this.bounds.center
        this.combinationType = combinationType !== undefined ? combinationType : CombinationType.OPEN
        this.findBestAngle()
        this.determineContent()
        this.deselect()
    }

    isSelected(): boolean {
        return this.selector.isSelected()
    }

    getRotation(): number {
        return this.angle
    }

    destroy() {
        this.children.forEach(c => this.remove(c))
        if (this.levelItem && this.levelItem.isInserted()) {
            this.levelItem.remove()
        }
        this.index = -1
        this.selector.destroy()
    }

    get bounds(): paper.Rectangle {
        let rect: paper.Rectangle | undefined = undefined
        for (let i = 0; i < this.children.length; i++) {
            const b = this.children[i].bounds
            if (b) {
                if (!rect) {
                    rect = b.clone()
                } else {
                    rect = rect.unite(b)
                }
            }
        }
        return rect || new paper.Rectangle(0, 0, 0, 0)
    }

    update() {
        this.levelItem = this.selector.create(this.center, this.size, this.getRotation(), true, SelectorKind.RECTANGLE, [5, 5])
        this.determineContent()
        this._updateCombinationDistance()
    }

    remove(item: Selectable, replaceWith?: Selectable | null): number {
        const idx = this.children.indexOf(item)
        if (idx >= 0) {
            if (replaceWith) {
                this.children.splice(idx, 1, replaceWith)
            } else {
                this.children.splice(idx, 1)
            }
        }
        item.parentSelector = undefined
        this.findBestAngle()
        return this.children.length
    }

    select(point?: paper.Point | undefined): void {
        this.changed = false
        this.update()
        this.selector.select(point)
    }

    deselect(): void {
        this.selector.deselect()
        this.changed = false
    }

    contains(point: paper.Point): boolean {
        if (this.levelItem?.contains(point)) {
            return true
        }
        return false
    }

    getPosition(): paper.Point {
        return this.center
    }

    setPosition(point: paper.Point): void {
        const delta = point.subtract(this.getPosition())
        this.move(delta)
    }

    drag(delta: paper.Point, point: paper.Point): void {
        this.selector.drag(delta, point)
    }

    move(delta: paper.Point): void {
        const positions = this.children.map(c => c.getPosition())
        if (this.cfg.params.limitToField) {
            clampDeltaToField(this.canvas.field, positions, delta)
        }
        if (!delta.isZero()) {
            this.levelItem?.translate(delta)
            this.center = this.center.add(delta)
            const routeOldPos = this.canvas.obstaclePath.getRoutePositions()    
            this.canvas.obstaclePath.moveGroup(this.children, delta)
            this.canvas.obstaclePath.adjustGroupMidPoints(routeOldPos, this.children)
            this.changed = true
        }
    }

    rotate(angleDelta: number): void {
        let angle = this.angle = (this.angle + angleDelta + 360) % 360
        this.angle = this.selector.snap(this.angle)
        let delta = this.angle - angle + angleDelta
        this.selector.rotate(delta, this.center)
        this.canvas.obstaclePath.rotateGroup(this.children, this.center, delta)
        this.changed = true
    }

    scaleObject(scale: number): boolean {
        this.size = this.size.multiply(scale)
        this.canvas.obstaclePath.scaleGroup(this.children, this.center, scale)
        this.changed = true
        return true
    }

    doubleClick(point?: paper.Point | undefined): boolean {
        return false
    }

    includes(item: Selectable): boolean {
        return this.children.includes(item)
    }

    replaceObject(from: Selectable, to?: Selectable | null) {
        if (from.parentSelector === this) {
            if (to) {
                to.parentSelector = this
            }
            this.remove(from, to)
        }
    }

    findBestAngle() {
        const p = this.center
//        const ab = (this.angle + 360) % 360
//        const quarter = Math.floor(ab / 90)
        let bestArea: number = -1
        let bestAngle: number = -1
        let bestSize: paper.Size | undefined = undefined
        let bestCenter: paper.Point | undefined = undefined
    
        const step = 0.5
        for (let a = 0; a < 90; a += step) {
            const bounds = this.bounds
            const size = bounds.size
            const area = Math.round(size.width + size.height)
            if (bestAngle < 0 || bestArea > area) {
                bestArea = area
                bestAngle = a
                bestSize = size
                bestCenter = bounds.center
            }
            this.children.forEach(c => {
                if (c instanceof ParkourObject) {
                    c.externalFrame.rotate(step, p)
                } else {
                    c.setPosition(c.getPosition().rotate(step, p))
                }
            })
        }
        this.children.forEach(c => {
            if (c instanceof ParkourObject) {
                c.externalFrame.rotate(-90, p)
            } else {
                c.setPosition(c.getPosition().rotate(-90, p))
            }
        })
        if (bestAngle >= 0) {
            if (bestCenter) {
                this.center = bestCenter.rotate(-bestAngle, p)
            }
            if (bestSize && bestAngle === 0) {
                bestSize = new paper.Size(bestSize.height, bestSize.width)
            }
            bestAngle = (-bestAngle + 90) % 90 + 90
            this.angle = bestAngle
            this.size = bestSize || new paper.Size(0, 0)
            this.update()
        }
    }    

    determineContent() {
        this.combination.length = 0
        if (this.children.length > 1) {
            let isCombination = true
            const items = this.children.filter(o => o instanceof PathObject) as PathObject[]
            for (let i = 1; i < items.length; i++) {
                const i1 = items[i - 1]
                let a1 = (i1.angle + 360) % 360
                if (a1 > 180) {
                    a1 -= 180
                }
                const i2 = items[i]
                let a2 = i2.angle
                if (a2 > 180) {
                    a2 -= 180
                }
                if (!i1.isObstacle() || !i2.isObstacle() || !areInLine(i1.getPosition(), a1, i2.getPosition(), a2, true)) {
                    isCombination = false
                    break
                }
            }
            if (isCombination) {
                this.combination = (items.filter(o => o instanceof Obstacle) as Obstacle[]).sort((a, b) => {
                    const pa = a.getPosition()
                    const pb = b.getPosition()
                    if (pa.x !== pb.x) {
                        return pa.x - pb.x
                    }
                    return pa.y - pa.y
                })
            }
        }
    }

    toJson(): DesignObjectGroup {
        const ret = new DesignObjectGroup()
        ret.copy({
            uuids: this.children.filter(c => c instanceof ParkourObject).map(c => (c as ParkourObject).uuid),
            combinationType: this.combinationType
        })
        return ret
    }
}
