import { DesignBaseObject, DesignSchema } from "../design.schema"
import * as B from '@babylonjs/core'

export class RenderedObject {
    uuid: string
    protected x: number
    protected y: number
    protected angle: number

    constructor(
        protected scene: B.Scene,
        protected object: DesignBaseObject,
        protected design: DesignSchema)
    {
        this.x = object.x / 100
        this.y = design.parkourHeight - object.y / 100
        this.uuid = object.uuid
        this.angle = object.angle * 2 * Math.PI / 360
    }

    destroy() {
        Object.getPrototypeOf(this).constructor.destroy()
    }

    static destroy() {
    }
}
