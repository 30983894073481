import paper from 'paper'
import { DesignTextBoxObject } from "../../design.schema"
import { LayerId } from '../../parkour-canvas/parkour-canvas'
import { SelectorKind } from "../detail.selectors"
import { ParkourObject, ParkourObjectConfig } from "./parkour-object"

export class TextBox extends ParkourObject {
    defaultContent = $localize`[Wprowadź tekst]`
    specialId: string
    content: string
    txt: paper.PointText
    border: boolean
    boxSize: paper.Size
    txtScale: number
    strokeColor: string
    fillColor: string
    borderColor: string
    fillEnable: boolean
    justification: string
    fontFamily: string

    protected readonly canResize: boolean = true
    protected readonly snapRotation: boolean = true
    protected readonly preferredSelectorKind: SelectorKind = SelectorKind.RECTANGLE
    protected readonly preferredLayer: LayerId = LayerId.FRONT
    protected readonly configurableLayer: boolean = true
    private readonly margin: paper.Size = new paper.Size(30, 10)

    constructor(protected config: ParkourObjectConfig) {
        super(config)
        const object = config.object
        this.objectSize.height = 0
        this.objectSize.width = 0
        if (!object.angle) {
            this.angle = 0
        }
        this.layer = object.layer || this.preferredLayer
        this.specialId = object.specialId || ''
        this.content = object.content || this.defaultContent
        this.border = object.border || false
        this.txtScale = object.txtScale || 1
        this.strokeColor = object.strokeColor || '#000000'
        this.fillColor = object.fillColor || '#aaaaaa'
        this.borderColor = object.borderColor || '#000000'
        this.fillEnable = object.fillEnable || false
        this.justification = object.justification || 'left'
        this.fontFamily = object.fontFamily || 'Courier New'

        this.txt = new paper.PointText({
            position: this.initialPos,
            content: this.content,
            fillColor: this.strokeColor,
            fontFamily: this.fontFamily,
            justification: this.justification,
            fontSize: this.cfg.params.fontSize
        })
        this.txt.position = this.txt.point
        this.txt.scale(this.txtScale)
        this.boxSize = this.txt.internalBounds.size.add(this.margin).multiply(this.txtScale)
        this.allGroup.addChild(this.txt)
        this.txt.bringToFront()
        this.objectReady(true)
    }

    updateContent() {
        if (!this.canvas.paper) {
            return
        }
        const pos = this.txt.position
        if (this.content !== this.txt.content) {
            this.txt.content = this.content === '' ? '  ' : this.content
        }
        this.txt.justification = this.justification
        this.txt.fontFamily = this.fontFamily
        this.txt.fillColor = new paper.Color(this.strokeColor)
        this.txt.position = pos
        this.boxSize = this.txt.internalBounds.size.add(this.margin).multiply(this.txtScale)
        this.createSelectionGfx()
        this.drawExtraElements()
    }

    getExternalSize(): paper.Size {
        return this.boxSize
    }

    scaleObject(scale: number): boolean {
        const s = this._trimScale(this.boxSize, scale, new paper.Size(100, 100))
        this.txt.scale(s, this.getPosition())
        this.boxSize = this.boxSize.multiply(s)
        this.txtScale *= s
        return true
    }

    setFontSize(fontSize: number) {
        if (!this.canvas.paper) {
            return
        }
        this.txt.fontSize = fontSize
        this.updateContent()
    }

    drawExtraElements(): void {
        this.configExternalFrame(this.border, this.fillEnable, this.fillColor, this.borderColor)
    }

    doubleClick(): boolean {
        this.view?.objectPanelEl?.selectTextInputArea()
        return false
    }

    toJson(): DesignTextBoxObject {
        return {
            ...super.toJson(),
            specialId: this.specialId,
            content: this.content,
            border: this.border,
            txtScale: this.txtScale,
            strokeColor: this.strokeColor,
            fillColor: this.fillColor,
            borderColor: this.borderColor,
            fillEnable: this.fillEnable,
            justification: this.justification,
            fontFamily: this.fontFamily,
            boxSize: [this.boxSize.width, this.boxSize.height],
        } as DesignTextBoxObject
    }
}
