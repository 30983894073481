
export interface ParkourDialog {
    open(): void
    closeOk(): void
    closeCancel(): void
}

export enum ParkourDialogId {
    COMPETITION_PARAMS = 'CompetitionParams',
    DISPLAY_OPTIONS = 'DisplayOptions',
    FIELD_SIZE = 'FieldSize',
    DEBUG_OPTIONS = 'DebugOptions',
    DESIGN_STATS = 'DesignStats',
    MATERIALS = 'Materials',
    VALIDATION = 'Validation',
    RENDER = 'Render',
}