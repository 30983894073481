import {
    ParkourObjectKind, ParkourObject, 
    ParkourObjectDecorations,
    ParkourObjectConfig
} from '../parkour-objects/parkour-object';
import { Landscape } from '../parkour-objects/landscape'
import { CombinationGenerator, ObjectGenerator } from '../parkour-objects/generator';
import { BankObstacle } from '../parkour-objects/bank'
import { Injectable } from '@angular/core'
import { InOutMarker } from '../parkour-objects/in-out-marker'
import { TextBox } from '../parkour-objects/text-box'
import { ObstacleWithBars } from '../parkour-objects/obstacle-with-bars'
import { Obstacle } from '../parkour-objects/obstacle'
import { PathObject } from '../parkour-objects/path-object'
import { FinishStart } from '../parkour-objects/finish-start'
import { Terminal } from '../parkour-objects/terminal'
import { ParkourBannerObject } from '../parkour-objects/banner'
import { Drawing } from '../parkour-objects/drawing'
import { UserImage } from '../parkour-objects/user-image'

type CombinationGeneratorOptions = {
    combination: ParkourObjectTemplate[]
}

export type ParkourObjectTemplateOptions = CombinationGeneratorOptions

// An extension to the object kind, which can be used to pass additional parameters to
// differentiate the created object
export type ParkourObjectTemplate = ParkourObjectKind & {
    decorations?: any,
    content?: string,
    border?: boolean,
    fontFamily?: string,
    txtScale?: number,
    smoothing?: boolean,
    imageId?: string,
    options?: ParkourObjectTemplateOptions,
}

@Injectable({
    providedIn: 'root'
})
export class ParkourObjectFactory {
    // Base objects list - contains objects that can be created using one of the ParkourObject classes
    // Each object on OBJECTS_LIST must have unique kind
    private readonly objects: { [id: string]: ParkourObjectKind } = {
        'gate': {
            kind: 'gate',
            bars: 1,
            stripes: 3,
            name: $localize`Bramka`,
            objectClass: ObstacleWithBars
        },
        'vertical-vector': {
            kind: 'vertical-vector',
            bars: 1,
            name: $localize`Stacjonata`,
            objectClass: ObstacleWithBars
        },
        'oxer-vector': {
            kind: 'oxer-vector',
            bars: 2,
            name: $localize`Okser`,
            objectClass: ObstacleWithBars
        },
        'triple-barre-vector': {
            kind: 'triple-barre-vector',
            bars: 3,
            name: $localize`Triple Barre`,
            objectClass: ObstacleWithBars
        },
        'ditch': {
            kind: 'ditch',
            bars: 0,
            name: $localize`Rów`,
            objectClass: ObstacleWithBars
        },
        'wall': {
            kind: 'wall',
            bars: 0,
            name: $localize`Mur`,
            objectClass: ObstacleWithBars
        },
        'bank': {
            kind: 'bank',
            icon: 'assets/bank.png',
            name: $localize`Bankiet`,
            url: 'assets/bank.png',
            doNotDropOnPath: true,
            objectClass: BankObstacle
        },
        'corner-oxer': {
            kind: 'corner-oxer',
            bars: 2,
            name: $localize`Korner (2)`,
            objectClass: ObstacleWithBars
        },
        'corner-triple-barre': {
            kind: 'corner-triple-barre',
            bars: 3,
            name: $localize`Korner (3)`,
            objectClass: ObstacleWithBars
        },
        'start': {
            kind: 'start',
            icon: 'assets/start.png',
            name: $localize`Start`,
            menuText: $localize`Drugi lub trzeci start dla rozgrywki`,
            objectClass: Terminal
        },
        'finish': {
            kind: 'finish',
            icon: 'assets/finish.png',
            name: $localize`Meta`,
            objectClass: Terminal
        },
        'finish-start': {
            kind: 'finish-start',
            url: 'assets/start-finish.svg',
            icon: 'assets/finish-start.svg',
            name: $localize`Meta/Start`,
            menuText: $localize`Meta/Start dla drugiej fazy`,
            objectClass: FinishStart
        },
        'grass-1': {
            kind: 'grass-1',
            url: 'assets/grass-1.svg',
            name: $localize`Rośliny 1`,
            objectClass: Landscape,
            doNotShowInChangeList: true,
        },
        'grass-2': {
            kind: 'grass-2',
            url: 'assets/grass-2.svg',
            name: $localize`Rośliny 2`,
            objectClass: Landscape,
            doNotShowInChangeList: true,
        },
        'service-hut': {
            kind: 'service-hut',
            url: 'assets/service-hut.svg',
            name: $localize`Namiot obsługi`,
            objectClass: Landscape,
            doNotShowInChangeList: true,
        },
        'in-out': {
            kind: 'in-out',
            url: 'assets/in-out.svg',
            icon: 'assets/in-out-toolbar.svg',
            name: $localize`Wjazd/wyjazd`,
            objectClass: InOutMarker
        },
        'text-box': {
            kind: 'text-box',
            icon: 'assets/text-box.png',
            name: $localize`Napis`,
            menuText: $localize`Dowolny tekst wprowadzony przez użytkownika`,
            objectClass: TextBox
        },
        'user-image': {
            kind: 'user-image',
            icon: 'assets/user-image.png',
            name: $localize`Obrazek`,
            menuText: $localize`Dowolny obrazek załadowany przez użytkownika`,
            objectClass: UserImage
        },
        'drawing': {
            kind: 'drawing',
            icon: 'assets/drawing-straight.png',
            name: $localize`Dowolny kształt`,
            objectClass: Drawing
        },
        'table-banner': {
            kind: 'table-banner',
            icon: 'assets/table.png',
            name: $localize`Tabelka`,
            objectClass: ParkourBannerObject
        },
        'car': {
            kind: 'car',
            url: 'assets/car.svg',
            name: $localize`Samochód`,
            objectClass: Landscape,
            doNotShowInChangeList: true,
        },
        'clock': {
            kind: 'clock',
            url: 'assets/clock.svg',
            name: $localize`Zegar`,
            objectClass: Landscape,
            doNotShowInChangeList: true,
        },
        'object-generator': {
            kind: 'object-generator',
            url: 'assets/combination-2.png',
            name: $localize`Generator obiektów`,
            objectClass: ObjectGenerator,
            doNotShowInChangeList: true,
        }
    }

    // This list is used to display icons of objects in the toolbar.
    private readonly obstacleTemplates: ParkourObjectTemplate[] = [
        this.objects['gate'],
        this.objects['vertical-vector'],
        this.objects['oxer-vector'],
        this.objects['triple-barre-vector'],
        this.objects['corner-oxer'],
        this.objects['corner-triple-barre'],
        {
            ...this.objects['ditch'],
            decorations: { water: true },
            name: $localize`Rów z wodą`
        },
        {
            ...this.objects['vertical-vector'],
            decorations: { water: true },
            name: $localize`Liverpool`
        },
        this.objects['wall'],
    ]

    private readonly objectTemplates: ParkourObjectTemplate[] = this.obstacleTemplates.concat([
        this.objects['in-out'],
        {
            ...this.objects['object-generator'],
            name: $localize`Szereg podwójny`,
            url: 'assets/combination-2.png',
            options: {
                combination: [
                    this.objects['vertical-vector'],
                    this.objects['vertical-vector'],
                ]
            },
            objectClass: CombinationGenerator
        },
        {
            ...this.objects['object-generator'],
            name: $localize`Szereg potrójny`,
            url: 'assets/combination-3.png',
            options: {
                combination: [
                    this.objects['vertical-vector'],
                    this.objects['vertical-vector'],
                    this.objects['vertical-vector'],
                ]
            },
            objectClass: CombinationGenerator
        },
        this.objects['start'],
        this.objects['finish'],
        this.objects['finish-start'],
        this.objects['text-box'],
        this.objects['user-image'],
        this.objects['drawing'],
        {
            ...this.objects['text-box'],
            name: $localize`Jury`,
            icon: 'assets/jury.png',
            content: 'JURY',
            border: true,
            fontFamily: 'Roboto',
            txtScale: 2,
            menuText: $localize`Komisja sędziowska`
        },
        this.objects['table-banner'],
        this.objects['bank'],
    ])

    public readonly ParkourObjectDecorations = ParkourObjectDecorations
    private readonly objectDefList: ParkourObjectKind[]
    private readonly obstacleDefList: ParkourObjectKind[]
    private readonly objectDefListWithFS: ParkourObjectKind[]
    private readonly obstacleDefListWithFS: ParkourObjectKind[]

    constructor() {
        const fs = this.objects['finish-start']
        this.obstacleDefList = Object.values(this.objects).filter((o) => o.objectClass.prototype instanceof Obstacle)
        this.obstacleDefListWithFS = [fs, ...this.obstacleDefList]
        this.objectDefList = Object.values(this.objects).filter((o) => {
            return !(o.objectClass.prototype instanceof PathObject) && !o.doNotShowInChangeList
        }).concat(this.obstacleDefList)
        this.objectDefListWithFS = [fs, ...this.objectDefList]
    }

    getObject(config: ParkourObjectConfig): ParkourObject | ObjectGenerator {
        const object = config.object
        if (!object.kind) {
            throw new Error('missing kind')
        }
        // restore the kind description from the base object list
        // object.kind can be a 'string' directly having kind key name or a ParkourObjectKind with key name inside
        let key = (Object.prototype.toString.call(object.kind) == '[object String]') ? object.kind : object.kind.kind
        if (!key) {
            throw new Error('object kind is malformed')
        }
        // temporary to get rid of logo objects
        if (key === 'logo') {
            key = 'user-image'
        }
        const kind: ParkourObjectKind = this.objects[key]
        if (!kind) {
            throw new Error('unknown base kind: ' + key)
        }
        let cl = object.kind.objectClass || kind.objectClass
        const obj = new cl({
            ...config,
            object: {
                ...object,
                kind: kind,
            }
        } as ParkourObjectConfig)
        return obj
    }

    getObstacleDefinitions(): ParkourObjectKind[] {
        return this.obstacleDefList
    }

    getObstacleTemplates(): ParkourObjectTemplate[] {
        return this.obstacleTemplates
    }

    getObjectTemplates(): ParkourObjectTemplate[] {
        return this.objectTemplates
    }

    getDefinition(kind: string): ParkourObjectKind {
        return this.objects[kind]
    }

    getObjectDefinitionsByCategory(category: string, currentObject: ParkourObjectKind): ParkourObjectKind[] {
        if (currentObject.kind === 'start' || currentObject.kind === 'finish') {
            return []
        }
        if (category === 'objects') {
            return currentObject.kind === 'finish-start' ? this.objectDefListWithFS : this.objectDefList
        } else if (category === 'obstacles') {
            return currentObject.kind === 'finish-start' ? this.obstacleDefListWithFS : this.obstacleDefList
        }
        return []
    }
}
