import * as B from '@babylonjs/core'
import { MaterialManager } from './parkour-renderer.materials'
import { DesignSchema } from '../design.schema'
import { rotateTextureUV as rotateMeshUV } from './parkour-renderer.component'

export class RenderedStands {
    private _chairMaterial?: B.Material
    private _chair?: B.Mesh
    private _rowStandMaterial?: B.Material
    stands?: B.TransformNode
    private _visible: boolean = true

    get visible(): boolean {
        return this._visible
    }

    set visible(v: boolean) {
        this.stands?.getChildMeshes().forEach(m => m.isVisible = v)
        this._visible = v
    }

    private _createChair(pos: B.Vector3, rotation: number, seatWidth: number, seatDepth: number, seatThickness: number, backrestHeight: number) {    
        let chair
        if (!this._chair) {
            const texture = this.materialManager.getTexture('stand-chair', () => {
                const t = new B.Texture('assets/textures/plastic-seat.jpg')
                t.vScale = 0.5
                t.uScale = 0.5
                t.level = 2
                return t
            })
            this._chairMaterial = this.materialManager.getMaterial('stand-chair', (name: string) => {
                const m = new B.StandardMaterial(name, this.scene)
                m.diffuseTexture = texture
                m.diffuseColor = new B.Color3(0.7, 0.3, 0.2)
                return m
            })
            const seat = B.MeshBuilder.CreateBox('seat', {
                width: seatWidth,
                depth: seatDepth,
                height: seatThickness,
                updatable: false,
            }, this.scene)
            seat.material = this._chairMaterial
            seat.position.y = 0
        
            const backrest = B.MeshBuilder.CreateBox('backrest', {
                width: seatWidth,
                depth: seatThickness,
                height: backrestHeight,
                updatable: false,
            }, this.scene)
            backrest.material = this._chairMaterial
            backrest.position.y = backrestHeight / 2 - seatThickness / 2
            backrest.position.z = -seatDepth / 2
            chair = this._chair = B.Mesh.MergeMeshes([seat, backrest], true) ?? undefined
        } else {
            chair = this._chair.createInstance('chair-copy')
        }
        if (chair) {
            chair.position = pos
            chair.rotate(B.Vector3.Up(), rotation)
        }
        return chair
    }

    private _createRowStand(pos: B.Vector3, width: number, height: number, depth: number): B.Mesh {
        const texture1 = this.materialManager.getTexture('stand-tex1', () => {
            const t = new B.Texture('assets/textures/concrete-19.jpg')
            t.vScale = 10
            return t
        })
        const texture2 = this.materialManager.getTexture('stand-tex1-ao', () => {
            const t = new B.Texture('assets/textures/concrete-19-ao.jpg')
            t.vScale = 10
            return t
        })
        this._rowStandMaterial = this.materialManager.getMaterial('stand-row', (name: string) => {
            const m = new B.StandardMaterial(name, this.scene)
            m.diffuseColor = new B.Color3(0.7, 0.7, 0.7)
            m.specularColor = new B.Color3(0, 0, 0)
            m.roughness = 0.9
            m.diffuseTexture = texture1
            m.ambientTexture = texture2
            return m
        })
        const rowStand = B.MeshBuilder.CreateBox('stand-row', {
            width: width,
            depth: depth,
            height: height,
            updatable: false,
        }, this.scene)
        rowStand.material = this._rowStandMaterial
        rotateMeshUV(rowStand, [0, 1, 2, 3])
        rowStand.checkCollisions = true
        rowStand.position = pos
        return rowStand
    }

    private _createStandsWithChairs(w: number, h: number) {
        const standDistanceFromFence = 2
        const standElevation = 0.0
 
        const seatsPerSection = 10
        const passageGapSeats = 3
        const rowGapV = 0.3
        const rowGapH = 0.2
        const rows = w > 80 || h > 80 ? 2 : 3
        const seatGap = 0.3
        const seatWidth = 0.5
        const seatDepth = 0.4
        const seatThickness = 0.03
        const backrestHeight = 0.4
    
        const standDepth = (seatDepth + rowGapH) * rows
        const sides = [
            { x: 0, z: -standDepth - standDistanceFromFence, width: w, rotation: 0 },
            { x: w, z: standDepth + standDistanceFromFence + h, width: w, rotation: Math.PI },
            { x: -standDepth - standDistanceFromFence, z: h, width: h, rotation: Math.PI / 2 },
            { x: standDepth + standDistanceFromFence + w, z: 0, width: h, rotation: -Math.PI / 2 },
        ]
    
        this.stands = new B.TransformNode('stands')

        sides.forEach(({ x, z, width, rotation }) => {
            const stand: B.TransformNode = new B.TransformNode('stand')
            const seatTotalWidth = seatWidth + seatGap
            let numSeats = Math.floor(width / seatTotalWidth)
            for (let i = 0; i < rows; i++) {
                let rowY = (rows - i) * rowGapV
                let rowZ = i * (rowGapH + seatDepth)

                const box = this._createRowStand(new B.Vector3(width / 2, (rowY - seatThickness) / 2 - standElevation / 2, rowZ), 
                    width, rowY + standElevation, seatDepth + rowGapH)
                box.parent = stand
                if (i < rows - 1) {
                    for (let j = 0; j < numSeats; j++) {                
                        const s = j % (seatsPerSection + passageGapSeats) 
                        if (s < seatsPerSection) {
                            let x = j * seatTotalWidth + seatTotalWidth / 2
                            let chair = this._createChair(
                                new B.Vector3(x, rowY, rowZ),
                                0,
                                seatWidth, seatDepth, seatThickness, backrestHeight
                            )
                            if (chair) {
                                chair.parent = stand
                                this.shadowGenerator.addShadowCaster(chair)
                            }
                        }
                    }
                }
            }
            stand.position = new B.Vector3(x, standElevation, z)
            stand.rotation.y = rotation
            stand.parent = this.stands ?? null
        })
    }
    
    constructor(
        private scene: B.Scene, 
        private materialManager: MaterialManager,
        private shadowGenerator: B.ShadowGenerator, 
        design: DesignSchema)
    {
        const pw = design.parkourWidth, ph = design.parkourHeight
        this._createStandsWithChairs(pw, ph)
    }
}

