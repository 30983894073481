import * as B from '@babylonjs/core'
import { DesignBaseObject, DesignSchema, DesignSvgImageObject } from '../design.schema'
import { MaterialManager } from './parkour-renderer.materials'
import { RenderedGlbObject } from './parkour-renderer-glb'

export class RenderedBush extends RenderedGlbObject {
    constructor(
        protected scene: B.Scene, 
        materialManager: MaterialManager,
        object: DesignBaseObject, 
        design: DesignSchema, 
        protected shadowGenerator: B.ShadowGenerator)
    {
        super('assets/objects/palm-opt.glb', scene, materialManager, object, design, shadowGenerator)

        this.promise.then(mesh => {
            const img = object as DesignSvgImageObject
            let size
            if (img.imageSize) {
                size = Math.max(img.imageSize[0], img.imageSize[1])
            } else {
                size = 530
            }
            const s = 4 * size / 530
            const scale = new B.Vector3(s, s, s)
            const pos = new B.Vector3(this.x, 0, this.y)
            mesh.scaling = scale
            mesh.position = pos.add(new B.Vector3(0, -0.22 * scale.y, 0))    
        })        
    }
}