import { ElementRef, Injectable } from "@angular/core"
import { Article, CompetitionLocation, LimitRange, Speed } from "../design.schema"
import { ParkourConfig } from "../parkour.config"
import { feetToCm, feetToMeters, justifyStrings } from "../utils"
import { ConversionService, UnitSet } from "./conversion.service"

export enum ClassNo {
    NONE = '',
    MINI_LL = 'mini-ll',
    LL = 'll',
    L = 'l',
    P = 'p',
    N = 'n',
    C = 'c',
    CC = 'cc',
    CS = 'cs',
    L1 = 'l1',
    P1 = 'p1',
    N1 = 'n1',
    C1 = 'c1',
    CC1 = 'cc1',
    CS1 = 'cs1',
    SMALL_TOUR = 'sm-tour',
    MED_TOUR = 'med-tour',
    BIG_TOUR = 'big-tour',
    NC1 = 'nc1',
    NC2 = 'nc2',
    NC3 = 'nc3',
    NC4 = 'nc4',
    NC5 = 'nc5'
}

export enum CompetitionTable {
    NONE = '',
    TABLE_A = 'A',
    TABLE_C = 'C'
}

type ObstacleHeightLimits = { [key in ClassNo]?: LimitRange }
export type ObstacleHeights = { [key in ClassNo]?: number }

export type SpeedLimits = {
    values: Speed[]
    default?: Speed
}

type SpeedSet = {
    [key in CompetitionLocation]: SpeedLimits
}

export type TimeLimits = {
    [key in CompetitionLocation]: LimitRange
}

export type LimitSet = {
    height?: LimitRange
    width?: {
        liverpool?: LimitRange
        ditch?: LimitRange
        oxer: LimitRange
        tripleBarre: LimitRange
        wall?: LimitRange
        vertical?: LimitRange
    }
    length?: LimitRange
    speeds?: SpeedSet
    obstacles?: LimitRange
    efforts?: LimitRange
    distance?: LimitRange
    twoVerticalsHeightLimit?: number
    sixOtherHeightLimit?: number
    twoSpreadHeightLimit?: number
    twoSpreadWidthLimit?: number
    noMaxTime?: boolean
    timeLimits?: TimeLimits
    table?: CompetitionTable
    allowedRounds?: AllowedRounds
    noRoute?: boolean
    userCanEditArrows?: boolean
    noCombinations?: boolean
    noFinishLine?: boolean
    combinations?: LimitRange
    minCombDistance?: LimitRange
    maxCombDistance?: LimitRange
    jumpBeforeLen?: LimitRange
    landAfterLen?: LimitRange
    strideMinLen?: LimitRange
    strideMaxLen?: LimitRange
}

export type LimitSetCombined = LimitSet & {
    heights?: ObstacleHeightLimits
}

export enum AllowedRounds {
    ONE_ROUND_ONLY = 1,
    OPTIONAL_JUMP_OFF,
    MANDATORY_JUMP_OFF,
    OPTIONAL_TWO_PHASES,
    MANDATORY_TWO_PHASES
}

type CompetitionLimits = {
    [key in (ClassNo | Article)]?: LimitSet
}

type NameValue = {
    name: string,
    disabled?: boolean
}

export type ClassNoNameValue = NameValue & {
    value: ClassNo,
}

export type CompLocNameValue = NameValue & {
    value: CompetitionLocation,
}

export type ArticleOption = {
    descr: string,
    article: Article,
}

@Injectable({
    providedIn: 'root'
})
export class LimitsService {
    private readonly timeLimitsTableC: TimeLimits = {
        indoor: { min: 180, max: 180, default: 180 },
        outdoor: { min: 180, max: 180, default: 180 },
    };

    public static readonly defaultsMetric: LimitSet = {
        height: { min: 0, max: 200, default: 110 },
        width: {
            liverpool: { min: 50, max: 200, default: 150 },
            ditch: { min: 50, max: 500, default: 300 },
            oxer: { min: 50, max: 200, default: 110 },
            tripleBarre: { min: 50, max: 250, default: 130 },
            wall: { min: 10, max: 100, default: 30 },
            vertical: { min: 10, max: 50, default: 10 }
        },
        length: { min: 200, max: 500, default: 350 },
        speeds: {
            outdoor: {
                values: [300, 325, 350, 375, 400],
                default: 350
            },
            indoor: {
                values: [300, 325, 350, 375, 400],
                default: 350
            }
        },
        obstacles: { min: 1, max: 30 },
        efforts: { min: 1, max: 30 },
        minCombDistance: { min: 0, max: undefined, default: 6 },
        maxCombDistance: { min: undefined, max: 50, default: 12 },
        jumpBeforeLen: { min: 0, max: 4, default: 1.5 },
        landAfterLen: { min: 0, max: 4, default: 1.5 },
        strideMinLen: { min: 2, max: 5, default: 3.5 },
        strideMaxLen: { min: 2, max: 5, default: 4 },
    }

    public static readonly defaultsFeet: LimitSet = {
        height: { min: feetToCm(1), max: feetToCm(7), default: feetToCm(3.6) },
        width: {
            liverpool: { min: feetToCm(1.5), max: feetToCm(7), default: feetToCm(5) },
            ditch: { min: feetToCm(1.5), max: feetToCm(16.5), default: feetToCm(9.9) },
            oxer: { min: feetToCm(1.5), max: feetToCm(7), default: feetToCm(3.7) },
            tripleBarre: { min: feetToCm(1.5), max: feetToCm(8.3), default: feetToCm(4.3) },
            wall: { min: feetToCm(0.3), max: feetToCm(7), default: feetToCm(1) },
            vertical: { min: feetToCm(0.3), max: feetToCm(2), default: feetToCm(0.3) }
        },
        length: { min: feetToCm(6.5), max: feetToCm(16.5), default: feetToCm(11.5) },
        speeds: {
            outdoor: {
                values: [300, 325, 350, 375, 400],
                default: 350
            },
            indoor: {
                values: [300, 325, 350, 375, 400],
                default: 350
            }
        },
        obstacles: { min: 1, max: 30 },
        efforts: { min: 1, max: 30 },
        minCombDistance: { min: 0, max: undefined, default: feetToMeters(1.8) },
        maxCombDistance: { min: undefined, max: feetToMeters(165), default: feetToMeters(40) },
        jumpBeforeLen: { min: 0, max: feetToMeters(14), default: feetToMeters(5) },
        landAfterLen: { min: 0, max: feetToMeters(14), default: feetToMeters(5) },
        strideMinLen: { min: feetToMeters(6.5), max: feetToMeters(17), default: feetToMeters(11.5)},
        strideMaxLen: { min: feetToMeters(6.5), max: feetToMeters(17), default: feetToMeters(13.5) },
    }

    private static readonly base: CompetitionLimits = {
        [ClassNo.MINI_LL]: {
            height: { max: 85, default: 85 },
            width: {
                ditch: { banned: true },
                liverpool: { max: 100, default: 100 },
                oxer: { max: 90, default: 90 },
                tripleBarre: { max: 110, default: 110 }
            },
            speeds: {
                outdoor: { values: [300, 325, 350], default: 350 },
                indoor: { values: [300, 325], default: 325 }
            },
            obstacles: { min: 8, max: 10 },
            efforts: { max: 11 }
        },
        [ClassNo.LL]: {
            height: { max: 90, default: 90 },
            width: {
                ditch: { banned: true },
                liverpool: { max: 100, default: 100 },
                oxer: { max: 90, default: 90 },
                tripleBarre: { max: 110, default: 110 }
            },
            speeds: {
                outdoor: { values: [325, 350], default: 350 },
                indoor: { values: [325], default: 325 }
            },
            obstacles: { min: 8, max: 10 },
            efforts: { max: 11 }
        },
        [ClassNo.L]: {
            height: { max: 100, default: 100 },
            width: {
                ditch: { banned: true },
                liverpool: { max: 100, default: 100 },
                oxer: { max: 110, default: 100 },
                tripleBarre: { max: 130, default: 120 }
            },
            speeds: {
                outdoor: { values: [350], default: 350 },
                indoor: { values: [325, 350], default: 350 }
            },
            obstacles: { min: 9, max: 11 },
            efforts: { max: 13 },
            combinations: { min: 1 },
        },
        [ClassNo.P]: {
            height: { max: 110, default: 110 },
            width: {
                ditch: { banned: true },
                liverpool: { max: 150, default: 100 },
                oxer: { max: 130, default: 110 },
                tripleBarre: { max: 150, default: 130 }
            },
            speeds: {
                outdoor: { values: [350], default: 350 },
                indoor: { values: [325, 350], default: 350 }
            },
            obstacles: { min: 10, max: 12 },
            efforts: { max: 14 },
            combinations: { min: 1 },
        },
        [ClassNo.N]: {
            height: { max: 120, default: 120 },
            width: {
                ditch: { banned: true },
                liverpool: { max: 150, default: 100 },
                oxer: { max: 140, default: 120 },
                tripleBarre: { max: 160, default: 140 }
            },
            speeds: {
                outdoor: { values: [350], default: 350 },
                indoor: { values: [325, 350], default: 350 }
            },
            obstacles: { min: 10, max: 12 },
            efforts: { max: 14 },
            combinations: { min: 1 },
        },
        [ClassNo.C]: {
            height: { max: 130, default: 130 },
            width: {
                ditch: { max: 350, default: 300 },
                oxer: { max: 150, default: 130 },
                tripleBarre: { max: 170, default: 150 }
            },
            speeds: {
                outdoor: { values: [350], default: 350 },
                indoor: { values: [350], default: 350 }
            },
            obstacles: { min: 10, max: 12 },
            efforts: { max: 15 },
            combinations: { min: 1 },
        },
        [ClassNo.CC]: {
            height: { max: 140, default: 140 },
            width: {
                ditch: { max: 400, default: 300 },
                oxer: { max: 160, default: 140 },
                tripleBarre: { max: 180, default: 160 }
            },
            speeds: {
                outdoor: { values: [350, 375], default: 375 },
                indoor: { values: [350], default: 350 }
            },
            obstacles: { min: 10, max: 13 },
            efforts: { max: 16 },
            combinations: { min: 1 },
        },
        [ClassNo.CS]: {
            height: { max: 150, default: 150 },
            width: {
                ditch: { max: 400, default: 300 },
                oxer: { max: 180, default: 150 },
                tripleBarre: { max: 200, default: 170 }
            },
            speeds: {
                outdoor: { values: [350, 375, 400], default: 400 },
                indoor: { values: [350], default: 350 }
            },
            obstacles: { min: 10, max: 13 },
            efforts: { max: 16 },
            combinations: { min: 1 },
        },
        [ClassNo.NC1]: {
            height: { min: 110, max: 125, default: 125 },
            width: {
                oxer: { max: 145, default: 145 },
                tripleBarre: { max: 170, default: 165 },
                ditch: { min: 270, max: 300, default: 300 }
            },
            speeds: {
                outdoor: { values: [350], default: 350 },
                indoor: { values: [350], default: 350 }
            },
            obstacles: { min: 12, max: 12 },
            distance: { min: 450, max: 650 },
            twoVerticalsHeightLimit: 125,
            sixOtherHeightLimit: 115,
            twoSpreadHeightLimit: 120,
            twoSpreadWidthLimit: 135,
            table: CompetitionTable.TABLE_A,
            allowedRounds: AllowedRounds.OPTIONAL_JUMP_OFF
        },
        [ClassNo.NC2]: {
            height: { min: 120, max: 140, default: 140 },
            width: {
                oxer: { max: 160, default: 140 },
                tripleBarre: { max: 190, default: 160 },
                ditch: { min: 320, max: 350, default: 350 }
            },
            speeds: {
                outdoor: { values: [375], default: 375 },
                indoor: { values: [350], default: 350 }
            },
            obstacles: { min: 12, max: 12 },
            distance: { min: 450, max: 650 },
            twoVerticalsHeightLimit: 140,
            sixOtherHeightLimit: 130,
            twoSpreadHeightLimit: 135,
            twoSpreadWidthLimit: 145,
            table: CompetitionTable.TABLE_A,
            allowedRounds: AllowedRounds.OPTIONAL_JUMP_OFF
        },
        [ClassNo.NC3]: {
            height: { min: 135, max: 150, default: 150 },
            width: {
                oxer: { max: 170, default: 150 },
                tripleBarre: { max: 200, default: 170 },
                ditch: { min: 350, max: 370, default: 370 }
            },
            speeds: {
                outdoor: { values: [375], default: 375 },
                indoor: { values: [350], default: 350 }
            },
            obstacles: { min: 12, max: 12 },
            distance: { min: 450, max: 650 },
            twoVerticalsHeightLimit: 150,
            sixOtherHeightLimit: 140,
            twoSpreadHeightLimit: 145,
            twoSpreadWidthLimit: 155,
            table: CompetitionTable.TABLE_A,
            allowedRounds: AllowedRounds.OPTIONAL_JUMP_OFF
        },
        [ClassNo.NC4]: {
            height: { min: 140, max: 155, default: 155 },
            width: {
                oxer: { max: 180, default: 155 },
                tripleBarre: { max: 210, default: 180 },
                ditch: { min: 370, max: 390, default: 390 }
            },
            speeds: {
                outdoor: { values: [400], default: 400 },
                indoor: { values: [350], default: 350 }
            },
            obstacles: { min: 12, max: 12 },
            distance: { min: 450, max: 650 },
            twoVerticalsHeightLimit: 155,
            sixOtherHeightLimit: 145,
            twoSpreadHeightLimit: 150,
            twoSpreadWidthLimit: 160,
            table: CompetitionTable.TABLE_A,
            allowedRounds: AllowedRounds.OPTIONAL_JUMP_OFF
        },
        [ClassNo.NC5]: {
            height: { min: 145, max: 160, default: 160 },
            width: {
                oxer: { max: 200, default: 160 },
                tripleBarre: { max: 220, default: 180 },
                ditch: { min: 380, max: 400, default: 400 }
            },
            speeds: {
                outdoor: { values: [400], default: 400 },
                indoor: { values: [350], default: 350 }
            },
            obstacles: { min: 12, max: 12 },
            distance: { min: 450, max: 650 },
            twoVerticalsHeightLimit: 160,
            sixOtherHeightLimit: 150,
            twoSpreadHeightLimit: 150,
            twoSpreadWidthLimit: 170,
            table: CompetitionTable.TABLE_A,
            allowedRounds: AllowedRounds.OPTIONAL_JUMP_OFF
        },
    };

    private static readonly extended: CompetitionLimits = {
        ...LimitsService.base,
        [ClassNo.SMALL_TOUR]: LimitsService.base[ClassNo.N],
        [ClassNo.MED_TOUR]: LimitsService.base[ClassNo.C],
        [ClassNo.BIG_TOUR]: LimitsService.base[ClassNo.CC],
        [ClassNo.L1]: {
            ...LimitsService.base.l!,
            height: { max: 105, default: 105 },
            width: {
                oxer: { max: 110, default: 105 },
                tripleBarre: { max: 130, default: 125 }
            }
        },
        [ClassNo.P1]: {
            ...LimitsService.base.p!,
            height: { max: 115, default: 115 },
            width: {
                oxer: { max: 130, default: 115 },
                tripleBarre: { max: 150, default: 135 }
            }
        },
        [ClassNo.N1]: {
            ...LimitsService.base.n!,
            height: { max: 125, default: 125 },
            width: {
                oxer: { max: 140, default: 125 },
                tripleBarre: { max: 160, default: 145 }
            }
        },
        [ClassNo.C1]: {
            ...LimitsService.base.c!,
            height: { max: 135, default: 135 },
            width: {
                oxer: { default: 135 },
                tripleBarre: { default: 155 }
            }
        },
        [ClassNo.CC1]: {
            ...LimitsService.base.cc!,
            height: { max: 145, default: 145 },
            width: {
                oxer: { max: 150, default: 145 },
                tripleBarre: { max: 170, default: 165 }
            }
        },
        [ClassNo.CS1]: {
            ...LimitsService.base.cs!,
            height: { max: 155, default: 155 },
            width: {
                oxer: { max: 160, default: 155 },
                tripleBarre: { max: 180, default: 175 }
            }
        }
    };

    private readonly noTimeLimit: TimeLimits = {
        indoor: { min: 0, max: 0, default: 0 },
        outdoor: { min: 0, max: 0, default: 0 }
    };

    private readonly noSpeed: SpeedSet = {
        indoor: { values: [], default: 0 },
        outdoor: { values: [], default: 0 }
    };

    private fromArticles: CompetitionLimits = {
        [Article.ART_238_GENERAL_TABLE_A]: {
            table: CompetitionTable.TABLE_A,
            allowedRounds: AllowedRounds.OPTIONAL_JUMP_OFF
        },
        [Article.ART_238_1_NOT_AGAINST_THE_CLOCK]: {
            table: CompetitionTable.TABLE_A,
            allowedRounds: AllowedRounds.OPTIONAL_JUMP_OFF
        },
        [Article.ART_238_1_1_NOT_AGAINST_THE_CLOCK]: {
            table: CompetitionTable.TABLE_A,
            allowedRounds: AllowedRounds.OPTIONAL_JUMP_OFF
        },
        [Article.ART_238_1_2_NOT_AGAINST_THE_CLOCK]: {
            table: CompetitionTable.TABLE_A,
            allowedRounds: AllowedRounds.OPTIONAL_JUMP_OFF
        },
        [Article.ART_238_1_3_NOT_AGAINST_THE_CLOCK]: {
            table: CompetitionTable.TABLE_A,
            allowedRounds: AllowedRounds.OPTIONAL_JUMP_OFF
        },
        [Article.ART_238_2_1_AGAINST_THE_CLOCK]: {
            table: CompetitionTable.TABLE_A,
            allowedRounds: AllowedRounds.OPTIONAL_JUMP_OFF
        },
        [Article.ART_238_2_2_AGAINST_THE_CLOCK]: {
            table: CompetitionTable.TABLE_A,
            allowedRounds: AllowedRounds.OPTIONAL_JUMP_OFF
        },
        [Article.ART_238_2_3_AGAINST_THE_CLOCK]: {
            table: CompetitionTable.TABLE_A,
            allowedRounds: AllowedRounds.OPTIONAL_JUMP_OFF
        },
        [Article.ART_239_GENERAL_TABLE_C]: {
            noMaxTime: true,
            timeLimits: this.timeLimitsTableC,
            table: CompetitionTable.TABLE_C
        },
        [Article.ART_261_NORMAL_AND_GP]: {
            table: CompetitionTable.TABLE_A,
            allowedRounds: AllowedRounds.OPTIONAL_JUMP_OFF
        },
        [Article.ART_262_POWER_AND_SKILL]: {
            noMaxTime: true,
            timeLimits: this.noTimeLimit,
            table: CompetitionTable.TABLE_A,
            allowedRounds: AllowedRounds.OPTIONAL_JUMP_OFF
        },
        [Article.ART_262_2_PUISSANCE]: {
            noMaxTime: true,
            timeLimits: this.noTimeLimit,
            table: CompetitionTable.TABLE_A,
            allowedRounds: AllowedRounds.OPTIONAL_JUMP_OFF,
            noCombinations: true,
        },
        [Article.ART_262_3_SIX_BAR]: {
            noMaxTime: true,
            timeLimits: this.noTimeLimit,
            table: CompetitionTable.TABLE_A,
            allowedRounds: AllowedRounds.OPTIONAL_JUMP_OFF
        },
        [Article.ART_262_4_MASTERS]: {
            height: { max: 150, default: 150 },
            width: {
                oxer: { max: 170, default: 150 },
                tripleBarre: { max: 170, default: 170 }
            },
            noMaxTime: true,
            timeLimits: this.noTimeLimit,
            table: CompetitionTable.TABLE_A,
            allowedRounds: AllowedRounds.OPTIONAL_JUMP_OFF
        },
        [Article.ART_263_HUNTING_HANDINESS]: {
            noMaxTime: true,
            timeLimits: this.timeLimitsTableC,
            table: CompetitionTable.TABLE_C,
            allowedRounds: AllowedRounds.ONE_ROUND_ONLY,
            noRoute: true,
            userCanEditArrows: true
        },
        [Article.ART_264_NATIONS_CUP]: {
            table: CompetitionTable.TABLE_A,
            allowedRounds: AllowedRounds.OPTIONAL_JUMP_OFF
        },
        [Article.ART_265_SPONSOR_AND_OTHER_TEAM]: {
            // no rules
        },
        [Article.ART_266_5_1_FAULT_AND_OUT_OBSTACLES]: {
            noMaxTime: true,
            timeLimits: this.noTimeLimit,
            allowedRounds: AllowedRounds.OPTIONAL_JUMP_OFF,
            noCombinations: true
        },
        [Article.ART_266_5_2_FAULT_AND_OUT_TIME]: {
            noMaxTime: true,
            timeLimits: {
                indoor: { min: 45, max: 45, default: 45 },
                outdoor: { min: 60, max: 90, default: 90 }
            },
            allowedRounds: AllowedRounds.ONE_ROUND_ONLY,
            noCombinations: true,
            noFinishLine: true,
        },
        [Article.ART_267_HIT_AND_HURRY]: {
            noMaxTime: true,
            timeLimits: {
                indoor: { min: 45, max: 45, default: 45 },
                outdoor: { min: 60, max: 90, default: 90 }
            },
            allowedRounds: AllowedRounds.ONE_ROUND_ONLY,
            noFinishLine: true,
        },
        [Article.ART_269_ACCUMULATOR]: {
            table: CompetitionTable.TABLE_A,
            allowedRounds: AllowedRounds.OPTIONAL_JUMP_OFF,
            noCombinations: true
        },
        [Article.ART_270_TOP_SCORE]: {
            noMaxTime: true,
            timeLimits: {
                indoor: { min: 45, max: 90, default: 90 },
                outdoor: { min: 45, max: 90, default: 90 }
            },
            allowedRounds: AllowedRounds.ONE_ROUND_ONLY,
            noRoute: true,
        },
        [Article.ART_271_TAKE_YOUR_OWN_LINE]: {
            speeds: this.noSpeed,
            noMaxTime: true,
            timeLimits: {
                indoor: { min: 180, max: 180, default: 180 },
                outdoor: { min: 180, max: 180, default: 180 },
            },
            table: CompetitionTable.TABLE_C,
            allowedRounds: AllowedRounds.ONE_ROUND_ONLY,
            noRoute: true,
            userCanEditArrows: true,
        },
        [Article.ART_273_TWO_ROUNDS]: {
            allowedRounds: AllowedRounds.MANDATORY_JUMP_OFF,
        },
        [Article.ART_274_1_TWO_PHASES_NORMAL]: {
            allowedRounds: AllowedRounds.MANDATORY_TWO_PHASES,
        },
        [Article.ART_274_1_5_1_TWO_PHASES_NORMAL]: {
            table: CompetitionTable.TABLE_A,
            allowedRounds: AllowedRounds.MANDATORY_TWO_PHASES,
        },
        [Article.ART_274_1_5_2_TWO_PHASES_NORMAL]: {
            table: CompetitionTable.TABLE_A,
            allowedRounds: AllowedRounds.MANDATORY_TWO_PHASES,
        },
        [Article.ART_274_1_5_3_TWO_PHASES_NORMAL]: {
            table: CompetitionTable.TABLE_A,
            allowedRounds: AllowedRounds.MANDATORY_TWO_PHASES,
        },
        [Article.ART_274_1_5_4_TWO_PHASES_NORMAL]: {
            allowedRounds: AllowedRounds.MANDATORY_TWO_PHASES,
        },
        [Article.ART_274_1_5_5_TWO_PHASES_NORMAL]: {
            allowedRounds: AllowedRounds.MANDATORY_TWO_PHASES,
        },
        [Article.ART_274_2_TWO_PHASES_SPECIAL]: {
            table: CompetitionTable.TABLE_A,
            allowedRounds: AllowedRounds.MANDATORY_TWO_PHASES,
        },
        [Article.ART_274_2_5_TWO_PHASES_SPECIAL]: {
            table: CompetitionTable.TABLE_A,
            allowedRounds: AllowedRounds.MANDATORY_TWO_PHASES,
        },
        [Article.ART_276_WITH_WINNING_ROUND]: {
            table: CompetitionTable.TABLE_A,
            allowedRounds: AllowedRounds.OPTIONAL_JUMP_OFF,
        },
        [Article.ART_277_DERBY]: {
            allowedRounds: AllowedRounds.OPTIONAL_JUMP_OFF,
        },
        [Article.ART_278_OVER_COMBINATIONS]: {
            allowedRounds: AllowedRounds.OPTIONAL_JUMP_OFF,
        },
    };

    private getClassNoOptions(): ClassNoNameValue[] {
        return [
            { name: 'Mini LL', value: ClassNo.MINI_LL },
            { name: 'LL', value: ClassNo.LL },
            { name: 'L', value: ClassNo.L },
            { name: 'L1', value: ClassNo.L1 },
            { name: 'P', value: ClassNo.P },
            { name: 'P1', value: ClassNo.P1 },
            { name: 'N', value: ClassNo.N },
            { name: 'N1', value: ClassNo.N1 },
            { name: 'C', value: ClassNo.C },
            { name: 'C1', value: ClassNo.C1 },
            { name: 'CC', value: ClassNo.CC },
            { name: 'CC1', value: ClassNo.CC1 },
            { name: 'CS', value: ClassNo.CS },
            { name: 'CS1', value: ClassNo.CS1 },
            { name: $localize`Mała runda`, value: ClassNo.SMALL_TOUR },
            { name: $localize`Średnia runda`, value: ClassNo.MED_TOUR },
            { name: $localize`Duża runda`, value: ClassNo.BIG_TOUR },
            { name: $localize`1* KZ`, value: ClassNo.NC1 },
            { name: $localize`2* KZ`, value: ClassNo.NC2 },
            { name: $localize`3* KZ`, value: ClassNo.NC3 },
            { name: $localize`4* KZ`, value: ClassNo.NC4 },
            { name: $localize`5* KZ`, value: ClassNo.NC5 },
        ]
    }

    private getCompLocOptions(): CompLocNameValue[] {
        return [
            { name: $localize`Otwarte`, value: CompetitionLocation.OUTDOOR },
            { name: $localize`Halowe`, value: CompetitionLocation.INDOOR },
        ]
    }

    private getArticleOptions(): ArticleOption[] {
        return [
            { descr: $localize`:used in a list of items@@classNo.none:brak`, article: Article.NONE },
            {
                descr: Article.ART_238_GENERAL_TABLE_A + ' - ' + $localize`Konkursy ogólne według tabeli A`,
                article: Article.ART_238_GENERAL_TABLE_A
            }, {
                descr: Article.ART_238_1_NOT_AGAINST_THE_CLOCK + ' - ' + $localize`Konkursy dokładności`,
                article: Article.ART_238_1_NOT_AGAINST_THE_CLOCK
            }, {
                descr: Article.ART_238_1_1_NOT_AGAINST_THE_CLOCK + ' - ' + $localize`Konkursy dokładności`,
                article: Article.ART_238_1_1_NOT_AGAINST_THE_CLOCK
            }, {
                descr: Article.ART_238_1_2_NOT_AGAINST_THE_CLOCK + ' - ' + $localize`Konkursy dokładności`,
                article: Article.ART_238_1_2_NOT_AGAINST_THE_CLOCK
            }, {
                descr: Article.ART_238_1_3_NOT_AGAINST_THE_CLOCK + ' - ' + $localize`Konkursy dokładności`,
                article: Article.ART_238_1_3_NOT_AGAINST_THE_CLOCK
            }, {
                descr: Article.ART_238_2_AGAINST_THE_CLOCK + ' - ' + $localize`Konkursy zwykłe`,
                article: Article.ART_238_2_AGAINST_THE_CLOCK
            }, {
                descr: Article.ART_238_2_1_AGAINST_THE_CLOCK + ' - ' + $localize`Konkursy zwykłe`,
                article: Article.ART_238_2_1_AGAINST_THE_CLOCK
            }, {
                descr: Article.ART_238_2_2_AGAINST_THE_CLOCK + ' - ' + $localize`Konkursy zwykłe`,
                article: Article.ART_238_2_2_AGAINST_THE_CLOCK
            }, {
                descr: Article.ART_238_2_3_AGAINST_THE_CLOCK + ' - ' + $localize`Konkursy zwykłe`,
                article: Article.ART_238_2_3_AGAINST_THE_CLOCK
            }, {
                descr: Article.ART_239_GENERAL_TABLE_C + ' - ' + $localize`Konkursy ogólne według tabeli C`,
                article: Article.ART_239_GENERAL_TABLE_C
            }, {
                descr: Article.ART_261_NORMAL_AND_GP + ' - ' + $localize`Konkursy klasyczne i Grand Prix`,
                article: Article.ART_261_NORMAL_AND_GP
            }, {
                descr: Article.ART_262_POWER_AND_SKILL + ' - ' + $localize`Konkursy skoczności i zręczności`,
                article: Article.ART_262_POWER_AND_SKILL
            }, {
                descr: Article.ART_262_2_PUISSANCE + ' - ' + $localize`Konkurs potęgi skoku`,
                article: Article.ART_262_2_PUISSANCE
            }, {
                descr: Article.ART_262_3_SIX_BAR + ' - ' + $localize`Konkurs sześciu barier`,
                article: Article.ART_262_3_SIX_BAR
            }, {
                descr: Article.ART_262_4_MASTERS + ' - ' + $localize`Konkurs Masters`,
                article: Article.ART_262_4_MASTERS
            }, {
                descr: Article.ART_263_HUNTING_HANDINESS + ' - ' + $localize`Konkurs myśliwski lub szybkości zręczności`,
                article: Article.ART_263_HUNTING_HANDINESS
            }, {
                descr: Article.ART_264_NATIONS_CUP + ' - ' + $localize`Konkurs zespołowy`,
                article: Article.ART_264_NATIONS_CUP
            }, {
                descr: Article.ART_265_SPONSOR_AND_OTHER_TEAM + ' - ' + $localize`Inne konkursy zespołowe`,
                article: Article.ART_265_SPONSOR_AND_OTHER_TEAM
            }, {
                descr: Article.ART_266_5_1_FAULT_AND_OUT_OBSTACLES + ' - ' + $localize`Konkurs do pierwszego błędu na określonej liczbie przeszkód`,
                article: Article.ART_266_5_1_FAULT_AND_OUT_OBSTACLES
            }, {
                descr: Article.ART_266_5_2_FAULT_AND_OUT_TIME + ' - ' + $localize`Konkurs do pierwszego błędu z limitem czasu`,
                article: Article.ART_266_5_2_FAULT_AND_OUT_TIME
            }, {
                descr: Article.ART_267_HIT_AND_HURRY + ' - ' + $localize`Konkurs z ustalonym czasem`,
                article: Article.ART_267_HIT_AND_HURRY
            }, {
                descr: Article.ART_269_ACCUMULATOR + ' - ' + $localize`Konkurs o wzrastającym stopniu trudności`,
                article: Article.ART_269_ACCUMULATOR
            }, {
                descr: Article.ART_270_TOP_SCORE + ' - ' + $localize`Konkurs z wyborem przeszkód`,
                article: Article.ART_270_TOP_SCORE
            }, {
                descr: Article.ART_271_TAKE_YOUR_OWN_LINE + ' - ' + $localize`Konkurs z wyborem trasy`,
                article: Article.ART_271_TAKE_YOUR_OWN_LINE
            }, {
                descr: Article.ART_273_TWO_ROUNDS + ' - ' + $localize`Konkurs dwunawrotowy`,
                article: Article.ART_273_TWO_ROUNDS
            }, {
                descr: Article.ART_274_1_TWO_PHASES_NORMAL + ' - ' + $localize`Konkurs dwufazowy normalny`,
                article: Article.ART_274_1_TWO_PHASES_NORMAL
            }, {
                descr: Article.ART_274_1_5_1_TWO_PHASES_NORMAL + ' - ' + $localize`Konkurs dwufazowy normalny`,
                article: Article.ART_274_1_5_1_TWO_PHASES_NORMAL
            }, {
                descr: Article.ART_274_1_5_2_TWO_PHASES_NORMAL + ' - ' + $localize`Konkurs dwufazowy normalny`,
                article: Article.ART_274_1_5_2_TWO_PHASES_NORMAL
            }, {
                descr: Article.ART_274_1_5_3_TWO_PHASES_NORMAL + ' - ' + $localize`Konkurs dwufazowy normalny`,
                article: Article.ART_274_1_5_3_TWO_PHASES_NORMAL
            }, {
                descr: Article.ART_274_1_5_4_TWO_PHASES_NORMAL + ' - ' + $localize`Konkurs dwufazowy normalny`,
                article: Article.ART_274_1_5_4_TWO_PHASES_NORMAL
            }, {
                descr: Article.ART_274_1_5_5_TWO_PHASES_NORMAL + ' - ' + $localize`Konkurs dwufazowy normalny`,
                article: Article.ART_274_1_5_5_TWO_PHASES_NORMAL
            }, {
                descr: Article.ART_274_2_TWO_PHASES_SPECIAL + ' - ' + $localize`Konkurs dwufazowy specjalny`,
                article: Article.ART_274_2_TWO_PHASES_SPECIAL
            }, {
                descr: Article.ART_274_2_5_TWO_PHASES_SPECIAL + ' - ' + $localize`Konkurs dwufazowy specjalny`,
                article: Article.ART_274_2_5_TWO_PHASES_SPECIAL
            }, {
                descr: Article.ART_275_IN_GROUPS + ' - ' + $localize`Konkurs grupowy z rundą zwycięzców`,
                article: Article.ART_275_IN_GROUPS
            }, {
                descr: Article.ART_276_WITH_WINNING_ROUND + ' - ' + $localize`Konkurs z jedną lub dwiema rundami i rundą zwycięzców`,
                article: Article.ART_276_WITH_WINNING_ROUND
            }, {
                descr: Article.ART_277_DERBY + ' - ' + $localize`Konkurs Derby`,
                article: Article.ART_277_DERBY
            }, {
                descr: Article.ART_278_OVER_COMBINATIONS + ' - ' + $localize`Konkurs szeregów`,
                article: Article.ART_278_OVER_COMBINATIONS
            },
        ]
    }

    public readonly articleNames: { [id: string]: string }
    public readonly articleOptions: ArticleOption[]
    public readonly classNoOptions: ClassNoNameValue[]
    public readonly classNoNames: { [id: string]: string }
    public readonly compLocOptions: CompLocNameValue[]

    constructor(
        private conversionService: ConversionService
    ) {
        this.articleOptions = this.getArticleOptions()
        this.articleNames = Object.fromEntries(this.articleOptions.filter(o => o.article).map(o => [o.article, o.descr]))    
        this.classNoOptions = this.getClassNoOptions()
        this.classNoNames = Object.fromEntries(this.classNoOptions.map(o => [o.value, o.name]))    
        this.compLocOptions = this.getCompLocOptions()        
    }

    // based on art number find a parent article number that is supported
    // for example if we support article 271 and art = 271 or 271.1 or 271.1.2 etc.
    // a returned article will be 271
    public getArticleBase(art: Article): Article {
        let matched: Article | undefined = undefined
        // get rid of trailing dot and any white space
        art = art.replace(/(\.$)|\s*/g, '') as Article
        if (art && this.matchArticle(art)) {
            const sections: string[] = art.split('.')
            while (sections.length > 0) {
                const base = sections.join('.') as Article
                if (Object.values(Article).includes(base)) {
                    matched = base
                    break
                }
                sections.pop()
            }
        }
        return matched !== undefined ? matched : Article.NONE
    }

    public getDefaults(unit: UnitSet | undefined): LimitSet {
        const set = unit && this.conversionService.isImperial(unit.size) ? LimitsService.defaultsFeet : LimitsService.defaultsMetric
        const distDefaults = unit && this.conversionService.isImperial(unit.distance) ? LimitsService.defaultsFeet : LimitsService.defaultsMetric
        return {
            ...set,
            distance: distDefaults.distance,
            minCombDistance: distDefaults.minCombDistance,
            maxCombDistance: distDefaults.maxCombDistance,
            jumpBeforeLen: distDefaults.jumpBeforeLen,
            landAfterLen: distDefaults.landAfterLen,
            strideMinLen: distDefaults.strideMinLen,
            strideMaxLen: distDefaults.strideMaxLen,
        }
    }

    // recursively copies properties from defaults to toObj, if property does not exist, does not merge arrays
    // does not overwrite properties
    private mergeProps(defaults: any, toObj: any): any {
        const isObject = ((obj: any) => obj && typeof obj === 'object')
        for (let key of Object.keys(defaults)) {
            const from = defaults[key]
            const to = toObj[key]
            if (!to) {
                toObj[key] = from
            } else if (Array.isArray(to) && Array.isArray(from)) {
                // preserve arrays, don't copy
            } else if (isObject(from) && isObject(to)) {
                toObj[key] = this.mergeProps(from, to)
            }
        }
        return toObj
    }

    private merge(defaults: any, to: any): any {
        const obj: any = structuredClone(to)
        Object.keys(obj).forEach(key => {
            this.mergeProps(defaults, obj[key])
        })
        return obj
    }

    // recursively copies properties from defaults to toObj, overwriting properties that exist, replaces arrays
    private copyProps(fromObj: any, toObj: any): any {
        const isObject = ((obj: any) => obj && typeof obj === 'object')
        for (let key of Object.keys(fromObj)) {
            const from = fromObj[key]
            const to = toObj[key]
            if (Array.isArray(to) && Array.isArray(from) && from.length > 0) {
                toObj[key] = from
            } else if (isObject(from) && isObject(to)) {
                toObj[key] = this.copyProps(from, to)
            } else {
                toObj[key] = from
            }
        }
        return toObj
    }

    private copy(from: any, to: any) {
        const obj: any = structuredClone(to)
        this.copyProps(from, obj)
        return obj
    }

    public isSpeedValid(classes: ClassNo[], loc: CompetitionLocation, article: Article, speed: number, unitSet: UnitSet): boolean {
        const speeds = this.getSpeedList(classes, loc, article, unitSet)
        return speeds !== undefined && speeds.includes(speed)
    }

    public getSpeedList(classes: ClassNo[], loc: CompetitionLocation, article: Article, unitSet: UnitSet): number[] | undefined {
        const range = this.getManyLimits(classes, article, unitSet) || this.getDefaults(unitSet)
        return range.speeds ? range.speeds[loc].values : undefined
    }

    private addArticleLimits(limits?: LimitSet, article?: Article): LimitSet | undefined {
        if (limits && article) {
            const fromArticle = this.fromArticles[article]
            if (fromArticle) {
                limits = this.copy(fromArticle, limits)
            }
        }
        return limits
    }

    private getExtendedLimits(classNo: ClassNo, unitSet: UnitSet | undefined): LimitSet {
        const limits =  LimitsService.extended[classNo]
        if (!limits) {
            return this.getDefaults(unitSet)
        }
        return limits
    }

    // full limits to control configuratio of sizes, always has all values
    private getLimits(classNo: ClassNo, article: Article | undefined, unitSet: UnitSet | undefined): LimitSet {
        const defaults = this.getDefaults(unitSet)
        let limits
        if (classNo === ClassNo.NONE) {
            limits = defaults
        } else {
            limits = this.merge(defaults, LimitsService.extended)
        }
        let range: LimitSet | undefined = limits[classNo]
        range = this.addArticleLimits(range, article)
        return range || defaults
    }

    // andMode = true  - intersection of the two limits
    // andMode = false - sum of the two limits
    private _mergeLimitRanges(r1: LimitRange | undefined, r2: LimitRange | undefined,
        andMode: boolean): LimitRange | undefined | null {
        if (!r1 || !r2) {
            return r1 || r2
        }
        const r: LimitRange = {}
        if (andMode) {
            if (r1.min && r2.min) {
                r.min = Math.max(r1.min, r2.min)
            } else {
                r.min = r1.min || r2.min
            }
            if (r1.max && r2.max) {
                r.max = Math.min(r1.max, r2.max)
            } else {
                r.max = r1.max || r2.max
            }
            if ((r.max !== undefined) && (r.min !== undefined) && r.max < r.min) {
                return null
            }
            if (r1.default && r2.default) {
                r.default = Math.min(r1.default, r2.default)
            } else {
                r.default = r1.default || r2.default
            }
            r.banned = r1.banned && r2.banned
        } else { // or mode
            if (r1.min && r2.min) {
                r.min = Math.min(r1.min, r2.min)
            } else {
                r.min = r1.min || r2.min
            }
            if (r1.max && r2.max) {
                r.max = Math.max(r1.max, r2.max)
            } else {
                r.max = r1.max || r2.max
            }
            if (r1.default && r2.default) {
                r.default = Math.max(r1.default, r2.default)
            } else {
                r.default = r1.default || r2.default
            }
            r.banned = r1.banned || r2.banned
        }
        return r
    }

    private _mergeNumbers(n1: number | undefined, n2: number | undefined, maxMode: boolean): number | undefined {
        if (!n1 || !n2) {
            return n1 || n2
        }
        return maxMode ? Math.max(n1, n2) : Math.min(n1, n2)
    }

    private _mergeAllowedRounds(a1: AllowedRounds | undefined, a2: AllowedRounds | undefined): AllowedRounds | undefined | null {
        if (!a1 || !a2) {
            return a1 || a2
        }
        if ((a1 === AllowedRounds.MANDATORY_JUMP_OFF || a1 === AllowedRounds.OPTIONAL_JUMP_OFF) &&
            (a2 === AllowedRounds.MANDATORY_TWO_PHASES || a2 === AllowedRounds.OPTIONAL_TWO_PHASES) ||
            (a2 === AllowedRounds.MANDATORY_JUMP_OFF || a2 === AllowedRounds.OPTIONAL_JUMP_OFF) &&
            (a1 === AllowedRounds.MANDATORY_TWO_PHASES || a1 === AllowedRounds.OPTIONAL_TWO_PHASES)) {
            return null
        }
        if (a1 === AllowedRounds.ONE_ROUND_ONLY && a2 !== AllowedRounds.ONE_ROUND_ONLY ||
            a2 === AllowedRounds.ONE_ROUND_ONLY && a1 !== AllowedRounds.ONE_ROUND_ONLY) {
            return null
        }
        if (a1 === AllowedRounds.MANDATORY_JUMP_OFF && a2 === AllowedRounds.OPTIONAL_JUMP_OFF ||
            a2 === AllowedRounds.MANDATORY_JUMP_OFF && a1 === AllowedRounds.OPTIONAL_JUMP_OFF) {
            return AllowedRounds.MANDATORY_JUMP_OFF
        }
        if (a1 === AllowedRounds.MANDATORY_TWO_PHASES && a2 === AllowedRounds.OPTIONAL_TWO_PHASES ||
            a2 === AllowedRounds.MANDATORY_TWO_PHASES && a1 === AllowedRounds.OPTIONAL_TWO_PHASES) {
            return AllowedRounds.MANDATORY_TWO_PHASES
        }
        return a1 === a2 ? a1 : null
    }

    private _mergeSpeedDefaults(l: SpeedLimits, s1: SpeedLimits, s2: SpeedLimits) {
        if (s1.default && l.values.includes(s1.default)) {
            l.default = s1.default
        } else if (s2.default && l.values.includes(s2.default)) {
            l.default = s2.default
        } else if (s1.default === 0 || s2.default === 0) {
            l.default = s1.default || s2.default
        } else {
            l.default = l.values[0]
        }
    }

    private _bothConditions(c1: boolean | undefined, c2: boolean | undefined): boolean | undefined {
        if (c1 === undefined) {
            return c2
        }
        if (c2 === undefined) {
            return c1
        }
        return c1 && c2
    }

    private _anyCondition(c1: boolean | undefined, c2: boolean | undefined): boolean | undefined {
        return c1 === true || c2 === true
    }

    private _mergeClassLimits(l1: LimitSet, l2: LimitSet): LimitSet | null {
        const l: LimitSet = {}
        let v = this._mergeLimitRanges(l1.height, l2.height, false)
        if (v === null) {
            return null
        }
        l.height = v
        if (!l1.width || !l2.width) {
            l.width = l1.width || l2.width
        } else {
            const liverpool = this._mergeLimitRanges(l1.width.liverpool, l2.width.liverpool, false)
            const ditch = this._mergeLimitRanges(l1.width.ditch, l2.width.ditch, false)
            const oxer = this._mergeLimitRanges(l1.width.oxer, l2.width.oxer, false)
            const tripleBarre = this._mergeLimitRanges(l1.width.tripleBarre, l2.width.tripleBarre, false)
            const wall = this._mergeLimitRanges(l1.width.wall, l2.width.wall, false)
            const vertical = this._mergeLimitRanges(l1.width.vertical, l2.width.vertical, false)
            if (liverpool === null || ditch === null || !oxer || !tripleBarre || wall === null || vertical === null) {
                return null
            }
            l.width = {
                liverpool: liverpool,
                ditch: ditch,
                oxer: oxer,
                tripleBarre: tripleBarre,
                wall: wall,
                vertical: vertical,
            }
        }
        v = this._mergeLimitRanges(l1.length, l2.length, false)
        if (v === null) {
            return null
        }
        l.length = v
        if (!l1.speeds || !l2.speeds) {
            l.speeds = l1.speeds || l2.speeds
        } else {
            l.speeds = {
                indoor: {
                    values: l1.speeds.indoor.values.filter(s => l2.speeds?.indoor.values.includes(s))
                },
                outdoor: {
                    values: l1.speeds.outdoor.values.filter(s => l2.speeds?.outdoor.values.includes(s))
                }
            }
            if (l.speeds.indoor.values.length === 0 || l.speeds.outdoor.values.length === 0) {
                return null
            }
            this._mergeSpeedDefaults(l.speeds.indoor, l1.speeds.indoor, l2.speeds.indoor)
            this._mergeSpeedDefaults(l.speeds.outdoor, l1.speeds.outdoor, l2.speeds.outdoor)
        }
        v = this._mergeLimitRanges(l1.obstacles, l2.obstacles, true)
        if (v === null) {
            return null
        }
        l.obstacles = v
        v = this._mergeLimitRanges(l1.efforts, l2.efforts, true)
        if (v === null) {
            return null
        }
        l.efforts = v
        v = this._mergeLimitRanges(l1.distance, l2.distance, true)
        if (v === null) {
            return null
        }
        l.distance = v
        l.twoVerticalsHeightLimit = this._mergeNumbers(l1.twoVerticalsHeightLimit, l2.twoVerticalsHeightLimit, true)
        l.sixOtherHeightLimit = this._mergeNumbers(l1.sixOtherHeightLimit, l2.sixOtherHeightLimit, true)
        l.twoSpreadHeightLimit = this._mergeNumbers(l1.twoSpreadHeightLimit, l2.twoSpreadHeightLimit, true)
        l.twoSpreadWidthLimit = this._mergeNumbers(l1.twoSpreadWidthLimit, l2.twoSpreadWidthLimit, true)
        l.noMaxTime = l1.noMaxTime && l2.noMaxTime
        if (!l1.timeLimits || !l2.timeLimits) {
            l.timeLimits = l1.timeLimits || l2.timeLimits
        } else {
            const indoor = this._mergeLimitRanges(l1.timeLimits.indoor, l2.timeLimits.indoor, false)
            if (indoor === null) {
                return null
            }
            const outdoor = this._mergeLimitRanges(l1.timeLimits.outdoor, l2.timeLimits.outdoor, false)
            if (outdoor === null) {
                return null
            }
            if (indoor && outdoor) {
                l.timeLimits = {
                    indoor: indoor,
                    outdoor: outdoor
                }
            }
        }
        if (!l1.table || !l2.table) {
            l.table = l1.table || l2.table
        } else {
            if (l1.table != l2.table) {
                l.table = undefined
            } else {
                l.table = l1.table
            }
        }
        const a = this._mergeAllowedRounds(l1.allowedRounds, l2.allowedRounds)
        if (a === null) {
            return null
        }
        l.allowedRounds = a
        l.noRoute = this._bothConditions(l1.noRoute, l2.noRoute)
        l.userCanEditArrows = this._bothConditions(l1.userCanEditArrows, l2.userCanEditArrows)
        l.noCombinations = this._anyCondition(l1.noCombinations, l2.noCombinations)
        l.noFinishLine = this._bothConditions(l1.noFinishLine, l2.noFinishLine)
        v = this._mergeLimitRanges(l1.combinations, l2.combinations, true)
        if (v === null) {
            return null
        }
        l.combinations = v
        v = this._mergeLimitRanges(l1.minCombDistance, l2.minCombDistance, false)
        if (v === null) {
            return null
        }
        l.minCombDistance = v
        v = this._mergeLimitRanges(l1.maxCombDistance, l2.maxCombDistance, false)
        if (v === null) {
            return null
        }
        l.maxCombDistance = v
        v = this._mergeLimitRanges(l1.jumpBeforeLen, l2.jumpBeforeLen, false)
        if (v === null) {
            return null
        }
        l.jumpBeforeLen = v
        v = this._mergeLimitRanges(l1.landAfterLen, l2.landAfterLen, false)
        if (v === null) {
            return null
        }
        l.landAfterLen = v
        v = this._mergeLimitRanges(l1.strideMinLen, l2.strideMinLen, false)
        if (v === null) {
            return null
        }
        l.strideMinLen = v
        v = this._mergeLimitRanges(l1.strideMaxLen, l2.strideMaxLen, false)
        if (v === null) {
            return null
        }
        l.strideMaxLen = v
        return l
    }

    // full limits to control configuratio of sizes, always has all values
    public getManyLimits(classes: ClassNo[], article: Article | undefined, unitSet: UnitSet | undefined): LimitSetCombined | null {
        if (classes.length === 0) {
            return {
                heights: {},
                ...this.getLimits(ClassNo.NONE, article, unitSet),
            }
        }
        let l: LimitSet | null = {}
        let hs: ObstacleHeightLimits = {}
        for (let c of classes) {
            const limits = this.getLimits(c, article, unitSet)
            l = this._mergeClassLimits(l, limits)
            if (l === null) {
                return null
            }
            if (limits.height) {
                hs[c] = limits.height
            }
        }
        return {
            heights: hs,
            ...l,
        }
    }

    // limits enforced by regulations, may not contain some values
    private getRegulationsLimits(classNo: ClassNo, article: Article | undefined, unitSet: UnitSet | undefined): LimitSet | undefined {
        let limits: LimitSet | undefined = this.getExtendedLimits(classNo, unitSet)
        limits = this.addArticleLimits(limits, article)
        return limits
    }

    // full limits to control configuratio of sizes, always has all values
    public getManyRegulationsLimits(classes: ClassNo[], article: Article | undefined, unitSet: UnitSet | undefined): LimitSetCombined | null {
        if (classes.length === 0) {
            return {
                heights: {},
                ...this.getRegulationsLimits(ClassNo.NONE, article, unitSet) || this.getDefaults(unitSet)
            }
        }
        let l: LimitSet | null = {}
        let hs: ObstacleHeightLimits = {}
        for (let c of classes) {
            const limits = this.getRegulationsLimits(c, article, unitSet) || this.getDefaults(unitSet)
            l = this._mergeClassLimits(l, limits)
            if (l === null) {
                return null
            }
            if (limits.height) {
                hs[c] = limits.height
            }
        }
        return {
            heights: hs, 
            ...l,
        }
    }

    public matchArticle(v: string): string | null {
        // strip entered value from whitespaces and convert to lower case
        v = v.replace(/\s/g, '').toLowerCase()
        // remove heading variations of art or article
        v = v.replace(/(artykuł|artykul|article|artikel|art)\.?/, '')
        // match article number nnn[.nn[.nn[.nn]]]
        if (v.match(/^\d{1,3}(\.\d{1,2}){0,3}$/)) {
            return v
        }
        return null
    }

    public getHeightLimits(classes: ClassNo[], unitSet: UnitSet | undefined): ObstacleHeightLimits {
        const hs: ObstacleHeightLimits = {}
        for (let c of classes) {
            const h = this.getExtendedLimits(c, unitSet)
            if (h && h.height) {
                hs[c] = h.height
            }
        }
        return hs
    }

    public getHeightDefaults(classes: ClassNo[], unitSet: UnitSet | undefined): ObstacleHeights {
        const hs: ObstacleHeights = {}
        for (let c of classes) {
            const h = this.getExtendedLimits(c, unitSet)
            if (h && h.height?.default) {
                hs[c] = h.height.default
            }
        }
        return hs
    }

    public setBaseArticleAndTooltipForm(cfg: ParkourConfig, articleEl: ElementRef<HTMLElement> | undefined, unitSet: UnitSet): string {
        let articleTooltip = ''
        this._fixArticleInForm(cfg)
        const base = this.getArticleBase(cfg.form.controls.feiRules.value)
        if (base) {
            cfg.baseFeiRulesForm = base
            cfg.formLimits = this.getManyLimits(cfg.form.controls.classes.value, cfg.baseFeiRulesForm, unitSet) || this.getDefaults(unitSet)
            const descr = this.articleNames[base]
            articleTooltip = descr
            articleEl?.nativeElement.dispatchEvent(new Event('mouseenter'));
        } else {
            cfg.baseFeiRulesForm = Article.NONE
        }
        return articleTooltip
    }

    public classNoToName(classNo?: ClassNo): string {
        if (!classNo) {
            return ''
        }
        return this.classNoNames[classNo]
    }

    public classesToName(classes?: ClassNo[], limit?: number): string {
        if (!classes) {
            return ''
        }
        if (!limit) {
            return classes.map(c => this.classNoToName(c)).join(', ')
        } else {
            return justifyStrings(classes.map(c => this.classNoToName(c)), limit, ', ')
        }
    }

    private _fixArticleInForm(cfg: ParkourConfig) {
        let v: string | null = cfg.form.controls.feiRules.value as string
        if (v) {
            v = this.matchArticle(v)
            if (v) {
                v = 'Art.' + v
                cfg.form.controls.feiRules.patchValue(v)
            }
        }
    }
}
