import * as B from '@babylonjs/core'
import { MaterialManager } from './parkour-renderer.materials'

export class RenderedGround {
    mesh: B.Mesh

    constructor(
        scene: B.Scene,
        materialManager: MaterialManager)
    {
        this.mesh = B.CreateGround('ground1', { width: 1000, height: 1000, subdivisions: 1 }, scene)

        const texture = materialManager.getTexture('ground-sand', () => {
            const t = new B.Texture('assets/textures/sandy_gravel.jpg', scene)
            t.uScale = 100
            t.vScale = 100
            return t
        })

        this.mesh.material = materialManager.getMaterial('ground-sand', (name: string) => {
            const m = new B.StandardMaterial(name, scene)
            m.diffuseTexture = texture
            m.diffuseColor = new B.Color3(0.7, 0.7, 0.7)
            m.specularColor = new B.Color3(0, 0, 0)    
            m.backFaceCulling = false
            return m
        })
        this.mesh.receiveShadows = true
        this.mesh.checkCollisions = true
    }
}
