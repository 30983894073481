import paper from 'paper'
import { Article, CombinationType, Direction, designPathPatternDistances } from '../design.schema'
import { LayerId, ParkourCanvas } from '../parkour-canvas/parkour-canvas'
import { ParkourConfig } from '../parkour.config'
import { SecondRoundType } from './detail.path'
import { ObstaclePathNode } from './detail.path.node'
import { Obstacle } from './parkour-objects/obstacle'
import { ObstacleWithBars } from './parkour-objects/obstacle-with-bars'
import { Terminal } from './parkour-objects/terminal'
import { ParkourObjectGroup } from './detail.group'

export type SectionConfig = {
    labelsVisible?: boolean
    pathVisible?: boolean
    pathControlPointsVisible?: boolean
    distancesVisible?: boolean
    midPointsVisible?: boolean
}

export class Section {
    length: number = 0;
    pathGroup?: paper.Group
    route: ObstaclePathNode[] = [];
    round123: number | undefined
    index: number = 0;
    static readonly defaultRoundConfig = {
        pathVisible: true,
        labelsVisible: false,
        pathControlPointsVisible: false,
        distancesVisible: true,
        midPointsVisible: false,
    };
    config: SectionConfig = Section.defaultRoundConfig;
    combinations: ObstaclePathNode[][] = [];
    closedCombinations: number = 0
    partiallyClosedCombinations: number = 0
    starts: number = 0;
    finishes: number = 0;
    finishStarts: number = 0;
    efforts: number = 0;
    obstacles: number = 0;
    secondRoundType: SecondRoundType = SecondRoundType.NONE;

    private roundLengthTo5M(len: number): number {
        if (len < 0) {
            return 0
        }
        let l = Math.round(len / 100 / 5)
        return l * 5
    }

    get roundedLengthTo5M(): number {
        return this.roundLengthTo5M(this.length)
    }

    get dashArray(): number[] {
        let dists
        if (this.round123 === 1) {
            dists = designPathPatternDistances[this.cfg.params.pathPattern1]
        } else if (this.round123 === 2) {
            dists = designPathPatternDistances[this.cfg.params.pathPattern2]
        } else if (this.round123 === 3) {
            dists = designPathPatternDistances[this.cfg.params.pathPattern25]
        } else {
            dists = designPathPatternDistances[this.cfg.params.pathPattern3]
        }
        //dists = dists.map(d => this.view.scaleAccordingToFieldSize(d / 1000))
        return dists
    }

    constructor(private cfg: ParkourConfig, private canvas: ParkourCanvas) {
    }

    get correctedLength(): number {
        const p = this.cfg.params
        let c = this.length
        if (this.round123 === 1 && p.overrideDistance1) {
            c = p.distance1M * 100
        } else if (this.round123 === 2 && p.overrideDistance2) {
            c = p.distance2M * 100
        } else if (this.round123 === 3 && p.overrideDistance3) {
            c = p.distance3M * 100
        }
        return c
    }

    get correctedRoundedLengthTo5M(): number {
        return this.roundLengthTo5M(this.correctedLength)
    }

    get complete(): boolean {
        if (this.round123 === undefined) {
            return false
        }
        if (this.starts !== 1 && this.round123 === 1 || this.starts > 1 && this.round123 === 2 || this.starts > 1 && this.round123 === 3) {
            console.error(`Error - number of starts in round ${this.round123} is unexpected - ${this.starts}`)
        }
        if (this.finishes > 1) {
            console.error(`Error - number of finishes in round ${this.round123} is >1 - ${this.finishes}`)
        }
        if ((this.round123 === 2 || this.round123 === 3) && this.finishes > 0 && this.starts === 0 && this.finishStarts === 0) {
            console.error(`Error - number of finishes in round ${this.round123} is >0 but no start/fs - ${this.finishes}`)
        }
        if ((this.round123 === 2  || this.round123 === 3) && this.finishStarts > 0 && this.starts > 0) {
            console.error(`Error - number of starts + number of fs in round ${this.round123} is >1 - ${this.starts}, ${this.finishStarts})`)
        }

        if (this.secondRoundType === SecondRoundType.JUMP_OFF) {
            if (this.round123 === 1) {
                // base round is complete
                return this.starts === 1 && this.finishes === 1 && this.finishStarts === 0
            } else {
                // jump-off is complete (valid for round 2 and 3)
                return this.finishStarts === 0 && this.starts === 1 && this.finishes === 1
            }
        } else if (this.secondRoundType === SecondRoundType.TWO_PHASE) {
            if (this.round123 === 1) {
                // first round is complete
                return this.starts === 1 && this.finishes === 0 && this.finishStarts === 1
            } else if (this.round123 === 2) {
                // second round is complete
                return this.finishStarts === 1 && this.starts === 0 && this.finishes === 1
            } else {
                // valid for round 3 - jump off
                return this.finishStarts === 0 && this.starts === 1 && this.finishes === 1
            }
        } else {
3            // it is customary for article 267 competitions to not include finish of the first round
            return this.starts === 1 && this.finishStarts === 0 &&
                (this.finishes === 1 || this.cfg.noFinishInFirstRound())
        }
    }

    isFirstRound(): boolean {
        return this.round123 === 1
    }

    isSecondRound(): boolean {
        return this.round123 === 2
    }

    isThirdRound(): boolean {
        return this.round123 === 3
    }

    initialize(route: ObstaclePathNode[]) {
        if (!this.pathGroup) {
            this.pathGroup = new paper.Group()
        } else {
            this.pathGroup.removeChildren()
        }
        this.canvas.getLayer(LayerId.OBSTACLES).addChild(this.pathGroup)
        this.pathGroup.sendToBack()
        this.length = 0
        this.combinations = []
        this.closedCombinations = 0
        this.partiallyClosedCombinations = 0
        this.starts = 0
        this.finishes = 0
        this.finishStarts = 0
        this.efforts = 0
        this.route = route
        this.round123 = undefined
        this.setConfig(Section.defaultRoundConfig)
        this.secondRoundType = SecondRoundType.NONE
        this.route.forEach(n => n.setSection(this))
    }

    setRoundConfig(round123: number, config: SectionConfig | undefined) {
        this.round123 = round123
        this.setConfig(config)
    }

    destroy() {
        if (this.pathGroup && this.pathGroup.isInserted()) {
            this.pathGroup.remove()
        }
    }

    setConfig(config?: SectionConfig) {
        if (!config) {
            return
        }
        this.config = config
        if (config.pathVisible !== undefined && this.pathGroup) {
            this.pathGroup.visible = config.pathVisible
        }
        for (let n of this.route) {
            if (config.distancesVisible !== undefined && n.distance) {
                n.distance.visible = config.distancesVisible
            }
            if (config.labelsVisible !== undefined && n.obstacle instanceof Obstacle) {
                n.obstacle.setLabelsVisibility(config.labelsVisible, this.round123)
            }
            if (config.pathControlPointsVisible !== undefined && n.forwardPath) {
                n.forwardPath.fullySelected = config.pathControlPointsVisible
            }
            if (config.midPointsVisible !== undefined) {
                for (let mp of n.midPoints) {
                    if (config.midPointsVisible) {
                        mp.show()
                    } else {
                        mp.hide()
                    }
                }
            }
        }
    }

    clear() {
        this.initialize([])
        this.setConfig(this.config)
    }

    updateStep1(idx: number, distFromStart: number, distFromPrev: number, secondRoundType: SecondRoundType) {
        if (!this.pathGroup || this.route.length === 0) {
            return 0
        }
        this.index = idx
        this.secondRoundType = secondRoundType

        if (this.route.length > 0) {
            this.route[0].distFromStart = distFromStart
            this.route[0].distFromPrev = distFromPrev
        }
        const roundRoute = this.route
        // go through used obstacles and initialize pass index and
        // all obstacles as uni-directional in the first round
        for (let i = 0; i <= this.route.length - 1; i++) {
            const obstacle = this.route[i].obstacle
            if (this.round123) {
                obstacle.useCount[this.round123 - 1] = 0
            }
            if (obstacle instanceof Terminal) {
                obstacle.round123 = this.round123 || 0
            } else if (obstacle instanceof ObstacleWithBars && obstacle.obstacleWidth > 0) {
                this.efforts++
            }
            if (obstacle.isStart()) {
                this.starts++
            } else if (obstacle.isFinish()) {
                this.finishes++
            } else if (obstacle.isFinishStart()) {
                this.finishStarts++
            }
            roundRoute[i].setSection(this)
            if (this.cfg.isNoRouteMode()) {
                obstacle.setArrowDirection(Direction.both)
            } else if (this.round123 === 1) {
                obstacle.setArrowDirection(Direction.none)
            }
            if (obstacle instanceof Terminal) {
                obstacle.updateLabel(this.round123 || 0)
            }
        }
        if (this.cfg.isNoRouteMode()) {
            this.route.forEach(n => n.clearForwardPath())
            this.obstacles = this.canvas.obstacles.filter(o => o.isObstacle()).length
            this.efforts = (this.cfg.baseFeiRules === Article.ART_271_TAKE_YOUR_OWN_LINE ? this.obstacles : 0)
        } else {
            // go through nodes and set the direction of jumping the nodes and
            // update obstacles which are bi-directional
            for (let i = 0; i <= this.route.length - 1; i++) {
                const node = this.route[i]
                const obstacle = node.obstacle
                // passNo - which pass through the obstacle in this round
                const round = this.round123 || 1 // objects outside of round 1 or 2 or 3 are treated like they were round 1
                const passNo = node.pass = ++obstacle.useCount[round - 1]
                // get in which direction this obstacle should be jumped in this round and pass
                const dir = obstacle.getDirection(round, passNo)
                node.direction = dir

                const arrow = obstacle.getArrowDirection(round)
                // if no arrow, set arrow pointing in current direction
                // if one arrow and opposite to current, set both arrows
                if (arrow == Direction.none) {
                    obstacle.setArrowDirection(dir, round)
                } else if ((arrow == Direction.forward || arrow == Direction.backward) && arrow != dir) {
                    obstacle.setArrowDirection(Direction.both, round)
                }

                if (i < this.route.length - 1) {
                    node.next = this.route[i + 1]
                }
                if (i > 0) {
                    node.prev = this.route[i - 1]
                }
            }
        }
    }

    updateStep2(idx: number, startLabelIdx: number,
        secondRoundType: SecondRoundType, changeMidPointsCount?: boolean, firstRound?: Section, secondRound?: Section): number {

        if (!this.pathGroup || this.route.length === 0) {
            return 0
        }

        const attrs = {
            strokeWidth: this.cfg.params.pathWidth,
            strokeScaling: false,
            strokeCap: 'round',
            dashArray: this.dashArray,
            selected: false,
            fullySelected: this.config.pathControlPointsVisible
        }

        const roundRoute = this.route
        let labelIdx = startLabelIdx
        if (!this.cfg.isNoRouteMode()) {
            // go through nodes and build forward paths, midpoints and labels
            let labelSubIdx = '', prevCombination = false
            if (this.round123 === 2 && secondRoundType === SecondRoundType.JUMP_OFF || this.round123 === 3) {
                // there is a gap in numbering in case of jump-off:
                // first new obstacle gets the index of last obstacle in the first round + 2
                labelIdx += 1
            }
            for (let i = 0; i <= this.route.length - 1; i++) {
                const node = roundRoute[i]
                node.pathGroup = this.pathGroup
                if (i === this.route.length - 1) {
                    if (!node.obstacle.isFinishStart()) {
                        node.clearForwardPath()
                    }
                } else {
                    node.buildForwardPath(roundRoute[i + 1], attrs, changeMidPointsCount)
                    node.midPoints.forEach(mp => {
                        if (mp.midPointMarker) {
                            mp.midPointMarker.visible = this.pathGroup?.visible || false
                        }
                    })
                }

                // all obstacles should have useCounts set in the previous for loop
                // if obstacle is not used in the other round, copy the passing directions from the
                // current round
                if (this.round123 === 2 || this.round123 === 3) {
                    node.obstacle.copyDirectionsToOtherRoundsIfNotUsed(1)
                    node.obstacle.copyDirectionsToOtherRoundsIfNotUsed(this.round123)
                }

                if (node.obstacle instanceof Obstacle) {
                    let skipLabel = false
                    if (this.round123 === 2 && secondRoundType === SecondRoundType.JUMP_OFF || this.round123 === 3) {
                        let otherRoundNode = this._findSameDirectionNode(firstRound?.route, node)
                        if (!otherRoundNode && this.round123 === 3) {
                            otherRoundNode = this._findSameDirectionNode(secondRound?.route, node)
                        }
                        if (otherRoundNode) {
                            // jump-off has specific labeling ie. new obstacles get subsequent indexes
                            // while reused obstacles stays with labels from the first round
                            node.label = otherRoundNode.label
                            skipLabel = true
                            // add round 1 label in round 2 when round 1 label is not visible
                            //round1Node.setLabel(node.label, 1, !firstRound.pathGroup?.visible && this.pathGroup.visible)
                        }
                    }
                    if (this.round123 === 1 || this.round123 === 2 || this.round123 === 3) {
                        let label, combination = false
                        if (i < this.route.length - 1 && this.route[i + 1].obstacle instanceof ObstacleWithBars &&
                            node.isInCombinationWith(this.route[i + 1])) {
                            // combination of obstacles case, obstacle gets subsequent subindex label starting from A
                            if (!labelSubIdx) {
                                labelSubIdx = 'A'
                            }
                            label = '' + labelIdx + labelSubIdx
                            labelSubIdx = String.fromCharCode(labelSubIdx.charCodeAt(0) + 1)
                            combination = true
                        } else {
                            // regular case, obstacle gets subsequent index label
                            label = '' + labelIdx + labelSubIdx
                            if (!skipLabel) {
                                labelIdx = labelIdx + 1
                            }
                            if (labelSubIdx) {
                                combination = true
                            }
                            labelSubIdx = ''
                        }
                        if (combination) {
                            if (!prevCombination) {
                                this.combinations.push([node])
                            } else if (this.combinations.length > 0) {
                                this.combinations[this.combinations.length - 1].push(node)
                            }
                        }
                        prevCombination = labelSubIdx !== '' && combination
                        if (!skipLabel) {
                            node.setLabel(label, this.round123)
                        }
                    } else {
                        node.label = ''
                    }
                } else {
                    prevCombination = false
                }
            }
            // calculate number of obstacles
            this.obstacles = this.route.filter(v => (
                v.obstacle.isObstacle()
            )).filter((v, i, a) => (
                i === a.findIndex(o => (v.obstacle === o.obstacle))
            )).filter((v, i, a) => (
                i < a.length - 1 ? !v.isInCombinationWith(a[i + 1]) : true
            )).length
            // calculate number of closed and partially closed combinations
            let cc = 0, pcc = 0
            this.combinations.forEach(c => {
                let g: ParkourObjectGroup | undefined = undefined
                for (let o of c) {
                    const parent = o.obstacle.parentSelector
                    if (parent instanceof ParkourObjectGroup) {
                        if (!g) {
                            g = parent
                        } else if (g !== parent) {
                            g = undefined
                            break
                        }
                    }
                }
                // all obstacles belong to one group
                if (g && g.combination) {
                    if (g.combinationType === CombinationType.CLOSED) {
                        cc++
                    } else if (g.combinationType === CombinationType.PARTIALLY_CLOSED) {
                        pcc++
                    }
                }
            })
            this.closedCombinations = cc
            this.partiallyClosedCombinations = pcc
        }
        return labelIdx
    }

    private _findSameDirectionNode(route: ObstaclePathNode[] | undefined, node: ObstaclePathNode): ObstaclePathNode | undefined {
        return route?.find(
            n => n.obstacle === node.obstacle && (n.direction === node.direction || n.direction === Direction.both))
    }
}