import { Component } from '@angular/core';

import { DesignsListComponent } from '../designs-list/designs-list.component'

@Component({
    selector: 'app-designs-library-page',
    standalone: true,
    imports: [DesignsListComponent],
    templateUrl: './designs-library-page.component.html',
    styleUrl: './designs-library-page.component.scss'
})
export class DesignsLibraryPageComponent {

}
