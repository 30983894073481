<p-dialog [(visible)]="previewDlgVisible">
    <div class="w-full h-full max-w-full max-h-full">
        <img class="big-preview-png" *ngIf="bigPreviewPngUrl" [src]="bigPreviewPngUrl" (click)="togglePreview('')"/>
    </div>
</p-dialog>

<div class="flex flex-column m-4">
    <div class="flex justify-content-between font-bold text-indigo-400 mb-4 pb-2 border-bottom-2">
        <div class="text-3xl" i18n>Biblioteka Projektów</div>
        <div>
<!--
            <p-button
                icon="pi pi-search"
                [rounded]="true" [text]="true"
                (onClick)="searchPanelVisible = !searchPanelVisible"></p-button>
            <p-button
                icon="pi pi-sort-amount-down-alt"
                [rounded]="true" [text]="true"
                (onClick)="searchPanelVisible = !searchPanelVisible"></p-button>
-->
            <p-button
                icon="pi pi-refresh"
                [rounded]="true" [text]="true"
                [loading]="loadingList"
                (onClick)="loadDesignsList()"></p-button>
        </div>
    </div>

    <div *ngIf="searchPanelVisible" class="mx-2 mt-1 mb-3">
        <input pInputText id="searchText" [(ngModel)]="searchText" (keyup)="doSearch($event)"/>
        <p-dropdown
            class="ml-4"
            [options]="sortOptions" [(ngModel)]="sortOption"
            optionLabel="name" optionValue="value"
            (onChange)="sortChange()"></p-dropdown>
    </div>

    <div *ngIf="listMsg" class="m-2">
        {{ listMsg }}
    </div>

    <ng-container *ngFor="let e of eventsWithDesigns">
    <div class="flex flex-column mx-1 my-2 p-3 pb-2 shadow-1"
         [ngClass]="e.designs.length == 1 ? '' : 'surface-50'">

        <!-- ROW of EVENT and its DESIGNS -->
        <div class="flex flex-wrap gap-3 pt-1">
            <!-- EVENT -->
            <div class="max-w-20rem min-w-20rem w-20rem flex-none pr-2">
                <div class="font-bold border-indigo-100 text-indigo-600 text-2xl">
                    {{ e.eventName.trim() }}
                </div>
                <div class="text-color-secondary pt-1">
                    <b>{{ e.dims[0] }}</b> x <b>{{ e.dims[1] }}</b> m
                </div>
                <div class="text-color-secondary pt-1">
                    {{ e.eventDescr }}
                </div>
            </div>

            <!-- DESIGNS of EVENT -->
            <div *ngFor="let d of e.designs" class="w-20rem flex flex-column p-1 pb-2">
                <div class="w-20rem font-bold pl-2 pb-1 mb-2 border-bottom-1 border-indigo-100 text-xl">
                    <a [routerLink]="user ? ['/designer'] : null" [queryParams]="{id: d.localId, author: d.author}" class="text-indigo-400">
                        <span *ngIf="d.title && d.title.trim()">{{ d.title.trim() }}</span>
                        <span *ngIf="!d.title || !d.title.trim()" i18n>(bez nazwy)</span>
                    </a>
                </div>
                <div class="flex">
                    <div style="height: 11rem; overflow-y: clip;">
                        <div class="preview-png" *ngIf="d.pngUrl != 'progress' && (d.pngUrl || d.png)"
                             pTooltip="Kliknij aby obejrzeć podgląd"
                             i18n-pTooltip
                             (click)="togglePreview(d)">
                            <img [src]="d.pngUrl || d.png" style="width: 100%; object-fit: cover; object-position: top;" crossorigin="anonymous"
                                 (error)="onImageError(d)" (load)="onImageLoad($event, d)">
                        </div>
                        <div class="preview-png" *ngIf="d.pngUrl == 'progress' && !d.png">
                            <p-skeleton width="200px" height="8rem" styleClass="m-2"></p-skeleton>
                        </div>
                        <div class="preview-png m-auto text-400" *ngIf="!d.pngUrl && !d.png" i18n>brak podglądu</div>
                    </div>
                    <div *ngIf="user" class="flex flex-column justify-content-start w-full align-items-end">
                        <p-button
                            icon="pi pi-clone"
                            [rounded]="true" [outlined]="true" severity="info"
                            styleClass="m-1"
                            (click)="cloneDesign(d)"
                            pTooltip="Zduplikuj projekt" i18n-pTooltip></p-button>
                        <p-button
                            icon="fa-solid fa-cube"
                            [rounded]="true" [outlined]="true" severity="help"
                            styleClass="m-1"
                            (click)="renderDesign(d)"
                            [disabled]="!is3dSupported"
                            pTooltip="Symulacja 3D" i18n-pTooltip></p-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </ng-container>
</div>

<parkour-canvas class="hidden" #canvasFor3d [cfg]="renderCfg">
</parkour-canvas>

<app-parkour-3d-render-dialog #renderDialog [canvas]="canvasFor3d" [cfg]="renderCfg"></app-parkour-3d-render-dialog>
