<p-dialog [modal]="true" [(visible)]="customerTypeDlgVisible" [resizable]="false"
    [style]="{ minWidth: '20vw', maxWidth: '50vw', width: 'auto' }"
    (keydown.escape)="customerTypeDlgVisible = false">
    <ng-template pTemplate="header">
        <span class="p-dialog-title"><i class="pi pi-id-card mr-2"></i> <span i18n>Konsument czy firma?</span></span>
    </ng-template>
    <div class="flex flex-column gap-5 mb-3 w-full">
        <div class="line-height-3">
            <div i18n>Proszę wskazać:</div>
            <div><span class="pl-3 pr-2">a)</span> <span i18n>czy jesteś konsumentem, osobą fizyczną - wtedy podatek VAT zostanie doliczony do ceny</span>,</div>
            <div><span class="pl-3 pr-2">b)</span> <span i18n>czy reprezentujesz firmę, podmiot gospodarczy - wtedy podatek VAT będziesz musiał rozliczyć sam</span>.</div>
        </div>
        <div class="flex flex-column gap-3 ml-3">
            <div class="flex align-items-center">
                <p-radioButton
                    name="consumer"
                    value="consumer"
                    [(ngModel)]="customerType"
                    inputId="consumer" />
                <label for="consumer" class="ml-2" i18n>Konsument, osoba fizyczna</label>
            </div>

            <div class="flex align-items-center">
                <p-radioButton
                    name="business"
                    value="business"
                    [(ngModel)]="customerType"
                    inputId="business" />
                <label for="business" class="ml-2" i18n>Firma, podmiot gospodarczy</label>
            </div>
        </div>
    </div>
    <div class="flex justify-content-end gap-2">
        <p-button icon="pi pi-times" (click)="customerTypeDlgVisible = false" label="Anuluj" i18n-label severity="danger"></p-button>
        <p-button icon="pi pi-check" (click)="chooseCustomerType()" label="OK" i18n-label></p-button>
    </div>
</p-dialog>

<p-dialog [modal]="true" [(visible)]="paymentConfirmationDlgVisible" [resizable]="false"
    [style]="{ minWidth: '30vw', maxWidth: '50vw', width: 'auto' }"
    (keydown.escape)="paymentConfirmationDlgVisible = false">
    <ng-template pTemplate="header">
        <span class="p-dialog-title"><i class="pi pi-shield mr-2"></i> <span i18n>Finalizacja płatności</span></span>
    </ng-template>
    <div class="flex ai-center jc-center component">
        <div class="w-3">
            <img src="assets/Paddle_Logo.jpg" alt="Paddle" style="max-width: 120px" class="mx-6 my-4">
        </div>
        <div class="w-9 flex flex-column justify-content-start gap-2">
            <div i18n>Za chwilę zostaniesz przekierowany na stronę naszego zaufanego pośrednika płatności – Paddle, gdzie bezpiecznie sfinalizujesz zakup.</div>
            <div i18n>Paddle obsługuje całą transakcję, w tym płatności, podatki oraz faktury.</div>
            <div i18n>Po zakończeniu płatności zostaniesz przekierowany z powrotem na naszą stronę.</div>
        </div>
    </div>
    <div class="flex justify-content-end gap-2">
        <p-button icon="pi pi-times" (click)="paymentConfirmationDlgVisible = false" label="Anuluj" i18n-label severity="danger"></p-button>
        <p-button icon="pi pi-check" (click)="confirmPayment()" label="OK" i18n-label></p-button>
    </div>
</p-dialog>


<div class="p-3 flex flex-column">
    <div *ngIf="userProfile && isTrial()">
        <div class="text-3xl" i18n>Okres próbny aktywny</div>
        <div class="pl-2"><span i18n>Początek</span>: {{ userProfile.trialStart | date }}</div>
        <div class="pl-2"><span i18n>Koniec</span>: {{ userProfile.trialEnd | date }}</div>
    </div>
    <div class="text-5xl mx-auto pb-5" *ngIf="!userProfile?.testerPlan">
        <span *ngIf="!userProfile?.planSelected"
            i18n>Wybierz plan cenowy</span>
        <span *ngIf="userProfile?.planSelected"
            i18n>Zarządzaj swoim planem cenowym</span>
    </div>
</div>


<div class="mb-5 flex-column lg:flex lg:flex-row align-items-center justify-content-center gap-4 lg:gap-8">
    <div>
        <p-selectButton
            [options]="pricingIntervalOptions" [(ngModel)]="pricingIntervalOption"
            [unselectable]="true" optionLabel="name" optionValue="value"></p-selectButton>
    </div>
    <div>
        <p-dropdown
            styleClass="w-16rem"
            [options]="currencies" [(ngModel)]="selectedCurrency"
            optionLabel="name" optionValue="value"></p-dropdown>
    </div>

</div>

<div class="mb-6 flex flex-column align-items-center justify-content-center text-lg text-600">
    <div class="text-center line-height-3" i18n>Ceny nie zawierają podatku VAT.</div>
</div>

<div class="grid lg:w-9 lg:mx-auto">
    <!-- STARTER / Startowy -->
    <div class="col-12 lg:col-4" *ngIf="false">
        <div class="p-3 h-full">
            <div class="shadow-2 p-3 h-full flex flex-column surface-card" style="border-radius: 6px">
                <div class="text-900 font-bold text-4xl mb-2" i18n>Startowy</div>
                <div class="text-600" i18n>dla hobbystów i początkujących</div>
                <hr class="my-3 mx-0 border-top-1 border-none surface-border" />
                <div class="flex align-items-center">
                    <span class="font-bold text-2xl text-900" i18n>Darmowy</span>
                    <!--<span class="ml-2 font-medium text-600">per month</span>-->
                </div>
                <hr class="my-3 mx-0 border-top-1 border-none surface-border" />
                <ul class="list-none p-0 m-0 flex-grow-1">
                    <li class="flex align-items-start mb-3">
                        <i class="pi pi-check-circle text-green-500 mr-2"></i>
                        <span class="text-900" i18n>Aplikacja dostępna przez przeglądarkę internetową</span>
                    </li>
                    <li class="flex align-items-start mb-3">
                        <i class="pi pi-check-circle text-green-500 mr-2"></i>
                        <span class="text-900" i18n>Projekty oraz wszystkie dane przechowywane w chmurze</span>
                    </li>
                    <li class="flex align-items-start mb-3">
                        <i class="pi pi-check-circle text-green-500 mr-2"></i>
                        <span class="text-900" i18n>Wszystkie projekty są prywatne - jedynie autor ma do nich dostęp</span>
                    </li>
                    <li class="flex align-items-start mb-3">
                        <i class="pi pi-check-circle text-green-500 mr-2"></i>
                        <span class="text-900" i18n>Liczba przeszkód ograniczona do 10</span>
                    </li>
                </ul>
                <hr class="mb-3 mx-0 border-top-1 border-none surface-border mt-auto" />
                <!-- user profile is not loaded yet or is loaded but plan is not loaded yet or all is loaded and plan is not starter;
                     so no action should be available to the user at this time -->
                <button *ngIf="userProfile === undefined || (userProfile && userProfile.planSelected && currentPlan && currentPlan.name != 'starter')"
                        pButton pRipple label="&nbsp;" class="p-3 w-full mt-auto"
                        [disabled]="true"></button>
                <!-- profile is loaded but plan was never selected (and before planSelected field existed the plan is starter);
                     so the user can choose starter plan at this time -->
                <button *ngIf="userProfile && !userProfile.planSelected && !userProfile.testerPlan"
                        pButton pRipple label="Wybierz" i18n-label class="p-3 w-full mt-auto"
                        [disabled]="advancedPlanLoading || mastersPlanLoading"
                        [loading]="starterPlanLoading"
                        (click)="choosePlan('starter')"></button>
                <!-- user profile loaded and choosen plan is starter; so no action is available to the user at this time -->
                <button *ngIf="userProfile && (userProfile.planSelected || userProfile.testerPlan) && currentPlan?.name == 'starter'"
                        pButton pRipple label="Twój obecny plan" i18n-label class="p-3 w-full mt-auto"
                        [disabled]="true"></button>
            </div>
        </div>
    </div>

    <!-- ADVANCED / Zaawansowany -->
    <div class="col-12 lg:col-6">
        <div class="p-3 h-full">
            <div class="shadow-2 p-3 h-full flex flex-column surface-card" style="border-radius: 6px">
                <div class="text-900 font-bold text-4xl mb-2" i18n>Zaawansowany</div>
                <div class="text-600" i18n>dla hobbystów, instruktorów i praktyków</div>
                <hr class="my-3 mx-0 border-top-1 border-none surface-border" />
                <div class="flex align-items-baseline">
                    <span class="font-bold text-2xl text-900">
                        {{ pricing.advanced[pricingIntervalOption][selectedCurrency] | number }}
                    </span>
                    <span class="font-bold text-lg text-900 ml-1">{{ selectedCurrency }}</span>
                    <span *ngIf="pricingIntervalOption == 'monthly'"  class="ml-2 font-medium text-600" i18n>miesięcznie</span>
                    <span *ngIf="pricingIntervalOption == 'yearly'" class="ml-2 font-medium text-600" i18n>rocznie</span>
                    <span *ngIf="pricingIntervalOption == 'yearly'" class="ml-2 font-medium text-green-500" i18n>(15% mniej)</span>
                </div>
                <hr class="my-3 mx-0 border-top-1 border-none surface-border" />
                <ul class="list-none p-0 m-0 flex-grow-1">
                    <li class="flex align-items-start mb-3">
                        <i class="pi pi-check-circle text-green-500 mr-2"></i>
                        <span class="text-900" i18n>Aplikacja dostępna przez przeglądarkę internetową</span>
                    </li>
                    <li class="flex align-items-start mb-3">
                        <i class="pi pi-check-circle text-green-500 mr-2"></i>
                        <span class="text-900" i18n>Projekty oraz wszystkie dane przechowywane w chmurze</span>
                    </li>
                    <li class="flex align-items-start mb-3">
                        <i class="pi pi-check-circle text-green-500 mr-2"></i>
                        <span class="text-900" i18n>Zapis projektu do PDF oraz wydruk</span>
                    </li>
                    <li class="flex align-items-start mb-3">
                        <i class="pi pi-check-circle text-green-500 mr-2"></i>
                        <span class="text-900" i18n>Wszystkie projekty są prywatne - jedynie autor ma do nich dostęp</span>
                    </li>
                </ul>
                <hr class="mb-3 mx-0 border-top-1 border-none surface-border" />
                <!-- profile is not loaded yet or is loaded but plan is not loaded yet or all is loaded but plan is masters (ie. not advanced);
                     so no action should be available to the user at this time -->
                <button *ngIf="userProfile === undefined || (userProfile && userProfile.planSelected && currentPlan && currentPlan.name == 'masters')"
                        pButton pRipple label="&nbsp;" class="p-3 w-full mt-auto"
                        [disabled]="true"></button>
                <!-- profile is defined (either user is not logged in or is logged and profile is loaded)
                     and if user is not logged in then selected plan must be starter;
                     so the user can choose this plan at this time -->
                <button *ngIf="userProfile && (!userProfile.planSelected || currentPlan?.name == 'starter')"
                        pButton pRipple label="Kup teraz!" i18n-label class="p-3 w-full"
                        [disabled]="starterPlanLoading || mastersPlanLoading"
                        [loading]="advancedPlanLoading"
                        (click)="choosePlan('advanced')"></button>
                <!-- profile is loaded and advanced plan is selected;
                     so the user can go to stripe portal to manage the plan -->
                <button *ngIf="userProfile && currentPlan?.name == 'advanced'"
                        pButton pRipple label="Twój obecny plan - Zarządzaj na portalu klienta" i18n-label class="p-3 w-full mt-auto"
                        [loading]="advancedPlanLoading"
                        (click)="teleportToCustomerPortal('advanced')"></button>
            </div>
        </div>
    </div>

    <!-- MASTERS / Mistrzowski -->
    <div class="col-12 lg:col-6">
        <div class="p-3 h-full">
            <div class="shadow-2 p-3 h-full flex flex-column surface-card" style="border-radius: 6px">
                <div class="text-900 font-bold text-4xl mb-2" i18n>Mistrzowski</div>
                <div class="text-600" i18n>dla wymagających gospodarzy torów</div>
                <hr class="my-3 mx-0 border-top-1 border-none surface-border" />
                <div class="flex align-items-baseline">
                    <span class="font-bold text-2xl text-900">
                        {{ pricing.masters[pricingIntervalOption][selectedCurrency] | number }}
                    </span>
                    <span class="font-bold text-lg text-900 ml-1">{{ selectedCurrency }}</span>
                    <span  *ngIf="pricingIntervalOption == 'monthly'"  class="ml-2 font-medium text-600" i18n>miesięcznie</span>
                    <span *ngIf="pricingIntervalOption == 'yearly'" class="ml-2 font-medium text-600" i18n>rocznie</span>
                    <span *ngIf="pricingIntervalOption == 'yearly'" class="ml-2 font-medium text-green-500" i18n>(15% mniej)</span>
                </div>
                <hr class="my-3 mx-0 border-top-1 border-none surface-border" />
                <ul class="list-none p-0 m-0 flex-grow-1" style="position: relative;">
                    <li class="flex align-items-start mb-3">
                        <i class="pi pi-check-circle text-green-500 mr-2"></i>
                        <span class="text-900" i18n>Wszystko to co w Zaawansowanym oraz...</span>
                    </li>
                    <li class="flex align-items-start mb-3">
                        <i class="pi pi-check-circle text-green-500 mr-2"></i>
                        <span class="text-900" i18n>Edycja materiałów przeszkód</span>
                    </li>
                    <li class="flex align-items-start mb-3">
                        <i class="pi pi-check-circle text-green-500 mr-2"></i>
                        <span class="text-900" i18n>Druk masterplanu oraz tabel ze szczegółowym opisem przeszkód</span>
                    </li>
                    <li class="flex align-items-start mb-3">
                        <i class="pi pi-check-circle text-green-500 mr-2"></i>
                        <span class="text-900" i18n>Praca również bez dostępu do sieci</span>
                    </li>
                </ul>
                <hr class="mb-3 mx-0 border-top-1 border-none surface-border" />
                <!-- user logged in but profile is not loaded yet or is loaded but plan is not loaded yet or all is loaded but plan is advanced (ie. not masters);
                     so no action should be available to the user at this time -->
                <button *ngIf="userProfile === undefined || (userProfile && userProfile.planSelected && currentPlan && currentPlan.name == 'advanced')"
                        pButton pRipple label="&nbsp;" class="p-3 w-full mt-auto"
                        [disabled]="true"></button>
                <!-- profile is defined (either user is not logged in or is logged and profile is loaded)
                     and if user is not logged in then selected plan must be starter;
                     so the user can choose this plan at this time -->
                <button *ngIf="userProfile && (!userProfile.planSelected || currentPlan?.name == 'starter')"
                        pButton pRipple label="Kup teraz!" i18n-label class="p-3 w-full"
                        [disabled]="starterPlanLoading || advancedPlanLoading"
                        [loading]="mastersPlanLoading"
                        (click)="choosePlan('masters')"></button>
                <!-- profile is loaded and masters plan is selected;
                     so the user can go to stripe portal to manage the plan -->
                <button *ngIf="userProfile && currentPlan?.name == 'masters'"
                        pButton pRipple label="Twój obecny plan - Zarządzaj na portalu klienta" i18n-label class="p-3 w-full mt-auto"
                        [loading]="mastersPlanLoading"
                        (click)="teleportToCustomerPortal('masters')"></button>
            </div>
        </div>
    </div>
</div>


<div class="surface-section text-center px-4 py-8 md:px-6 lg:px-8 w-full lg:w-6 mx-auto">
    <h1 class="text-900 font-bold text-5xl mx-auto" i18n>Często zadawane pytania</h1>

    <section class="grid text-left mx-auto">
        <div class="col-6">
            <div class="mb-6">
                <p class="mb-4 font-semibold" i18n>Jak mogę anulować lub zaktualizować moją kartę kredytową w serwisie Parkour.Design?</p>
                <p class="text-gray-800" i18n>Po zalogowaniu się,
                ponownie odwiedź stronę z cenami. Kliknięcie
                "Zarządzaj" pod odpowiednim planem umożliwi Ci
                anulowanie, aktualizację metody płatności lub pobranie
                paragonu.</p>
            </div>
            <div class="mb-6">
                <p class="mb-4 font-semibold" i18n>Dlaczego podatek VAT nie jest wliczony w cenę?</p>
                <p class="text-gray-800" i18n>Podatek VAT nie jest wliczony w cenę, ponieważ jego stawki różnią się w zależności od kraju. Na przykład, mogą wynosić od 5% do 25%, więc aby precyzyjniej określić cenę, VAT jest wyłączony. Na przykład – w Niemczech stawka VAT wynosi 19%, podczas gdy we Francji wynosi 20%. Właściwy VAT zostanie naliczony w na stronie dokonywania płatności przez pośrednika płatności.</p>
            </div>
            <div class="mb-6">
                <p class="mb-4 font-semibold" i18n>Czy przechowujecie dane potrzebne do płatności, w szczególności dane karty kredytowej?</p>
                <p class="text-gray-800" i18n>Nie, nie przechowujemy żadnych danych dotyczących płatności, w tym danych karty kredytowej. Wszystkie transakcje są przetwarzane przez naszego zaufanego pośrednika płatności – Paddle, który zapewnia ich bezpieczeństwo.</p>
            </div>
        </div>
        <div class="col-6">
            <div class="mb-6">
                <p class="mb-4 font-semibold" i18n>Jakie metody płatności są dostępne?</p>
                <p class="text-gray-800" i18n>Aktualnie akceptujemy tylko karty kredytowe i karty debetowe. Skontaktuj się z nami pod adresem support(at)parkour.design, aby poprosić o inne opcje.</p>
            </div>
            <div class="mb-6">
                <p class="mb-4 font-semibold" i18n>Jak działa prawo autorskie na Parkour.Design? Kto jest właścicielem projektów, które tworzę?</p>
                <p class="text-gray-800">
                    <span i18n>Niezależnie od planu, jesteś właścicielem obrazów, które tworzysz na Parkour.Design. Możesz ich używać do celów osobistych lub komercyjnych, pod warunkiem przestrzegania naszych warunków użytkowania:</span>
                    <a routerLink="/terms" i18n>warunki użytkowania</a>.
                </p>
            </div>
            <div class="mb-6">
                <p class="mb-4 font-semibold" i18n>Nie ma tutaj Twojego pytania?</p>
                <p class="text-gray-800" i18n>Jeśli nie ma tutaj nurtującej Cię kwesti, skontaktuj się z nami mailem: support(at)parkour.design</p>
            </div>
        </div>
    </section>
</div>
