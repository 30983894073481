import { Component, OnDestroy, OnInit, Inject, LOCALE_ID } from '@angular/core';
import { Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { FormsModule } from '@angular/forms'

import { Subscription, distinct } from 'rxjs'
import { MessageService, PrimeNGConfig } from 'primeng/api';

// PrimeNG
import { MenuModule } from 'primeng/menu';
import { ButtonModule } from 'primeng/button';
import { ToastModule } from 'primeng/toast'
import { TooltipModule } from 'primeng/tooltip';
import { InputTextModule } from 'primeng/inputtext';
import { DividerModule } from 'primeng/divider';
import { PanelModule } from 'primeng/panel';
import { DialogModule } from 'primeng/dialog';
import { PasswordModule } from 'primeng/password';
import { ProgressBarModule } from 'primeng/progressbar';
import { CarouselModule } from 'primeng/carousel';
import { SelectButtonModule } from 'primeng/selectbutton';
import { CheckboxModule } from 'primeng/checkbox';


import { LeftTopBarComponent } from './left-top-bar/left-top-bar.component';
import { RightTopBarComponent } from './right-top-bar/right-top-bar.component';
import { ElectronService } from './core/services';
import { APP_CONFIG } from '../environments/environment';
import { getBuildVersion, getBuildDate, showErrorBox, showInfoBox, getWPWebLink } from './utils';
import { AuthService } from './services/auth.service';
import { UserSubscriptionService } from './services/user-subscription.service';
import { StorageService } from './services/storage.service'
import { AngularDeviceInformationService } from 'angular-device-information'


@Component({
    selector: 'app-root',
    standalone: true,
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    imports: [
        FormsModule,
        // primeng
        MenuModule,
        ButtonModule,
        ToastModule,
        TooltipModule,
        InputTextModule,
        DividerModule,
        PanelModule,
        DialogModule,
        PasswordModule,
        ProgressBarModule,
        CarouselModule,
        SelectButtonModule,
        CheckboxModule,
        LeftTopBarComponent,
        RightTopBarComponent,
    ]
})
export class AppComponent implements OnInit, OnDestroy {
    private subs: Subscription = new Subscription()
    nonDesktopWarning: boolean = false
    proceedAnyway: boolean = false

    version: string = ''
    buildDate: string = ''

    linkToGuides: string = ''
    linkToWebsite: string = ''

    constructor(
        private electronService: ElectronService,
        protected auth: AuthService,
        private userSubscriptionService: UserSubscriptionService,
        private router: Router,
        private msgSvc: MessageService,
        private updates: SwUpdate,
        private primeNgConfig: PrimeNGConfig,
        private store: StorageService,
        @Inject(LOCALE_ID) private locale: string,
        private deviceInfo: AngularDeviceInformationService,
    ) {
        console.log('APP_CONFIG', APP_CONFIG);

        this.version = getBuildVersion()
        this.buildDate = getBuildDate()

        if ((deviceInfo.isMobile() || deviceInfo.isTablet()) && !deviceInfo.isDesktop()) {
            this.nonDesktopWarning = true
        }

        if (electronService.isElectron) {
            console.log(process.env);
            console.log('Run in electron');
            console.log('Electron ipcRenderer', this.electronService.ipcRenderer);
            console.log('NodeJS childProcess', this.electronService.childProcess);
        } else {
            console.log('Run in browser');
        }

        this.linkToGuides = getWPWebLink('wiki/guides', locale)
        this.linkToWebsite = getWPWebLink('', locale)

        // detect app updates and ask user for reloading
        this.subs.add(
            this.updates.versionUpdates.subscribe({
                next: evt => {
                    switch (evt.type) {
                        case 'VERSION_DETECTED':
                            console.info('Downloading new app version', evt.version)
                            break;
                        case 'VERSION_READY':
                            console.info('Current app version', evt.currentVersion)
                            console.info('New app version ready for use', evt.latestVersion)
                            // ask user for reload
                            this.msgSvc.add({
                                key: 'update-confirm',
                                severity: 'info',
                                life: 20000,
                            });
                            break;
                        case 'VERSION_INSTALLATION_FAILED':
                            console.error('Failed to install app version', evt.version, ' error:', evt.error)
                            break;
                    }
                },
                error: (err) => {
                    console.error('error occured', err)
                    showErrorBox(this.msgSvc, $localize`Pobieranie informacji o wersji aplikacji`, $localize`Wystąpił nieznany błąd`)
                }
            })
        )

        this.subs.add(
            this.userSubscriptionService.getPlanState().pipe(distinct()).subscribe({
                next: (planState: string) => {
                    console.info('plan state:', planState)
                    if (planState === 'trial-begin') {
                        showInfoBox(this.msgSvc,
                            $localize`Początek okresu próbnego`,
                            $localize`Właśnie rozpoczął się okres próbny. Masz dostęp do pełnej funkcjonalności Parkour.Design przez następne 2 tygodnie.`)
                    }
                    if (planState === 'trial-end') {
                        this.router.navigate(['/user-plan'])
                        showInfoBox(this.msgSvc,
                            $localize`Koniec okresu próbnego`,
                            $localize`Twój okres próbny się zakończył. Wybierz odpowiedni dla Ciebie plan.`)
                    }
                },
                error: (err: any) => {
                    console.error('error occured', err)
                }
            })
        )
    }

    onUpdateReject() {
        this.msgSvc.clear('update-confirm')
    }

    onUpdateConfirm() {
        document.location.reload()
    }

    ngOnInit(): void {
        this.primeNgConfig.setTranslation({
            weak: $localize`Słabe`,
            medium: $localize`Średnie`,
            strong: $localize`Silne`,
            passwordPrompt: $localize`Podaj hasło`
        })
    }

    ngOnDestroy() {
        this.subs.unsubscribe()
    }
    getLocationPath() {
        return window.location.pathname
    }
}
