import { LimitRange } from "../design.schema"
import { AllowedRounds } from "../services/limits.service"
import { formatLimits } from "../utils"
import { DetailComponent } from "./detail.component"
import { ParkourObject } from "./parkour-objects/parkour-object"
import { Obstacle } from "./parkour-objects/obstacle"
import { ObstacleWithBars } from "./parkour-objects/obstacle-with-bars"
import { PathObject } from "./parkour-objects/path-object"
import { Terminal } from "./parkour-objects/terminal"
import { ObstaclePath, SecondRoundType } from "./detail.path"
import { ObstaclePathNode } from './detail.path.node'
import { Section } from "./detail.path.section"


export enum Severity {
    ERROR = 0,
    WARNING = 1
}

export type Warning = {
    id?: number,
    text: string,
    severity: Severity,
    objTxt?: string,
    objects?: ParkourObject[],
    article?: string
}

export type ValidationOptions = {
    art: string,
    noRoute: boolean,
    allowedRounds: AllowedRounds | undefined,
    ignoreSequenceCheck: boolean,
    ignoreStraightLineCheck: boolean,
    noCombinations: boolean,
    ignoreDistance: boolean,
    ignoreObstacles: boolean,
    ignore60mRule: boolean,
    noFinishLine: boolean,
    ignoreFirstCombination: boolean,
    ignoreTripleBarreFirst: boolean,
    ignoreClassCombinationsCheck: boolean,
}

export class ParkourValidator {
    public validationWarnings: Warning[] = []
    public validationWarningsCount: number[] = [0, 0]
    protected id: number = 1
    protected path?: ObstaclePath
    protected firstRound?: Section
    protected secondRound?: Section
    protected thirdRound?: Section
    protected bothPhasesCombinations: ObstaclePathNode[][] = []
    protected rounds: Section[] = []
    protected allObstacles: number = 0
    protected efforts2ndRoundOpposite: number = 0
    protected efforts3rdRoundOpposite: number = 0
    protected efforts2ndRoundSame: number = 0
    protected efforts3rdRoundSame: number = 0
    protected parkourIsComplete: boolean = false
    protected unusedObstacles: number = 0
    protected secondRoundIsOk: boolean = false

    private warnings: Warning[] = []

    constructor (public view: DetailComponent) {
    }

    protected addW(w: Warning) {
        this.warnings.push({
            ...w, id: this.id++,
            article: w.article?.replace('Art.', '')
        })
    }

    protected addWO(w: Warning) {
        const ww = {
            ...w, id: this.id++,
            article: w.article?.replace('Art.', '')
        }
        this.warnings.push(ww)
        ww.objects?.forEach(obj => {
            if (obj instanceof Obstacle) {
                obj.warnings.warnings.push(ww)
            }
        })
    }

    validate() {
    }

    startValidation(opts: ValidationOptions): boolean {
        if (!this.view.canvas) {
            return false
        }
        this.id = 1
        this.warnings = []
        const path = this.path = this.view.canvas.obstaclePath
        if (path.route.length === 0) {
            return false
        }

        const firstRound = this.firstRound = path.firstRound
        const secondRound = this.secondRound = path.secondRound
        const thirdRound = this.thirdRound = path.thirdRound
        
        this.bothPhasesCombinations = firstRound?.combinations.slice() || []
        if (path.secondRoundType === SecondRoundType.TWO_PHASE && secondRound) {
            this.bothPhasesCombinations.push(...secondRound.combinations)
        }
        const rounds: Section[] = this.rounds = []
        if (firstRound) {
            rounds.push(firstRound)
        }
        if (secondRound) {
            rounds.push(secondRound)
        }
        if (thirdRound) {
            rounds.push(thirdRound)
        }
        const allObstacles = this.allObstacles = (firstRound?.obstacles || 0) + (secondRound?.obstacles || 0)
        this.efforts2ndRoundOpposite = this.efforts3rdRoundOpposite = this.efforts2ndRoundSame = this.efforts3rdRoundSame = 0
        
        const addW = this.addW.bind(this)
        // const addWO = this.addWO.bind(this)

        for (let o of this.view.canvas.obstacles) {
            if (o instanceof Obstacle) {
                o.resetWarnings()
            }
        }

        this.parkourIsComplete = firstRound && firstRound.complete &&
            (!secondRound || secondRound && secondRound.complete) &&
            (!thirdRound || thirdRound && thirdRound.complete) || false

        this.unusedObstacles = this.view.canvas.obstacles.length
        const art = opts.art

        if (!opts.noRoute) {
            if (path.route.length > 1) {
                this.view.attentionForStartSet(false)
            }
            if (path.secondRoundType === SecondRoundType.NONE) {
                if (opts.allowedRounds === AllowedRounds.MANDATORY_JUMP_OFF) {
                    addW({
                        severity: Severity.WARNING, article: art,
                        text: $localize`Wybrano ${art} w którym projekt powinien mieć rozgrywkę, podczas gdy ma tylko przebieg podstawowy`
                    })
                } else if (opts.allowedRounds === AllowedRounds.MANDATORY_TWO_PHASES) {
                    addW({
                        severity: Severity.WARNING, article: art,
                        text: $localize`Wybrano ${art} w którym projekt powinien mieć dwie fazy, podczas gdy ma tylko przebieg podstawowy`
                    })
                }
            } else if (path.secondRoundType === SecondRoundType.TWO_PHASE) {
                if (opts.allowedRounds === AllowedRounds.OPTIONAL_JUMP_OFF) {
                    addW({
                        severity: Severity.WARNING, article: art,
                        text: $localize`Wybrano ${art} w którym projekt powinien mieć przebieg podstawowy z rozgrywką lub bez, podczas gdy ma dwie fazy`
                    })
                } else if (opts.allowedRounds === AllowedRounds.MANDATORY_JUMP_OFF) {
                    addW({
                        severity: Severity.WARNING, article: art,
                        text: $localize`Wybrano ${art} w którym projekt powinien mieć rozgrywkę, podczas gdy ma dwie fazy`
                    })
                } else if (opts.allowedRounds === AllowedRounds.ONE_ROUND_ONLY) {
                    addW({
                        severity: Severity.WARNING, article: art,
                        text: $localize`Wybrano ${art} w którym projekt powinien mieć tylko przebieg podstawowy, podczas gdy ma dwie fazy`
                    })
                } else {
                    this.secondRoundIsOk = true
                }
            } else if (path.secondRoundType === SecondRoundType.JUMP_OFF) {
                if (opts.allowedRounds === AllowedRounds.OPTIONAL_TWO_PHASES) {
                    addW({
                        severity: Severity.WARNING, article: art,
                        text: $localize`Wybrano ${art} w którym projekt powinien dwie fazy lub tylko przebieg podstawowy, podczas gdy ma rozgrywkę`
                    })
                } else if (opts.allowedRounds === AllowedRounds.MANDATORY_TWO_PHASES) {
                    addW({
                        severity: Severity.WARNING, article: art,
                        text: $localize`Wybrano ${art} w którym projekt powinien mieć dwie fazy, podczas gdy ma rozgrywkę`
                    })
                } else if (opts.allowedRounds === AllowedRounds.ONE_ROUND_ONLY) {
                    addW({
                        severity: Severity.WARNING, article: art,
                        text: $localize`Wybrano ${art} w którym projekt powinien mieć tylko przebieg podstawowy, podczas gdy ma rozgrywkę`
                    })
                } else {
                    this.secondRoundIsOk = true
                }
            }
            for (let r of rounds) {
                for (let i = 0; i < r.route.length - 1; i++) {
                    const n = path.route[i]
                    if (n.warnAboutStrides) {
                        let objs
                        if (path.route[i + 1].obstacle.isFinishStart() && i < path.route.length - 2) {
                            objs = [n, path.route[i + 1], path.route[i + 2]]
                            i++
                        } else {
                            objs = [n, path.route[i + 1]]
                        }
                        const [labels, objects] = this._addWarningObjects(objs)
                        addW({
                            severity: Severity.WARNING,
                            text: $localize`Odległość między przeszkodami jest nieodpowiednia dla skonfigurowanej długości fouli`,
                            objTxt: labels, objects: objects
                        })    
                    }
                }
            }

            // Calculate various course characteristics to be used in validation
            // and check series of connected obstacles
            rounds.forEach((r, rIdx) => {
                let obstacleCount: number[] = [0, 0, 0, 0]
                let lastBars = 0
                for (let i = 0; i < r.route.length; i++) {
                    const item = r.route[i]
                    const o = item.obstacle
                    if (o instanceof Obstacle && o.useCount[0] > 0) {
                        if (r.round123 === 2) {
                            if (o.getDirection(2, 1) !== o.getDirection(1, 1)) {
                                // opposite direction in second round
                                this.efforts2ndRoundOpposite++
                            } else {
                                this.efforts2ndRoundSame++
                            }
                        } else if (r.round123 === 3) {
                            if (o.getDirection(3, 1) !== o.getDirection(1, 1)) {
                                // opposite direction in third round
                                this.efforts3rdRoundOpposite++
                            } else {
                                this.efforts3rdRoundSame++
                            }
                        }
                    }

                    // More than 2 obstacles in a row of the same base kind are not allowed
                    const bars = (o instanceof ObstacleWithBars && o.bars >= 0 && o.bars <= 3) ? o.bars : 0
                    obstacleCount[bars]++
                    if (!opts.ignoreSequenceCheck && lastBars !== bars) {
                        const count = obstacleCount[lastBars]
                        if (count >= 3) {
                            let txt
                            if (lastBars === 1) {
                                txt = $localize`Więcej niż dwie stacjonaty po kolei`
                            } else if (lastBars === 2) {
                                txt = $localize`Więcej niż dwa oksery po kolei`
                            } else if (lastBars === 3) {
                                txt = $localize`Więcej niż dwa triple barre po kolei`
                            }
                            if (txt) {
                                const [labels, objects] = this._addWarningObjects(r.route, i - count, i - 1)
                                addW({ severity: Severity.WARNING, text: txt, objTxt: labels, objects: objects })
                            }
                        }
                        obstacleCount[lastBars] = 0
                        lastBars = bars
                    }

                    // If route between two obstacles is shorter than 18m, it should be a straight path
                    // 3% deviation allowed between straight line and path length
                    if (!opts.ignoreStraightLineCheck && i < r.route.length - 1 && item.forwardPath) {
                        const next = r.route[i + 1]
                        if (next.obstacle instanceof Obstacle && o instanceof Obstacle) {
                            const distance = o.getExitPoint(item.direction).getDistance(next.obstacle.getEntryPoint(next.direction))
                            if (item.forwardPath.length < 1800 && item.forwardPath.length / distance > 1.03) {
                                const [labels, objects] = this._addWarningObjects(r.route, i, i + 1)
                                addW({
                                    severity: Severity.WARNING,
                                    text: $localize`Jeżeli trasa łącząca przeszkody jest krótsza niż 18 metrów, to powinna być prosta`,
                                    objTxt: labels, objects: objects
                                })
                            }
                        }
                    }
                }
    
            })
    
            if (this.parkourIsComplete) {
                const finishes = this.view.canvas.objects.filter(o => o instanceof PathObject && 
                    o.isFinish() && o.useCountAll === 0)
                if (finishes.length > 0) {
                    const [labels, objects] = this._addWarningObjects(finishes)
                    addW({
                        severity: Severity.ERROR, text: $localize`Nadmiarowa meta na placu`,
                        objTxt: labels, objects: objects
                    })    
                }
            }

            path.sections.forEach(s => {
                if (!s.round123) {
                    const [labels, objects] = this._addWarningObjects(s.route)
                    addW({
                        severity: Severity.ERROR, text: $localize`Część ścieżki niepodłączona do żadnej rundy`,
                        objTxt: labels, objects: objects
                    })       
                }
            })
        } 
        return true
    }

    protected finishValidation() {
        // finish validation
        this.validationWarnings = this.warnings.sort((a, b) => a.severity - b.severity)
        for (let i = 0; i < this.validationWarningsCount.length; i++) {
            this.validationWarningsCount[i] = this.warnings.filter(w => w.severity === i).length
        }
    }
    
    private _getWarningLabel(n: ObstaclePathNode | ParkourObject, i: number, end: number): string {
        let label
        const r = n instanceof ObstaclePathNode ? n.obstacle : n
        if (r instanceof Terminal) {
            label = r.label
        } else if (r.isFinishStart()) {
            label = 'FS'
        } else {
            label = n instanceof ObstaclePathNode ? n.label || '-' : r.getKindName()
        }
        if (i < end) {
            label += ', '
        }
        return label
    }

    protected _addWarningObjects(route: (ObstaclePathNode | ParkourObject)[], start?: number, end?: number): [string, ParkourObject[]] {
        if (!end) {
            end = route.length - 1
        }
        if (!start) {
            start = 0
        }
        let labels = '', objects: ParkourObject[] = []
        for (let j = start; j <= end; j++) {
            const r = route[j]
            labels += this._getWarningLabel(r, j, end)
            if (r instanceof ObstaclePathNode) {
                objects.push(r.obstacle)
            } else if (r instanceof ParkourObject) {
                objects.push(r)
            }
        }
        return [labels, objects]
    }

    private _addWarningObjectsCond(cond: (o: PathObject) => unknown): [string, ParkourObject[]] {
        let labels = '', objects: ParkourObject[] = []
        this.view.canvas?.obstaclePath.route.forEach((r) => {
            if (cond(r.obstacle)) {
                if (labels !== '') {
                    labels += ', '
                }
                if (r.obstacle instanceof Terminal) {
                    labels += r.obstacle.label
                } else {
                    labels += r.label || r.obstacle.getKindName()
                }
                objects.push(r.obstacle)
            }
        })
        return [labels, objects]
    }

    protected _addDefaultParamsWarning(l: LimitRange | undefined, v: number, text: string, height: boolean, bars?: number): Warning | undefined {
        if (!l) {
            return undefined
        }
        if (l.min !== undefined && v < l.min || l.max !== undefined && v > l.max) {
            const [labels, objects] = this._addWarningObjectsCond(o => {
                // for bars === 1 this is a liverpool, not all verticals
                if (o instanceof ObstacleWithBars && o.bars === 1 && !o.decorations.water) {
                    return false
                }
                if (o instanceof ObstacleWithBars) {
                    const ov = height ? o.obstacleHeight : o.obstacleWidth
                    const od = height ? o.defaultObstacleHeight : o.defaultObstacleWidth
                    const ret = (!bars || o.bars === bars) && ov === od &&
                        (l.min !== undefined && ov < l.min || l.max !== undefined && ov > l.max)
                    if (ret) {
                        if (height) {
                            o.warnings.height = true
                        } else {
                            o.warnings.width = true
                        }
                    }
                    return ret
                }
                return false
            })
            if (objects.length > 0) {
                return {
                    id: 0, severity: Severity.WARNING,
                    text: text + ' ' + formatLimits(l, 'cm') + '. ' + $localize`Popraw wartość w parametrach konkursu.`,
                    objTxt: labels, objects: objects
                }
            }
        }
        return undefined
    }

    protected _addNonDefaultParamsWarning(l: LimitRange | undefined, text: string, height: boolean, bars?: number): Warning | undefined {
        if (!l) {
            return undefined
        }
        const [labels, objects] = this._addWarningObjectsCond(o => {
            // for bars === 1 this is a liverpool, not all verticals
            if (o instanceof ObstacleWithBars && o.bars === 1 && !o.decorations.water) {
                return false
            }
            if (o instanceof ObstacleWithBars) {
                const ov = height ? o.obstacleHeight : o.obstacleWidth
                const od = height ? o.defaultObstacleHeight : o.defaultObstacleWidth
                const ret = (!bars || o.bars === bars) && ov !== od &&
                    (l.min !== undefined && ov < l.min || l.max !== undefined && ov > l.max)
                if (ret) {
                    if (height) {
                        o.warnings.height = true
                    } else {
                        o.warnings.width = true
                    }
                    return ret
                }
            }
            return false
        })
        if (objects.length > 0) {
            return {
                id: 0, severity: Severity.WARNING,
                text: text + ' ' + formatLimits(l, 'cm') + '. ' + $localize`Popraw wartość w paramterach przeszkody.`,
                objTxt: labels, objects: objects
            }
        }
        return undefined
    }
}