import { DecimalPipe } from '@angular/common'
import { Pipe, PipeTransform } from '@angular/core'
import { DomSanitizer } from '@angular/platform-browser'
import { feetToMeters, metersToFeet } from './utils'

@Pipe({
    name: 'safe',
    standalone: true
})
export class SafePipe implements PipeTransform {

    constructor(protected sanitizer: DomSanitizer) {}

    transform(value: any, args: any): any  {
        switch (args) {
        case 'html': return this.sanitizer.bypassSecurityTrustHtml(value);
        case 'style': return this.sanitizer.bypassSecurityTrustStyle(value);
        case 'script': return this.sanitizer.bypassSecurityTrustScript(value);
        case 'url': return this.sanitizer.bypassSecurityTrustUrl(value);
        case 'resourceUrl': return this.sanitizer.bypassSecurityTrustResourceUrl(value);
        default: console.log('Invalid safe type specified');
        }
    }
}

export enum Unit {
    M = 'm',
    CM ='cm',
    FT = 'ft',
}

export enum UnitSign {
    FT = 'ft',
    SIGN = 'sign',
    M = 'm',
    CM = 'cm'
}

export type UnitRule = {
    rounding: number | undefined,
    precision: number | undefined,
}

export type UnitRulesSet = {
    [key in Unit | string]?: UnitRule
}

export type UnitParams = {
    from: Unit | string,
    to: string,
    skipSuffix: boolean,
    rules: UnitRulesSet,
}

@Pipe({
    name: 'parkunit',
    standalone: true
})
export class UnitPipe implements PipeTransform {

    protected format(v: number, rounding?: number): number {
        if (v < 0) {
            return 0
        } else if (rounding) {
            return Math.round(v / rounding) * rounding
        }
        return v
    }

    transform(value: string | number, params: Partial<UnitParams>): string | undefined {
        const rules = {
            [Unit.M]: {
                rounding: 1,
                precision: 0,
                ...params.rules?.m,
            },
            [Unit.CM]: {
                rounding: 5,
                precision: 0,
                ...params.rules?.cm,
            },
            [Unit.FT]: {
                rounding: 0.01,
                precision: 2,
                ...params.rules?.ft,
            }
        }
        const s = this.convert(value, {
            ...params,
            rules
        })
        let t: string | undefined = undefined
        if (s !== undefined) {
            let to = params.to || 'm-m'
            const sections = to.split('-')
            let unit
            if (sections.length > 0) {
                unit = sections[0]
            } else {
                unit = to
            }
            if (unit === Unit.FT) {
                t = s.toFixed(rules.ft.precision)
            } else if (unit === Unit.M) {
                t = s.toFixed(rules.m.precision)
            } else if (unit === Unit.CM) {
                t = s.toFixed(rules.cm.precision)
            }
            t = '' + +(t || '') // skip trailing zeroes
            if (t && !params.skipSuffix) {
                t += UnitPipe.getUnitSuffix(to)
            }
        }
        return t
    }

    convert(value: string | number, params: Partial<UnitParams>): number | undefined {
        let from: Unit | string = params.from || Unit.M
        let sections = from.split('-')
        if (sections.length > 0) {
            from = sections[0]
        }
        let to = params.to || 'm-m'
        sections = to.split('-')
        if (sections.length > 0) {
            to = sections[0]
        }

        let v = +value
        if (from === Unit.M) {
            if (to === Unit.FT) {
                v = metersToFeet(v)
            } else if (to === Unit.CM) {
                v = v * 100
            }
        } else if (from === Unit.CM) {
            if (to === Unit.FT) {
                v = metersToFeet(v / 100)
            } else if (to === Unit.M) {
                v = v / 100
            }
        } else if (from === Unit.FT) {
            if (to === Unit.M) {
                v = feetToMeters(v)
            } else if (to === Unit.CM) {
                v = feetToMeters(v) * 100
            }
        }

        if (to === Unit.FT) {
            return this.format(v, params.rules?.ft?.rounding)
        } else if (to === Unit.M) {
            return this.format(v, params.rules?.m?.rounding)
        } else if (to === Unit.CM) {
            return this.format(v, params.rules?.cm?.rounding)
        }
        return undefined
    }

    static getUnit(unit: string): string {
        const units = unit.split('-')
        if (units.length > 0) {
            unit = units[0]
        }
        if (unit === Unit.FT) {
            return 'ft'
        } else if (unit === Unit.M) {
            return 'm'
        } else if (unit === Unit.CM) {
            return 'cm'
        }
        return '?'
    }

    static getUnitSuffix(to: string): string {
        let suffix: string | undefined = undefined
        let unit = to
        const sections = to.split('-')
        if (sections.length > 1) {
            suffix = sections[sections.length - 1]
        }
        if (sections.length > 0) {
            unit = sections[0]
        } else {
            unit = to
        }
        if (unit === Unit.FT) {
            if (suffix === UnitSign.FT || !suffix) {
                return ' ft'
            }
            if (suffix === UnitSign.SIGN) {
                return '\''
            }
        } else if (unit === Unit.M) {
            if (suffix === UnitSign.M || !suffix) {
                return ' m'
            }
        } else if (unit === Unit.CM) {
            if (suffix === UnitSign.CM || !suffix) {
                return ' cm'
            }
        }
        return ' ?'
    }
}
