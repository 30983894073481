import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { InputNumberModule } from 'primeng/inputnumber';
import { TooltipModule } from 'primeng/tooltip';
import { DetailComponent } from '../../detail.component';
import { DialogModule } from 'primeng/dialog'
import { ParkourDialog } from '../../../dialog.interface'
import { SelectButtonModule } from 'primeng/selectbutton'
import { ObstacleMaterial } from '../../../design.schema'

import { ObstacleWithBars } from '../../parkour-objects/obstacle-with-bars'
import { Obstacle } from '../../parkour-objects/obstacle'
import { SafePipe, Unit } from '../../../pipes';
import { NewColorPicker } from '../../../new-colorpicker/new-colorpicker'
import { ObstacleBar } from '../../detail.materials'
import { ConversionService } from '../../../services/conversion.service'

@Component({
    selector: 'app-parkour-materials',
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        SafePipe,
        ButtonModule,
        InputNumberModule,
        TooltipModule,
        DialogModule,
        SelectButtonModule,
        NewColorPicker,
    ],
    templateUrl: './parkour-materials.component.html',
    styleUrl: './parkour-materials.component.scss'
})
export class ParkourMaterialsComponent implements ParkourDialog {
    @Input({ required: true }) view!: DetailComponent
    @Output() onOk = new EventEmitter<Obstacle>()
    @Output() onCancel = new EventEmitter<Obstacle>()
    @Output() onDelete = new EventEmitter<Obstacle>()

    raisedHeight: number = 0
    raisedHeightEnabled: boolean = false

    topMaterialOptions = [
        { name: $localize`Drąg`, value: ObstacleMaterial.BAR },
        { name: $localize`Drąg wyżej`, value: ObstacleMaterial.BAR_RAISED, styleClass: "w-full" },
    ]

    materialOptionsWithWall = [
        { name: $localize`Drąg`, value: ObstacleMaterial.BAR },
        { name: $localize`Deska`, value: ObstacleMaterial.PLANK },
        { name: $localize`Mur`, value: ObstacleMaterial.BRICKS },
    ]

    materialOptionsNoWall = [
        { name: $localize`Drąg`, value: ObstacleMaterial.BAR },
        { name: $localize`Deska`, value: ObstacleMaterial.PLANK },
        { name: $localize`Mur`, value: ObstacleMaterial.BRICKS, disabled: true },
    ]

    get sizeUnit(): string {
        return this.conversionService.getUnit(this.view.cfg.params.sizeUnit) || ''
    }

    visibility: boolean = false
    object?: ObstacleWithBars

    constructor(private conversionService: ConversionService) {
    }

    addRemoveMaterialsRow(add: boolean) {
        const o = this.object
        if (o && o.materials) {
            if (add) {
                o.materials.addMaterialRow()
            } else {
                o.materials.removeMaterialRow()
            }
            o.drawMaterials(false)
            o.materials.generateSvg()
        }
    }

    finishChangeMaterials(bar: ObstacleBar, defaultColors: boolean) {
        const o = this.object
        if (o && o.materials) {
            if (defaultColors) {
                bar.assignDefaultColors()
            }
            this._updateRaisedHeight()
            o.drawMaterials(false)
            o.materials.generateSvg()
        }
    }

    isNotBar(m: ObstacleBar) {
        return (m.material !== ObstacleMaterial.BAR && m.material !== ObstacleMaterial.BAR_RAISED)
    }

    onRestoreDefaultColors() {
        this.object?.materials?.topRow.forEach(b => b.assignDefaultColors())
        this.object?.materials?.rows.forEach(b => b.assignDefaultColors())
    }

    onCloseDialog() {
    }

    onShowDialog() {
        const o = this.object
        if (o && o.materials) {
            this.raisedHeight = this.conversionService.convert(
                o.materials.raisedHeight, {
                    from: Unit.CM,
                    to: this.view.cfg.params.sizeUnit,
                    rules: this.conversionService.rulesForObstacleHeight,
                    skipSuffix: true,
                }
            ) || 0
        }
        this.raisedHeightEnabled = false
        this._updateRaisedHeight()
    }

    onHideDialog() {
    }

    onOkDialog() {
        if (!this.object) {
            return
        }
        if (this.raisedHeightEnabled && this.object.materials) {
            this.object.materials.raisedHeight = this.conversionService.convert(
                this.raisedHeight, {
                from: this.view.cfg.params.sizeUnit,
                to: Unit.CM,
                rules: this.conversionService.fullPrecisionNoRounding,
                skipSuffix: true,
            }) || 0
        }
        this.object.drawMaterials(true)
        this.visibility = false
        this.onOk.emit(this.object)
    }

    onCancelDialog() {
        if (!this.object) {
            return
        }
        this.object.restoreMaterials(true)
        this.visibility = false
        this.onCancel.emit()
    }

    onDeleteDialog() {
        if (!this.object) {
            return
        }
        if (this.object.materials) {
            this.object.materials.destroy()
            this.object.materials = undefined
        }
        this.visibility = false
        this.onDelete.emit(this.object)
    }

    open(o?: ObstacleWithBars) {
        if (!o) {
            return
        }
        this.object = o
        o.addMaterials(false)
        if (o.materials) {
            o.materials.visible = true
            o.drawMaterials(false)
            o.materials.generateSvg()
            this.visibility = true
        } else {
            this.object = undefined
        }
    }

    closeOk() {
        this.onOkDialog()
    }

    closeCancel() {
        this.onCancelDialog()
    }

    stepInUnits(stepMetric: number, stepFeet: number): number {
        if (this.conversionService.isImperial(this.view.cfg.params.sizeUnit)) {
            return stepFeet
        }
        return stepMetric
    }

    private _updateRaisedHeight() {
        const o = this.object
        if (o && o.materials) {
            if (o.materials.containsRaisedBar()) {
                if (!this.raisedHeightEnabled) {
                    this.raisedHeight = this.conversionService.convert(
                        o.materials.raisedHeight || 5, {
                            from: Unit.CM,
                            to: this.view.cfg.params.sizeUnit,
                            rules: this.conversionService.rulesForObstacleHeight,
                            skipSuffix: true,
                        }
                    ) || 5
                }
                this.raisedHeightEnabled = true
            } else {
                if (this.raisedHeightEnabled && this.raisedHeight > 0) {
                    o.materials.raisedHeight = this.conversionService.convert(
                        this.raisedHeight, {
                        from: this.view.cfg.params.sizeUnit,
                        to: Unit.CM,
                        rules: this.conversionService.fullPrecisionNoRounding,
                        skipSuffix: true,
                    }) || 0
                }
                this.raisedHeight = 0
                this.raisedHeightEnabled = false
            }
        }
    }
}