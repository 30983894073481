import * as B from '@babylonjs/core'
import { RenderedObject } from './parkour-renderer.object'
import { DesignBaseObject, DesignSchema, DesignSvgImageObject } from '../design.schema'
import { MaterialManager } from './parkour-renderer.materials'

export class RenderedGlbObject extends RenderedObject {
    promise: Promise<B.Mesh>
    private _mesh?: B.Mesh

    private _replacePbrMaterials(mesh: B.Mesh) {
        const pbrMaterials: B.PBRMaterial[] = []
        mesh.getChildMeshes().forEach((m, i) => {
            if (i === 1) {
                m.dispose()
            } else {
                // convert material from PBR to standard, otherwise the time to add mesh is very long
                if (m.material && m.material instanceof B.PBRMaterial) {
                    const pbrMat = m.material as B.PBRMaterial
                    m.material = this.materialManager.getMaterial(pbrMat.name + "_converted", (name: string) => {
                        const m = new B.StandardMaterial(name, this.scene)
                        if (pbrMat.albedoTexture) {
                            m.diffuseTexture = pbrMat.albedoTexture
                            m.diffuseTexture.level = 3.0
                        }        
                        m.diffuseColor = pbrMat.albedoColor.toLinearSpace()
                        m.emissiveColor = pbrMat.emissiveColor
                        m.specularColor = new B.Color3(1, 1, 1)
                        return m
                    })
                    pbrMaterials.push(pbrMat)
                }
            }
        })
    }

    constructor(
        name: string,
        protected scene: B.Scene, 
        protected materialManager: MaterialManager,
        object: DesignBaseObject, 
        design: DesignSchema, 
        protected shadowGenerator: B.ShadowGenerator)
    {
        super(scene, object, design)

        this.promise = materialManager.getMesh(name, () => {
            return B.LoadAssetContainerAsync(name, scene).then(asset => {
                const mesh = asset.createRootMesh()
                this._replacePbrMaterials(mesh)
                return mesh
            })
        }).then(mesh => {
            const pos = new B.Vector3(this.x, 0, this.y)
            this._mesh = mesh.clone()
            this.scene.addMesh(this._mesh, true)
            this._mesh.rotate(new B.Vector3(0, 1, 0), this.angle)
            this.shadowGenerator.addShadowCaster(this._mesh)
            return this._mesh
        })
    }
}
