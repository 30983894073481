import { Routes } from '@angular/router';

import { authGuard, subscriptionGuard } from './auth.guard'
import { PageNotFoundComponent } from './shared/components';
import { MainPageComponent } from './main-page/main-page.component';
import { DetailComponent } from './detail/detail.component';
import { UserProfileComponent } from './user-profile/user-profile.component'
import { DesignsLibraryPageComponent } from './designs-library-page/designs-library-page.component'
import { AdminHomeComponent } from './admin-home/admin-home.component';
import { DesignRenderComponent } from './design-render/design-render.component'

export const appRoutes: Routes = [
    {
        path: '',
        component: MainPageComponent,
        pathMatch: 'full',
        canActivate: [subscriptionGuard],
    },
    {
        path: 'admin-home',
        component: AdminHomeComponent,
        canActivate: [authGuard],
    },
    {
        path: 'designer',
        component: DetailComponent,
        canActivate: [authGuard],
    },
    {
        path: 'user',
        component: UserProfileComponent,
        canActivate: [authGuard],
    },
    {
        path: 'user-plan',
        component: UserProfileComponent,
        canActivate: [authGuard],
    },
    {
        path: 'user-delete',
        component: UserProfileComponent,
        canActivate: [authGuard],
    },
    {
        path: 'designs-library',
        component: DesignsLibraryPageComponent,
        // designs library is public
        canActivate: [subscriptionGuard],
    },
    {
        path: 'design-render',
        component: DesignRenderComponent,
    },
    {
        path: '**',
        component: PageNotFoundComponent
        //redirectTo: 'detail',
    }
];
