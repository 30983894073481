import { Injectable } from '@angular/core'

import { Analytics, logEvent } from '@angular/fire/analytics'
import {
    Auth,
    AuthProvider,
    authState,
    createUserWithEmailAndPassword,
    FacebookAuthProvider,
    getAdditionalUserInfo,
    GoogleAuthProvider,
    OAuthProvider,
    sendPasswordResetEmail,
    signInWithEmailAndPassword,
    signInWithPopup,
    signOut,
    TwitterAuthProvider,
    User
} from '@angular/fire/auth'
import { BehaviorSubject, defer, from, Observable } from 'rxjs'
import { map } from 'rxjs/operators'

// Highlight for session recording
import * as Sentry from "@sentry/angular"
import posthog from 'posthog-js'

import { getBuildDate, getBuildVersion, isDeepEqual, isProduction } from '../utils'
import { BrevoService } from './brevo.service'

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    public readonly user: BehaviorSubject<User | null>
    public readonly remoteUser: Observable<User | null>
    public concluded: boolean = false
    private _user: any = null
    private _userRecord: User | null = null
    public signUpSession?: boolean

    constructor(
        private auth: Auth,
        private analytics: Analytics,
        private brevoService: BrevoService,
    ) {
        this.auth.useDeviceLanguage()

        // expose user to subsribers including local user
        this.user = new BehaviorSubject<any>(null)

        // first get user from local storage if user was persisted
        const localUserStr = localStorage.getItem('parkour-user')
        let localUserData = null
        if (localUserStr) {
            localUserData = JSON.parse(localUserStr)
            if (localUserData) {
                this._storeUserData(null, localUserData)
            }
        }

        // then get user from remote auth service
        this.remoteUser = authState(this.auth)
        this.remoteUser.subscribe({
            next: (u: User | null) => {
                this._storeUserData(u, null)
            },
            error: (err) => {
                console.error('error occured', err)
            }
        })
    }

    private _storeUserData(user: User | null, userData: any | null) {
        this.concluded = true
        this._userRecord = user
        if (user) {
            const u: any = user.toJSON()
            userData = {}
            for (let k of ["uid", "email", "displayName", "photoURL", "createdAt", "lastLoginAt"]) {
                userData[k] = u[k]
            }
        }
        if (isDeepEqual(userData, this._user)) {
            return
        }
        this._user = userData
        localStorage.setItem('parkour-user', JSON.stringify(userData))
        this.user.next(userData)

        this.brevoService.updateUserData(this._user)
        if (this._user) {
            this.startSessionRecording(this._user)
            if (isProduction() && user) {
                Sentry.setUser({ email: user?.email || '' });
            }
        }
    }

    handlePostSignUp(email?: string) {
        logEvent(this.analytics, 'sign_up')
        this.brevoService.sendEvent('sign-up', { email: this._user?.email || email })
        posthog.capture('sign-up')
        this.signUpSession = true
    }

    handlePostSignIn(result: any, email?: string) {
        const info = getAdditionalUserInfo(result)
        if (!email) {
            email = this._user?.email || info?.profile?.email
        }
        if (info?.isNewUser) {
            this.handlePostSignUp(email)
            this.signUpSession = true
        } else {
            logEvent(this.analytics, 'login')
            posthog.capture('sign-in')
            this.signUpSession = false
        }
        if (email) {
            this.brevoService.sendEvent('sign-in', { email: email })
        }
        return result
    }

    login(provider: string, email: string, password: string) {
        let providerInstance: AuthProvider
        if (provider === 'google') {
            providerInstance = new GoogleAuthProvider()
        } else if (provider === 'facebook') {
            providerInstance = new FacebookAuthProvider()
        } else if (provider === 'twitter') {
            providerInstance = new TwitterAuthProvider()
        } else if (provider === 'microsoft') {
            providerInstance = new OAuthProvider('microsoft.com')
        } else if (provider === 'email') {
            return defer(() => from(signInWithEmailAndPassword(this.auth, email, password)).pipe(
                map(result => this.handlePostSignIn(result, email))))
        } else {
            console.error('unsupported provider', provider)
            throw new Error('unsupported provider')
        }
        return defer(() => from(signInWithPopup(this.auth, providerInstance)).pipe(
            map(result => this.handlePostSignIn(result, email))))
    }

    async logout() {
        localStorage.setItem('parkour-user', JSON.stringify(null))
        this._user = undefined
        this.signUpSession = undefined
        return await signOut(this.auth);
    }

    signUp(email: string, password: string) {
        return defer(() => from(createUserWithEmailAndPassword(this.auth, email, password))).pipe(
            map(result => {
                this._storeUserData(result.user, null)
                this.handlePostSignUp()
                return result
            }))
    }

    isLogged(): boolean {
        return this._user
    }

    getUserDisplayName(): string {
        if (this._user) {
            if (this._user.displayName) {
                return this._user.displayName
            }
            if (this._user.email) {
                return this._user.email.split('@')[0]
            }
        }
        return $localize`nieznany`
    }

    sendResetPasswdEmail(email: string) {
        const actionCodeSettings = {
            url: window.location.origin
        };
        return defer(() => from(sendPasswordResetEmail(this.auth, email, actionCodeSettings)))
    }

    startSessionRecording(user: any) {
        if (!user) {
            return
        }
        if (!isProduction()) {
            return
        }

        // posthog for session recording instead of highlight.io
        posthog.identify(
            user.email,  // Replace 'distinct_id' with your user's unique identifier
            { email: user.email } // optional: set additional user properties
        )
        posthog.register({
            build_ver: getBuildVersion() + ' ' + getBuildDate(),
        })
    }

    deleteUserAccount() {
        if (this._userRecord) {
            const p = this._userRecord.delete()
            return defer(() => from(p))
        }
        return null
    }

    getUserToken() {
        if (!this.auth.currentUser) {
            return
        }
        const p = this.auth.currentUser.getIdToken(/* forceRefresh */ true)
        return defer(() => from(p))
    }
}

declare global {
    interface Window {
        sessionRewind: any;
    }
}
