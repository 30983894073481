import * as B from '@babylonjs/core'
import { RenderedObject } from './parkour-renderer.object'
import { DesignBaseObject, DesignSchema, DesignSvgImageObject } from '../design.schema'
import { MaterialManager } from './parkour-renderer.materials'

//
// File taken from https://github.com/BabylonJS/Extensions/blob/master/TreeGenerators/SPSTreeGenerator/TreeGenerator.js
//

export type TreeParams = {
    trunkHeight: number,
    trunkTaper: number,
    trunkSlices: number,
    boughs: number,
    forks: number,
    forkAngle: number,
    forkRatio: number,
    branches: number,
    branchAngle: number,
    bowFreq: number,
    bowHeight: number,
    leavesOnBranch: number,
    leafWHRatio: number,
}

type Coords = {
    x: B.Vector3
    y: B.Vector3
    z: B.Vector3
}

type Branch = {
    branch: B.Mesh,
    core: B.Vector3[],
    _radii: number[],
}

type TreeBase = {
    tree: B.Nullable<B.Mesh>,
    paths: B.Vector3[][],
    radii: number[][],
    directions: Coords[],
}

export class RenderedTree extends RenderedObject {
    private mesh: B.Mesh | undefined

    // creates an x, y, z coordinate system using the parameter vec3 for the y axis
    private _coordSystem(vec3: B.Vector3): Coords {
        const y = vec3.normalize()
        let x
        if(Math.abs(vec3.x) === 0 && Math.abs(vec3.y) === 0) {
            x = new B.Vector3(vec3.z, 0, 0).normalize()
        } else {
            x = new B.Vector3(vec3.y, -vec3.x, 0).normalize()
        }
        const z = B.Vector3.Cross(x, y)
        return { x: x, y: y, z: z }
    }

    // randomize a value v +/- p*100% of v
    private _randPct(v: number, p: number) {
        if (p === 0) {
            return v
        }
        
        return (1 + (1 - 2 * Math.random()) * p) * v
    }

    /*
    * Creates a single branch of the tree starting from branchAt (Vector3) using the coordinate system branchSys.
    * The branch is in the direction of branchSys.y with the cross section of the branch in the branchSys.x and branchSys.z plane.
    * The branch starts with radius branchRadius and tapers to branchRadius*branchTaper over its length branchLength.
    * The parammeter branchSlices gives the number of cross sectional slices that the branch is divided into
    * The number of bows (bends) in the branch is given by bowFreq and the height of the bow by bowHeight
    */
    private _createBranch(
        branchAt: B.Vector3, branchSys: Coords, branchLength: number, 
        branchTaper: number, branchSlices: number, bowFreq: number, bowHeight: number, 
        branchRadius: number, scene: B.Scene): Branch
    {
        const cross_section_paths: B.Vector3[][] = [] // array of paths, each path running along an outer length of the branch
        const core_path = [] // array of Vector3 points that give the path of the central core of the branch
        const radii = [] // array of radii for each core point
        const a_sides = 12 // number of sides for each cross sectional polygon slice
        for (let a = 0; a < a_sides; a++) {
            cross_section_paths[a] = []
        }	
        
        for (let d = 0; d < branchSlices; d++) {
            const d_slices_length = d / branchSlices
            const core_point = branchSys.y.scale(d_slices_length * branchLength) //central point along core path
            // add damped wave along branch to give bows
            core_point.addInPlace(branchSys.x.scale(bowHeight*Math.exp(-d_slices_length) * Math.sin(bowFreq * d_slices_length * Math.PI)))
            // set core point start at spur position.
            core_point.addInPlace(branchAt)
            core_path[d] = core_point

            // randomize radius by +/- 40% of radius and taper to branchTaper*100% of radius at top	
            const xsr = branchRadius*(1 + (0.4 * Math.random() - 0.2)) * (1 - (1 - branchTaper) * d_slices_length)
            radii.push(xsr)
        
            // determine the paths for each vertex of the cross sectional polygons
            for(let a = 0; a < a_sides; a++) {
                const theta = a * Math.PI / 6
                // path followed by one point on cross section in branchSys.y direction
                const path = branchSys.x.scale(xsr * Math.cos(theta)).add(branchSys.z.scale(xsr * Math.sin(theta)))
                // align with core
                path.addInPlace(core_path[d])
                cross_section_paths[a].push(path)
            }
        
        }

        // Add cap at end of branch.
        for (let a = 0; a < a_sides; a++) {
            cross_section_paths[a].push(core_path[core_path.length - 1])
        } 
        
        // Create ribbon mesh to repreent the branch.
        const branch = B.MeshBuilder.CreateRibbon("branch", {
            pathArray: cross_section_paths,
            closeArray:true
        }, scene)

        return { branch: branch, core: core_path, _radii: radii }
    } 

    /*
     * Creates a trunk and some branches. This is used as the base of the tree and as the base mesh for an SPS mesh to give additional branches. 
     * When boughs = 1 the trunk is created then branches are created at the top of the trunk, the number of branches being given by forks.
     * When boughs = 2 the trunk and branches are created as for boughs = 1, then further branches are created at the end of each of these branches.
     * The parameter forkRatio gives the fraction of branch length as branches are added to branches.
     * The angle of a new branch to the one it is growing from is given by forkAngle. 
     */
    private _createTreeBase(
        trunkHeight: number, trunkTaper: number, trunkSlices: number, 
        boughs: number, forks: number, forkAngle: number, forkRatio: number,
        bowFreq: number, bowHeight: number, scene: B.Scene): TreeBase
    {   
        const phi = 2 / (1 + Math.sqrt(5)) // golden ratio for scale	
        const trunk_direction = new B.Vector3(0, 1, 0)   //trunk starts in y direction

        const trunk_sys: Coords = this._coordSystem(trunk_direction)
        const trunk_root_at = new B.Vector3(0, 0, 0)
        const tree_branches: B.Mesh[] = []  // Array holding the mesh of each branch
        const tree_paths: B.Vector3[][] = []  // Array holding the central core points for each branch created
        const tree_radii: number[][] = [] // Array holding the branch radius for each brnch created
        const tree_directions: Coords[] = [] // Array holding the branch direction for each branch created
        
        const trunk = this._createBranch(trunk_root_at, trunk_sys, trunkHeight, trunkTaper, trunkSlices, 1, bowHeight, 1, scene) //create trunk
        tree_branches.push(trunk.branch)
        const core_path: B.Vector3[] = trunk.core
        tree_paths.push(core_path)
        tree_radii.push(trunk._radii)
        tree_directions.push(trunk_sys)
        
        const core_top = core_path.length - 1
        const top_point = core_path[core_top]

        const fork_turn = 2 * Math.PI / forks //angle of spread of forks around a branch
        
        // create new branch at top of trunk for number of forks
        for (let f = 0; f < forks; f++) {       
            const turn = this._randPct(f * fork_turn, 0.25) // randomise angle of spread for a fork	
            const fork_branch_direction = trunk_sys.y.scale(Math.cos(this._randPct(forkAngle, 0.15))).add(
                trunk_sys.x.scale(Math.sin(this._randPct(forkAngle, 0.15)) * Math.sin(turn))).add(
                trunk_sys.z.scale(Math.sin(this._randPct(forkAngle, 0.15)) * Math.cos(turn)))

            const fork_branchSys = this._coordSystem(fork_branch_direction)
            const branch = this._createBranch(top_point, fork_branchSys, trunkHeight*forkRatio, trunkTaper, trunkSlices,
                bowFreq, bowHeight*phi, trunkTaper, scene)
            const bough_core_path = branch.core
            const bough_top = bough_core_path[bough_core_path.length - 1]
        
            // store branch details
            tree_branches.push(branch.branch)
            tree_paths.push(branch.core)
            tree_radii.push(branch._radii)
            tree_directions.push(fork_branchSys)
            
            // When boughs = 2 create further branches at end of new branch
            if (boughs > 1) {
                for(let k = 0; k < forks; k++) {
                    const bough_turn = this._randPct(k*fork_turn, 0.25)
                    const bough_direction = fork_branchSys.y.scale(Math.cos(this._randPct(forkAngle, 0.15))).add(
                        fork_branchSys.x.scale(Math.sin(this._randPct(forkAngle, 0.15)) * Math.sin(bough_turn))).add(
                        fork_branchSys.z.scale(Math.sin(this._randPct(forkAngle, 0.15)) * Math.cos(bough_turn)))

                    const bough_sys = this._coordSystem(bough_direction)
                    const bough = this._createBranch(bough_top, bough_sys, trunkHeight * forkRatio * forkRatio,
                        trunkTaper, trunkSlices, bowFreq, bowHeight * phi * phi, 
                        trunkTaper * trunkTaper, scene)
                    
                    // store branch details
                    tree_branches.push(bough.branch)
                    tree_paths.push(bough.core)
                    tree_radii.push(branch._radii)
                    tree_directions.push(bough_sys)
                }
            }
        }
        const tree = B.Mesh.MergeMeshes(tree_branches) // merge branch meshes into a single mesh
        return { tree: tree, paths: tree_paths, radii: tree_radii, directions: tree_directions }
    }

    /* 
     * Primary function that creates the tree.
     *
     * A base tree is created consisting of a trunk which forks into branches, which then themselves may fork or not.
     * This base tree is used in two different ways. 
     *    1. as the trunk and parent branches for the whole tree.
     *    2. with leaves added as a mini-tree that is added a number of times to the base trunk and parent branches to form the whole tree.
     *
     * @Param trunkHeight - height of trunk of tree.
     * @Param trunkTaper - 0< trunkTaper <1 - fraction of starting radius for the end radius of a branch. 
     * @Param trunkSlices - the number of points on the paths used for the ribbon mesh that forms the branch.
     * @Param boughs - 1 or 2 only - the number of times the tree will split into forked branches, 1 trunk splits into branches, 2 these branches also spilt into branches.
     * @Param forks - 5 or more really slows the generation. The number of branches a branch can split into.
     * @Param forkAngle - the angle a forked branch makes with its parent branch measured from the direction of the branch.
     * @Param forkRatio - 0 < forkRatio < 1 - the ratio of the length of a branch to its parent's length.
     * @Param branches - the number of mini-trees that are randomally added to the tree..
     * @Param branchAngle - the angle the mini-tree makes with its parent branch from the direction of the branch.
     * @Param bowFreq - the number of bows (bends) in a branch. A trunk is set to have only one bow.
     * @Param bowHeight - the height of a bow from the line of direction of the branch.
     * @Param leavesOnBranch - the number of leaves to be added to one side of a branch.
     * @Param leafWHRatio - 0 <  leafWHRatio  < 1, closer to 0 longer leaf, closer to 1 more circular.
     * @Param scene - B scene.
     */
    private _createTree(p: TreeParams): B.Mesh | undefined
    {
        const scene = this.scene
        const greenMaterial = this.materialManager.getMaterial('tree-green', (name: string) => {
            const m = new B.StandardMaterial(name, scene)
            m.diffuseColor = new B.Color3(0, 0.8 ,0)
            return m
        })
            
        const texture = this.materialManager.getTexture('tree-bark', () => {
            const t = new B.Texture('assets/textures/bark.jpg', scene)
            t.uScale = 3
            t.vScale = 3
            return t
        })

        const barkMaterial = this.materialManager.getMaterial('tree-bark', (name: string) => {
            const m = new B.StandardMaterial(name, scene)
            m.diffuseTexture = texture
            return m
        })
    
        const boughs = (!(p.boughs === 1 || p.boughs === 2)) ? 1 : p.boughs

        // create base tree  
        const base = this._createTreeBase(p.trunkHeight, p.trunkTaper, p.trunkSlices, boughs, p.forks, 
            p.forkAngle, p.forkRatio, p.bowFreq, p.bowHeight, scene)
        if (!base.tree) {
            return
        }
        base.tree.material = barkMaterial
         
        // create one leaf
        const branch_length = p.trunkHeight * Math.pow(p.forkRatio, boughs)
        const leaf_gap = branch_length/(2 * p.leavesOnBranch)
        const leaf_width = 1.5 * Math.pow(p.trunkTaper, boughs - 1)
        const leaf = B.MeshBuilder.CreateDisc("leaf", { radius: leaf_width / 2, tessellation: 12, sideOrientation: B.Mesh.DOUBLESIDE }, scene )
        
        // create solid particle system for leaves 
        const leaves_SPS = new B.SolidParticleSystem("leaveSPS", scene, { updatable: false })
        
        // function to position leaves on base tree
        const set_leaves = (particle: any, i: number, s: number) => {
            let a = Math.floor(s / (2 * p.leavesOnBranch))
            if (boughs === 1) {
                a++
            } else {
                a = 2 + a % p.forks + Math.floor(a / p.forks)*(p.forks + 1)
            }
            const j = s % (2 * p.leavesOnBranch)
            const g = (j * leaf_gap + 3 * leaf_gap / 2) / branch_length
            
            let upper = Math.ceil(p.trunkSlices * g)
            if(upper > base.paths[a].length - 1) {
                upper = base.paths[a].length - 1
            }
            const lower = upper - 1
            const gl = lower / (p.trunkSlices - 1)
            const gu = upper / (p.trunkSlices - 1)
            const px = base.paths[a][lower].x + (base.paths[a][upper].x - base.paths[a][lower].x) * (g - gl) / (gu - gl)
            const py = base.paths[a][lower].y + (base.paths[a][upper].y - base.paths[a][lower].y) * (g - gl) / (gu - gl)
            const pz = base.paths[a][lower].z + (base.paths[a][upper].z - base.paths[a][lower].z) * (g - gl) / (gu - gl)             
            particle.position = new B.Vector3(px, py + (0.6 * leaf_width / p.leafWHRatio + base.radii[a][upper]) * (2 * (s % 2) - 1), pz) 
            particle.rotation.z = Math.random()*Math.PI / 4
            particle.rotation.y = Math.random()*Math.PI / 2
            particle.rotation.z = Math.random()*Math.PI / 4
            particle.scale.y = 1 / p.leafWHRatio
        }
        
        // add leaf mesh _leaf enough for all the final forked branches
        leaves_SPS.addShape(leaf, 2 * p.leavesOnBranch*Math.pow(p.forks, boughs), { positionFunction: set_leaves })
        const leaves: B.Mesh = leaves_SPS.buildMesh() // mesh of leaves
        leaves.billboardMode = B.Mesh.BILLBOARDMODE_ALL
        leaf.dispose()
        
        // create SPS to use with base tree mesh base.tree
        const mini_trees_SPS = new B.SolidParticleSystem("miniSPS", scene, { updatable: false })
        
        // create SPS to use with leaves mesh
        const mini_leaves_SPS = new B.SolidParticleSystem("minileavesSPS", scene, { updatable: false })
        
        //The mini base trees and leaves added to both the SPS systems have to be positioned at the same places and angles.
        //An array of random angles are formed to be used by both the mini base trees and the leaves
        //when they are added as forks at the end of the final branches. 
        const turns: number[] = []
        const fork_turn = 2 * Math.PI / p.forks
        for (let f = 0; f < Math.pow(p.forks, boughs + 1); f++) {
            turns.push(this._randPct(Math.floor(f / Math.pow(p.forks, boughs)) * fork_turn, 0.2))
        }

        // the _set_mini_trees function positions mini base trees and leaves at the end of base tree branches, one for each of the forks
        const set_mini_trees = (particle: any, i: number, s: number) => {  
            let a = s % Math.pow(p.forks, boughs)
            if (boughs === 1) {
                a++
            } else {
                a = 2 + a % p.forks + Math.floor(a / p.forks) * (p.forks + 1)
            }
            const mini_sys = base.directions[a]
            const mini_top = new B.Vector3(base.paths[a][base.paths[a].length - 1].x,
                base.paths[a][base.paths[a].length - 1].y,
                base.paths[a][base.paths[a].length - 1].z)
            const turn = turns[s]
            const mini_direction = mini_sys.y.scale(Math.cos(this._randPct(p.forkAngle, 0))).add(
                mini_sys.x.scale(Math.sin(this._randPct(p.forkAngle, 0)) * Math.sin(turn))).add(
                mini_sys.z.scale(Math.sin(this._randPct(p.forkAngle, 0)) * Math.cos(turn)))
            const axis  = B.Vector3.Cross(B.Axis.Y, mini_direction)
            const theta = Math.acos(B.Vector3.Dot(mini_direction, B.Axis.Y) / mini_direction.length())
            particle.scale = new B.Vector3(Math.pow(p.trunkTaper, boughs + 1), Math.pow(p.trunkTaper, boughs + 1), Math.pow(p.trunkTaper, boughs + 1))
            particle.quaternion = B.Quaternion.RotationAxis(axis, theta)
            particle.position = mini_top
        }
        
        // The mini base trees and leaves added to both the SPS systems have to be positioned at the same places and angles.
        // An array of random positions and angles are formed to be used by both the mini base trees and the leaves
        // when they are added as random mini leafed trees to the forked tree. 
        // The random positions are chosen by selecting one of the random paths for a branch and a random point along the branch.
        const bturns: number[] = []
        const places: number[][] = []
        const bplen = base.paths.length
        const bp0len = base.paths[0].length
        for (let b = 0; b < p.branches; b++) {
            bturns.push(2 * Math.PI * Math.random() - Math.PI)
            places.push([Math.floor(Math.random() * bplen), Math.floor(Math.random() * (bp0len - 1) + 1)])
        }
        
        // the _set_branches function positions mini base trees and leaves at random positions along random branches
        const set_branches = (particle: any, i: number, s: number) => {   
            const a = places[s][0]
            const b = places[s][1]        
            const mini_sys = base.directions[a]    
            const mini_place = new B.Vector3(base.paths[a][b].x, base.paths[a][b].y, base.paths[a][b].z)
            mini_place.addInPlace(mini_sys.z.scale(base.radii[a][b] / 2))
            const turn = bturns[s]       
            const mini_direction = mini_sys.y.scale(Math.cos(this._randPct(p.branchAngle, 0))).add(
                mini_sys.x.scale(Math.sin(this._randPct(p.branchAngle, 0)) * Math.sin(turn))).add(
                mini_sys.z.scale(Math.sin(this._randPct(p.branchAngle, 0)) * Math.cos(turn)))
            const axis  = B.Vector3.Cross(B.Axis.Y, mini_direction)
            const theta = Math.acos(B.Vector3.Dot(mini_direction, B.Axis.Y) / mini_direction.length())
            particle.scale = new B.Vector3(Math.pow(p.trunkTaper, boughs + 1), Math.pow(p.trunkTaper, boughs + 1), Math.pow(p.trunkTaper, boughs + 1))
            particle.quaternion = B.Quaternion.RotationAxis(axis, theta)
            particle.position = mini_place
        }
        
        // add base tree mesh enough for all the final forked branches  
        mini_trees_SPS.addShape(base.tree, Math.pow(p.forks, boughs + 1), { positionFunction: set_mini_trees })
        
        // add base tree mesh given the number of branches in that parameter. 
        mini_trees_SPS.addShape(base.tree, p.branches, { positionFunction: set_branches })
        const tree_crown = mini_trees_SPS.buildMesh() // form mesh with all mini trees
        tree_crown.material = barkMaterial
        
        // add leaves mesh enough for all the final forked branches  
        mini_leaves_SPS.addShape(leaves, Math.pow(p.forks, boughs + 1), { positionFunction: set_mini_trees })
        
        // add leaves mesh given the number of branches in that parameter. 
        mini_leaves_SPS.addShape(leaves, p.branches, { positionFunction: set_branches })
        const leaves_crown = mini_leaves_SPS.buildMesh() // form mesh of all leaves
        leaves.dispose()
        leaves_crown.material = greenMaterial
        
        //Give the three mesh elements of full tree the same parent.
        const root = B.MeshBuilder.CreateBox("", {}, scene)
        root.isVisible = false;
        base.tree.parent = root
        tree_crown.parent = root
        leaves_crown.parent = root
        return root        
    }

    constructor(
        protected scene: B.Scene,
        private materialManager: MaterialManager,
        object: DesignBaseObject,
        design: DesignSchema,
        shadowGenerator: B.ShadowGenerator,
        params: TreeParams)
    {        
        super(scene, object, design)
        this.mesh = this._createTree(params)
        if (this.mesh) {
            const img = object as DesignSvgImageObject
            let size
            if (img.imageSize) {
                size = Math.max(img.imageSize[0], img.imageSize[1])
            } else {
                size = 854
            }
            const scale = size * 0.18 / 854
            this.mesh.scaling = new B.Vector3(scale, scale, scale)
            this.mesh.position = new B.Vector3(this.x, 0, this.y)
            shadowGenerator.addShadowCaster(this.mesh)
        }
    }
}
