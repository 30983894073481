import * as B from '@babylonjs/core'
import { DesignBaseObject, DesignSchema, DesignSvgImageObject } from '../design.schema'
import { MaterialManager } from './parkour-renderer.materials'
import { RenderedGlbObject } from './parkour-renderer-glb'



export class RenderedCar extends RenderedGlbObject {


    constructor(
        protected scene: B.Scene, 
        materialManager: MaterialManager,
        object: DesignBaseObject, 
        design: DesignSchema, 
        protected shadowGenerator: B.ShadowGenerator)
    {
        super('assets/objects/car-opt.glb', scene, materialManager, object, design, shadowGenerator)

        this.promise.then(mesh => {
            const img = object as DesignSvgImageObject
            let size
            if (img.imageSize) {
                size = Math.max(img.imageSize[0], img.imageSize[1])
            } else {
                size = 530
            }
            const s = 2.3 * size / 100000
            const scale = new B.Vector3(s, s, s)
            const pos = new B.Vector3(this.x, 0, this.y)
            mesh.scaling = scale
            mesh.position = pos.add(new B.Vector3(0, 0, 0))   

            mesh.getChildMeshes().forEach(c => {
                c.checkCollisions = true 
                const m = c.material
                if (m && m instanceof B.StandardMaterial && m.name.startsWith('Body')) {
                    const mat = materialManager.getMaterial('car-mat', (name) => {
                        const nm = new B.StandardMaterial(name, scene)
                        nm.roughness = 0.1
                        nm.diffuseColor = new B.Color3(84/295, 160/295, 233/295)
                        nm.specularColor = new B.Color3(0.3, 0.3, 0.3)
                        nm.ambientColor = B.Color3.Black()
                        nm.emissiveColor = B.Color3.Black()
                        return nm
                    })
                    c.material = mat
                }
            })

        })        
    }
}