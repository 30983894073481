import { Injectable } from '@angular/core';

import { BehaviorSubject, map } from 'rxjs'

import { StorageService } from '../services/storage.service';

@Injectable({
    providedIn: 'root'
})
export class UserImagesService {

    private imagesList: any[] = []
    private images = new BehaviorSubject<any[]>([])

    constructor(
        public store: StorageService,
    ) {
        this._loadImages()
    }

    _loadImages() {
        this.store.listUserImages().subscribe({
            next: (resp) => {
                this.imagesList = resp
                this.imagesList.sort((a, b) => a.id.localeCompare(b.id))
                this.images.next(this.imagesList)
            },
            error: (err) => {
                console.error('unexpected error', err)
            }
        })
    }

    getImages() {
        return this.images
    }

    uploadImage(imageId: string, imageFormat: string, file: any) {
        return this.store.saveUserImage(imageId, imageFormat, file, null, null).pipe(
            map(resp => {
                // add image id to this.images
                this.imagesList.push({ id: imageId, url: resp[1] })
                this.imagesList.sort((a, b) => a.id.localeCompare(b.id))
                this.images.next(this.imagesList)
            }))

    }

    deleteImage(imageId: string) {
        return this.store.deleteUserImage(imageId).pipe(
            map(resp => {
                for (let idx = 0; idx < this.imagesList.length; idx++) {
                    if (this.imagesList[idx].id == imageId) {
                        this.imagesList.splice(idx, 1)
                        this.images.next(this.imagesList)
                    }
                }
            }))
    }

    restoreImage(imageId: string) {
        return this.store.restoreUserImage(imageId).pipe(
            map(() => {
                this._loadImages()
            }))
    }
}
