<p-toast position="top-right" key="whats-new" [baseZIndex]="5000">
    <ng-template let-message pTemplate="message">
        <div class="flex flex-column align-items-start" >
            <div class="flex flex-row align-items-center justify-content-start font-bold pb-3">
                <i class="p-toast-message-icon pi pi-info-circle pr-3"></i>
                <span>{{ newVersionText }}</span>
            </div>
            <div class="pb-1" pTooltip="Otworzy się nowa zakładka przeglądarki" i18n-pTooltip tooltipPosition="left">
                <a [href]="linkWhatsNew" target="_blank" i18n>Kliknij aby przeczytać co nowego.</a>
            </div>
        </div>
    </ng-template>
</p-toast>

<p-toast position="top-right" key="new-user" [baseZIndex]="5000">
    <ng-template let-message pTemplate="message">
        <div class="flex flex-column align-items-start" >
            <div class="flex flex-row align-items-center justify-content-start font-bold pb-3">
                <i class="p-toast-message-icon pi pi-info-circle pr-3"></i>
                <span i18n>Witaj, nowy użytkowniku!</span>
            </div>
            <div class="pb-1" pTooltip="Otworzy się nowa zakładka przeglądarki" i18n-pTooltip tooltipPosition="left">
                <a [href]="linkGuides" target="_blank" i18n>Kliknij, aby zobaczyć jak stworzyć Twój pierwszy projekt.</a>
            </div>
        </div>
    </ng-template>
</p-toast>

<p-dialog transitionOptions="0s" [(visible)]="designPreviewVisible" [closable]="false"
    position="center" [style]="{ 'max-width': '50vw' }">
    <img class="big-preview-png" *ngIf="designPreviewUrl" [src]="designPreviewUrl" width="100%" height="100%"/>
</p-dialog>

<p-dialog [(visible)]="keyShortcutsDlgVisible" [style]="{'min-width': '40rem', 'max-width': '75rem','width': 'fit-content'}">
    <ng-template pTemplate="header">
        <span class="p-dialog-title"><i class="fa-regular fa-keyboard mr-2"></i> <span i18n>Skróty klawiszowe</span></span>
    </ng-template>
    <div>
        <ng-container *ngFor="let gr of uiCommands.shortcuts.shortcuts">
            <div class="grid align-items-center m-0" style="border-bottom: 1px solid #ddd;">
                <div class="col-8 md:col-8 py-2 px-0 m-0 font-bold">
                    {{ gr.label }}
                </div>
            </div>
            <ng-container *ngFor="let ks of gr.shortcuts">
                <div class="grid align-items-center m-0" style="border-bottom: 1px solid #ddd;" *ngIf="ks.descr">
                    <div class="col-8 md:col-8 py-2 px-3 m-0">
                        {{ ks.descr }}
                    </div>
                    <div class="col-2 md:col-1 py-2 px-0 m-0 text-right"
                        [innerHTML]="ks.label | safe:'html'">
                    </div>
                    <div class="col-1 md:col-1"></div>
                    <input *ngIf="keyShortcutsEdit" placeholder="Zmień" i18n-placeholder class="col-2 md:col-2 p-1 m-0" type="text"
                        pInputText [disabled]="ks.ro" (keydown)="uiCommands.shortcuts.setKey(ks, $event)"/>
                </div>
            </ng-container>
        </ng-container>
    </div>
    <ng-template pTemplate="footer">
        <div *ngIf="keyShortcutsEdit">
            <div class="flex justify-content-end">
                <p-button icon="pi pi-times" (click)="uiCommands.shortcuts.restore(); keyShortcutsEdit = false"
                    label="Anuluj" i18n-label severity="danger"></p-button>
                <p-button icon="pi pi-check" (click)="saveUserData(); keyShortcutsEdit = false"
                    label="Zapisz" i18n-label></p-button>
            </div>
        </div>
        <div *ngIf="!keyShortcutsEdit">
            <div class="flex justify-content-between">
                <div class="flex justify-content-between">
                    <p-button icon="pi pi-user-edit" (click)="uiCommands.shortcuts.save(); keyShortcutsEdit = true"
                        label="Zmień skróty" i18n-label></p-button>
                    <p-button icon="pi pi-refresh" (click)="uiCommands.shortcuts.restoreDefault(); saveUserData();"
                        label="Przywróć domyślne" i18n-label></p-button>
                </div>
                <p-button icon="pi pi-check" (click)="keyShortcutsDlgVisible = false"
                    label="Zamknij" i18n-label></p-button>
            </div>
        </div>
    </ng-template>
</p-dialog>

<app-parkour-field-size #fieldSizeDialog [view]="this" (onOk)="onFieldSizeOk()"></app-parkour-field-size>
<app-parkour-display-options #displayOptionsDialog [view]="this" (onOk)="onDisplayOptionsOk()"></app-parkour-display-options>
<app-parkour-competition-params #paramsDialog [view]="this" (onOk)="onCompetitionParamsOk()"></app-parkour-competition-params>
<app-parkour-debug-options #debugOptionsDialog [view]="this" (onOk)="onDebugOptionsOk()"></app-parkour-debug-options>
<app-parkour-design-stats #designStatsDialog [view]="this"></app-parkour-design-stats>
<app-parkour-materials #materialsDialog [view]="this" (onOk)="onMaterialsDialogOk()" (onDelete)="onMaterialsDialogDelete()"></app-parkour-materials>
<app-parkour-validation #validationDialog
    [validator]="this.validator" [callbackMode]="validationCallback !== undefined"
    (onOk)="onValidationOk()" (onCancel)="onValidationCancel()" (onSelected)="onValidationSelected($event)">
</app-parkour-validation>
<app-parkour-3d-render-dialog #renderDialog [view]="this" [canvas]="this.canvas" [cfg]="this.cfg"></app-parkour-3d-render-dialog>

<p-dialog [(visible)]="printDlgVisible" [style]="{'min-width': '55rem', 'max-width': '55rem'}">
    <ng-template pTemplate="header">
        <span class="p-dialog-title"><i class="pi pi-print mr-2"></i> <span i18n>Wybór stron PDF</span></span>
    </ng-template>
    <p-treeTable styleClass="p-treetable-sm p-treetable-gridlines" [value]="pdfOptions" [scrollable]="false" [rowHover]="true">
        <ng-template pTemplate="header">
            <tr>
                <th i18n>Strony i ich opcje</th>
                <th i18n>Opis</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowNode let-rowData="rowData">
            <tr [ttRow]="rowNode">
                <td [pTooltip]="rowData.tooltip" tooltipPosition="top">
                    <div class="flex align-items-center">
                        <p-treeTableToggler [rowNode]="rowNode" />
                        <p-checkbox [inputId]="rowData.id" class="pr-3" [(ngModel)]="rowData.checked" [binary]="true" [disabled]="rowData.disabled"></p-checkbox>
                        <label [for]="rowData.id">{{ rowData.name }}</label>
                    </div>
                </td>
                <td>
                    {{ rowData.description }}
                </td>
            </tr>
        </ng-template>
    </p-treeTable>
    <ng-template pTemplate="footer">
        <div class="flex justify-content-between">
            <p-button icon="fa-regular fa-user" (click)="savePdfOptions()" label="Ustaw jako domyślne" i18n-label></p-button>
            <div class="flex justify-content-end">
                <p-button icon="pi pi-times" (click)="printDlgVisible = false"
                        label="Anuluj" i18n-label severity="danger"></p-button>
                <p-button icon="pi pi-check" (click)="printDlgVisible = false; generatePDF()"
                        [label]="getPDFDialogOperation()"></p-button>
            </div>
        </div>
    </ng-template>
</p-dialog>

<p-dialog [(visible)]="resolutionWarningDlgVisible" position="topleft" [style]="{'min-width': '20rem', 'width': '20rem'}">
    <ng-template pTemplate="header">
        <span class="p-dialog-title"><i class="pi pi-info-circle mr-2"></i> <span i18n>Porada</span></span>
    </ng-template>
    <div class="ml-3 mt-1">
        <div class="mb-2" i18n>Aplikacja działa optymalnie na laptopach lub komputerach typu desktop.</div>
        <div i18n>Minimalna zalecana rozdzielczość ekranu to 1366x768.</div>
    </div>
    <ng-template pTemplate="footer">
        <div class="flex justify-content-end">
            <p-button icon="pi pi-check" (click)="resolutionWarningDlgVisible = false"
                      label="OK" i18n-label></p-button>
        </div>
    </ng-template>
</p-dialog>


<p-blockUI [blocked]="inProgress" [baseZIndex]="10000">
    <div class="text-4xl text-white" i18n>Operacja w trakcie...</div>
</p-blockUI>

<p-blockUI [blocked]="blockUI" [baseZIndex]="20000">
    <div class="flex flex-column justify-content-center">
    <div class="text-3xl text-white text-center" i18n>Sieć rozłączona, zaczekaj na wznowienie połączenia.</div>
    <div><br/></div>
    <div class="text-xl text-white text-center" i18n>Twój plan cenowy pozwala na pracę przy aktywnym połączeniu do Internetu.</div>
    <div><br/></div>
    <div class="text-xl text-white text-center" i18n>Po przywróceniu połączenia możesz zmienić plan cenowy w menu Pomoc.</div>
</div>
</p-blockUI>

<!-- top bar -->
<div class="bg-indigo-400 text-white p-2 flex justify-content-between header-1-height">
    <div id="top-menu-bar" class="flex gap-1 align-items-center">
        <app-left-top-bar></app-left-top-bar>
        <ng-container *ngFor="let m of uiCommands.menus">
            <p-menu #menu [model]="m.contextItems?.() || m.items" [popup]="true" styleClass="w-21rem"></p-menu>
            <p-button
                [text]="true"
                (click)="menu.toggle($event)">
                <i [class]="m.icon"></i>
                <span class="px-2">{{ m.label }}</span>
                <i class="pi pi-chevron-down"></i>
            </p-button>
        </ng-container>
    </div>
    <app-right-top-bar></app-right-top-bar>
</div>

<!-- top toolbar -->
<div class="flex align-items-center justify-content-between px-1 border-100 border-solid border-1 header-2-height">
    <div #toolbar id="toolbar" style="width: 100%;" class="flex flex-wrap align-items-center gap-1">

        <ng-container *ngFor="let group of groupedToggleItems">
            <span class="icon-toolbar border-1 border-primary border-round border-dotted py-1 pl-1 pr-0">
            <ng-container *ngFor="let item of group">
                <button pButton type="button" class="icon-small-button mr-1" [icon]="item.icon"
                    [class.p-button-outlined]="!item.onOffState"
                    (click)="item.command($event)"
                    [pTooltip]="item.tooltip" tooltipPosition="top">
                </button>
            </ng-container>
            </span>
            <div class="p-toolbar-separator icon-toolbar mr-1"></div>
        </ng-container>

        <span class="icon-toolbar border-none py-1 pl-1 pr-0">
            <ng-container *ngFor="let item of individualToggleItems">
                <button pButton type="button" class="icon-small-button mr-1" [icon]="item.icon"
                    [class.p-button-outlined]="!item.onOffState"
                    (click)="item.command($event)"
                    [pTooltip]="item.tooltip" tooltipPosition="top">
                </button>
            </ng-container>
        </span>

        <span class="icon-toolbar mr-1 flex">
            <ng-container *ngFor="let item of objectButtons">
                <p-button *ngIf="item && item.icon && item.command" type="button" class="icon-button mr-1"
                    [icon]="item.icon" (click)="item.command($event)"
                    [disabled]="item.disabled"
                    [pTooltip]="item.buttonTip" tooltipPosition="top">
                </p-button>
            </ng-container>
        </span>

        <div class="p-toolbar-separator icon-toolbar mr-1"></div>

        <span class="icon-toolbar mr-1 flex">
            <ng-container *ngFor="let item of largeButtons">
                <p-button *ngIf="item && item.icon && item.command" type="button" class="icon-button mr-1"
                    [disabled]="item.disabled" [icon]="item.icon" (click)="item.command($event)"
                    [pTooltip]="item.tooltip" tooltipPosition="top">
                </p-button>
            </ng-container>
        </span>

        <div class="p-toolbar-separator icon-toolbar mr-1"></div>

        <span class="icon-toolbar mr-1 flex">
            <ng-container *ngFor="let item of largeToggleButtons">
                <button *ngIf="item" pButton type="button" class="icon-button mr-1" [icon]="item.icon"
                    [class.p-button-outlined]="!item.onOffState"
                    (click)="item.command($event)"
                    [pTooltip]="item.tooltip" tooltipPosition="top">
                </button>
            </ng-container>
        </span>

        <div class="p-toolbar-separator icon-toolbar mr-1"></div>

        <span class="p-buttonset icon-toolbar mr-1 flex">
            <button pButton type="button" class="icon-button" icon="fa-solid fa-plug"
                [class.p-button-outlined]="cfg.params.editMode !== 0"
                (click)="togglePathEditMode(false)"
                pTooltip="Łączenie przeszkód liniami" i18n-pTooltip tooltipPosition="top">
            </button>
            <button pButton type="button" class="icon-button" icon="fa-solid fa-pencil"
                [class.p-button-outlined]="cfg.params.editMode !== 1"
                (click)="togglePathEditMode(true)"
                pTooltip="Łączenie przeszkód poprzez rysowanie ścieżki" i18n-pTooltip tooltipPosition="top">
            </button>
        </span>

        <div class="p-toolbar-separator icon-toolbar mr-1"></div>

        <span class="p-buttonset icon-toolbar flex">
            <button #zoomButtonDown pButton type="button" class="icon-button" icon="pi pi-search-minus" (click)="doZoomDelta(-5);zoomButtonDown.blur();">

            </button>
            <input #zoomSelect type="text" pInputText [ngModel]="canvasO.zoom" readonly
                style="width: 3rem; pointer-events: auto; user-select: none; border-radius: 0;" tabindex="-1" class="icon-toolbar p-2 text-center"
                (dblclick)="canvasO.setZoom(100);"
                (select)="zoomSelect.selectionStart = zoomSelect.selectionEnd" />
            <button #zoomButtonUp pButton type="button" class="icon-button" icon="pi pi-search-plus" (click)="doZoomDelta(5);zoomButtonUp.blur();">
            </button>
        </span>

        <div class="p-toolbar-separator icon-toolbar mr-1"></div>

        <div class="icon-toolbar hidden lg:flex flex-row align-items-center cursor-pointer" (click)="validateParkour(true)">
            <span class="p-2" i18n>Problemy:</span>
            <ng-container *ngIf="validator.validationWarningsCount[0] + validator.validationWarningsCount[1] > 0; else noProblemsAtAll">
                <i class="fa fa-exclamation-triangle text-red-500"></i>
                <span class="p-2">{{validator.validationWarningsCount[0] }}</span>
                <i class="fa fa-exclamation-triangle text-yellow-500"></i>
                <span class="p-2">{{ validator.validationWarningsCount[1] }}</span>
            </ng-container>
            <ng-template #noProblemsAtAll>
                <i class="pi pi-thumbs-up font-bold text-green-500"></i>
                <span class="p-2">OK</span>
            </ng-template>
        </div>

    </div>

    <div class="flex align-items-center justify-content-center" style="width:45px; height:45px">
        <p-progressSpinner *ngIf="inProgress" styleClass="w-2rem h-2rem" strokeWidth="5" animationDuration="2s"></p-progressSpinner>
    </div>

    <div class="hidden md:flex align-items-center justify-content-center cursor-pointer text-3xl font-medium text-indigo-400 md:h-full md:max-h-full"
         pTooltip="Kliknij aby poprosić o pomoc" i18n-pTooltip
         (click)="showSupportChat()">
        <img style="max-height: 38px;height: 38px;"
             src="assets/chat.png"/>
    </div>
</div>

<!--
  main working panel that contains:
    1. left: toolbox
    2. middle: canvas
    3. right: objects panel
-->
<div id="work-planel"
     class="flex flex-column md:flex-row align-items-stretch w-full border-1 surface-border content-2-height">

    <!-- 1. toolbox on the left -->
    <div id="toolbox"
         class="flex flex-row flex-wrap md:h-full align-items-start align-content-start">
        <div *ngFor="let o of objectFactory.getObjectTemplates()"
             id="tb-{{ o.kind }}"
             (click)="showDropWarning()"
             draggable="true"
             (dragstart)="dragObjectStart($event, o)"
             [pTooltip]="o.menuText || o.name"
             class="shadow-1 toolbox-item">
            <img *ngIf="!o.svg" class="p-1"
                 [src]="o.icon || o.url">
            <svg *ngIf="o.svg"
                 width="50px" height="50px" viewBox="-25 -25 50 50"
                 fill="none" xmlns="http://www.w3.org/2000/svg" [innerHTML]="o.svg"
                 (contextmenu)="$event.preventDefault()"></svg>
        </div>
    </div>

    <!-- 2. canvas in the middle -->
    <div class="flex-1">
        <div id="canvas-wrapper" #canvas2 class="flex relative"
            (waResizeObserver)="onCanvasParentResized($event)"
            (dragover)="allowDrop($event)" (drop)="objectDrop($event)"
            [ngStyle]="{ 'cursor': cursor }">

            <parkour-canvas #canvasO class="w-full" [cfg]="cfg" [view]="this"></parkour-canvas>

            <ng-container *ngIf="selection.selectedItems.length >= 1">
                <p-contextMenu styleClass="w-20rem"
                    [target]="canvas2" [model]="contextMenuItems" appendTo="body">
                    <ng-template pTemplate="item" let-item>
                        <div class="flex align-items-center" style="padding: 10.5px">
                            <span class="w-2rem text-center mr-2 " [class]="item.icon" [pTooltip]="item.tooltip" tooltipPosition="left"></span>
                            <div class="flex justify-content-between w-full" [innerHTML]="item.label">
                            </div>
                        </div>
                    </ng-template>
                </p-contextMenu>
            </ng-container>
        </div>
    </div>

    <!-- 3. objects panel on the right -->

    <!-- 3a collapsed right panel -->
    <div class="bg-indigo-100 p-2 font-bold flex flex-column align-items-center"
         style="font-size: 1.2em; color: #222;"
         *ngIf="obstaclesListCollapsed">
        <p-button
            icon="pi pi-angle-double-left"
            [outlined]="true"
            (click)="obstaclesListCollapsed = false"></p-button>
        <div class="mt-8 w-3rem" style="transform: rotate(-90deg);" i18n>Przeszkody</div>
    </div>

    <!-- 3b uncollapsed right panel -->
    <div class="flex md:flex-none flex-column md:h-full md:max-h-full w-full md:w-20rem lg:w-27rem align-items-stretch p-0"
         style="border-left: 1px solid #eee;"
         *ngIf="!obstaclesListCollapsed">

        <!-- 3b.1 list of tabs at the top -->
        <div class="flex justify-content-between" style="height: 45px; max-height: 45px">
            <p-tabMenu #objectTabMenu [model]="tabItems" [(activeItem)]="activeTab" class="w-full" (activeItemChange)="onTabMenuActiveItemChange()">
                <ng-template pTemplate="item" let-item>
                    <a class="flex align-items-center gap-2 p-menuitem-link" pRipple [pTooltip]="item.title">
                        <span class="p-menuitem-icon m-0" [class]="item.icon"></span>
                    </a>
                </ng-template>
            </p-tabMenu>
            <p-button
                icon="pi pi-angle-double-right"
                [outlined]="true"
                class="hidden md:block"
                (click)="obstaclesListCollapsed = true"></p-button>
        </div>

        <!-- 3b.2 tables panel (upper) and details panel (lower) -->
        <div style="height: calc(100% - 43px); max-height: calc(100% - 43px);">

            <!--upper part with tables containing obstacles, objects, groups, designs and images -->
            <div *ngIf="activeTab.id === 'obstacles' || activeTab.id === 'objects' || activeTab.id === 'groups' || activeTab.id === 'designs' || activeTab.id === 'images'"
                 [style.height]="(selection.focusItem && (activeTab.id === 'obstacles' || activeTab.id === 'objects' || activeTab.id === 'groups')) ? '48%' : '100%'"
                 [style.maxHeight]="(selection.focusItem && (activeTab.id === 'obstacles' || activeTab.id === 'objects' || activeTab.id === 'groups')) ? '48%' : '100%'"
                 style="border-bottom: 1px solid lightgray;">
                <p-table
                    #obstaclesTable
                    *ngIf="activeTab.id == 'obstacles'"
                    [value]="getObstaclesTabItems()"
                    styleClass="p-datatable-sm text-sm"
                    [scrollable]="true" scrollHeight="flex"
                    selectionMode="multiple" [(selection)]="selectedObstaclesInList" dataKey="obstacle.uuid"
                    (onRowSelect)="onRowChange($event, 0)"
                    (onRowUnselect)="onRowChange($event, 0)"
                    [metaKeySelection]="true">
                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td colspan="5" i18n>Nie ma jeszcze przeszkód.</td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="header">
                        <tr>
                            <th class="end-of-section"></th>
                            <th class="end-of-section" i18n>Typ</th>
                            <th class="hidden lg:table-cell end-of-section" i18n>Pozycja</th>
                            <th class="end-of-section"><span i18n>Od poprzedniej</span>&nbsp;/<br/><span i18n>od startu</span></th>
                            <th class="end-of-section" i18n>Wysokość</th>
                            <th class="px-0 end-of-section" style="width: 1rem;"></th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-o let-index="rowIndex">
                        <tr *ngIf="{ last: o.isSectionClosing() || o.isLastInSection() } as n" [pSelectableRow]="o" [ngStyle]="{ 'height': rowHeight + 'px', 'border-bottom-width': '10px' }">
                            <!-- typ -->
                            <td class="text-right" [class.end-of-section]="n.last">
                                <b>{{ !o.obstacle.isObstacle() ? '' : o.manualLabel || o.label }}</b>
                            </td>
                            <td [class.end-of-section]="n.last">
                                <b>{{ o.obstacle.getKindName() }}</b>
                                <br/><span class="text-indigo-600">{{ o.obstacle.name ? o.obstacle.name : '' }}</span>
                            </td>
                            <!-- Pozycja -->
                            <td class="hidden lg:table-cell" [class.end-of-section]="n.last">
                                {{ (o.obstacle.getPosition().x - canvasO.field.x) | parkunit: {
                                    from: 'cm', to: cfg.params.distanceUnit, skipSuffix: true,
                                    rules: conversionService.rulesForArenaPosition
                                } }},
                                {{ (o.obstacle.getPosition().y - canvasO.field.y) | parkunit: {
                                    from: 'cm', to: cfg.params.distanceUnit, skipSuffix: true,
                                    rules: conversionService.rulesForArenaPosition
                                 } }}
                            </td>
                            <!-- Odległość od poprzedniej / startu -->
                            <td [class.end-of-section]="n.last">
                                {{ o.distFromPrev | parkunit: {
                                    from: 'cm', to: cfg.params.distanceUnit,
                                    rules: conversionService.rulesForDistanceNarrow
                                } }} /
                                {{ o.distFromStart | parkunit: {
                                    from: 'cm', to: cfg.params.distanceUnit,
                                    rules: conversionService.rulesForDistanceNarrow
                                } }}
                            </td>
                            <!-- Wysokość -->
                            <td [class.end-of-section]="n.last">
                                <ng-container *ngIf="o.obstacle.obstacleHeight">
                                    {{ o.obstacle.obstacleHeight | parkunit: {
                                        from: 'cm', to: cfg.params.sizeUnit,
                                        rules: conversionService.rulesForObstacleHeight
                                    } }} 
                                </ng-container>
                            </td>
                            <!-- delete -->
                            <td class="px-0" [class.end-of-section]="n.last">
                                <p-button
                                    *ngIf="index > 0"
                                    icon="pi pi-trash"
                                    (click)="deleteNode(o)"
                                    pTooltip="Usuń przeszkodę z trasy" i18n-pTooltip
                                    severity="danger" [text]="true" size="small"></p-button>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>

                <!-- objects tab -->
                <p-table
                    #objectsTable
                    *ngIf="activeTab.id == 'objects'"
                    [value]="canvasO.getNonPathObjects()"
                    styleClass="p-datatable-sm text-sm"
                    [scrollable]="true" scrollHeight="flex"
                    selectionMode="multiple" [(selection)]="selectedObjectsInList" dataKey="uuid"
                    (onRowSelect)="onRowChange($event, 1)"
                    (onRowUnselect)="onRowChange($event, 1)"
                    [metaKeySelection]="true">
                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td colspan="2" i18n>Nie ma jeszcze obiektów.</td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="header">
                        <tr>
                            <th i18n>Typ</th>
                            <th i18n>Pozycja</th>
                            <th style="width: 1rem;" class="px-0"></th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-o let-index="rowIndex">
                        <tr [pSelectableRow]="o" [ngStyle]="{ 'height': rowHeight + 'px' }">
                            <!-- typ -->
                            <td>
                                <b>{{ o.getKindName() }}</b>
                                <br><span class="text-indigo-600">{{ o.name ? o.name : '' }}</span>
                            </td>
                            <!-- Pozycja -->
                            <td>
                                {{ o.getPositionInField().x | parkunit: {
                                    from: 'cm', to: cfg.params.distanceUnit, skipSuffix: true,
                                    rules: conversionService.rulesForArenaPosition
                                } }},
                                {{ o.getPositionInField().y | parkunit: {
                                    from: 'cm', to: cfg.params.distanceUnit, skipSuffix: true,
                                    rules: conversionService.rulesForArenaPosition
                                } }}
                            </td>
                            <!-- delete -->
                            <td class="px-0">
                                <p-button
                                    icon="pi pi-trash"
                                    (click)="deleteObstacle(o)"
                                    pTooltip="Usuń obiekt" i18n-pTooltip
                                    severity="danger" [text]="true" size="small"></p-button>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>

                <!-- groups tab -->
                <p-table
                    #objectsTable
                    *ngIf="activeTab.id == 'groups'"
                    [value]="canvasO.objectGroups"
                    styleClass="p-datatable-sm text-sm"
                    [scrollable]="true" scrollHeight="flex"
                    selectionMode="multiple" [(selection)]="selectedGroupsInList"
                    (onRowSelect)="onRowChange($event, 2)"
                    (onRowUnselect)="onRowChange($event, 2)"
                    [metaKeySelection]="true">
                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td colspan="2" i18n>Nie ma jeszcze grup.</td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="header">
                        <tr>
                            <th i18n>Nazwa</th>
                            <th i18n>Szereg</th>
                            <th i18n>Elementy</th>
                            <th style="width: 1rem;" class="px-0"></th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-o let-index="rowIndex">
                        <tr [pSelectableRow]="o" [ngStyle]="{ 'height': rowHeight + 'px' }">
                            <!-- Nazwa -->
                            <td>
                                <b>{{ o.name }}</b>
                            </td>
                            <td>
                                <i class="fa-solid fa-check fa-xl" *ngIf="o.combination.length > 0"></i>
                            </td>
                            <!-- Elementy -->
                            <td>
                                {{ o.children.length }}
                            </td>
                            <!-- delete -->
                            <td class="px-0">
                                <p-button
                                    icon="pi pi-trash"
                                    (click)="deleteObjects([o])"
                                    pTooltip="Usuń grupę" i18n-pTooltip
                                    severity="danger" [text]="true" size="small"></p-button>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>

                <!-- designs tab -->
                <div *ngIf="activeTab.id == 'designs'" class="h-full max-h-full">
                    <div class="px-1 flex justify-content-between align-items-center w-full">
                        <div style="width:65%">
                            <p-dropdown id="designs-filter"
                                styleClass="w-full"
                                [options]="myEventsWithAll"
                                optionLabel="eventName" optionValue="eventName"
                                [(ngModel)]="filteringEvent"
                                (onChange)="applyEventFilter()">
                            </p-dropdown>
                        </div>
                        <div class="flex justify-content-end pl-2" >
                            <p-button *ngFor="let s of compSorter.modes"
                                [icon]="s.icon" [rounded]="true" [text]="true" [pTooltip]="s.name" tooltipPosition="left"
                                (onClick)="designsSort(s)" [outlined]="s.active" [disabled]="s.disabled">
                            </p-button>
                        </div>
                    </div>
                    <p-table
                        #designsTable
                        [value]="allMyDesigns"
                        styleClass="p-datatable-sm text-sm"
                        [style]="{'height': 'calc(100% - 35px)'}"
                        [scrollable]="true" scrollHeight="flex"
                        selectionMode="single" [(selection)]="selectedDesign" dataKey="localId"
                        [filters]="designesTableInitFilter"
                        (onFilter)="onDesignsFilter($event)"
                        (onRowSelect)="selectDesign()">
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td colspan="2" i18n>Brak projektów.</td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <tr>
                                <th i18n>Podgląd</th>
                                <th i18n>Konkurs</th>
                                <th></th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-p let-index="rowIndex">
                            <tr [pSelectableRow]="p" [ngStyle]="{ 'height': rowHeight + 'px' }">
                                <td>
                                    <div class="preview-png shadow-2" style="max-width: fit-content"
                                        *ngIf="p.pngUrl != 'progress' && (p.pngUrl || p.png)"
                                        (mouseenter)="showDesignPreview(p)" (mouseleave)="hideDesignPreview()">
                                        <img [src]="p.png || p.pngUrl" style="width: 5rem" crossorigin="anonymous"
                                            (error)="onImageError(p)" (load)="onImageLoad($event, p)">
                                    </div>
                                    <div class="preview-png text-center text-400" style="max-width: 5rem" *ngIf="!p.pngUrl && !p.png" i18n>brak</div>
                                </td>
                                <td [pTooltip]="(p.eventDate | date:'yyyy-MM-dd') || undefined" tooltipPosition="left">
                                    <div *ngIf="p.eventName && p.eventName != filteringEvent" class="font-bold">
                                        {{ p.eventName }}
                                    </div>
                                    {{ p.title }}
                                </td>
                                <td class="px-0 w-6rem">
                                    <p-button
                                        icon="pi pi-copy"
                                        (click)="cloneDesign(p)"
                                        pTooltip="Zduplikuj projekt" tooltipPosition="left" i18n-pTooltip
                                        [text]="true"></p-button>
                                    <p-button
                                        icon="pi pi-trash"
                                        (click)="deleteDesign(p)"
                                        pTooltip="Usuń projekt" tooltipPosition="left" i18n-pTooltip
                                        severity="danger" [text]="true"></p-button>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>

                <!-- images tab -->
                <div *ngIf="activeTab.id == 'images'" class="h-full max-h-full">
                    <app-image-select-panel
                        [selectedObject]="selection.focusItem"
                        (selectedImage)="imageSelectFinish($event)"
                        (dragImageStarted)="dragObjectStart($event.event, $event.img)"></app-image-select-panel>
                </div>
            </div>
            <div>
        </div>

            <!-- lower part with selected object details -->
            <div style="height: 52%; max-height: 52%"
                 class="overflow-y-auto text-sm py-1 px-3"
                 *ngIf="(activeTab.id === 'obstacles' || activeTab.id === 'objects' || activeTab.id === 'groups') && $any(selection.focusItem) as o">

                <!-- ParkourObject/MidPoint panel -->
                <ng-container *ngIf="o && (o.kind || o.midpointobject || o.groupobject)">
                    <app-parkour-object-panel #objectPanel [obj]="o" [view]="this"></app-parkour-object-panel>
                </ng-container>
            </div>
        </div>
    </div>
</div>

<!--
    <div class="hidden md:block p-1 text-700 bg-indigo-50"><span i18n>Podpowiedź:</span>&nbsp;<span [innerHTML]="tipForUser"></span></div>
-->
